import {
  OrganizationSubjectAdminResponse,
  OrganizationSubjectPublicResponse,
} from "client/openapi";
import { TagColor } from "components/Tag";
import { TagList } from "components/Tag/TagList";
import pluralize from "util/pluralize";
import "./index.css";

export function ProfileSubjects({
  subjects,
}: {
  subjects:
    | OrganizationSubjectAdminResponse[]
    | OrganizationSubjectPublicResponse[];
}) {
  const decoratedSubjects = subjects.map(
    (
      s: OrganizationSubjectAdminResponse | OrganizationSubjectPublicResponse
    ) => {
      return s.name;
    }
  );

  return (
    <>
      {subjects.length > 0 && (
        <>
          <h2 className="profile_body_title profile_body_title_big">
            {pluralize("Subject", "Subjects", subjects.length)} of Focus
          </h2>

          {decoratedSubjects.length > 0 && (
            <div className="profile_subjects_group">
              <TagList list={decoratedSubjects} color={TagColor.GOLD} />
            </div>
          )}
        </>
      )}
    </>
  );
}
