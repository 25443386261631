import {
  Auth0AccountRole,
  MeetingAdminResponse,
  MeetingLimitedResponse,
} from "client/openapi";
import { Dialog, DialogTrigger } from "components/Dialog";
import MeetingDialog from "components/MeetingDialog";
import moment from "moment";
import { useState } from "react";
import { returnSubjectName } from "util/concatenateSubject";
import { getAttendees } from "util/getAttendees";

function generateColor(id: number): string {
  if (id % 5 === 0) {
    return "pink";
  } else if (id % 5 === 1) {
    return "yellow";
  } else if (id % 5 === 2) {
    return "purple";
  } else if (id % 5 === 3) {
    return "slate-blue";
  } else {
    return "blue";
  }
}

export default function Meeting({
  meeting,
  meetings,
  index,
  role,
  setEvents,
  showAllDetails = false,
}: {
  meeting: MeetingAdminResponse | MeetingLimitedResponse;
  meetings: MeetingAdminResponse[] | MeetingLimitedResponse[];
  index: number;
  role: Auth0AccountRole;
  setEvents: () => Promise<void>;
  showAllDetails?: boolean;
}) {
  const [updatedMeeting, setUpdatedMeeting] = useState<
    MeetingAdminResponse | MeetingLimitedResponse
  >(meeting);

  // Prevent the dialog from mounting prematurely
  const [open, setOpen] = useState(false);

  return (
    <Dialog key={index} open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <div
          className={`calendar--day-event calendar--day-event-${generateColor(
            updatedMeeting.session.id
          )} cursor-pointer`}
        >
          <b className="calendar--day-title">
            {updatedMeeting.name
              ? updatedMeeting.name
              : returnSubjectName(updatedMeeting.org_subject)}
          </b>

          <p className="calendar--day-time">
            {moment(updatedMeeting.start).format("h:mma")}-
            {moment(updatedMeeting.start)
              .add(updatedMeeting.duration, "minutes")
              .format("h:mma")}
          </p>

          {(updatedMeeting.duration >= 30 || showAllDetails) && (
            <p className="mb-1 leading-tight calendar--day-time">
              {getAttendees(role, updatedMeeting)}
            </p>
          )}

          {(updatedMeeting.duration >= 45 || showAllDetails) && (
            <p className="mb-1 leading-tight calendar--day-time">
              {updatedMeeting.location?.name || updatedMeeting.other_location}
            </p>
          )}
        </div>
      </DialogTrigger>

      {open && (
        <MeetingDialog
          role={role}
          event={updatedMeeting}
          setEvents={setEvents}
        />
      )}
    </Dialog>
  );
}
