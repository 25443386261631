import {
  DialogAction,
  DialogActions,
  DialogClose,
  DialogContent,
} from "components/Dialog";
import PaymentMethods from "./PaymentMethods";
import { Button, ButtonColor } from "components/Button";
import {
  Auth0AccountRole,
  ParentLimitedResponse,
  PaymentLinkUserType,
  PaymentsService,
  StudentLimitedResponse,
} from "client/openapi";
import { useContext, useState } from "react";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";
import Notifications from "util/notifications";

export default function PaymentInformationModal({
  studentIfInitiatedByAdmin,
  parentIfInitiatedByAdmin,
  initiatedByStudentOrParentDirectly,
}: {
  studentIfInitiatedByAdmin?: StudentLimitedResponse;
  parentIfInitiatedByAdmin?: ParentLimitedResponse;
  initiatedByStudentOrParentDirectly?: boolean;
}) {
  const { currently_selected_role, currently_selected_organization } =
    useContext(OrgRolesAndAccountContext);

  const [
    isRequestingPaymentMethodViaText,
    setIsRequestingPaymentMethodViaText,
  ] = useState(false);
  const [paymentMethodViaTextStatus, setPaymentMethodViaTextStatus] = useState<
    "pending" | "success" | "error" | null
  >(null);

  const [content, setContent] = useState<string>("");

  // New states for the checkboxes
  const [requestACH, setRequestACH] = useState<boolean>(true); // Banks checked by default
  const [requestCard, setRequestCard] = useState<boolean>(false); // Cards not checked by default

  const [sendSMS, setSendSMS] = useState<boolean>(true); // SMS checked by default
  const [sendEmail, setSendEmail] = useState<boolean>(false); // Email not checked by default

  const sendPaymentMethodRequestViaText = () => {
    setPaymentMethodViaTextStatus("pending");

    PaymentsService.generatePaymentLink({
      orgId: currently_selected_organization as number,
      content: content,
      requestBody: {
        user_type: studentIfInitiatedByAdmin
          ? PaymentLinkUserType.STUDENT
          : PaymentLinkUserType.PARENT,
        student_id: studentIfInitiatedByAdmin?.id || null,
        parent_id: parentIfInitiatedByAdmin?.id || null,
        request_ach: requestACH,
        request_card: requestCard,
        send_request_via_text: sendSMS,
        send_request_via_email: sendEmail,
      },
    })
      .then((response) => {
        setPaymentMethodViaTextStatus("success");
        Notifications.success(sendSMS ? "Text sent!" : "Email sent!");
      })
      .catch((error) => {
        setPaymentMethodViaTextStatus("error");
        Notifications.error(
          "Could not send text! Does this person have a valid phone number on file?"
        );
      });
  };

  const reset = () => {
    setIsRequestingPaymentMethodViaText(false);
    setPaymentMethodViaTextStatus(null);
    setContent("");
    setRequestACH(true);
    setRequestCard(false);
  };

  // Condition to disable the send button if no payment method type is selected
  const canSend =
    paymentMethodViaTextStatus !== "pending" &&
    paymentMethodViaTextStatus !== "success" &&
    (requestACH || requestCard) &&
    (sendSMS || sendEmail);

  return (
    <DialogContent className="dialog-content max-w-[800px]" onClose={reset}>
      <div className="mt-3 font-semibold text-center header text-lg">
        Payment Information
      </div>

      <div className="border rounded-lg border-gray-400 px-6 py-4 mt-6">
        <PaymentMethods
          showAddCardButton
          showAction
          studentIfInitiatedByAdmin={studentIfInitiatedByAdmin}
          parentIfInitiatedByAdmin={parentIfInitiatedByAdmin}
          initiatedByStudentOrParentDirectly={
            initiatedByStudentOrParentDirectly
          }
        />

        {currently_selected_role === Auth0AccountRole.ORG_ADMIN && (
          <>
            <hr className="mt-6 mb-3 border-gray-300" />

            <div className="text-sm text-gray-500">
              Text + Email a request to{" "}
              {studentIfInitiatedByAdmin
                ? studentIfInitiatedByAdmin?.first_name
                : parentIfInitiatedByAdmin?.first_name}{" "}
              to add a payment method
            </div>

            {!isRequestingPaymentMethodViaText ? (
              <div className="mt-4">
                <Button
                  color={ButtonColor.PURPLE}
                  onClick={() => setIsRequestingPaymentMethodViaText(true)}
                >
                  Request Payment Method via Text/Email
                </Button>
              </div>
            ) : (
              <div className="mt-4 space-y-4">
                {/* Message Input */}
                <input
                  type="text"
                  className="w-full p-2 mb-2 rounded-lg border border-gray-300 bg-gray-50 focus:outline-none focus:border-purple-400 focus:ring-1 focus:ring-purple-400 text-sm"
                  placeholder="Enter a message (optional)..."
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                />

                {/* Payment Method Type Checkboxes */}
                <div className="flex space-x-4 items-center">
                  <label className="inline-flex items-center space-x-2 text-sm text-gray-700">
                    <input
                      type="checkbox"
                      checked={requestACH}
                      onChange={() => setRequestACH(!requestACH)}
                      className="h-4 w-4 text-purple-600 bg-gray-100 border-gray-300 rounded focus:ring-purple-500"
                    />
                    <span>Link bank (easy and recommended)</span>
                  </label>
                  <label className="inline-flex items-center space-x-2 text-sm text-gray-700">
                    <input
                      type="checkbox"
                      checked={requestCard}
                      onChange={() => setRequestCard(!requestCard)}
                      className="h-4 w-4 text-purple-600 bg-gray-100 border-gray-300 rounded focus:ring-purple-500"
                    />
                    <span>Link card</span>
                  </label>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "1rem",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <label style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      checked={sendSMS}
                      onChange={(e) => setSendSMS(e.target.checked)}
                      style={{
                        appearance: "none",
                        width: "18px",
                        height: "18px",
                        border: "2px solid #4ade80",
                        borderRadius: "4px",
                        backgroundColor: sendSMS ? "#4ade80" : "#fff",
                        cursor: "pointer",
                      }}
                    />
                    <span style={{ marginLeft: 8 }}>SMS</span>
                  </label>

                  <label style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      checked={sendEmail}
                      onChange={(e) => setSendEmail(e.target.checked)}
                      style={{
                        appearance: "none",
                        width: "18px",
                        height: "18px",
                        border: "2px solid #4ade80",
                        borderRadius: "4px",
                        backgroundColor: sendEmail ? "#4ade80" : "#fff",
                        cursor: "pointer",
                      }}
                    />
                    <span style={{ marginLeft: 8 }}>Email</span>
                  </label>
                </div>

                <Button
                  color={!canSend ? ButtonColor.GRAY : ButtonColor.GREEN}
                  disabled={!canSend}
                  onClick={() => sendPaymentMethodRequestViaText()}
                >
                  Send
                </Button>
              </div>
            )}
          </>
        )}
      </div>

      <DialogActions>
        <DialogClose asChild>
          <DialogAction color={ButtonColor.PURPLE}>Close</DialogAction>
        </DialogClose>
      </DialogActions>
    </DialogContent>
  );
}
