import {
  StudentLimitedResponse,
  ParentLimitedResponse,
  ParentsService,
} from "client/openapi";
import { useState, useEffect } from "react";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import { Select } from "components/Select";
import { concatenateName } from "util/concatenateName";
import Avatar from "components/Avatar";
import { generateInitials } from "util/generateInitials";
import Notifications from "util/notifications";

import { useNavigate } from "react-router-dom";

export default function Students({
  parent,
  orgStudents,
  parentStudents,
  setParentStudents,
}: {
  parent: ParentLimitedResponse;
  orgStudents: StudentLimitedResponse[];
  parentStudents: StudentLimitedResponse[];
  setParentStudents: (students: StudentLimitedResponse[]) => void;
}) {
  const [studentOptions, setStudentOptions] =
    useState<StudentLimitedResponse[]>(parentStudents);
  const [addingStudent, setAddingStudent] = useState<boolean>(false);

  const [deleteIntentKey, setDeleteIntentKey] = useState<number>();

  const navigate = useNavigate();

  async function handleAssignStudent(student: StudentLimitedResponse) {
    await ParentsService.addExistingStudentToParent({
      parentId: parent.id,
      studentId: student.id,
    })
      .then(() => {
        const updatedStudents = [...parentStudents, student];
        setParentStudents(updatedStudents);
        Notifications.success(
          `${student.first_name} successfully linked to ${parent.first_name}`
        );
      })
      .catch((err) => {
        Notifications.error(
          `Unable to link ${student.first_name} to ${parent.first_name}`
        );
        console.error(err);
      });
  }

  async function handleRemoveStudent(student: StudentLimitedResponse) {
    await ParentsService.removeExistingStudentFromParent({
      parentId: parent.id,
      studentId: student.id,
    })
      .then(() => {
        const updatedStudents = parentStudents.filter(
          (s) => s.id !== student.id
        );
        setParentStudents(updatedStudents);
        Notifications.success(
          `${student.first_name} is no longer linked to ${parent.first_name}`
        );
      })
      .catch((err) => {
        Notifications.error(
          `Unable to unlink ${student.first_name} from ${parent.first_name}`
        );
        console.error(err);
      });
  }

  useEffect(() => {
    const filteredStudents = orgStudents.filter(
      (s) => !parentStudents.some((t) => t.id === s.id)
    );
    setStudentOptions(filteredStudents);
  }, [orgStudents, parent.students, parentStudents]);

  return (
    <section className="parent-profile--section" id="parent-students">
      <div className="parent-profile--section-header">
        <h2>Students</h2>

        {addingStudent ? (
          <Select
            id="add-student"
            name="add-student"
            options={studentOptions}
            value={null}
            isDisabled={studentOptions?.length === 0}
            getOptionLabel={(a) => `${a ? concatenateName(a) : ""}`}
            getOptionValue={(a) => (a ? a.id.toString() : "")}
            onChange={(student: StudentLimitedResponse) =>
              handleAssignStudent(student)
            }
            className="w-36 h-9 text-sm"
          />
        ) : (
          <div className="flex flex-row gap-1.5 items-center">
            {studentOptions.length > 0 && (
              <Button
                color={ButtonColor.SKYBLUE}
                size={ButtonSize.SMALL}
                onClick={() => setAddingStudent(true)}
              >
                Assign Student
              </Button>
            )}
          </div>
        )}
      </div>

      <div className="parent-profile--section-items">
        {parentStudents.length > 0 ? (
          parentStudents.map((student, i) => (
            <div
              key={i}
              className="parent-profile--section-item parent-detail--student"
            >
              <Avatar
                alt={concatenateName(student)}
                fallback={generateInitials(concatenateName(student), 2)}
                color="skyblue"
              />

              <div className="parent-detail--student-details">
                <p>
                  {student.first_name} {student.last_name}
                </p>
                <div>
                  {deleteIntentKey === student.id ? (
                    <>
                      <span style={{ marginRight: "5px" }}>
                        <Button
                          onClick={() => {
                            handleRemoveStudent(student);
                            setDeleteIntentKey(-1);
                          }}
                          color={ButtonColor.RED}
                          size={ButtonSize.EXTRA_SMALL}
                        >
                          confirm removal
                        </Button>
                      </span>
                      <span>
                        <Button
                          onClick={() => setDeleteIntentKey(-1)}
                          color={ButtonColor.SKYBLUE}
                          size={ButtonSize.EXTRA_SMALL}
                        >
                          cancel
                        </Button>
                      </span>
                    </>
                  ) : (
                    <>
                      <span style={{ marginRight: "5px" }}>
                        <Button
                          color={ButtonColor.ORANGE}
                          size={ButtonSize.EXTRA_SMALL}
                          onClick={() => setDeleteIntentKey(student.id)}
                        >
                          remove
                        </Button>
                      </span>
                      <span>
                        <Button
                          onClick={() =>
                            navigate(`/manage/students/${student.id}`)
                          }
                          color={ButtonColor.SKYBLUE}
                          size={ButtonSize.EXTRA_SMALL}
                        >
                          view
                        </Button>
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="parent-profile--section-item">No students.</div>
        )}
      </div>
    </section>
  );
}
