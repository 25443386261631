import { useRef, useState } from "react";
import "./index.css";
import {
  OrganizationPublicResponse,
  OrganizationWebPageResponse,
} from "client/openapi";
import BookingInterface from "./BookingInterface";
import TutorsGrid from "components/TutorsGrid";
import SubjectsGrid from "components/SubjectsGrid";

export type WebPageType = "org" | "tutor";

// Simple helper to darken a hex color a bit
function darkenColor(hexColor: string, amount: number = 20): string {
  const r = Math.max(0, parseInt(hexColor.slice(1, 3), 16) - amount).toString(
    16
  );
  const g = Math.max(0, parseInt(hexColor.slice(3, 5), 16) - amount).toString(
    16
  );
  const b = Math.max(0, parseInt(hexColor.slice(5, 7), 16) - amount).toString(
    16
  );
  return `#${r.padStart(2, "0")}${g.padStart(2, "0")}${b.padStart(2, "0")}`;
}

// Helper to scroll a bit above the section so it’s not cut off
function scrollToSection(ref: React.RefObject<HTMLDivElement>) {
  if (ref.current) {
    // Adjust offset to match your fixed navbar height
    const offset = 100;
    const elementPosition = ref.current.offsetTop - offset;
    window.scrollTo({ top: elementPosition, behavior: "smooth" });
  }
}

export function OrganizationPageContent({
  orgWebPage,
  organization,
}: {
  orgWebPage: OrganizationWebPageResponse;
  organization: OrganizationPublicResponse;
}) {
  const [activeSection, setActiveSection] = useState<
    "quick" | "tutors" | "subjects"
  >("quick");

  const bookingRef = useRef<HTMLDivElement>(null);
  const tutorsRef = useRef<HTMLDivElement>(null);
  const subjectsRef = useRef<HTMLDivElement>(null);

  return (
    <>
      {/* Navbar */}
      <div className="navbar">
        {/* Left-aligned org logo */}
        <div className="nav-left">
          {orgWebPage.photo_url && (
            <img
              className="org-logo"
              src={orgWebPage.photo_url}
              alt={organization.name}
            />
          )}
        </div>

        {/* Centered toggle group */}
        <div className="nav-center">
          <div
            className="web-page-toggle-container"
            style={{
              backgroundColor: orgWebPage.secondary_color,
              borderColor: darkenColor(orgWebPage.secondary_color),
            }}
          >
            <button
              className={`web-page-toggle-button ${
                activeSection === "quick" ? "active" : ""
              }`}
              onClick={() => {
                setActiveSection("quick");
                scrollToSection(bookingRef);
              }}
            >
              Quick Book
            </button>
            <button
              className={`web-page-toggle-button ${
                activeSection === "tutors" ? "active" : ""
              }`}
              onClick={() => {
                setActiveSection("tutors");
                scrollToSection(tutorsRef);
              }}
            >
              See Tutors
            </button>
            <button
              className={`web-page-toggle-button ${
                activeSection === "subjects" ? "active" : ""
              }`}
              onClick={() => {
                setActiveSection("subjects");
                scrollToSection(subjectsRef);
              }}
            >
              Browse Subjects
            </button>
          </div>
        </div>
      </div>

      {/* Optional Bio */}
      {orgWebPage.bio && (
        <p
          className="org-bio mt-12"
          style={{
            color: orgWebPage.text_color,
          }}
        >
          {orgWebPage.bio}
        </p>
      )}

      <div className="profile_body mt-12">
        <hr className="section-divider" />
        {/* Booking Section */}
        <div className="container mx-auto" ref={bookingRef}>
          <BookingInterface
            availableSubjects={organization.subjects.filter((s) => s.active)}
            availableTutors={organization.tutors}
            orgId={organization.id}
          />
        </div>

        <hr className="section-divider" />

        {/* Tutors Section */}
        <div className="container mx-auto my-12" ref={tutorsRef}>
          <TutorsGrid
            tutors={organization.tutors}
            orgWebPage={orgWebPage}
            organization={organization}
          />
        </div>

        <hr className="section-divider" />

        {/* Subjects Section */}
        <div className="container mx-auto my-12" ref={subjectsRef}>
          <SubjectsGrid
            subjects={organization.subjects.filter((s) => s.active)}
            orgWebPage={orgWebPage}
          />
        </div>
      </div>

      {/* Bottom Navbar */}
      <div className="bottom-navbar">
        <div className="bottom-left">
          <strong>Contact</strong>
          <br />
          Phone Number: {organization.public_phone_number || "N/A"}
          <br />
          Email: {organization.public_email || "N/A"}
        </div>
        <div className="bottom-right">
          <strong>Address</strong>
          <br />
          {organization.address_line_1}
          {organization.address_line_2 && `, ${organization.address_line_2}`}
          <br />
          {organization.city}, {organization.state} {organization.zip}
        </div>
      </div>
    </>
  );
}
