import moment from "moment";
import {
  ApprovalStatus,
  Auth0AccountRole,
  MeetingLimitedResponse,
  MeetingsService,
} from "client/openapi";
import { useContext, useEffect, useState } from "react";
import { PageStatus } from "types";
import { GoCheckCircleFill, GoCircle, GoXCircleFill } from "react-icons/go";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";
import { getStudents, getTutors } from "util/getAttendees";
import { TutorContext } from "../TutorDashboard/tutorContext";
import { ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import { Dialog, DialogTrigger } from "components/Dialog";
import { Button } from "components/Button";
import MeetingDialog from "components/MeetingDialog";
import Notifications from "util/notifications";

function getWeekdayColor(weekday: number): string {
  const colors = [
    "text-red-600", // Sunday
    "text-blue-600", // Monday
    "text-green-600", // Tuesday
    "text-purple-600", // Wednesday
    "text-orange-600", // Thursday
    "text-pink-600", // Friday
    "text-yellow-600", // Saturday
  ];
  return colors[weekday];
}

export default function TutoringHours({
  startingMeetings,
  status,
  isAdmin,
}: {
  startingMeetings: MeetingLimitedResponse[];
  status: PageStatus;
  isAdmin: boolean;
}) {
  const { available_tutors } = useContext(TutorContext);
  const [updatedMeetings, setUpdatedMeetings] =
    useState<MeetingLimitedResponse[]>(startingMeetings);

  const { currently_selected_role } = useContext(OrgRolesAndAccountContext);

  // Track the currently "open" dialog's meeting ID
  const [openMeetingId, setOpenMeetingId] = useState<number | null>(null);

  useEffect(() => {
    setUpdatedMeetings(startingMeetings);
  }, [startingMeetings]);

  if (status === PageStatus.LOADING) {
    return <div>Loading...</div>;
  }

  if (!updatedMeetings || updatedMeetings.length === 0) {
    return <div>No meetings</div>;
  }

  /**
   * If you're an admin, you're interested in whether *all* tutors
   * have approved or if any have denied.
   */
  function getAllTutorsApprovalIcon(meeting: MeetingLimitedResponse) {
    const tutorApprovals = meeting.tutors.map(
      (t) => t.reviewed_and_approved_by_tutor
    );
    if (tutorApprovals.length === 0) return <GoCircle size={20} />;
    if (tutorApprovals.every((a) => a === ApprovalStatus.APPROVED)) {
      return <GoCheckCircleFill color="var(--green)" size={20} />;
    } else if (tutorApprovals.some((a) => a === ApprovalStatus.DENIED)) {
      return <GoXCircleFill color="var(--red)" size={20} />;
    } else {
      return <GoCircle size={20} />;
    }
  }

  /**
   * If you're a tutor, you only care about the admin's overall approval
   * (or denial).
   */
  function getAdminApprovalIcon(meeting: MeetingLimitedResponse) {
    if (meeting.reviewed_and_approved_by_admin === ApprovalStatus.APPROVED) {
      return <GoCheckCircleFill color="var(--green)" size={20} />;
    } else if (
      meeting.reviewed_and_approved_by_admin === ApprovalStatus.DENIED
    ) {
      return <GoXCircleFill color="var(--red)" size={20} />;
    } else {
      return <GoCircle size={20} />;
    }
  }

  /**
   * Helper to update a single meeting in local state.
   */
  function updateMeetingInState(updatedMeeting: MeetingLimitedResponse) {
    setUpdatedMeetings((prev) =>
      prev.map((m) => (m.id === updatedMeeting.id ? updatedMeeting : m))
    );
  }

  /**
   * Admin sets approval or denial for an entire meeting.
   */
  async function handleAdminRadioChange(
    meetingIndex: number,
    radioIndex: number
  ) {
    const meeting = updatedMeetings[meetingIndex];
    const updatedMeeting = {
      ...meeting,
      reviewed_and_approved_by_admin:
        radioIndex === 0 ? ApprovalStatus.APPROVED : ApprovalStatus.DENIED,
    };

    updateMeetingInState(updatedMeeting);

    MeetingsService.editMeeting({
      meetingId: meeting.id,
      requestBody: {
        reviewed_and_approved_by_admin:
          radioIndex === 0 ? ApprovalStatus.APPROVED : ApprovalStatus.DENIED,
      },
    })
      .then(() => {
        Notifications.success("Meeting updated!");
      })
      .catch((error) => {
        Notifications.error(error.message || "Error updating meeting");
      });
  }

  /**
   * Tutor sets their own approval or denial for this meeting.
   */
  async function handleTutorRadioChange(
    meetingIndex: number,
    radioIndex: number
  ) {
    const meeting = updatedMeetings[meetingIndex];
    const myTutor = meeting.tutors.find((mt) =>
      available_tutors?.some((at) => at.id === mt.tutor.id)
    );
    if (!myTutor) return;

    const updatedMeetingTutors = meeting.tutors.map((meeting_tutor) => {
      if (meeting_tutor.id === myTutor.id) {
        return {
          ...meeting_tutor,
          reviewed_and_approved_by_tutor:
            radioIndex === 0 ? ApprovalStatus.APPROVED : ApprovalStatus.DENIED,
        };
      }
      return meeting_tutor;
    });

    const updatedMeeting = { ...meeting, tutors: updatedMeetingTutors };
    updateMeetingInState(updatedMeeting);

    MeetingsService.editMeetingTutor({
      meetingTutorId: myTutor.id,
      requestBody: {
        reviewed_and_approved_by_tutor:
          radioIndex === 0 ? ApprovalStatus.APPROVED : ApprovalStatus.DENIED,
      },
    })
      .then(() => {
        Notifications.success("Meeting updated!");
      })
      .catch((error) => {
        Notifications.error(error.message || "Error updating meeting");
      });
  }

  /**
   * Renders table rows for each meeting; each row will have a button
   * that triggers the dialog for *that* meeting only.
   */
  return (
    <>
      <table className="w-full bg-white border-collapse">
        <thead className="sticky top-0 bg-white z-10 shadow-sm">
          <tr className="border-b-2 border-gray-300">
            <th className="text-left p-2 text-sm font-bold">Date</th>
            <th className="text-left p-2 text-sm font-bold">Activity</th>
            <th className="text-left p-2 text-sm font-bold">Start</th>
            <th className="text-left p-2 text-sm font-bold">End</th>
            <th className="text-left p-2 text-sm font-bold">Tutors</th>
            <th className="text-left p-2 text-sm font-bold">Students</th>
            <th className="text-center p-2 text-sm font-bold">Details</th>
            <th className="p-2 text-sm font-bold text-center">
              <div className="flex justify-center items-center">
                {isAdmin ? "Approved by All Tutors" : "Approved by Admin"}
              </div>
            </th>
            <th className="text-center p-2 text-sm font-bold">
              {isAdmin ? "Admin Approve" : "Tutor Approve"}
            </th>
            <th className="text-center p-2 text-sm font-bold">
              {isAdmin ? "Admin Deny" : "Tutor Deny"}
            </th>
          </tr>
        </thead>
        <tbody>
          {updatedMeetings.map((meeting, index) => {
            const startMoment = moment(meeting.start);
            const endMoment = startMoment
              .clone()
              .add(meeting.duration, "minutes");
            const weekdayColor = getWeekdayColor(startMoment.day());
            const statusIcon = isAdmin
              ? getAllTutorsApprovalIcon(meeting)
              : getAdminApprovalIcon(meeting);

            return (
              <tr
                key={meeting.id}
                className="border-b border-gray-200 hover:bg-gray-50"
              >
                <td className={`p-2 text-sm font-bold ${weekdayColor}`}>
                  {startMoment.format("ddd MMM Do")}
                </td>
                <td className="p-2 text-sm">
                  {meeting.name ?? meeting.org_subject.name}
                </td>
                <td className="p-2 text-sm">{startMoment.format("h:mma")}</td>
                <td className="p-2 text-sm">{endMoment.format("h:mma")}</td>
                <td className="p-2 text-sm">{getTutors(meeting)}</td>
                <td className="p-2 text-sm">{getStudents(meeting)}</td>

                {/* Instead of rendering <Dialog> here, just show a button that sets openMeetingId */}
                <td className="p-2 text-sm text-center">
                  <Button
                    color={ButtonColor.SKYBLUE}
                    size={ButtonSize.EXTRA_SMALL}
                    fill={ButtonFill.HOLLOW}
                    extraClasses="text-center"
                    onClick={() => setOpenMeetingId(meeting.id)}
                  >
                    View
                  </Button>
                </td>

                <td className="p-2 text-center">
                  <div className="flex justify-center items-center ml-4">
                    {statusIcon}
                  </div>
                </td>

                <td className="p-2 text-center">
                  <input
                    type="radio"
                    name={
                      isAdmin
                        ? `admin-approval-${meeting.id}`
                        : `tutor-approval-${meeting.id}`
                    }
                    checked={
                      isAdmin
                        ? meeting.reviewed_and_approved_by_admin ===
                          ApprovalStatus.APPROVED
                        : meeting.tutors.find((meeting_tutor) =>
                            available_tutors?.some(
                              (at) => at.id === meeting_tutor.tutor.id
                            )
                          )?.reviewed_and_approved_by_tutor ===
                          ApprovalStatus.APPROVED
                    }
                    onChange={() =>
                      isAdmin
                        ? handleAdminRadioChange(index, 0)
                        : handleTutorRadioChange(index, 0)
                    }
                    disabled={
                      [Auth0AccountRole.ME, Auth0AccountRole.PARENT].includes(
                        currently_selected_role as Auth0AccountRole
                      ) || meeting.locked
                    }
                  />
                </td>
                <td className="p-2 text-center">
                  <input
                    type="radio"
                    name={
                      isAdmin
                        ? `admin-approval-${meeting.id}`
                        : `tutor-approval-${meeting.id}`
                    }
                    checked={
                      isAdmin
                        ? meeting.reviewed_and_approved_by_admin ===
                          ApprovalStatus.DENIED
                        : meeting.tutors.find((meeting_tutor) =>
                            available_tutors?.some(
                              (at) => at.id === meeting_tutor.tutor.id
                            )
                          )?.reviewed_and_approved_by_tutor ===
                          ApprovalStatus.DENIED
                    }
                    onChange={() =>
                      isAdmin
                        ? handleAdminRadioChange(index, 1)
                        : handleTutorRadioChange(index, 1)
                    }
                    disabled={
                      [Auth0AccountRole.ME, Auth0AccountRole.PARENT].includes(
                        currently_selected_role as Auth0AccountRole
                      ) || meeting.locked
                    }
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* We render only ONE dialog overall, keyed by the openMeetingId. */}
      <Dialog
        open={!!openMeetingId}
        onOpenChange={(isOpen) => {
          // if user closes the dialog or clicks outside, reset openMeetingId
          if (!isOpen) setOpenMeetingId(null);
        }}
      >
        {/* If we have a meeting ID, find that meeting and show the MeetingDialog */}
        {openMeetingId && (
          <MeetingDialog
            isCreate={false}
            role={
              isAdmin ? Auth0AccountRole.ORG_ADMIN : Auth0AccountRole.ORG_TUTOR
            }
            event={updatedMeetings.find((m) => m.id === openMeetingId)!}
            setEvents={() => Promise.resolve()}
          />
        )}
      </Dialog>
    </>
  );
}
