import { Cross1Icon, PlusIcon } from "@radix-ui/react-icons";
import { AccountResponse, CalendarService } from "client/openapi";
import AddAvailability from "components/AddAvailability";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import ClearAvailability from "components/ClearAvailability";
import { Dialog, DialogTrigger } from "components/Dialog";
import { LoadingBlock } from "components/StatusBlock";
import moment from "moment-timezone";
import { Fragment, useContext, useEffect, useState } from "react";
import { PageStatus } from "types";
import parseAvailability from "util/parseAvailability";
import range from "util/range";
import Notifications from "util/notifications";
import "./index.css";
import GoogleCalendarDialog from "./syncExternalCalendars/googleCalendarDialog";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

export default function TutorAvailability() {
  const { account, updateOrgRolesAndAccount } = useContext(
    OrgRolesAndAccountContext
  );

  const [availability, setAvailability] = useState<boolean[]>([]);
  const [timezone, setTimezone] = useState(
    moment.tz.guess(true) || process.env.REACT_APP_DEFAULT_TIMEZONE || "UTC"
  );

  const weekStart = moment().tz(timezone).startOf("isoWeek");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      CalendarService.enableGoogleCalendar({ code: code })
        .then(() => {
          Notifications.success(`Google calendar enabled!`);
          updateOrgRolesAndAccount({
            account: {
              ...(account as AccountResponse),
              google_calendar_enabled: true,
            },
          });
        })
        .catch(() => {
          Notifications.error(`Could not enable google calendar at this time.`);
        });
    }
  }, []);

  useEffect(() => {
    if (account && account.availability) {
      const avails = parseAvailability(account.availability[0], timezone);
      setAvailability(avails);
    }
  }, [account, availability.length, timezone]);

  return (
    <div className="container mx-auto page--tutor-availability">
      {!account && <LoadingBlock status={PageStatus.LOADING} />}

      {account && (
        <>
          <div className="page--header">
            <h1 className="section--title">Set Weekly Availability</h1>
          </div>

          <div className="mb-4">
            <Dialog>
              <DialogTrigger asChild>
                <Button color={ButtonColor.PURPLE} size={ButtonSize.SMALL}>
                  Google Calendar
                </Button>
              </DialogTrigger>

              <GoogleCalendarDialog />
            </Dialog>
          </div>

          <div className="schedule">
            {range(7).map((d) => {
              const avails = availability?.filter((a, i) => {
                return i >= d * 96 && i < (d + 1) * 96;
              });

              return (
                <div className="schedule--day" key={d}>
                  <div className="date">
                    {moment(weekStart).add(d, "days").format("dddd")}

                    <div className="date--actions">
                      <Dialog>
                        <DialogTrigger asChild>
                          <Button
                            color={ButtonColor.RED}
                            fill={ButtonFill.HOLLOW}
                            extraClasses="button--manage-day"
                            aria-label={`Clear all availability for ${moment(
                              weekStart
                            )
                              .add(d, "days")
                              .format("dddd")}`}
                          >
                            <Cross1Icon />
                          </Button>
                        </DialogTrigger>

                        <ClearAvailability
                          day={moment(weekStart)
                            .add(d, "days")
                            .format("ddd")
                            .toLowerCase()}
                          timezone={timezone}
                          setAvailability={setAvailability}
                        />
                      </Dialog>

                      <Dialog>
                        <DialogTrigger asChild>
                          <Button
                            color={ButtonColor.ORANGE}
                            extraClasses="button--manage-day"
                            aria-label={`Add availability for ${moment(
                              weekStart
                            )
                              .add(d, "days")
                              .format("dddd")}`}
                          >
                            <PlusIcon />
                          </Button>
                        </DialogTrigger>

                        <AddAvailability
                          day={moment(weekStart)
                            .add(d, "days")
                            .format("ddd")
                            .toLowerCase()}
                          timezone={timezone}
                          setAvailability={setAvailability}
                        />
                      </Dialog>
                    </div>
                  </div>

                  <div className="availabilities">
                    {avails?.filter((a) => a).length === 0 ? (
                      <div className="availability--none">No availability</div>
                    ) : (
                      avails?.map((a, i) => (
                        <Fragment key={i}>
                          {a ? (
                            <div className="availability--free">
                              {moment(weekStart)
                                .add(d, "days")
                                .add(i * 15, "minutes")
                                .format("h:mma")}
                            </div>
                          ) : (
                            <div className="availability--busy" hidden>
                              {moment(weekStart)
                                .add(d, "days")
                                .add(i * 15, "minutes")
                                .format("h:mma")}
                            </div>
                          )}
                        </Fragment>
                      ))
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="timezone">Showing times in: {timezone}</div>
        </>
      )}
    </div>
  );
}
