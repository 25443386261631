import { TrashIcon } from "@radix-ui/react-icons";
import bankImage from "./bank.png";
import {
  ACHResponse,
  CreditCardResponse,
  PaymentsService,
} from "client/openapi";
import { APIResponse, PageStatus } from "types";
import { useEffect, useState } from "react";
import Notifications from "util/notifications";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import SuccessDialog from "components/SuccessDialog";
import ErrorDialog from "components/ErrorDialog";

export default function Banks({
  bank,
  showAction,
  selectable = false,
  handleSelectPaymentMethod,
}: {
  bank: ACHResponse;
  showAction?: boolean;
  selectable?: boolean;
  handleSelectPaymentMethod?: (
    method: CreditCardResponse | ACHResponse | string
  ) => void;
}) {
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<APIResponse>();
  const [deleteIntent, setDeleteIntent] = useState(false);

  const deleteBank = () => {
    setStatus(PageStatus.LOADING);
    PaymentsService.removeRegisteredPaymentMethodFromIndividual({
      paymentMethodId: bank.payment_method_id,
    })
      .then(() => {
        Notifications.success(
          "Payment method deleted. Exit and re-enter the pop-up to see your changes."
        );
        setStatus(PageStatus.SUCCESS);
      })
      .catch((error) => {
        setError(error);
        setStatus(PageStatus.ERROR);
        console.error(`Error (#${error.status}): ${error.message}`);
      });
  };

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  if (status === PageStatus.SUCCESS) {
    return <SuccessDialog message="Payment method deleted!" />;
  }

  if (status === PageStatus.ERROR) {
    return (
      <ErrorDialog message="Could not delete payment method at this time!" />
    );
  }

  return (
    <tr className="header text-sm align-top">
      <td className="flex justify-between gap-2 font-bold mr-2 items-center">
        <div className="flex gap-2 items-center mr-2">
          {selectable && handleSelectPaymentMethod && (
            <input
              type="radio"
              name="bill-to"
              onChange={() => handleSelectPaymentMethod(bank)}
            />
          )}
          <img src={bankImage} alt="bank" className="w-5" />
          <p className="text-nowrap whitespace-nowrap ">
            {bank.bank_name}
            <span className="text-gray-400"> ending with {bank.last_four}</span>
          </p>
        </div>
        {showAction && (
          <div style={{ paddingTop: "2px" }}>
            {deleteIntent ? (
              <Button
                onClick={deleteBank}
                color={ButtonColor.RED}
                size={ButtonSize.EXTRA_SMALL}
              >
                delete
              </Button>
            ) : (
              <Button
                color={ButtonColor.LIGHT_PURPLE}
                size={ButtonSize.EXTRA_SMALL}
                onClick={() => setDeleteIntent(true)}
              >
                <TrashIcon />
              </Button>
            )}
          </div>
        )}
      </td>
    </tr>
  );
}
