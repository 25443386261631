import { useAuth0 } from "@auth0/auth0-react";
import {
  ApiError,
  Auth0AccountRole,
  OrganizationNameResponse,
  OrganizationsService,
} from "client/openapi";
import OrganizationDashboard from "components/Dashboard/OrganizationDashboard";
import ParentDashboard from "components/Dashboard/ParentDashboard";
import { ParentProvider } from "components/Dashboard/ParentDashboard/parentContext";
import StudentDashboard from "components/Dashboard/StudentDashboard";
import { StudentProvider } from "components/Dashboard/StudentDashboard/studentContext";
import TutorDashboard from "components/Dashboard/TutorDashboard";
import { TutorProvider } from "components/Dashboard/TutorDashboard/tutorContext";
import { Select } from "components/Select";
import { ErrorBlock, LoadingBlock } from "components/StatusBlock";
import { useContext, useEffect, useState } from "react";
import { APIResponse, PageStatus } from "types";
import { findOrgIdsByRole } from "util/accounts";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

function Dashboard() {
  const { isLoading } = useAuth0();

  // Roles and orgs
  const {
    account,
    all_account_permissions,
    roles_available_to_account,
    currently_selected_role,
    currently_selected_organization,
    updateOrgRolesAndAccount,
  } = useContext(OrgRolesAndAccountContext);

  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING);
  const [error, setError] = useState<APIResponse>();

  const [
    organizations_available_as_student,
    set_organizations_available_as_student,
  ] = useState<number[]>([]);
  const [
    organizations_available_as_parent,
    set_organizations_available_as_parent,
  ] = useState<number[]>([]);
  const [
    organizations_available_as_tutor,
    set_organizations_available_as_tutor,
  ] = useState<number[]>([]);
  const [
    organizations_available_as_admin,
    set_organizations_available_as_admin,
  ] = useState<number[]>([]);

  const [
    names_of_orgs_user_is_an_admin_in,
    set_names_of_orgs_user_is_an_admin_in,
  ] = useState<OrganizationNameResponse[]>();

  // store dashboards
  const [dashboards, setDashboards] = useState<any[]>([]);
  const [currentDashboard, setCurrentDashboard] = useState<any>();

  // Set the organizations available to each of the user's roles.
  // For the user's admin dashboard(s), grab the names of the organizations
  // so the drop down can display options available to the user.
  useEffect(() => {
    if (all_account_permissions) {
      set_organizations_available_as_student(
        findOrgIdsByRole(all_account_permissions, Auth0AccountRole.ME)
      );

      set_organizations_available_as_parent(
        findOrgIdsByRole(all_account_permissions, Auth0AccountRole.PARENT)
      );

      set_organizations_available_as_tutor(
        findOrgIdsByRole(all_account_permissions, Auth0AccountRole.ORG_TUTOR)
      );

      const orgs_as_admin = findOrgIdsByRole(
        all_account_permissions,
        Auth0AccountRole.ORG_ADMIN
      );

      set_organizations_available_as_admin(orgs_as_admin);

      OrganizationsService.getOrganizationNames({
        requestBody: orgs_as_admin,
      })
        .then((orgs) => {
          set_names_of_orgs_user_is_an_admin_in(orgs);
        })
        .catch((e: ApiError) => {
          setStatus(PageStatus.ERROR);
          setError({ message: "Unable to fetch admin details" });
          console.error(`Error (#${e.status}): ${e.message}`);
        });
    }
  }, [all_account_permissions]);

  // handle dashboard switching
  useEffect(() => {
    let admin_dashboards: any[] = [];
    let tutor_dashboard: any[] = [];
    let student_dashboard: any[] = [];
    let parent_dashboard: any[] = [];

    if (
      roles_available_to_account?.includes(Auth0AccountRole.ORG_ADMIN) &&
      names_of_orgs_user_is_an_admin_in
    ) {
      names_of_orgs_user_is_an_admin_in.forEach((o) => {
        const label = `Admin: ${o.name}`;
        if (!admin_dashboards.some((dash) => dash.label === label)) {
          admin_dashboards.push({
            label: `Admin: ${o.name}`,
            value: o.id,
          });
        }
      });
    }

    if (
      roles_available_to_account?.includes(Auth0AccountRole.ORG_TUTOR) &&
      organizations_available_as_tutor
    ) {
      const tutorLabel = `${account?.first_name}'s Tutor Dashboard`;
      if (!tutor_dashboard.some((dash) => dash.label === tutorLabel)) {
        tutor_dashboard.push({
          label: `${account?.first_name}'s Tutor Dashboard`,
          value: "tutor",
        });
      }
    }

    if (
      roles_available_to_account?.includes(Auth0AccountRole.ME) &&
      organizations_available_as_student
    ) {
      const studentLabel = `${account?.first_name}'s Student Dashboard`;
      if (!student_dashboard.some((dash) => dash.label === studentLabel)) {
        student_dashboard.push({
          label: `${account?.first_name}'s Student Dashboard`,
          value: "student",
        });
      }
    }

    if (
      roles_available_to_account?.includes(Auth0AccountRole.PARENT) &&
      organizations_available_as_parent
    ) {
      const parentLabel = `${account?.first_name}'s Parent Dashboard`;
      if (!parent_dashboard.some((dash) => dash.label === parentLabel)) {
        parent_dashboard.push({
          label: `${account?.first_name}'s Parent Dashboard`,
          value: "parent",
        });
      }
    }

    let all_dashboards = [
      ...admin_dashboards,
      ...tutor_dashboard,
      ...student_dashboard,
      ...parent_dashboard,
    ];

    setDashboards(all_dashboards);

    if (currently_selected_role === Auth0AccountRole.ME) {
      setCurrentDashboard(
        all_dashboards.find((d) => d.label.includes("Student Dashboard"))
      );
    }

    if (currently_selected_role === Auth0AccountRole.PARENT) {
      setCurrentDashboard(
        all_dashboards.find((d) => d.label.includes("Parent Dashboard"))
      );
    }

    if (currently_selected_role === Auth0AccountRole.ORG_TUTOR) {
      setCurrentDashboard(
        all_dashboards.find((d) => d.label.includes("Tutor Dashboard"))
      );
    }

    if (currently_selected_role === Auth0AccountRole.ORG_ADMIN) {
      setCurrentDashboard(
        all_dashboards.find((d) => d.value === currently_selected_organization)
      );
    }
  }, [
    roles_available_to_account,
    organizations_available_as_admin,
    organizations_available_as_tutor,
    organizations_available_as_student,
    account?.first_name,
    currently_selected_role,
    names_of_orgs_user_is_an_admin_in,
    currently_selected_organization,
    organizations_available_as_parent,
  ]);

  async function handleDashboardChange(newDashboard) {
    if (newDashboard.label.includes("Student")) {
      updateOrgRolesAndAccount({
        currently_selected_role: Auth0AccountRole.ME,
        organizations_available_to_role: organizations_available_as_student,
        currently_selected_organization: organizations_available_as_student[0],
      });
    }

    if (newDashboard.label.includes("Parent")) {
      updateOrgRolesAndAccount({
        currently_selected_role: Auth0AccountRole.PARENT,
        organizations_available_to_role: organizations_available_as_parent,
        currently_selected_organization: organizations_available_as_parent[0],
      });
    }

    if (newDashboard.label.includes("Tutor")) {
      updateOrgRolesAndAccount({
        currently_selected_role: Auth0AccountRole.ORG_TUTOR,
        organizations_available_to_role: organizations_available_as_tutor,
        currently_selected_organization: organizations_available_as_tutor[0],
      });
    }

    if (newDashboard.label.includes("Admin")) {
      updateOrgRolesAndAccount({
        currently_selected_role: Auth0AccountRole.ORG_ADMIN,
        organizations_available_to_role: organizations_available_as_admin,
        currently_selected_organization: newDashboard.value,
      });
    }

    setCurrentDashboard(newDashboard);
  }

  return (
    <>
      {isLoading || status !== PageStatus.ERROR ? (
        <LoadingBlock
          status={
            dashboards.length > 0 ? PageStatus.SUCCESS : PageStatus.LOADING
          }
        />
      ) : null}

      {!isLoading ? (
        <ErrorBlock status={status} message={error?.message} />
      ) : null}

      {dashboards.length > 1 && (
        <div className="flex flex-col md:flex-row items-center gap-4 m-4">
          <label htmlFor="dashboard-selector--dropdown" className="input-label">
            Change Dashboard:
          </label>
          <div className="max-w-lg">
            <Select
              name="dashboard-selector--dropdown"
              value={currentDashboard}
              onChange={handleDashboardChange}
              isSearchable={false}
              options={dashboards}
            />
          </div>
        </div>
      )}

      {currently_selected_role === Auth0AccountRole.ME ? (
        <StudentProvider>
          <StudentDashboard />
        </StudentProvider>
      ) : null}

      {currently_selected_role === Auth0AccountRole.PARENT ? (
        <ParentProvider>
          <ParentDashboard />
        </ParentProvider>
      ) : null}

      {currently_selected_role === Auth0AccountRole.ORG_TUTOR ? (
        <TutorProvider>
          <TutorDashboard />
        </TutorProvider>
      ) : null}

      {currently_selected_role === Auth0AccountRole.ORG_ADMIN ? (
        <OrganizationDashboard />
      ) : null}
    </>
  );
}

export default Dashboard;
