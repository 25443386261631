import { useState, useMemo, useEffect } from "react";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import {
  OrganizationSubjectPublicResponse,
  OrganizationWebPageResponse,
} from "client/openapi";
import "./index.css";

export default function SubjectsGrid({
  subjects,
  orgWebPage,
}: {
  subjects: OrganizationSubjectPublicResponse[];
  orgWebPage: OrganizationWebPageResponse;
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  // Tracks which subjects are currently expanded
  const [expandedSubjects, setExpandedSubjects] = useState<Set<number>>(
    () => new Set()
  );

  // Update `isMobile` on resize
  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 600);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // You can tweak how many subjects appear per page
  const subjectsPerPage = isMobile ? 5 : 10;

  // Filter by searchTerm
  const filteredSubjects = useMemo(() => {
    const term = searchTerm.trim().toLowerCase();
    if (!term) return subjects;
    return subjects.filter((s) => s.name.toLowerCase().includes(term));
  }, [subjects, searchTerm]);

  // Handle pagination
  const maxPage = Math.max(
    0,
    Math.ceil(filteredSubjects.length / subjectsPerPage) - 1
  );
  const currentPage = Math.min(page, maxPage);

  // Subjects to display on the current page
  const displayedSubjects = useMemo(() => {
    const startIndex = currentPage * subjectsPerPage;
    return filteredSubjects.slice(startIndex, startIndex + subjectsPerPage);
  }, [filteredSubjects, currentPage, subjectsPerPage]);

  // Page handlers
  const handlePrev = () => setPage((p) => Math.max(0, p - 1));
  const handleNext = () => setPage((p) => Math.min(maxPage, p + 1));

  // Truncate text beyond 100 chars
  const truncateDescription = (desc: string) =>
    desc.length > 100 ? desc.slice(0, 100) + "..." : desc;

  // Check if a subject is expanded
  const isExpanded = (id: number) => expandedSubjects.has(id);

  // Toggle expand/collapse for a subject
  const toggleExpanded = (id: number) => {
    setExpandedSubjects((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  return (
    <div>
      {/* Header with search input + arrows */}
      <div className="subjects--header">
        <div className={`subjects--header-left ${isMobile ? "mx-auto" : ""}`}>
          <input
            type="text"
            placeholder="Search subjects..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setPage(0); // reset to first page when search changes
            }}
            className="input"
            style={{ maxHeight: "42px", fontSize: "14px" }}
          />
        </div>

        {/* Arrows on the right if not mobile */}
        {!isMobile && (
          <div className="subjects--header-right mt-3">
            <Button
              color={
                currentPage === 0
                  ? ButtonColor.GRAY
                  : orgWebPage.secondary_color
              }
              size={ButtonSize.SMALL}
              onClick={handlePrev}
              disabled={currentPage === 0}
            >
              ←
            </Button>
            <Button
              color={
                currentPage === maxPage
                  ? ButtonColor.GRAY
                  : orgWebPage.secondary_color
              }
              size={ButtonSize.SMALL}
              onClick={handleNext}
              disabled={currentPage === maxPage}
              style={{ marginLeft: "0.5rem" }}
            >
              →
            </Button>
          </div>
        )}
      </div>

      {/* Subject Cards */}
      <div className="subjects--grid">
        {displayedSubjects.map((subj) => (
          <div className="subject-card" key={subj.id}>
            <div
              className="subject-card--border"
              style={{ backgroundColor: orgWebPage.primary_color }}
            />
            <div className="subject-card--content">
              <h3
                className="subject--name"
                style={{ color: orgWebPage.secondary_color }}
              >
                {subj.name}
              </h3>
              {subj.description && (
                <p className="subject--description">
                  {isExpanded(subj.id)
                    ? subj.description
                    : truncateDescription(subj.description)}
                  {subj.description.length > 100 && (
                    <span
                      className="toggle-description"
                      style={{
                        color: orgWebPage.primary_color,
                        cursor: "pointer",
                        marginLeft: "0.5rem",
                        fontWeight: "500",
                      }}
                      onClick={() => toggleExpanded(subj.id)}
                    >
                      {isExpanded(subj.id) ? "See Less" : "See More"}
                    </span>
                  )}
                </p>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Mobile arrows at bottom */}
      {isMobile && (
        <div style={{ textAlign: "center", marginTop: "1rem" }}>
          <Button
            color={
              currentPage === 0 ? ButtonColor.GRAY : orgWebPage.secondary_color
            }
            size={ButtonSize.SMALL}
            onClick={handlePrev}
            disabled={currentPage === 0}
          >
            ←
          </Button>
          <Button
            color={
              currentPage === maxPage
                ? ButtonColor.GRAY
                : orgWebPage.secondary_color
            }
            size={ButtonSize.SMALL}
            onClick={handleNext}
            disabled={currentPage === maxPage}
            style={{ marginLeft: "0.5rem" }}
          >
            →
          </Button>
        </div>
      )}
    </div>
  );
}
