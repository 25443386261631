import {
  MeetingStudentAdminResponse,
  MeetingTutorAdminResponse,
  MeetingTutorLimitedResponse,
  StudentAdminResponse,
  StudentLimitedResponse,
  TutorAdminResponse,
  MeetingStudentLimitedResponse,
  TutorLimitedResponse,
  AccountLimitedResponse,
  AccountResponse,
} from "client/openapi";

export function concatenateName(
  user:
    | MeetingStudentLimitedResponse
    | MeetingTutorLimitedResponse
    | StudentLimitedResponse
    | TutorLimitedResponse
    | MeetingStudentAdminResponse
    | MeetingTutorAdminResponse
    | StudentAdminResponse
    | TutorAdminResponse
    | AccountResponse
    | AccountLimitedResponse
): string {
  // Handle meeting student/tutor types
  if ("student" in user) {
    return `${user.student.first_name} ${user.student.last_name}`;
  }
  if ("tutor" in user) {
    return `${user.tutor.first_name} ${user.tutor.last_name}`;
  }

  // Handle student/admin types directly
  return `${user.first_name} ${user.last_name}`;
}

/**
 * Creates a comma-separated sentence with an "and" before the final item.
 * @param items list of strings to join into a sentence
 * @returns a comma-separated sentence with an "and" before the final item
 */
export function createSentenceList(items: string[]): string {
  let list = [...items];

  switch (list.length) {
    case 0:
      return "";
    case 1:
      return list[0];
    case 2:
      return list.join(" and ");
    default:
      return list.join(", ");
  }
}
