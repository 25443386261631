import { DialogContent } from "components/Dialog";
import { useCallback, useEffect, useState } from "react";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import { PageStatus } from "types";
import SuccessDialog from "components/SuccessDialog";
import moment from "moment";
import { studentToOrgTransactionTypeToHumanReadable } from "../../../Transactions";
import {
  ACHResponse,
  PaymentsService,
  CreditCardResponse,
  PaymentMethodType,
  StudentToOrgTransactionResponse,
  StudentLimitedResponse,
  OrgToTadpoleTransactionResponse,
  TransactionType,
  TadpoleToTutorTransactionResponse,
} from "client/openapi";
import Cards from "../../../../../../../../components/PaymentMethods/cards";
import Banks from "../../../../../../../../components/PaymentMethods/bank";
import ErrorDialog from "components/ErrorDialog";

export default function RetryModal({
  transaction,
  student,
  orgId,
}: {
  transaction:
    | StudentToOrgTransactionResponse
    | OrgToTadpoleTransactionResponse
    | TadpoleToTutorTransactionResponse;

  student?: StudentLimitedResponse;
  orgId?: number;
}) {
  const [status, setStatus] = useState<PageStatus>(PageStatus.EDITING);

  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<
    CreditCardResponse | ACHResponse
  >();
  const [paymentEmail, setPaymentEmail] = useState<string>();

  type AllPaymentMethods = CreditCardResponse | ACHResponse;

  const [
    makeSureUserDoesntSpamChargeButton,
    setMakeSureUserDoesntSpamChargeButton,
  ] = useState<boolean>(false);

  const handleRetry = () => {
    PaymentsService.retryTransaction({
      transactionId: transaction.id,
    })
      .then(() => {
        setStatus(PageStatus.SUCCESS);
      })
      .catch((err) => {
        setStatus(PageStatus.ERROR);
      });

    setMakeSureUserDoesntSpamChargeButton(false);
  };

  const getAndSetPaymentMethodsOnFile = useCallback(async () => {
    if (
      transaction.transaction_type === TransactionType.STUDENT_TO_ORGANIZATION
    ) {
      let newMethods: AllPaymentMethods[] = [];

      try {
        const existing_cards = await PaymentsService.getStudentCards({
          studentId: student?.id as number,
        });
        if (existing_cards) {
          newMethods = [...newMethods, ...existing_cards];
        }
      } catch (error: any) {
        setStatus(PageStatus.ERROR);
        console.error("Error:", error);
      }

      try {
        const existing_banks = await PaymentsService.getStudentAchAccounts({
          studentId: student?.id as number,
        });
        if (existing_banks) {
          newMethods = [...newMethods, ...existing_banks];
        }
      } catch (error: any) {
        setStatus(PageStatus.ERROR);
        console.error("Error:", error);
      }

      const studentToOrgTransaction =
        transaction as StudentToOrgTransactionResponse;
      if (
        studentToOrgTransaction.payment_method_type ===
        PaymentMethodType.INVOICE
      ) {
        setPaymentEmail(studentToOrgTransaction.invoice_email as string);
      } else {
        setCurrentPaymentMethod(
          Array.from(newMethods).find(
            (paymentMethod) =>
              paymentMethod.payment_method_id ===
              studentToOrgTransaction.payment_method_id
          )
        );
      }
    }

    if (
      transaction.transaction_type === TransactionType.ORGANIZATION_TO_TADPOLE
    ) {
      let newMethods: AllPaymentMethods[] = [];

      try {
        const existing_cards = await PaymentsService.getOrgCards({
          orgId: orgId as number,
        });
        if (existing_cards) {
          newMethods = [...newMethods, ...existing_cards];
        }
      } catch (error: any) {
        setStatus(PageStatus.ERROR);
        console.error("Error:", error);
      }

      try {
        const existing_banks = await PaymentsService.getOrgAchAccounts({
          orgId: orgId as number,
        });
        if (existing_banks) {
          newMethods = [...newMethods, ...existing_banks];
        }
      } catch (error: any) {
        setStatus(PageStatus.ERROR);
        console.error("Error:", error);
      }

      const orgToTadpoleTransaction =
        transaction as OrgToTadpoleTransactionResponse;

      setCurrentPaymentMethod(
        Array.from(newMethods).find(
          (paymentMethod) =>
            paymentMethod.payment_method_id ===
            orgToTadpoleTransaction.payment_method_id
        )
      );
    }
  }, [orgId, student?.id, transaction]);

  useEffect(() => {
    if (transaction.transaction_type !== TransactionType.TADPOLE_TO_TUTOR) {
      getAndSetPaymentMethodsOnFile();
    }
  }, [getAndSetPaymentMethodsOnFile, transaction.transaction_type]);

  const reset = () => {
    if (transaction.transaction_type !== TransactionType.TADPOLE_TO_TUTOR) {
      getAndSetPaymentMethodsOnFile();
    }
  };

  if (status === PageStatus.SUCCESS) {
    return (
      <SuccessDialog
        message="Attempting retry, reload the page to see your changes."
        onClose={() => {
          reset();
          setStatus(PageStatus.EDITING);
        }}
      />
    );
  }

  if (status === PageStatus.ERROR) {
    return (
      <ErrorDialog
        message="This transaction cannot be retried at this time"
        onClose={() => {
          reset();
          setStatus(PageStatus.EDITING);
        }}
      />
    );
  }

  return (
    <DialogContent className="dialog-content max-w-[800px]" onClose={reset}>
      <div className="mt-3 font-semibold text-center header text-lg">
        You are about to retry this transaction
      </div>

      <div className="mt-4">
        <p className="underline text-gray-300 header text-lg font-bold">Date</p>
        <p className="header text-sm">
          {moment(transaction.timestamp).format("MM/DD/YYYY")}
        </p>
      </div>

      <div className="mt-4">
        <p className="underline text-gray-300 header text-lg font-bold">
          Amount
        </p>
        <p className="header text-sm">
          ${(transaction.amount / 100).toFixed(2)}
        </p>
      </div>

      <div className="mt-4">
        <p className="underline text-gray-300 header text-lg font-bold">
          Billing Type
        </p>
        <p className="header text-sm">
          {transaction.transaction_type ===
          TransactionType.STUDENT_TO_ORGANIZATION
            ? studentToOrgTransactionTypeToHumanReadable.find(
                (t) =>
                  t.value ===
                  (transaction as StudentToOrgTransactionResponse)
                    .student_to_org_transaction_type
              )?.label
            : transaction.transaction_type ===
              TransactionType.ORGANIZATION_TO_TADPOLE
            ? "Charge to organization"
            : "Payment to tutor"}
        </p>
      </div>

      <div className="mt-4">
        <p className="underline text-gray-300 header text-lg font-bold">
          Service
        </p>
        <p className="header text-sm">
          {transaction.transaction_type ===
          TransactionType.STUDENT_TO_ORGANIZATION
            ? (transaction as StudentToOrgTransactionResponse).reason
            : "Funding payroll"}
        </p>
      </div>

      {transaction.transaction_type !== TransactionType.TADPOLE_TO_TUTOR && (
        <div className="mt-4 border border-gray-300 border-2 rounded-lg p-4">
          <p className="mb-2 text-sm font-bold">Retrying with:</p>
          <div>
            {currentPaymentMethod || paymentEmail ? (
              <div>
                {currentPaymentMethod && "brand" in currentPaymentMethod ? (
                  <Cards
                    key={currentPaymentMethod.payment_method_id}
                    card={currentPaymentMethod as CreditCardResponse}
                  />
                ) : currentPaymentMethod &&
                  "bank_name" in currentPaymentMethod ? (
                  <Banks
                    key={currentPaymentMethod.payment_method_id}
                    bank={currentPaymentMethod as ACHResponse}
                  />
                ) : (
                  <span className="text-sm font-bold">{paymentEmail}</span>
                )}
              </div>
            ) : (
              <div>
                <span className="font-semibold text-sm">
                  Payment method cannot be found. Perhaps it was deleted?
                </span>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="w-full text-center mt-6">
        <Button
          color={
            makeSureUserDoesntSpamChargeButton || transaction.retried
              ? ButtonColor.GRAY
              : ButtonColor.GREEN
          }
          size={ButtonSize.SMALL}
          disabled={makeSureUserDoesntSpamChargeButton || transaction.retried}
          onClick={() => {
            handleRetry();
            setMakeSureUserDoesntSpamChargeButton(true);
          }}
        >
          Retry
        </Button>
      </div>
    </DialogContent>
  );
}
