import {
  Auth0AccountRole,
  MeetingAdminResponse,
  MeetingLimitedResponse,
} from "client/openapi";
import moment from "moment";
import { useEffect, useState } from "react";
import { PageStatus } from "types";
import { returnSubjectName } from "util/concatenateSubject";
import "./index.css";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../../../../src/sass/custom.scss";
import Meeting from "./MeetingBlocks/Meeting";
import { convertUTCtoLocal } from "util/timezone";

type CalendarViewType = "week" | "day";

const CalendarView = {
  WEEK: "week" as CalendarViewType,
  DAY: "day" as CalendarViewType,
};

export type CalendarEvent = {
  id: number;
  title: string;
  start: Date;
  end: Date;
};

function mapToCalendarEvents(
  meetings: MeetingAdminResponse[] | MeetingLimitedResponse[]
): CalendarEvent[] {
  return meetings.map((m) => {
    const localStart = moment(m.start).format();
    const localEnd = moment(localStart).add(m.duration, "minutes").toDate();

    return {
      id: m.session_id,
      title: m.name ?? returnSubjectName(m.org_subject),
      start: moment(localStart).toDate(),
      end: localEnd,
    };
  });
}

function CalendarWrapper({
  role,
  weekStart,
  events,
  setEvents,
  days,
  status,
  calendarViewType,
}: {
  role: Auth0AccountRole;
  events: MeetingAdminResponse[] | MeetingLimitedResponse[];
  setEvents: () => Promise<void>;
  weekStart: Date;
  days: number;
  status: PageStatus;
  calendarViewType: CalendarViewType;
}) {
  const localizer = momentLocalizer(moment);
  const [calendarEvents, setCalendarEvents] = useState<CalendarEvent[]>([]);

  // Convert the raw events into RBC events:
  useEffect(() => {
    setCalendarEvents(mapToCalendarEvents(events));
  }, [events]);

  // Example custom event component for RBC:
  const CustomEvent = ({ event }) => {
    // If you have more data in "events", you can find the original meeting
    const originalMtg = events.find((m) => m.session_id === event.id);
    if (!originalMtg) return null;

    // Decide if you want to render your existing <Meeting> or <EventBlock>:
    return (
      <div className="meeting-container">
        <Meeting
          meeting={originalMtg}
          meetings={events}
          index={event.id}
          role={role}
          setEvents={setEvents}
        />
      </div>
    );
  };

  return (
    <Calendar
      // Let RBC handle the logic for stacking/overlapping
      dayLayoutAlgorithm="overlap"
      localizer={localizer}
      events={calendarEvents}
      view={calendarViewType === CalendarView.DAY ? Views.DAY : Views.WEEK}
      date={weekStart}
      defaultDate={weekStart}
      // For a custom header
      components={{
        week: {
          header: ({ date }) => (
            <div>
              <span className="calendar--date-number">
                {localizer.format(date, "DD")}
              </span>
              <span className="calendar--day-separator px-3">|</span>
              {localizer.format(date, "dddd")}
            </div>
          ),
        },
        event: CustomEvent,
      }}
      formats={{
        timeGutterFormat: (date, culture) =>
          localizer.format(date, "h A", culture),
      }}
      scrollToTime={moment()
        .subtract(moment().hour() !== 0 ? 1 : 0, "hour")
        .toDate()}
      toolbar={false}
      onNavigate={() => {}}
      onView={() => {}}
      popup
    />
  );
}

export default CalendarWrapper;
