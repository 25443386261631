import {
  ChartSubscriptionResponse,
  OrganizationResponseForAdmins,
  ChartXAxisType,
} from "client/openapi";
import { PerformanceChartDot, PerformanceChartLine, filterChartDP } from ".";
import { useState, useEffect } from "react";
import { Dialog, DialogTrigger, DialogContent } from "components/Dialog";
import { Button, ButtonColor } from "components/Button";
import LinearRegressionChart from "components/LinearRegressionChart";
import ViewEditDataDialog from "./ViewEditDataDialog";
import { Tooltip, TooltipContent, TooltipTrigger } from "components/Tooltip";
import { InfoCircledIcon } from "@radix-ui/react-icons";

export default function ExpandedChartDialog({
  xAxisLabel,
  yAxisLabel,
  chart,
  organization,
  defaultMinMax,
  minMax,
  setMinMax,
  defaultDotsDP,
  displayDotsDP,
  setDisplayDotsDP,
  displayLineDP,
  linearRegression,
}: {
  xAxisLabel: string;
  yAxisLabel: string;
  chart: ChartSubscriptionResponse;
  organization: OrganizationResponseForAdmins;
  defaultMinMax: [number, number];
  minMax: [number, number];
  setMinMax: React.Dispatch<React.SetStateAction<[number, number]>>;
  defaultDotsDP: PerformanceChartDot[];
  displayDotsDP: PerformanceChartDot[];
  setDisplayDotsDP: React.Dispatch<
    React.SetStateAction<PerformanceChartDot[] | undefined>
  >;
  displayLineDP: PerformanceChartLine[];
  linearRegression: {
    slope: number;
    intercept: number;
    rSquared: number;
    confidenceInterval: number[];
  };
}) {
  const [dotsDP, setDotsDP] = useState<PerformanceChartDot[]>(displayDotsDP);
  const [lineDP, setLineDP] = useState<PerformanceChartLine[]>(displayLineDP);
  const [start, setStart] = useState<number>(minMax[0]);
  const [end, setEnd] = useState<number>(minMax[1]);
  const [hoveredDot, setHoveredDot] = useState<[number, number] | undefined>();

  let yDomain: number[] = [];
  yDomain.push(chart.chart_prototype.metric_definition.y_axis_min);
  yDomain.push(chart.chart_prototype.metric_definition.y_axis_max);

  function setToDefaultTimescale() {
    setStart(defaultMinMax[0]);
    setEnd(defaultMinMax[1]);
    setMinMax(defaultMinMax);
  }

  useEffect(() => {
    const filteredDotsDP = filterChartDP(displayDotsDP, start, end);
    setDotsDP(filteredDotsDP);

    const startLine: PerformanceChartLine = {
      x: minMax[0],
      line: linearRegression.slope * minMax[0] + linearRegression.intercept,
    };
    const endLine: PerformanceChartLine = {
      x: minMax[1],
      line: linearRegression.slope * minMax[1] + linearRegression.intercept,
    };
    const updatedLineDP: PerformanceChartLine[] = [];
    updatedLineDP.push(startLine);
    updatedLineDP.push(endLine);
    setLineDP(updatedLineDP);
  }, [
    start,
    end,
    minMax,
    displayDotsDP,
    linearRegression,
    setMinMax,
    setDisplayDotsDP,
  ]);

  return (
    <DialogContent
      className="expanded-chart-dialog-content dialog-content--left pb-2 px-12 font-montserrat"
      style={{ height: "85vh" }}
      onClose={() => {
        setStart(defaultMinMax[0]);
        setEnd(defaultMinMax[1]);
        setDotsDP(displayDotsDP);
        setLineDP(displayLineDP);
        setHoveredDot(undefined);
      }}
    >
      <div className="h-1/5 pt-10">
        <h2 className="font-bold text-3xl">
          {chart.chart_prototype.chart_title}
        </h2>

        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row gap-2 py-2">
            <div className="flex flex-row gap-2">
              <div className="w-20 h-7 border-b border-gray-300">
                <input
                  type="number"
                  id="start-hour"
                  value={isNaN(start) ? "" : start}
                  placeholder="Start"
                  onChange={(e) => setStart(parseFloat(e.target.value))}
                  className="border-transparent focus:border-transparent focus:ring-0 w-full h-full"
                />
              </div>
              -
              <div className="w-20 h-7 border-b border-gray-300">
                <input
                  type="number"
                  id="end-hour"
                  value={isNaN(end) ? "" : end}
                  min={isNaN(start) ? 0 : start}
                  placeholder="End"
                  onChange={(e) => setEnd(parseFloat(e.target.value))}
                  className="border-transparent focus:border-transparent focus:ring-0 w-full h-full"
                />
              </div>
            </div>
          </div>

          <Dialog>
            <DialogTrigger asChild>
              <Button color={ButtonColor.SKYBLUE} extraClasses="rounded-xl">
                View/Edit Data
              </Button>
            </DialogTrigger>

            <ViewEditDataDialog
              xAxisLabel={xAxisLabel}
              yAxisLabel={yAxisLabel}
              organization={organization}
              chart={chart}
              defaultDotsDP={defaultDotsDP}
              displayDotsDP={displayDotsDP}
              setDisplayDotsDP={setDisplayDotsDP}
            />
          </Dialog>
        </div>
      </div>

      <div className="h-4/5 flex flex-row ">
        <div className="h-full w-9/12">
          {dotsDP && lineDP && (
            <LinearRegressionChart
              dotsData={dotsDP}
              lineData={lineDP}
              xKey="x"
              xAxisLabel={xAxisLabel}
              dotYKey="score"
              lineYKey="line"
              yAxisLabel={yAxisLabel}
              xDomain={[start, end]}
              yDomain={yDomain}
              formatter={undefined}
              setHoveredDot={setHoveredDot}
              linearRegression={linearRegression}
            />
          )}
        </div>

        <div className="h-full w-3/12 flex mt-10 justify-center">
          {Number.isNaN(linearRegression.slope) ||
          dotsDP.length <= 1 ||
          Number.isNaN(linearRegression.intercept) ? (
            dotsDP.length <= 1 ? (
              <div>No data available to calculate linear regression.</div>
            ) : (
              <div className="w-full whitespace-pre-line">
                The line of best fit is undefined since all coordinates lie on
                the same vertical line. More variations in the x-axis are
                required to determine the linear regression.
              </div>
            )
          ) : (
            <div className="w-full flex flex-col gap-y-7">
              <div className="w-full whitespace-pre-line">
                y = {linearRegression.slope.toFixed(2)}x +{" "}
                {linearRegression.intercept.toFixed(2)}
              </div>

              <div>
                On average, every
                {chart.chart_prototype.chart_x_axis_type ===
                ChartXAxisType.HOURS_OF_TUTORING
                  ? " 10 hours "
                  : " 4 weeks "}
                of tutoring results in a{" "}
                {chart.chart_prototype.chart_x_axis_type ===
                ChartXAxisType.HOURS_OF_TUTORING
                  ? (linearRegression.slope * 10).toFixed(2)
                  : (linearRegression.slope * 28).toFixed(2)}{" "}
                increase in{" "}
                {chart.chart_prototype.metric_definition.y_axis_name}.
                {displayDotsDP.length > 2 && (
                  <Tooltip>
                    <TooltipTrigger>
                      <span className="inline-flex items-center">
                        <InfoCircledIcon className="h-4 w-4 text-red-300 ml-1 cursor-pointer" />
                      </span>
                    </TooltipTrigger>

                    <TooltipContent>
                      <div className="w-60">
                        <div className="w-full whitespace-pre-line">
                          {chart.chart_prototype.chart_x_axis_type ===
                          ChartXAxisType.HOURS_OF_TUTORING
                            ? (linearRegression.slope * 10).toFixed(2)
                            : (linearRegression.slope * 28).toFixed(2)}{" "}
                          represents an approximation of the impact of{" "}
                          {chart.chart_prototype.chart_x_axis_type ===
                          ChartXAxisType.HOURS_OF_TUTORING
                            ? "10 hours of tutoring "
                            : "4 weeks of tutoring "}{" "}
                          on{" "}
                          {chart.chart_prototype.metric_definition.y_axis_name}.
                          To be more statistcally precise, we can say with 95%
                          confidence that the true impact of every{" "}
                          {chart.chart_prototype.chart_x_axis_type ===
                          ChartXAxisType.HOURS_OF_TUTORING
                            ? " 10 hours "
                            : " 4 weeks "}
                          of tutoring is between a{" "}
                          {chart.chart_prototype.chart_x_axis_type ===
                          ChartXAxisType.HOURS_OF_TUTORING
                            ? (
                                linearRegression.confidenceInterval[0] * 10
                              ).toFixed(2) +
                              " and " +
                              (
                                linearRegression.confidenceInterval[1] * 10
                              ).toFixed(2)
                            : (
                                linearRegression.confidenceInterval[0] * 14
                              ).toFixed(2) +
                              " and " +
                              (
                                linearRegression.confidenceInterval[1] * 14
                              ).toFixed(2)}{" "}
                          increase.
                        </div>
                        <div>
                          <br />
                          If the numbers above look funny, that is because
                          statisticians typically require at least 30 data
                          points to create results that are statistcally
                          relevant and precise!
                        </div>
                      </div>
                    </TooltipContent>
                  </Tooltip>
                )}
              </div>

              {displayDotsDP.length > 2 && (
                <div>
                  The R-Sqaured value is {linearRegression.rSquared.toFixed(2)}.
                  This indicates that about{" "}
                  {(linearRegression.rSquared * 100).toFixed(0)}% of the
                  variance in{" "}
                  {chart.chart_prototype.metric_definition.y_axis_name} can be
                  explained by the total{" "}
                  {chart.chart_prototype.chart_x_axis_type ===
                  ChartXAxisType.HOURS_OF_TUTORING
                    ? "hours"
                    : "days"}{" "}
                  of tutoring a student has received.
                </div>
              )}

              {hoveredDot && (
                <div className="w-full whitespace-pre-line">
                  The data shows on average that students with{" "}
                  {hoveredDot[0].toFixed(2)}{" "}
                  {chart.chart_prototype.chart_x_axis_type ===
                  ChartXAxisType.DATE
                    ? "days"
                    : "hours"}{" "}
                  of tutoring have a score of{" "}
                  {(
                    linearRegression.slope * hoveredDot[0] +
                    linearRegression.intercept
                  ).toFixed(2)}{" "}
                  .
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </DialogContent>
  );
}
