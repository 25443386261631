/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountCreate } from "../models/AccountCreate";
import type { AccountResponse } from "../models/AccountResponse";
import type { AccountUpdate } from "../models/AccountUpdate";
import type { BooleanResponse } from "../models/BooleanResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class AccountsService {
  /**
   * Get Current Account
   * Get the details of the currently-authenticated account.
   * @returns AccountResponse Successful Response
   * @throws ApiError
   */
  public static getCurrentAccount(): CancelablePromise<AccountResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/accounts/me",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Account
   * Create a new account and return the resulting account from the database.
   * @returns AccountResponse Successful Response
   * @throws ApiError
   */
  public static createAccount({
    requestBody,
  }: {
    requestBody: AccountCreate;
  }): CancelablePromise<AccountResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/accounts",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Registered
   * Determine if an account has completed signup.
   * @returns BooleanResponse Successful Response
   * @throws ApiError
   */
  public static getRegistered(): CancelablePromise<BooleanResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/accounts/registered",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Account
   * Update an account by its ID using specified attributes
   * within AccountUpdate to update the database.
   * @returns AccountResponse Successful Response
   * @throws ApiError
   */
  public static updateAccount({
    requestBody,
  }: {
    requestBody: AccountUpdate;
  }): CancelablePromise<AccountResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/accounts/update",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
