/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents the status of a financial transaction.
 */
export enum TransactionStatus {
  CARD_CHARGE_SUCCESSFUL = "CARD_CHARGE_SUCCESSFUL",
  CARD_CHARGE_PENDING = "CARD_CHARGE_PENDING",
  CARD_CHARGE_FAILED = "CARD_CHARGE_FAILED",
  CARD_REFUND_SUCCESSFUL = "CARD_REFUND_SUCCESSFUL",
  CARD_REFUND_PENDING = "CARD_REFUND_PENDING",
  CARD_REFUND_FAILED = "CARD_REFUND_FAILED",
  ACH_CHARGE_SUCCESSFUL = "ACH_CHARGE_SUCCESSFUL",
  ACH_CHARGE_PENDING = "ACH_CHARGE_PENDING",
  ACH_CHARGE_FAILED = "ACH_CHARGE_FAILED",
  ACH_REFUND_SUCCESSFUL = "ACH_REFUND_SUCCESSFUL",
  ACH_REFUND_PENDING = "ACH_REFUND_PENDING",
  ACH_REFUND_FAILED = "ACH_REFUND_FAILED",
  INVOICE_SUCCESSFUL = "INVOICE_SUCCESSFUL",
  INVOICE_PENDING = "INVOICE_PENDING",
  INVOICE_FAILED = "INVOICE_FAILED",
  INVOICE_CANCELLED = "INVOICE_CANCELLED",
  INVOICE_REFUND_SUCCESSFUL = "INVOICE_REFUND_SUCCESSFUL",
  INVOICE_REFUND_PENDING = "INVOICE_REFUND_PENDING",
  INVOICE_REFUND_FAILED = "INVOICE_REFUND_FAILED",
  UNKNOWN_PAYMENT_METHOD_CHARGE_SUCCESSFUL = "UNKNOWN_PAYMENT_METHOD_CHARGE_SUCCESSFUL",
  UNKNOWN_PAYMENT_METHOD_CHARGE_PENDING = "UNKNOWN_PAYMENT_METHOD_CHARGE_PENDING",
  UNKNOWN_PAYMENT_METHOD_CHARGE_FAILED = "UNKNOWN_PAYMENT_METHOD_CHARGE_FAILED",
  UNKNOWN_PAYMENT_METHOD_REFUND_SUCCESSFUL = "UNKNOWN_PAYMENT_METHOD_REFUND_SUCCESSFUL",
  UNKNOWN_PAYMENT_METHOD_REFUND_PENDING = "UNKNOWN_PAYMENT_METHOD_REFUND_PENDING",
  UNKNOWN_PAYMENT_METHOD_REFUND_FAILED = "UNKNOWN_PAYMENT_METHOD_REFUND_FAILED",
  TRANSFER_SUCCESSFUL = "TRANSFER_SUCCESSFUL",
  TRANSFER_PENDING = "TRANSFER_PENDING",
  TRANSFER_FAILED = "TRANSFER_FAILED",
  TRANSFER_REFUND_SUCCESSFUL = "TRANSFER_REFUND_SUCCESSFUL",
  TRANSFER_REFUND_PENDING = "TRANSFER_REFUND_PENDING",
  TRANSFER_REFUND_FAILED = "TRANSFER_REFUND_FAILED",
  CREDITS_PAYMENT_SUCCESSFUL = "CREDITS_PAYMENT_SUCCESSFUL",
  CREDITS_REFUND_SUCCESSFUL = "CREDITS_REFUND_SUCCESSFUL",
  PAYOUT_SUCCESSFUL = "PAYOUT_SUCCESSFUL",
  PAYOUT_PENDING = "PAYOUT_PENDING",
  PAYOUT_FAILED = "PAYOUT_FAILED",
  TRANSFER_NOT_YET_FACTORED_INTO_PAYROLL = "TRANSFER_NOT_YET_FACTORED_INTO_PAYROLL",
  TRANSFER_FACTORED_INTO_PAYROLL = "TRANSFER_FACTORED_INTO_PAYROLL",
  TRANSFER_AWAITING_ORG_TO_TADPOLE_PAYMENT = "TRANSFER_AWAITING_ORG_TO_TADPOLE_PAYMENT",
  TRANSFER_QUEUED = "TRANSFER_QUEUED",
  CHARGE_NOT_YET_FACTORED_INTO_BILLING = "CHARGE_NOT_YET_FACTORED_INTO_BILLING",
  CHARGE_FACTORED_INTO_BILLING = "CHARGE_FACTORED_INTO_BILLING",
  AWAITING_CONFIRMATION_TO_FUND_PAYROLL = "AWAITING_CONFIRMATION_TO_FUND_PAYROLL",
  CHARGE_QUEUED = "CHARGE_QUEUED",
}
