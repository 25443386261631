/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enum for session frequency.
 */
export enum SessionFrequency {
  SINGLE = "single",
  EVERY_7_DAYS = "every_7_days",
  EVERY_14_DAYS = "every_14_days",
  EVERY_21_DAYS = "every_21_days",
  EVERY_28_DAYS = "every_28_days",
}
