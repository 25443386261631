import { useAuth0 } from "@auth0/auth0-react";
import { Auth0AccountRole } from "client/openapi";
import { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import logo_white_img from "../../images/logoWhite.png";
import "./index.css";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

export default function Footer() {
  const { currently_selected_role, account } = useContext(
    OrgRolesAndAccountContext
  );
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  type FooterLink = {
    name: string;
    link: string;
    roles: string[];
  };

  const primaryLinks: FooterLink[] = [
    {
      name: "Dashboard",
      link: "/dashboard",
      roles: [
        Auth0AccountRole.ORG_ADMIN,
        Auth0AccountRole.ORG_TUTOR,
        Auth0AccountRole.ME,
        Auth0AccountRole.PARENT,
      ],
    },
    {
      name: "Performance",
      link: "/performance",
      roles: [Auth0AccountRole.ORG_ADMIN],
    },
  ];

  const hashLinks: FooterLink[] = [
    {
      name: "About",
      link: "/#about",
      roles: [],
    },
    {
      name: "Features",
      link: "/#about",
      roles: [],
    },
    {
      name: "Contact Us",
      link: "/#contact",
      roles: [],
    },
  ];

  return (
    <div
      className={`footer footer--${currently_selected_role || "default"} mt-6`}
    >
      <div className="container mx-auto">
        <div className="grow">
          <Link to="/" className="footer--logo">
            <img src={logo_white_img} alt="tadpole logo" />
          </Link>

          <div className="mt-8">
            <p className="important-text">Need help?</p>
            <p>
              Contact{" "}
              <a
                className="footer--link"
                href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
              >
                {process.env.REACT_APP_SUPPORT_EMAIL}
              </a>
            </p>
          </div>
          <div className="mt-4">
            <p className="important-text">Legal</p>
            <p>
              <a
                className="footer--link"
                href={`${process.env.REACT_APP_FRONTEND_DOMAIN}/terms-of-service`}
              >
                Terms of Service
              </a>
            </p>
            <p>
              <a
                className="footer--link"
                href={`${process.env.REACT_APP_FRONTEND_DOMAIN}/privacy-policy`}
              >
                Privacy Policy
              </a>
            </p>
          </div>
          <div className="mt-6">
            <p className="important-text">Account</p>
            <div>
              <ul>
                {primaryLinks.map((l, i) => (
                  <Fragment key={i}>
                    {currently_selected_role &&
                      l.roles.includes(currently_selected_role) && (
                        <li>
                          <Link to={l.link} className="footer--link">
                            {l.name}
                          </Link>
                        </li>
                      )}
                  </Fragment>
                ))}

                {/* {currently_selected_role === null
                  ? hashLinks.map((l, i) => (
                      <li key={i}>
                        <HashLink smooth to={l.link} className="footer--link">
                          {l.name}
                        </HashLink>
                      </li>
                    ))
                  : null} */}
              </ul>

              {isAuthenticated ? (
                <button
                  className="footer--link"
                  onClick={() => {
                    localStorage.removeItem(
                      account ? account.reference_id : "OrgRolesAndAccountState"
                    );
                    logout({
                      logoutParams: {
                        returnTo: window.location.origin,
                      },
                    });
                  }}
                >
                  Log Out
                </button>
              ) : (
                <p>
                  Have an account?{" "}
                  <button
                    className="footer--link"
                    onClick={() => {
                      loginWithRedirect({
                        authorizationParams: {
                          redirect_uri: `${process.env.REACT_APP_FRONTEND_DOMAIN}/dashboard`,
                        },
                      });
                    }}
                  >
                    Log In
                  </button>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
