import {
  Auth0AccountRole,
  OrganizationResponseForAdmins,
  OrganizationResponseForTutors,
  OrganizationsService,
  TutorLimitedResponse,
  TutorsService,
} from "client/openapi";
import ProfileData from "components/Dashboard/ProfileData";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Select } from "components/Select";

import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

export default function ManageOrganizationProfile() {
  const { roles_available_to_account } = useContext(OrgRolesAndAccountContext);

  const [isLoading, setIsLoading] = useState(true);

  // Lists of orgs based on role
  const [adminOrgs, setAdminOrgs] = useState<
    OrganizationResponseForAdmins[] | OrganizationResponseForTutors[]
  >([]);

  const [tutorOrgs, setTutorOrgs] = useState<
    OrganizationResponseForAdmins[] | OrganizationResponseForTutors[]
  >([]);

  const [tutors, setTutors] = useState<TutorLimitedResponse[]>([]);

  // Dropdown options
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    []
  );

  // Currently selected dropdown option
  const [currentOption, setCurrentOption] = useState<{
    label: string;
    value: string;
  } | null>(null);

  useEffect(() => {
    async function fetchData() {
      try {
        // If user can be an ORG_ADMIN, fetch admin orgs
        if (roles_available_to_account?.includes(Auth0AccountRole.ORG_ADMIN)) {
          const orgs = await OrganizationsService.getOrganizationsMeByRole({
            role: Auth0AccountRole.ORG_ADMIN,
          });
          setAdminOrgs(orgs as OrganizationResponseForAdmins[]);
        }

        // If user can be an ORG_TUTOR, fetch tutor orgs and tutor info
        if (roles_available_to_account?.includes(Auth0AccountRole.ORG_TUTOR)) {
          const orgs = await OrganizationsService.getOrganizationsMeByRole({
            role: Auth0AccountRole.ORG_TUTOR,
          });
          setTutorOrgs(orgs as OrganizationResponseForTutors[]);

          const myTutors = await TutorsService.getTutorsMe();
          setTutors(myTutors);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    }

    if (roles_available_to_account) {
      fetchData();
    }
  }, [roles_available_to_account]);

  useEffect(() => {
    if (isLoading) return;

    // 1) Build the Org options from adminOrgs ONLY
    //    => If the user is not an admin for the org, it will NOT appear in this list
    const orgOptions = (adminOrgs || []).map((org) => ({
      label: `Organization Page (${org.name})`,
      value: `org-${org.id}`,
    }));

    // 2) Build the Tutor options from the 'tutors' array
    //    - For each tutor, find the matching org in tutorOrgs and use its name.
    const tutorOptions = (tutors || []).map((tutor) => {
      const matchingOrg = (tutorOrgs || []).find((o) => o.id === tutor.org_id);
      const orgName = matchingOrg ? matchingOrg.name : "Unknown Org";
      return {
        label: `${tutor.first_name}'s Tutor Page (${orgName})`,
        value: `tutor-${tutor.id}`,
      };
    });

    // 3) Combine them for the dropdown
    const allOptions = [...orgOptions, ...tutorOptions];
    setOptions(allOptions);

    // 4) Set default selection if none is selected yet
    if (!currentOption && allOptions.length > 0) {
      setCurrentOption(allOptions[0]);
    }
  }, [adminOrgs, tutorOrgs, tutors, isLoading, currentOption]);

  // Helper to parse the current option's value and decide what to render
  const renderProfileData = () => {
    if (!currentOption) return null;

    if (currentOption.value.startsWith("org-")) {
      const orgId = parseInt(currentOption.value.replace("org-", ""), 10);
      return (
        <ProfileData
          context={Auth0AccountRole.ORG_ADMIN}
          organization_id={orgId}
        />
      );
    }

    if (currentOption.value.startsWith("tutor-")) {
      const tutorId = parseInt(currentOption.value.replace("tutor-", ""), 10);
      return (
        <ProfileData context={Auth0AccountRole.ORG_TUTOR} tutor_id={tutorId} />
      );
    }

    return null;
  };

  return (
    <section>
      <Helmet>
        <title>Create Webpage</title>
      </Helmet>

      <Select
        options={options}
        value={currentOption}
        onChange={(option) => setCurrentOption(option)}
        placeholder="Select an Organization or Tutor..."
        className="mx-auto w-96"
      />

      {renderProfileData()}
    </section>
  );
}
