import { useAuth0 } from "@auth0/auth0-react";
import { DrawingPinIcon, Pencil1Icon, TrashIcon } from "@radix-ui/react-icons";
import {
  AccountResponse,
  ApiError,
  Auth0AccountRole,
  NoteResponse,
  NotesService,
  OrganizationResponseForStudents,
  OrganizationResponseForTutors,
  OrganizationResponseForParents,
  OrganizationResponseForAdmins,
  ParentLimitedResponse,
  StudentLimitedResponse,
} from "client/openapi";
import { Dialog, DialogTrigger } from "components/Dialog";
import { ErrorBlock } from "components/StatusBlock";
import moment from "moment-timezone";
import { useCallback, useContext, useEffect, useState } from "react";
import { APIResponse, Account, PageStatus } from "types";
import { concatenateName } from "util/concatenateName";
import Notifications from "util/notifications";
import { renderTimestamp } from "util/renderTimestamp";
import EditNoteDialog from "../EditNoteDialog";
import "./index.css";
import DeleteNoteDialog from "../DeleteNoteDialog";
import { getUserTypeColor } from "util/contextColor";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

function NotesDialog({
  role,
  author,
  student_recipient,
  parent_recipient,
  organization,
  notes,
  setNotes,
  text_parent_or_student_parents,
}: {
  role: Auth0AccountRole | null;
  author?: Account | AccountResponse;
  student_recipient?: StudentLimitedResponse;
  parent_recipient?: ParentLimitedResponse;
  organization?:
    | OrganizationResponseForStudents
    | OrganizationResponseForTutors
    | OrganizationResponseForParents
    | OrganizationResponseForAdmins;
  notes: NoteResponse[];
  setNotes: React.Dispatch<React.SetStateAction<NoteResponse[]>>;
  text_parent_or_student_parents: boolean;
}) {
  const { user } = useAuth0();
  const { currently_selected_role } = useContext(OrgRolesAndAccountContext);

  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING);
  const [error, setError] = useState<APIResponse>();

  const [editNoteOpen, setEditNoteOpen] = useState<number>();

  const timezone = moment.tz.guess(true);

  const fetchNotes = useCallback(async () => {
    setStatus(PageStatus.LOADING);

    if (student_recipient) {
      await NotesService.getNotesForStudent({
        studentId: student_recipient.id,
        getTextedNotes: text_parent_or_student_parents,
      })
        .then((notesResponse) => {
          setNotes(notesResponse);
          setStatus(PageStatus.SUCCESS);
        })
        .catch((e: ApiError) => {
          setStatus(PageStatus.ERROR);
          setError({ error: "An unexpected error occurred" });
          console.error(`Error (#${e.status}): ${e.message}`);
        });
    }

    if (parent_recipient) {
      await NotesService.getNotesForParent({
        parentId: parent_recipient.id,
        getTextedNotes: text_parent_or_student_parents,
      })
        .then((notesResponse) => {
          setNotes(notesResponse);
          setStatus(PageStatus.SUCCESS);
        })
        .catch((e: ApiError) => {
          setStatus(PageStatus.ERROR);
          setError({ error: "An unexpected error occurred" });
          console.error(`Error (#${e.status}): ${e.message}`);
        });
    }
  }, [
    parent_recipient,
    setNotes,
    student_recipient,
    text_parent_or_student_parents,
  ]);

  async function toggleNotePin(noteId: number, currentlyPinned: boolean) {
    await NotesService.updateNote({
      noteId: noteId,
      requestBody: {
        pinned: !currentlyPinned,
      },
    })
      .then(() => {
        let note: NoteResponse = notes.filter((n) => n.id === noteId)[0];
        note["pinned"] = !currentlyPinned;
        setNotes([...notes]);
        setStatus(PageStatus.SUCCESS);
        const actionPerformed = currentlyPinned ? "unpinned" : "pinned";
        Notifications.success(`Note ${actionPerformed}!`);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ error: "An unexpected error occurred" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  useEffect(() => {
    fetchNotes();
  }, [fetchNotes]);

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  return (
    <div className="account-notes--dialog">
      {status === PageStatus.LOADING ? (
        <div className="flex flex-col items-center justify-center gap-4 py-5">
          <span
            className="spinner-border"
            role="status"
            aria-hidden="true"
          ></span>

          <p>Loading...</p>
        </div>
      ) : null}

      {status === PageStatus.SUCCESS && (
        <div>
          {notes.length > 0 ? (
            notes
              .sort((a, b) => {
                if (a.pinned && !b.pinned) {
                  // If a is pinned and b is not, put a first
                  return -1;
                } else if (!a.pinned && b.pinned) {
                  // If a is not pinned and b is, put b first
                  return 1;
                } else {
                  // If both are pinned or both are not pinned, compare by last modified
                  const aCreated = a.created_at;
                  const bCreated = b.created_at;

                  if (aCreated > bCreated) {
                    // If time A is more recent than time B, put A first
                    return -1;
                  } else if (aCreated < bCreated) {
                    // If time B is more recent than time A, put B first
                    return 1;
                  } else {
                    // If times are the same, keep original order
                    return 0;
                  }
                }
              })
              .map((n) => {
                if (!n.author || !(n.student_recipient || n.parent_recipient)) {
                  return (
                    <ErrorBlock
                      status={PageStatus.ERROR}
                      title="An unexpected error occurred"
                    />
                  );
                }

                const authorColor = getUserTypeColor(n.author_type);

                return (
                  <div className={`account-note ${authorColor}`} key={n.id}>
                    <div className="account-notes--meta">
                      <div>
                        <span className="block">
                          <b>{concatenateName(n.author)}</b>
                          {n.author_type === Auth0AccountRole.ORG_ADMIN && (
                            <small> admin</small>
                          )}
                        </span>

                        <span className="font-thin account-notes--timestamp">
                          <span
                            title={moment(n.created_at)
                              .tz(timezone)
                              .format("YYYY-MM-DD hh:mm A")}
                          >
                            {renderTimestamp(
                              moment(n.created_at).tz(timezone).toDate()
                            )}
                          </span>

                          {!moment(n.content_modified_at).isSame(
                            n.created_at
                          ) && (
                            <>
                              {" "}
                              | Modified:{" "}
                              <span
                                title={moment(n.content_modified_at)
                                  .tz(timezone)
                                  .format("YYYY-MM-DD hh:mm A")}
                              >
                                {renderTimestamp(
                                  moment(n.content_modified_at)
                                    .tz(timezone)
                                    .toDate()
                                )}
                              </span>
                            </>
                          )}
                        </span>
                      </div>

                      <div className="account-notes--actions">
                        <button
                          className={`button button--small button--square ${
                            !n.pinned ? "button--hollow" : ""
                          } button--${authorColor}`}
                          aria-label={n.pinned ? "Unpin note" : "Pin note"}
                          onClick={() => toggleNotePin(n.id, n.pinned)}
                        >
                          <DrawingPinIcon />
                        </button>

                        {(user?.sub === n.author.reference_id ||
                          currently_selected_role ===
                            Auth0AccountRole.ORG_ADMIN) && (
                          <>
                            <Dialog open={editNoteOpen === n.id}>
                              <DialogTrigger asChild>
                                <button
                                  className={`button button--small button--square button--hollow button--${authorColor}`}
                                  aria-label="Edit note"
                                  onClick={() => setEditNoteOpen(n.id)}
                                >
                                  <Pencil1Icon />
                                </button>
                              </DialogTrigger>

                              <EditNoteDialog
                                role={role}
                                note={n}
                                notes={notes}
                                setOpen={setEditNoteOpen}
                                setNotes={setNotes}
                              />
                            </Dialog>

                            <Dialog>
                              <DialogTrigger asChild>
                                <button
                                  className={`button button--small button--square button--hollow button--${authorColor}`}
                                  aria-label="Delete note"
                                >
                                  <TrashIcon />
                                </button>
                              </DialogTrigger>
                              <DeleteNoteDialog
                                noteId={n.id}
                                notes={notes}
                                setNotes={setNotes}
                              />
                            </Dialog>
                          </>
                        )}
                      </div>
                    </div>

                    <p className="account-notes--note">{n.content}</p>
                  </div>
                );
              })
          ) : (
            <p>No notes.</p>
          )}
        </div>
      )}
    </div>
  );
}

export default NotesDialog;
