import React from "react";

export default function TermsOfServicePage() {
  return (
    <div
      style={{
        padding: "2rem",
        fontFamily: "sans-serif",
        maxWidth: "1200px",
        margin: "0 auto",
      }}
    >
      <h1>Terms of Service and Acceptable Use Policy</h1>
      <p>
        <strong>EFFECTIVE: Jan 1, 2025</strong>
      </p>

      <p>
        These Terms of Use constitute a legally binding agreement (the
        “Agreement”) between you (“Customer”) and Tadpole Education Incorporated
        (“Tadpole”) governing your use of Tadpole’s applications, website, and
        technology Platform (collectively, the “Platform”).
      </p>

      <h2>1. Service.</h2>
      <p>
        Subject to the terms of this Agreement, Tadpole will provide to Customer
        proprietary software as a service offering(s) made available through a
        URL in a hosted environment (together with any other products and
        services identified in the Contract, the “Service”). All rights in and
        to the Service not expressly granted to Customer in this Agreement are
        reserved by Tadpole. Tadpole shall: (a) deploy all updates and upgrades
        to the Service to Customer that Tadpole provides to its customers
        generally for no additional charge; and (b) provide support (“Support”)
        pursuant to the terms described on the Customer’s contract. For purposes
        of this Agreement, “User” means an individual who is authorized by the
        Customer to use the Service and for whom the Customer has purchased a
        subscription.
      </p>

      <h2>2. Customer Restrictions.</h2>
      <p>
        Customer shall not (and shall not permit Users to): (a) sell, resell,
        rent, lease, lend, sublicense, distribute, assign, timeshare, or
        otherwise transfer or provide access to the Service to any third party
        except as expressly authorized under this Agreement; (b) use or access
        the Service for competitive purposes; (c) copy, modify, adapt, or create
        derivative works from any feature, function, interface, or graphic in
        the Service; (d) remove or modify Tadpole’s policies or proprietary
        markings displayed within the Service; (e) use, interfere with, disrupt
        or circumvent the integrity, security or performance of the Service,
        including by probing, scanning, or testing any Tadpole system or network
        or its security or authentication measures; (f) store or transmit any
        malicious code; (g) permit direct or indirect access to or use of any
        Service or Customer Content (as defined below) in a way that circumvents
        a contractual usage limit; (h) attempt to gain unauthorized access to
        the Service, its related systems or networks or Third-Party Services (as
        defined below); (i) use the Service or any Third-Party Services to store
        or transmit infringing, libelous, or otherwise unlawful or tortious
        material, or to store or transmit material in violation of third-party
        privacy rights; or (j) use the Service to distribute software or tools
        that gather information, distribute advertisements, or engage in conduct
        that may result in retaliation against Tadpole or its data, systems, or
        networks.
      </p>

      <h2>3. Customer Responsibilities.</h2>
      <p>
        Customer shall have sole responsibility for Customer Content and use of
        the Service by Users in compliance with this Agreement and the
        Acceptable Use Policy provided within the Service and available below
        this agreement. Customer agrees to reasonably assist Tadpole in
        connection with a User’s adherence to the AUP. Customer further agrees
        to: (a) maintain the confidentiality and security of passwords and abide
        by any access protocols or credential requirements set by Tadpole; (b)
        obtain from Users any consents necessary under this Agreement or to
        allow Tadpole to provide the Service; (c) use commercially reasonable
        efforts to prevent unauthorized access to or use of the Service; (d)
        notify Tadpole promptly of any such unauthorized access or use of which
        it learns; (e) cooperate reasonably in all respects with respect to
        implementation, access, support, and maintenance of the Service; and (f)
        ensure that a current email address is associated with each User’s
        account.
      </p>

      <h2>4. Representations.</h2>
      <p>
        Each party represents that (a) it has the power and authority to validly
        enter into this Agreement, (b) this Agreement has been duly and validly
        authorized, executed and delivered by such party, (c) the execution and
        delivery of this Agreement does not violate or conflict with any other
        agreement, license, or obligation of such party, (d) it has not received
        or been offered any illegal or improper bribe, kickback, payment, gift,
        or thing of value from or on behalf of any employees or agents of the
        other party in connection with this Agreement, and (e) it is financially
        solvent and has the ability to perform its obligations hereunder.
      </p>

      <h2>5. Tadpole Warranties.</h2>
      <p>
        Tadpole warrants that: (a) it shall implement reasonable administrative,
        technical, and physical safeguards in an effort to secure its facilities
        and systems from unauthorized access and to secure the Customer Content;
        (b) the functionality or features of the Service and Support may change
        but will not materially degrade during the Term; and (c) the Service
        will materially conform to its then-current documentation. As Customer's
        sole and exclusive remedy for Tadpole’s breach of the warranties set
        forth in this Section 5: (i) Tadpole shall correct the non-conforming
        Service at no additional charge to Customer; or (ii) in the event
        Tadpole is unable to correct such deficiencies after good-faith efforts,
        Tadpole shall refund Customer amounts paid that are attributable to the
        defective Service from the date Tadpole received such notice. Customer
        must report deficiencies in writing to Tadpole within thirty (30) days
        of their identification in order to receive any warranty remedies
        herein. EXCEPT AS EXPRESSLY PROVIDED IN THIS SECTION 5 AND TO THE
        MAXIMUM EXTENT OF THE LAW, TADPOLE AND ITS SUPPLIERS DISCLAIM ALL
        WARRANTIES, WHETHER WRITTEN, ORAL, EXPRESS, IMPLIED, OR STATUTORY,
        INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
        MERCHANTABILITY, TITLE, NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR
        PURPOSE. WITHOUT LIMITING THE FOREGOING, TADPOLE DOES NOT WARRANT THE
        RESULTS OR OUTCOMES FROM USE OF THE SERVICE OR THAT THE SERVICE WILL BE
        UNINTERRUPTED OR ERROR-FREE. TO THE EXTENT THE FOREGOING DISCLAIMER IS
        EXPRESSLY PROHIBITED BY LAW, ANY AVAILABLE WARRANTY SHALL BE LIMITED TO
        THIRTY (30) DAYS AND TO THE SERVICE REMEDIES PROVIDED BY TADPOLE IN THIS
        SECTION 5.
      </p>

      <h2>6. Fees.</h2>
      <p>
        As consideration for the subscription to the Service, Customer shall pay
        all fees set forth in their contract (“Fees”) either annually in advance
        or monthly, depending on what the parties agree to in the contract. In
        addition to these subscription fees, the parties may agree that Tadpole
        will charge a percentage of revenues (paid in real time), per-user fees,
        per-feature fees, or any other fee structure set forth in the Customer’s
        contract. These additional fees are distinct from, and in addition to,
        any subscription fee arrangement and must be paid no later than the
        date(s) specified in the contract. If any Fees (including additional
        fees) are not paid when due, Tadpole reserves the right (in addition to
        any other rights or remedies Tadpole may have) to discontinue the
        Service and to suspend all Users’ and Customer’s access to the Service
        if any Fees are overdue until such amounts are paid in full. Except as
        expressly set forth in this Agreement, all Fees are non-refundable.
      </p>

      <h2>7. Compliance.</h2>
      <p>
        Each party will comply with all applicable laws and regulations with
        respect to its activities under this Agreement, including with respect
        to personally identifiable information from records that are subject to
        applicable privacy laws, including, but not limited to, the Family
        Educational Rights and Privacy Act, as amended (“Personal Information”).
        Without limiting the generality of the foregoing, Customer shall not
        make the Service available to any person or entity that: (a) is located
        in a country that is subject to a U.S. government embargo; or (b) is
        listed on any U.S. government list of prohibited or restricted parties.
      </p>

      <h2>8. Customer Content.</h2>
      <p>
        As between Tadpole and Customer, any and all information, data, results,
        plans, sketches, text, files, links, images, photos, videos, audio
        files, notes, or other materials uploaded by a User through the Service
        (“Customer Content”) remain the sole property of Customer. Tadpole may
        use the Customer Content solely to provide and improve the Service in
        accordance with this Agreement or Customer’s instructions.
      </p>

      <h2>9. Data Use.</h2>
      <p>
        Customer agrees that data derived from Tadpole’s provision of the
        Service or Customer’s use of the Service (“Usage Data”) may be used by
        Tadpole for the purposes of analysis, including statistical analysis,
        trend analysis, creation of data models, and creation of statistical
        rules. Such Usage Data will only be used in its aggregated or anonymized
        form and such results may be used by Tadpole for any lawful purpose not
        otherwise excluded by this Agreement. As between the parties, Tadpole
        owns the Usage Data. Notwithstanding anything contained in this
        Agreement to the contrary, Usage Data does not include Customer Content
        or any information that identifies or can be reasonably used to identify
        an individual person or Customer.
      </p>

      <h2>10. Third-Party Services.</h2>
      <p>
        Customer may access third-party services, content or links through the
        use of the Service (collectively “Third-Party Services”). Tadpole does
        not control Third-Party Services or make any representations or
        warranties with respect to Third-Party Services. In addition, Tadpole is
        not responsible for Third-Party Services.
      </p>

      <h2>11. Limitation of Liability.</h2>
      <p>
        EACH PARTY AND ITS SUPPLIERS SHALL NOT BE LIABLE TO THE OTHER PARTY FOR
        ANY INDIRECT, SPECIAL, EXEMPLARY, PUNITIVE, INCIDENTAL, OR CONSEQUENTIAL
        DAMAGES ARISING OUT OF OR RELATED TO THIS AGREEMENT OR THE USE OR
        INABILITY TO USE THE SERVICE (INCLUDING, WITHOUT LIMITATION, COSTS OF
        DELAY, LOSS OR INACCURACY OF DATA, RECORDS OR INFORMATION, COST(S) OF
        PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, AND ANY FAILURE OF DELIVERY
        OF THE SERVICE), EVEN IF THE OTHER PARTY HAS BEEN NOTIFIED OF THE
        LIKELIHOOD OF SUCH DAMAGES. EXCEPT FOR A PARTY’S INDEMNITY OBLIGATIONS
        IN SECTION 16.1, EACH PARTY’S CUMULATIVE MAXIMUM LIABILITY FOR DAMAGES
        ARISING OUT OF OR RELATED TO THIS AGREEMENT (WHETHER IN CONTRACT, TORT
        OR OTHERWISE) SHALL NOT EXCEED THE AMOUNT PAID BY CUSTOMER UNDER THIS
        AGREEMENT WITHIN THE TWELVE (12) MONTHS IMMEDIATELY PRECEDING THE EVENT
        GIVING RISE TO LIABILITY.
      </p>

      <h2>12. Confidentiality.</h2>
      <p>
        Each party acknowledges that it or any entity that directly, or
        indirectly through one or more intermediaries’ controls, is controlled
        by or is under common control with such party (an “Affiliate”) may
        disclose (in such capacity the “Disclosing Party”) Confidential
        Information to the other party or its Affiliates (in such capacity, the
        “Receiving Party”) in the performance of this Agreement. Accordingly,
        the Receiving Party shall: (a) keep the Confidential Information
        disclosed by the other party confidential; (b) use Confidential
        Information only for purposes of fulfilling its obligations and
        exercising its rights hereunder; and (c) disclose such Confidential
        Information only to the Receiving Party’s employees or Affiliates who
        have a need to know and only for the purposes of fulfilling this
        Agreement or to the extent required by law. As used herein,
        “Confidential Information” means any and all non-public, confidential
        and proprietary information, data, or know-how, including all Personal
        Information and information about the Disclosing Party’s businesses,
        operations, finances, properties, employees, relationships with third
        parties, plans, trade secrets, and other intellectual property and all
        analyses, compilations, forecasts, studies, summaries, notes, reports,
        memoranda, interpretations, data, and other materials which contain or
        are generated from the Confidential Information, whether disclosed in
        writing, orally, electronically, or by other means, and whether or not
        identified as confidential. For the avoidance of doubt, any non-public
        aspect of the Service will be considered the Confidential Information of
        Tadpole. Confidential Information shall not include information that:
        (i) is or becomes a matter of public knowledge through no fault of the
        Receiving Party; (ii) is rightfully received by the Receiving Party by a
        third party without a duty of confidentiality; (iii) is independently
        developed by the Receiving Party without the use of any Confidential
        Information of the Disclosing Party; or (iv) is identified by the
        Disclosing Party in writing as no longer confidential and proprietary.
        Notwithstanding the restrictions above, the Receiving Party may disclose
        the Confidential Information pursuant to law, regulation, subpoena or
        court orders, provided that the Receiving Party promptly notifies the
        Disclosing Party in writing prior to making any such disclosure to
        permit the Disclosing Party an opportunity to prevent disclosure or seek
        an appropriate remedy from the proper authority. The Receiving Party
        agrees to cooperate with the Disclosing Party in seeking such order or
        other remedy. The Receiving Party further agrees that if the Disclosing
        Party is not successful in precluding the requesting legal body from
        requiring the disclosure of the Confidential Information, it will
        furnish only that portion of the Confidential Information which is
        legally required (based on the advice of counsel) and will exercise all
        reasonable efforts to obtain reliable assurances that confidential
        treatment will be afforded the Confidential Information. Further, any
        information obtained by monitoring, reviewing, or recording is subject
        to review by law enforcement organizations in connection with
        investigation or prosecution of possible criminal or unlawful activity
        on the Service as well as to disclosures required by or under applicable
        law or related government agency actions. Tadpole will also comply with
        all court orders or subpoenas involving requests for such information.
      </p>

      <h2>13. Proprietary Rights.</h2>
      <p>
        As between Customer and Tadpole, the Tadpole Intellectual Property is,
        and shall at all times remain the sole and exclusive property of
        Tadpole. Tadpole shall have the right, in its sole discretion, to modify
        the Tadpole Intellectual Property. “Tadpole Intellectual Property”
        means: (a) the Service; (b) all improvements, changes, enhancements, and
        components thereof; (c) all other proprietary materials of Tadpole
        and/or its licensors; and (d) all other intellectual property owned by
        Tadpole including, but not limited to, all copyrights, patents,
        trademarks and trade names, trade secrets, specifications,
        methodologies, documentation, algorithms, criteria, designs, report
        formats, and know-how, as well as any underlying source code and object
        code related thereto.
      </p>

      <h2>14. Term and Termination.</h2>
      <p>
        The term of this Agreement is specified in the contract (“Term”) and
        shall continue for its full duration unless earlier terminated by a
        party in accordance with this Section 14. In addition to any other
        rights and remedies that may be available, either party may terminate
        this Agreement for a material breach of any provision of this Agreement
        by the other party if such material breach remains uncured for thirty
        (30) days after receipt of written notice of such breach from the
        non-breaching party. In the event the Agreement is terminated, all
        contracts are simultaneously terminated. Upon expiration or termination
        of this Agreement: (a) Customer shall immediately cease using the
        Service; and (b) in connection with certain aspects of the Service that
        feature an export function Customer may export the Customer Content by
        using the export feature within the Service for a period of three (3)
        months from termination, after which Tadpole shall have no obligation to
        maintain or provide any Customer Content.
      </p>

      <h2>15. Suspension of Service.</h2>
      <p>
        Tadpole may suspend a User’s access to the Service for a violation of
        Section 3 of this Agreement, any applicable law, or third-party rights
        to the extent and for the duration necessary to
        help@tadpoletutoring.orgaddress any such violation. Tadpole will use
        commercially reasonable efforts to provide notice to Customer in advance
        of any suspension unless such violation may cause direct harm to the
        Service or may result in liability to Tadpole. Customer agrees that
        Tadpole will not be liable to Customer or a User if Tadpole exercises
        its suspension rights as permitted by this Section 15.
      </p>

      <h2>16. Release / Indemnification.</h2>
      <p>
        Customer agrees to release Tadpole, its members, managers, officers,
        employees, and agents from any and all liability and obligations
        whatsoever in connection with or arising from their use of the Platform.
        If at any time Customer is not happy with the Platform or objects to any
        material within the Platform, Customer’s sole remedy is to cease using
        it. Customer shall indemnify, defend, and hold harmless Tadpole from any
        third-party claims arising from Customer’s breach of this Agreement or
        misuse of the Service. Tadpole shall indemnify, defend, and hold
        harmless Customer from any third-party claims that the Service infringes
        a third party’s intellectual property rights. Tadpole’s obligations do
        not apply if the alleged infringement arises from Customer Content,
        modifications by Customer, or Third-Party Services.
      </p>

      <h2>17. Dispute Resolution and Arbitration</h2>
      <p>
        YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING,
        INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A
        CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION. WE EXPLAIN SOME
        EXCEPTIONS AND HOW YOU CAN OPT OUT OF ARBITRATION BELOW.
      </p>

      <h3>a) Agreement to Binding Arbitration Between Customer and Tadpole.</h3>
      <p>
        CUSTOMER AND TADPOLE MUTUALLY AGREE TO WAIVE OUR RESPECTIVE RIGHTS TO
        RESOLUTION OF DISPUTES IN A COURT OF LAW BY A JUDGE OR JURY AND AGREE TO
        RESOLVE ANY DISPUTE BY ARBITRATION, as set forth below. This arbitration
        provision (“Arbitration Provision”) is governed by the Federal
        Arbitration Act and survives after the Agreement terminates or
        Customer’s relationship with Tadpole ends. ANY ARBITRATION UNDER THIS
        ARBITRATION PROVISION WILL TAKE PLACE ON AN INDIVIDUAL BASIS; CLASS
        ARBITRATIONS AND CLASS ACTIONS ARE NOT PERMITTED. Except as expressly
        provided below, this Arbitration Provision applies to all claims between
        Customer and Tadpole, including Tadpole’s affiliates, subsidiaries,
        parents, successors and assigns, and each of Tadpole’s respective
        officers, directors, employees, agents, or shareholders.
      </p>

      <p>
        Except as expressly provided below, ALL DISPUTES AND CLAIMS BETWEEN US
        (EACH A “CLAIM” AND COLLECTIVELY, “CLAIMS”) SHALL BE EXCLUSIVELY
        RESOLVED BY BINDING ARBITRATION SOLELY BETWEEN CUSTOMER AND TADPOLE.
        These claims include, but are not limited to, any dispute, claim or
        controversy, whether based on past, present, or future events, arising
        out of or relating to: this Agreement and prior versions thereof
        (including the breach, termination, enforcement, interpretation or
        validity thereof); the Platform; any other goods or service made
        available through the Platform; Customer relationship with Tadpole; the
        threatened or actual suspension, deactivation or termination of this
        Agreement; payments made by Customer or any payments made or allegedly
        owed to Customer; any promotions or offers made by Tadpole; any city,
        county, state or federal wage-hour law; trade secrets; unfair
        competition; breaks and rest periods; expense reimbursement; wrongful
        termination; discrimination; harassment; retaliation; fraud; defamation;
        emotional distress; breach of any express or implied contract or
        covenant; claims arising under federal or state consumer protection
        laws; claims arising under antitrust laws; claims arising under the
        Telephone Consumer Protection Act; and claims arising under the Uniform
        Trade Secrets Act, Civil Rights Act of 1964, Americans With Disabilities
        Act, Age Discrimination in Employment Act, Older Workers Benefit
        Protection Act, Family Medical Leave Act, Fair Labor Standards Act,
        Employee Retirement Income Security Act (except for individual claims
        for employee benefits under any benefit plan sponsored by Tadpole and
        covered by the Employee Retirement Income Security Act of 1974 or funded
        by insurance for employees eligible under the specific benefit plan),
        and state statutes, if any, addressing the same or similar subject
        matters, and all other federal and state statutory and common law
        claims. All disputes concerning the arbitrability of a Claim (including
        disputes about the scope, applicability, enforceability, revocability or
        validity of the Arbitration Provision) shall be decided by the
        arbitrator, except as expressly provided below.
      </p>

      <p>
        BY AGREEING TO ARBITRATION, CUSTOMER UNDERSTANDS THAT CUSTOMER AND
        TADPOLE ARE WAIVING THE RIGHT TO SUE IN COURT OR HAVE A JURY TRIAL FOR
        ALL CLAIMS, EXCEPT AS EXPRESSLY OTHERWISE PROVIDED IN THIS ARBITRATION
        PROVISION. This Arbitration Provision is intended to require arbitration
        of every claim or dispute that can lawfully be arbitrated, except for
        those claims and disputes which by the terms of this Arbitration
        Provision are expressly excluded from the requirement to arbitrate.
      </p>

      <h3>b) Prohibition of Class Actions and Non-Individualized Relief.</h3>
      <p>
        CUSTOMER UNDERSTANDS AND AGREES THAT CUSTOMER AND TADPOLE MAY EACH BRING
        CLAIMS IN ARBITRATION AGAINST THE OTHER ONLY IN AN INDIVIDUAL CAPACITY
        AND NOT ON A CLASS, COLLECTIVE ACTION, OR REPRESENTATIVE BASIS (“CLASS
        ACTION WAIVER”). CUSTOMER UNDERSTANDS AND AGREES THAT CUSTOMER AND
        TADPOLE BOTH ARE WAIVING THE RIGHT TO PURSUE OR HAVE A DISPUTE RESOLVED
        AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS, COLLECTIVE OR
        REPRESENTATIVE PROCEEDING. NOTWITHSTANDING THE FOREGOING, THIS SECTION
        (b) SHALL NOT APPLY TO REPRESENTATIVE PRIVATE ATTORNEYS GENERAL ACT
        CLAIMS BROUGHT AGAINST TADPOLE, WHICH ARE ADDRESSED SEPARATELY IN
        SECTION (c).
      </p>

      <p>
        The arbitrator shall have no authority to consider or resolve any Claim
        or issue any relief on any basis other than an individual basis. The
        arbitrator shall have no authority to consider or resolve any claim or
        issue any relief on a class, collective, or representative basis.
      </p>

      <p>
        Notwithstanding any other provision of this Agreement, the Arbitration
        Provision, the American Arbitration Association (“AAA”) Consumer
        Arbitration Rules (“AAA Rules”), disputes regarding the scope,
        applicability, enforceability, revocability or validity of the Class
        Action Waiver may be resolved only by a civil court of competent
        jurisdiction and not by an arbitrator. In any case in which: (1) the
        dispute is filed as a class, collective, or representative action and
        (2) there is a final judicial determination that the Class Action Waiver
        is unenforceable as to any claims, the class, collective, and/or
        representative action on such claims must be litigated in a civil court
        of competent jurisdiction, but the Class Action Waiver shall be enforced
        in arbitration on an individual basis as to all other claims to the
        fullest extent possible.
      </p>

      <h3>c) Representative PAGA Waiver.</h3>
      <p>
        Notwithstanding any other provision of this Agreement or the Arbitration
        Provision, to the fullest extent permitted by law: (1) Customer and
        Tadpole agree not to bring a representative action on behalf of others
        under the Private Attorneys General Act of 2004 (“PAGA”), California
        Labor Code § 2698 et seq., in any court or in arbitration, and (2) for
        any claim brought on a private attorney general basis, including under
        the California PAGA, both Customer and Tadpole agree that any such
        dispute shall be resolved in arbitration on an individual basis only
        (i.e., to resolve whether Customer have personally been aggrieved or
        subject to any violations of law), and that such an action may not be
        used to resolve the claims or rights of other individuals in a single or
        collective proceeding (i.e., to resolve whether other individuals have
        been aggrieved or subject to any violations of law) (collectively,
        “representative PAGA Waiver”). Notwithstanding any other provision of
        this Agreement, the Arbitration Provision or the AAA Rules, disputes
        regarding the scope, applicability, enforceability, revocability or
        validity of this representative PAGA Waiver may be resolved only by a
        civil court of competent jurisdiction and not by an arbitrator. If any
        provision of this representative PAGA Waiver is found to be
        unenforceable or unlawful for any reason: (i) the unenforceable
        provision shall be severed from this Agreement; (ii) severance of the
        unenforceable provision shall have no impact whatsoever on the
        Arbitration Provision or the requirement that any remaining claims be
        arbitrated on an individual basis pursuant to the Arbitration Provision;
        and (iii) any such representative PAGA or other representative private
        attorneys general act claims must be litigated in a civil court of
        competent jurisdiction and not in arbitration. To the extent that there
        are any claims to be litigated in a civil court of competent
        jurisdiction because a civil court of competent jurisdiction determines
        that the representative PAGA Waiver is unenforceable with respect to
        those claims, the parties agree that litigation of those claims shall be
        stayed pending the outcome of any individual claims in arbitration.
      </p>

      <h3>d) Rules Governing the Arbitration.</h3>
      <p>
        Any arbitration conducted pursuant to this Arbitration Provision shall
        be administered by the AAA pursuant to its Consumer Arbitration Rules
        that are in effect at the time the arbitration is initiated, as modified
        by the terms set forth in this Arbitration Provision. Copies of the
        Consumer Arbitration Rules can be obtained at the AAA’s website (
        <a href="http://www.adr.org">www.adr.org</a>) or by calling the AAA at
        1-800-778-7879. Notwithstanding the foregoing, if requested by Customer
        and if proper based on the facts and circumstances of the claims
        presented, the arbitrator shall have the discretion to select a
        different set of AAA Rules, but in no event shall the arbitrator
        consolidate more than one person’s claims, or otherwise preside over any
        form of representative, collective, or class proceeding.
      </p>

      <p>
        As part of the arbitration, both Customer and Tadpole will have the
        opportunity for reasonable discovery of non-privileged information that
        is relevant to the claim. The arbitrator may award any individualized
        remedies that would be available in court. The arbitrator may award
        declaratory or injunctive relief only in favor of the individual party
        seeking relief and only to the extent necessary to provide relief
        warranted by that party’s individual claims. The arbitrator will provide
        a reasoned written statement of the arbitrator’s decision which shall
        explain the award given and the findings and conclusions on which the
        decision is based.
      </p>

      <p>
        The arbitrator will decide the substance of all claims in accordance
        with applicable law, and will honor all claims of privilege recognized
        by law. The arbitrator’s award shall be final and binding and judgment
        on the award rendered by the arbitrator may be entered in any court
        having jurisdiction thereof, provided that any award may be challenged
        in a court of competent jurisdiction.
      </p>

      <h3>e) Arbitration Fees and Awards.</h3>
      <p>
        The payment of filing and arbitration fees will be governed by the
        relevant AAA Rules subject to the following modifications:
      </p>
      <p>
        If Customer initiates arbitration under this Arbitration Provision after
        participating in the optional Negotiation process described in section
        (j) below and are otherwise required to pay a filing fee under the
        relevant AAA Rules, Tadpole agrees that, unless Customer’s claim is for
        $5,000 or more, Customer’s share of the filing and arbitration fees is
        limited to $50, and that, after Customer submits proof of payment of the
        filing fee to Tadpole, Tadpole will promptly reimburse Customer for all
        but $50 of the filing fee. If, however, the arbitrator finds that either
        the substance of Customer’s claim or the relief sought in the claim is
        frivolous or brought for an improper purpose (as measured by the
        standards set forth in Federal Rule of Civil Procedure 11(b)), then the
        payment of all such fees will be governed by the AAA Rules.
      </p>

      <p>
        If Tadpole initiates arbitration under this Arbitration Provision,
        Tadpole will pay all AAA filing and arbitration fees.
      </p>

      <p>
        Except as provided in Federal Rule of Civil Procedure 68 or any state
        equivalents, each party shall pay its own attorneys’ fees and pay any
        costs that are not unique to the arbitration (i.e., costs that each
        party would incur if the claim(s) were litigated in a court such as
        costs to subpoena witnesses and/or documents, take depositions and
        purchase deposition transcripts, copy documents, etc.).
      </p>

      <p>
        At the end of any arbitration, the arbitrator may award reasonable
        arbitration fees and costs or any portion thereof to Customer if
        Customer prevails, to the extent authorized by applicable law.
      </p>

      <p>
        Although under some laws Tadpole may have a right to an award of
        attorneys' fees and non-filing fee expenses if it prevails in an
        arbitration, Tadpole agrees that it will not seek such an award.
      </p>

      <p>
        If the arbitrator issues Customer an award that is greater than the
        value of Tadpole’s last written settlement offer made after Customer
        participated in good faith in the optional Negotiation process described
        in section (i) below, then Tadpole will pay Customer the amount of the
        award or U.S. $200, whichever is greater.
      </p>

      <h3>f) Location and Manner of Arbitration.</h3>
      <p>
        Unless Customer and Tadpole agree otherwise, any arbitration hearings
        between Customer and Tadpole will take place in the county of Customer’s
        billing address. If AAA arbitration is unavailable in your county, the
        arbitration hearings will take place in the nearest available location
        for a AAA arbitration. If your claim is for $10,000 or less, Tadpole
        agrees that you may choose whether the arbitration will be conducted
        solely on the basis of documents submitted to the arbitrator, through a
        telephonic hearing, or by an in-person hearing as determined by the AAA
        Rules. If your claim exceeds $10,000, the right to a hearing will be
        determined by the AAA Rules.
      </p>

      <h3>g) Exceptions to Arbitration.</h3>
      <p>
        This Arbitration Provision shall not require arbitration of the
        following types of claims: (1) small claims actions brought on an
        individual basis that are within the scope of such small claims court’s
        jurisdiction; (2) a representative action brought on behalf of others
        under PAGA or other private attorneys general acts, to the extent the
        representative PAGA Waiver in section (c) of such action is deemed
        unenforceable by a court of competent jurisdiction; (3) claims for
        workers’ compensation, state disability insurance and unemployment
        insurance benefits; and (4) claims that may not be subject to
        arbitration as a matter of law.
      </p>

      <p>
        Nothing in this Arbitration Provision prevents Customer from making a
        report to or filing a claim or charge with any local, state, or federal
        agency and nothing in this Arbitration Provision shall be deemed to
        preclude or excuse a party from bringing an administrative claim before
        any local, state, or federal agency, to the extent Customer is entitled
        to pursue such a claim, in order to fulfill the party’s obligation to
        exhaust administrative remedies before making a claim in arbitration,
        however Customer knowingly and voluntarily waives the right to seek or
        recover money damages of any type pursuant to any administrative
        complaint and instead may seek such relief only through arbitration
        under this Arbitration Provision. Nothing in this Agreement or
        Arbitration Provision prevents Customer’s participation in an
        investigation by a government agency of any report, claim or charge
        otherwise covered by this Arbitration Provision.
      </p>

      <h3>h) Severability.</h3>
      <p>
        In addition to the severability provisions in section (c) above, in the
        event that any portion of this Arbitration Provision is deemed illegal
        or unenforceable, such provision shall be severed and the remainder of
        the Arbitration Provision shall be given full force and effect.
      </p>

      <h3>i) Opting Out of Arbitration.</h3>
      <p>
        Customers may opt out of the requirement to arbitrate claims defined in
        section (e)(3) pursuant to the terms of this section. If Customer does
        not wish to be subject to this Arbitration Provision with respect to
        claims, Customer may opt out of arbitration with respect to such claims
        by notifying Tadpole in writing of Customer’s desire to opt out of
        arbitration for such claims, which writing must be dated, signed and
        delivered by: (1) electronic mail to andrew@tadpoletutoring.org or (2)
        by certified mail, postage prepaid and return receipt requested, or by
        any nationally recognized delivery Service (e.g, UPS, Federal Express,
        etc.) that is addressed to:
      </p>
      <p>
        Tadpole Education Incorporated
        <br />
        Attn: Legal Department
        <br />
        251 Little Falls Drive,
        <br />
        Wilmington, New Castle County, Delaware 19808
      </p>
      <p>
        In order to be effective, (A) the writing must clearly indicate
        Customer’s intent to opt out of this Arbitration Provision with respect
        to claims, (B) the writing must include the name, phone number, and
        email address associated with Customer, and (C) the email or envelope
        containing the signed writing must be sent within 30 days of the date
        this Agreement is executed by Customer. Should Customer not opt out
        within the 30-day period, Customer and Tadpole shall be bound by the
        terms of this Arbitration Provision in full.
      </p>

      <p>
        Customer should assume that there may be now, and may be in the future,
        lawsuits against Tadpole alleging class, collective, and/or
        representative claims in which the plaintiffs seek to act on Customer’s
        behalf, and which, if successful, could result in some monetary recovery
        to Customer. But if Customer does agree to arbitration of claims with
        Tadpole under this Arbitration Provision, Customer is agreeing in
        advance that Customer will bring all such claims, and seek all monetary
        and other relief, against Tadpole in an individual arbitration
        provision. Customer is also agreeing in advance that Customer will not
        participate in, or seek to recover monetary or other relief, for such
        claims in any court action or class, collective, and/or representative
        action. Customer has the right to consult with counsel of their choice
        concerning this Arbitration Provision and Customer will not be subject
        to retaliation, if Customer exercise their right to assert claims or
        timely opt-out of arbitration, for any claims under this Arbitration
        Provision.
      </p>

      <h3>j) Optional Pre-Arbitration Negotiation Process.</h3>
      <p>
        Before initiating any arbitration or proceeding, Customer and Tadpole
        may agree to first attempt to negotiate any dispute, claim or
        controversy between the parties informally for 30 days, unless this time
        period is mutually extended by Customer and Tadpole. A party who intends
        to seek negotiation under this section must first send to the other a
        written notice of the dispute (“Notice”). The Notice must (1) describe
        the nature and basis of the claim or dispute; and (2) set forth the
        specific relief sought. All offers, promises, conduct and statements,
        whether oral or written, made in the course of the negotiation by any of
        the parties, their agents, employees, and attorneys are confidential,
        privileged and inadmissible for any purpose, including as evidence of
        liability or for impeachment, in arbitration or other proceeding
        involving the parties, provided that evidence that is otherwise
        admissible or discoverable shall not be rendered inadmissible or
        non-discoverable as a result of its use in the negotiation.
      </p>

      <h2>18. General.</h2>
      <p>
        Each party acknowledges that any breach, threatened or actual, of this
        Agreement, including, without limitation, with respect to unauthorized
        use of proprietary assets, will cause irreparable injury to the other
        party, such injury would not be quantifiable in monetary damages, and
        the other party would not have an adequate remedy at law. Each party
        therefore agrees that the other party shall be entitled, in addition to
        other available remedies, to seek and be awarded an injunction or other
        appropriate equitable relief from a court of competent jurisdiction
        restraining any breach, threatened or actual, of this Agreement. Each
        party waives any requirement that the other party post any bond or other
        security in the event any injunctive or equitable relief is sought by or
        awarded to enforce any provision of this Agreement. Any legal notice by
        a party under this Agreement shall be in writing and either personally
        delivered, delivered by email or reputable overnight courier (such as
        Federal Express) or certified mail, postage prepaid and return receipt
        requested, addressed to the other party at the address specified in the
        contract or such other address of which either party may from time to
        time notify the other in accordance with this Section 17. A copy of all
        notices to Tadpole shall be sent to: Tadpole Education Incorporated, 251
        Little Falls Drive, Wilmington, New Castle County, Delaware 19808,
        Attention: General Counsel and, if by email, to
        andrew@tadpoletutoring.org. For purposes of service messages and notices
        about the Service, Tadpole may place a banner notice or send an email to
        the current email address associated with an account and all notices
        shall be in English and deemed effective upon receipt. If Tadpole is
        unable to perform its obligations under this Agreement due to
        circumstances beyond its reasonable control, including, but not limited
        to, acts of God, earthquakes, hacker attacks, actions or decrees of
        governmental bodies, changes in applicable laws, or communication or
        power failures, such obligations will be suspended so long as those
        circumstances persist. This Agreement shall be interpreted, governed,
        and construed by the laws of the State of Delaware without regard to
        principles of conflict of laws. Tadpole is an independent contractor to
        Customer. If any term of this Agreement is invalid or unenforceable, the
        other terms remain in effect and the invalid or unenforceable provision
        will be deemed modified so that it is valid and enforceable to the
        maximum extent permitted by law. Tadpole may amend, revise or update
        these Master Terms and Conditions at any time. Such amendment, revision
        or update shall be effective upon 30 days’ notice to Customer by any
        means reasonable to give Customer actual or constructive notice,
        including by posting such terms on Tadpole’s website. In the event
        Customer does not agree with any amendment, revision or update, Customer
        shall give Tadpole notice within 30 days of receiving actual or
        constructive notice from Tadpole and Customer and Tadpole shall
        negotiate in good faith to come to an agreement solely with respect to
        such amendment, revision or update. In the event Customer does not give
        notice to Tadpole, Customer’s continued use of the Service after the
        30-day notice period shall indicate Customer’s agreement with any such
        amendment, revision or update. The Parties agree that: (a) this
        Agreement constitutes the entire agreement between the parties with
        respect to the subject matter thereof, and any prior representations,
        statements, and agreements relating thereto are superseded by the terms
        of this Agreement; and (b) Customer may use purchase orders or similar
        documents only as proof of acceptance of each Contract and for
        convenience only, and all terms and conditions (preprinted or otherwise
        and regardless of how referenced) shall be void and of no effect. Any
        attempt by Customer to assign this Agreement, in whole or part, to any
        entity, without Tadpole’s prior written consent shall be void. This
        Agreement shall be binding upon and shall inure to the benefit of the
        parties hereto and their successors and permitted assigns. Any failure
        by either party to enforce the other party's strict performance of any
        provision of this Agreement will not constitute a waiver of its right to
        subsequently enforce such provision or any other provision of this
        Agreement. Customer agrees to allow Tadpole to use its name, logo, and
        non-competitive use details in both text and pictures in its various
        marketing communications and materials, in accordance with Customer’s
        trademark guidelines and policies. Any terms that by their nature
        survive termination or expiration of this Agreement will survive
        (including, but not limited to, Sections 4, 5, 6, 12, 13, 14, 15, and
        18).
      </p>

      <hr style={{ margin: "4rem 0" }} />

      <h2>Acceptable Use Policy</h2>
      <p>
        Tadpole Educated and Incorporated (and its affiliate(s)) (referenced
        herein as “Tadpole”) Acceptable Use Policy rules and guidelines
        (referenced herein collectively as the “AUP GUIDELINES”) cover and
        govern each individual end user’s (referenced herein with “you” or with
        “your”) use and access to Tadpole’s services and websites (referenced
        herein as the “SERVICE”). Tadpole’s Privacy Policy explains how or
        whether Tadpole (referenced herein with “we” “our” and “us”) collects
        and uses your personally identifiable information. BY USING OUR SERVICE,
        YOU’RE AGREEING TO USE THE SERVICE IN ACCORDANCE WITH THESE AUP
        GUIDELINES, AND TO REVIEW TADPOLE’S PRIVACY POLICY. PLEASE ALSO NOTE
        THAT THESE AUP GUIDELINES AND THE PRIVACY POLICY ARE SUBJECT TO CHANGE.
        PLEASE REVIEW THE APPLICABLE CURRENT VERSIONS PRIVACY POLICY AND
        ACCEPTABLE USE POLICY. IF YOU DO NOT AGREE TO BE BOUND BY THESE AUP
        GUIDELINES OR DISAGREE WITH THE PERSONALLY IDENTIFIABLE INFORMATION
        COLLECTION AND USE PRACTICES, YOU SHOULD AND MAY NOT ACCESS OR USE THE
        SERVICE.
      </p>

      <h3>LICENSE GRANT</h3>
      <p>
        These AUP Guidelines provide to you a personal, revocable, limited,
        non-exclusive, and non-transferable license to use the Service
        conditioned upon your continued compliance with these AUP Guidelines.
        Tadpole reserves all rights not granted in the AUP Guidelines.
      </p>

      <h3>YOUR CONTENT &amp; YOUR PERMISSIONS</h3>
      <p>
        When you use our Service, you provide us with things like your files,
        content, messages, etc. (“Your Content”). Your Content is yours. You
        represent that you have all necessary right, power, and authority to use
        the Service and share Your Content and will comply with all applicable
        laws when doing so. These AUP Guidelines don’t give us any rights to
        Your Content except for the limited rights that enable us to offer the
        Service. We need your permission to do things like host Your Content,
        back it up, or share it when you ask us to. This permission extends to
        our trusted third parties we work with. You therefore give us, and third
        parties (with whom we work) permission to use, display, host, share, and
        back up Your Content. By submitting Your Content, you also permit us to
        identify you by your username and as the contributor of Your Content.
      </p>

      <h3>SHARING YOUR CONTENT</h3>
      <p>
        Our Service lets you share Your Content with others, so please think
        carefully about what you share. By using the Service, you agree to do so
        responsibly and to not misuse the Service or help anyone else do so.
        This includes, but is not limited to, not doing or trying to do any of
        the following in connection with the Service:
      </p>

      <ul>
        <li>
          store, transmit or create libelous, obscene, deceptive, defamatory,
          pornographic, racist, sexual, hateful, unlawful, tortious or otherwise
          objectionable content (except as necessary for legitimate
          instructional purposes, but in all cases in compliance with applicable
          laws and regulations);
        </li>
        <li>
          modify, translate, adapt, merge, make derivative works of,
          disassemble, decompile, reverse compile or reverse engineer any part
          of the Service except to the extent the foregoing restrictions are
          expressly prohibited by applicable law;
        </li>
        <li>
          harm, harass, threaten, or impersonate any person or violate the
          rights of any third party;
        </li>
        <li>
          probe, scan, or test the vulnerability of any system or network;
        </li>
        <li>
          interfere with or disrupt the integrity or performance of the Service;
        </li>
        <li>
          attempt to gain unauthorized access to the Service or its related
          systems or networks;
        </li>
        <li>
          introduce viruses, Trojan horses, worms, spyware, or other such
          malicious code into the Service;
        </li>
        <li>
          breach or otherwise circumvent any security or authentication
          measures;
        </li>
        <li>
          access, tamper with, or use non-public areas or parts of the Service,
          or shared areas of the Service you haven't been invited to;
        </li>
        <li>
          access, search, or create accounts for the Service by any means other
          than our publicly supported interfaces (for example, "scraping" or
          creating accounts in bulk);
        </li>
        <li>
          send unsolicited communications, promotions or advertisements, or
          spam;
        </li>
        <li>
          send altered, deceptive or false source-identifying information,
          including "spoofing" or "phishing";
        </li>
        <li>
          promote or advertise products or services other than your own without
          appropriate authorization;
        </li>
        <li>
          sell or re-sell the Service unless specifically authorized to do so;
        </li>
        <li>
          copy, reproduce, distribute, republish, download, display, post or
          transmit in any form or by any means any part of the Service unless
          specifically authorized to do so;
        </li>
        <li>
          remove or destroy any copyright notices or other proprietary markings
          contained on or in the Service;
        </li>
        <li>
          violate the law in any way, including by storing, publishing or
          sharing material that is infringing, fraudulent, defamatory,
          misleading, or damaging to Tadpole in any way.
        </li>
      </ul>

      <h3>YOUR RESPONSIBILITIES</h3>
      <p>
        You must provide all equipment necessary to access the Service and are
        responsible for all fees incurred when accessing the Service.
        Additionally, you’re responsible for your conduct and Your Content and
        your compliance with these AUP Guidelines. Content in the Service may be
        protected by others’ intellectual property rights. You therefore agree
        that you won’t copy, reproduce, create derivative works of, decompile,
        upload, download, share, or otherwise exploit third party content unless
        you have the right to do so. We may review your conduct and content for
        compliance with these AUP Guidelines and remove content from the Service
        at any time and without notice. With that said, we have no obligation to
        do so. We aren’t responsible for the content people post and share via
        the Service. You agree also to safeguard your password to the Service,
        make sure that others don’t have access to it, and keep your account
        information current.
      </p>

      <h3>THIRD PARTY WEBSITES</h3>
      <p>
        The Service may contain links to third-party websites that are not under
        our control. We are not responsible for such third-party websites.
      </p>

      <h3>OUR PROPERTY</h3>
      <p>
        The Service is protected by copyright, trademark, and other US and
        foreign laws. These AUP Guidelines don’t grant you any right, title, or
        interest in the Service or others’ content in the Service. We welcome
        feedback, but note that we may use comments or suggestions without any
        obligation to you.
      </p>

      <h3>INFRINGEMENT</h3>
      <p>
        We respect the intellectual property of others and ask that you do too.
        We respond to notices of alleged infringement after receipt of a proper
        notice that complies with the law and is received by our designated
        agent. We reserve the right to delete or disable content alleged to be
        infringing and terminate accounts of repeat infringers. If you believe
        that any content in the Service in a way that constitutes infringement,
        please provide our designated agent with the following information:
      </p>

      <ul>
        <li>
          an electronic or physical signature of the person authorized to act on
          behalf of the rights owner;
        </li>
        <li>a description of the content you claim is being infringed;</li>
        <li>
          a description or link to the location of the material claim is
          infringing;
        </li>
        <li>your physical address, telephone number and e-mail address;</li>
        <li>
          a statement that you have a good faith belief that the content is not
          authorized by the rights owner, its agent or the law;
        </li>
        <li>
          a statement, made under penalty of perjury, that the information in
          your notice is accurate and that you are the owner or authorized to
          act on behalf of the owner.
        </li>
      </ul>

      <p>
        Contact information for Tadpole’s designated agent for notice of claims
        of infringement is as follows: General Counsel; 251 Little Falls Drive,
        Wilmington, New Castle County, Delaware 19808;
        andrew@tadpoletutoring.org. Tadpole may also take any appropriate action
        in the event it receives a proper counter-notice under the law.
      </p>

      <h3>TERMINATION &amp; RIGHT TO SUSPEND</h3>
      <p>
        You’re free to stop using our Service at any time. We also reserve the
        right to suspend or end the Service at any time at our discretion and
        without notice. For example, we may suspend or terminate your use of the
        Service and remove Your Content if you’re not complying with these AUP
        Guidelines, or using the Service in a manner that may cause us legal
        liability, disrupt the Service, disrupt others’ use of the Service or,
        in our sole opinion, reason, cause harm. All provisions of these AUP
        Guidelines, which by their nature should survive, will survive
        termination of the Service.
      </p>

      <h3>SERVICE “AS IS”</h3>
      <p>
        TADPOLE AND ITS PARTNERS MAKE NO WARRANTIES, EITHER EXPRESS OR IMPLIED,
        ABOUT THE SERVICE. THE SERVICE IS PROVIDED “AS IS.” IN ADDITION, WE
        DISCLAIM ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
        PURPOSE, TITLE AND NON-INFRINGEMENT. ANY CONTENT DOWNLOADED OR ACCESSED
        FROM THE SERVICE IS ACCESSED AT YOUR OWN RISK.
      </p>

      <h3>LIMITATION OF LIABILITY</h3>
      <p>
        YOU EXPRESSLY ABSOLVE AND RELEASE TADPOLE FROM ANY CLAIM OF HARM
        RESULTING FROM A CAUSE BEYOND TADPOLE’S CONTROL. YOU FURTHER AGREE THAT
        IN NO EVENT SHALL TADPOLE AND ITS SUPPLIERS BE LIABLE FOR ANY DAMAGES
        OTHER THAN DIRECT DAMAGES, OR ANY LOSS OF USE, DATA BUSINESS, OR
        PROFITS. THIS WILL BE REGARDLESS OF WHETHER OR NOT TADPOLE OR ANY OF ITS
        PARTNERS HAS BEEN WARNED OF THE POSSIBILITY OF SUCH DAMAGES.
        ADDITIONALLY, TADPOLE AND ITS SUPPLIERS WILL NOT BE LIABLE TO YOU FOR
        MORE THAN ANY AMOUNTS RECEIVED BY TADPOLE FROM YOU AS A RESULT OF YOUR
        PERSONAL USE OF THE SERVICE IN THE 12 MONTHS PRECEDING YOUR CLAIM. IF
        YOU HAVE NOT PAID INDIVIDUALLY ANY AMOUNTS IN THE 12 MONTHS PRECEDING
        YOUR CLAIM, TADPOLE’S SOLE AND EXCLUSIVE LIABILITY SHALL BE NO MORE THAN
        $50. MOREOVER, TADPOLE SHALL NOT BE LIABLE FOR THE LOSS OR FAILURE TO
        STORE YOUR CONTENT, AND YOU UNDERSTAND AND AGREE THAT BY USING THE
        SERVICE, YOU AGREE TO WAIVE THE RIGHT TO A TRIAL BY JURY AND THE RIGHT
        TO PARTICIPATE IN A CLASS ACTION RELATED TO USE OF THE SERVICE OR BREACH
        OF THESE AUP GUIDELINES.
      </p>

      <h3>CONTROLLING LAW</h3>
      <p>
        These AUP Guidelines and your use of the Service will be interpreted,
        governed and construed by the laws of Delaware law except for its
        conflicts of laws principles, unless otherwise agreed in a separate
        written agreement with Tadpole or as required by a mandatory law of a
        competent jurisdiction.
      </p>

      <h3>ENTIRE AGREEMENT</h3>
      <p>
        These AUP Guidelines set forth terms governing your use of the Service,
        and supersede and replace any other prior or contemporaneous agreements
        applicable to the subject matter of these AUP Guidelines. These AUP
        Guidelines create no third-party beneficiary rights. These AUP
        Guidelines shall inure to the benefit of Tadpole and its respective
        legal representatives, successors, and assigns.
      </p>

      <h3>WAIVER, SEVERABILITY &amp; ASSIGNMENT</h3>
      <p>
        Tadpole’s failure to enforce a provision is not a waiver of its right to
        do so later. You may not assign any of your rights under these AUP
        Guidelines, and any such attempt will be void. Tadpole may assign its
        rights to any of its affiliates or subsidiaries, or to any successor in
        interest of any business associated with the Service. If any provision
        of these AUP Guidelines is held by a court of competent jurisdiction to
        be contrary to law, such provision shall be changed and interpreted so
        as to best accomplish the objectives of the original provision to the
        fullest extent allowed by law and the remaining provisions of these AUP
        Guidelines will remain in full force and effect.
      </p>

      <h3>MODIFICATIONS</h3>
      <p>
        We may revise these AUP Guidelines from time to time, and will always
        post the most current version on the Platform. If a revision
        meaningfully reduces your rights, we will provide notice to you (by, for
        example, sending a message to the email address associated with your
        account). By continuing to use or access the Service after the revisions
        come into effect, you agree to be bound by and subject to the revised
        AUP Guidelines.
      </p>

      <h3>NOTICES</h3>
      <p>
        For any notices relating to these AUP Guidelines, you may contact
        Tadpole by sending an email to{" "}
        <a href="mailto:andrew@tadpoletutoring.org">
          andrew@tadpoletutoring.org
        </a>
        .
      </p>
    </div>
  );
}
