/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enum for meeting statuses-- has the tutor agreed to take the meeting.
 */
export enum MeetingStatus {
  ACCEPTED = "Accepted",
  PROPOSED = "Proposed",
  REJECTED = "Rejected",
}
