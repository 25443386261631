/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents the system that is used for calculating financial statistics
 * (revenue and expenses).
 */
export enum AccountingSystemForCalculatingFinancialStatistics {
  TRANSACTIONS = "TRANSACTIONS",
  MEETINGS = "MEETINGS",
}
