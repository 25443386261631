import { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentStatusInfo from "./PaymentStatusInfo";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TADPOLE_BILLING as string
);

const PaymentStatus = ({
  addedByStudentOrParentDirectly,
  initiatedByAdminFromOrgDashboardForStudent,
  initiatedByAdminFromOrgDashboardForParent,
}: {
  addedByStudentOrParentDirectly: boolean;
  initiatedByAdminFromOrgDashboardForStudent?: boolean;
  initiatedByAdminFromOrgDashboardForParent?: boolean;
}) => {
  const [userId, setUserId] = useState<string>("");
  const [setupIntentClientSecret, setSetupIntentClientSecret] =
    useState<string>("");
  const [options, setOptions] = useState<any>();

  useEffect(() => {
    // Retrieve the "setup_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const queryParams = new URLSearchParams(window.location.search);
    const setup_intent_client_secret = queryParams.get(
      "setup_intent_client_secret"
    );
    const incomingUserId = queryParams.get("user_id"); // Retrieve student_id or parent_id

    if (setup_intent_client_secret !== null) {
      setSetupIntentClientSecret(setup_intent_client_secret);
    }

    if (incomingUserId !== null) {
      setUserId(incomingUserId);
    }

    setOptions({ clientSecret: setup_intent_client_secret });
  }, []);

  return (
    <Elements stripe={stripePromise} options={options}>
      {addedByStudentOrParentDirectly && (
        <PaymentStatusInfo setupIntentClientSecret={setupIntentClientSecret} />
      )}

      {initiatedByAdminFromOrgDashboardForStudent && (
        <PaymentStatusInfo
          studentIdIfInitiatedByAdminFromOrgDashboard={userId}
          setupIntentClientSecret={setupIntentClientSecret}
        />
      )}

      {initiatedByAdminFromOrgDashboardForParent && (
        <PaymentStatusInfo
          parentIdIfInitiatedByAdminFromOrgDashboard={userId}
          setupIntentClientSecret={setupIntentClientSecret}
        />
      )}
    </Elements>
  );
};

export default PaymentStatus;
