import React, { useCallback, useContext, useEffect, useState } from "react";
import RadioInput from "components/Inputs/RadioInput";
import styles from "../styles.module.css";
import {
  StudentLimitedResponse,
  StudentsService,
  TutorLimitedResponse,
  TutorsService,
} from "client/openapi";
import Notifications from "util/notifications";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

export interface OrganizationTabProps {
  changeHandler: (values: Omit<OrganizationTabProps, "changeHandler">) => void;
  tutors_can_view_unassigned_students: boolean;
  auto_review_and_approve_meetings_for_admin: boolean;
  auto_review_and_approve_meetings_for_tutors: boolean;
  require_tutors_to_accept_meetings_booked_by_admins: boolean;
  require_tutors_to_accept_meetings_booked_by_tutors: boolean;
  require_tutors_to_accept_meetings_booked_by_students_or_parents: boolean;
  grace_period_for_editing_meetings_before_billing_and_payroll: number;
}

export const OrganizationTabContent: React.FC<OrganizationTabProps> = ({
  changeHandler,
  tutors_can_view_unassigned_students,
  auto_review_and_approve_meetings_for_admin,
  auto_review_and_approve_meetings_for_tutors,
  require_tutors_to_accept_meetings_booked_by_admins,
  require_tutors_to_accept_meetings_booked_by_tutors,
  require_tutors_to_accept_meetings_booked_by_students_or_parents,
  grace_period_for_editing_meetings_before_billing_and_payroll,
}) => {
  const { currently_selected_organization } = useContext(
    OrgRolesAndAccountContext
  );
  const [tutorUpForPromotion, setTutorUpForPromotion] =
    useState<TutorLimitedResponse>();
  const [tutor, setTutor] = useState<TutorLimitedResponse>();
  const [tutors, setTutors] = useState<TutorLimitedResponse[]>([]);
  const [student, setStudent] = useState<StudentLimitedResponse>();
  const [students, setStudents] = useState<StudentLimitedResponse[]>([]);
  const [dontSpamRemoveTutorButton, setDontSpamRemoveTutorButton] =
    useState<boolean>(false);
  const [dontSpamRemoveStudentButton, setDontSpamRemoveStudentButton] =
    useState<boolean>(false);
  const [dontSpamAdminPromotion, setDontSpamAdminPromotion] =
    useState<boolean>(false);

  // Local state for the grace period input
  const [graceInputValue, setGraceInputValue] = useState(
    grace_period_for_editing_meetings_before_billing_and_payroll.toString()
  );

  const updateField = <
    K extends keyof Omit<OrganizationTabProps, "changeHandler">
  >(
    fieldName: K,
    value: OrganizationTabProps[K]
  ) => {
    changeHandler({
      tutors_can_view_unassigned_students,
      auto_review_and_approve_meetings_for_admin,
      auto_review_and_approve_meetings_for_tutors,
      require_tutors_to_accept_meetings_booked_by_admins,
      require_tutors_to_accept_meetings_booked_by_tutors,
      require_tutors_to_accept_meetings_booked_by_students_or_parents,
      grace_period_for_editing_meetings_before_billing_and_payroll,
      [fieldName]: value,
    });
  };

  const handleTutorUpForPromotionChange = (option: any) => {
    setTutorUpForPromotion(option);
  };

  const handleTutorChange = (option: any) => {
    setTutor(option);
  };

  const handleStudentChange = (option: any) => {
    setStudent(option);
  };

  const getAndSetTutors = useCallback(async () => {
    TutorsService.getTutorsByOrganization({
      orgId: currently_selected_organization as number,
    })
      .then((tutors) => {
        setTutors(tutors);
      })
      .catch((error) => {
        Notifications.error("Could not get tutors");
        console.error(`Error (#${error.status}): ${error.message}`);
      });
  }, [currently_selected_organization]);

  const getAndSetStudents = useCallback(async () => {
    StudentsService.getStudentsByOrganizationIfAdmin({
      orgId: currently_selected_organization as number,
    })
      .then((students) => {
        setStudents(students);
      })
      .catch((error) => {
        Notifications.error("Could not get students");
        console.error(`Error (#${error.status}): ${error.message}`);
      });
  }, [currently_selected_organization]);

  useEffect(() => {
    getAndSetTutors();
    getAndSetStudents();
  }, [getAndSetStudents, getAndSetTutors]);

  const graceInputStyle: React.CSSProperties = {
    borderRadius: "8px",
    border: "1px solid #ccc",
    backgroundColor: "#e6ffe6",
    padding: "5px",
    fontSize: "14px",
    width: "100px",
  };

  const handleGracePeriodBlur = () => {
    if (graceInputValue.trim() === "") {
      // If empty, revert to "1"
      setGraceInputValue("1");
      updateField(
        "grace_period_for_editing_meetings_before_billing_and_payroll",
        1
      );
    } else {
      const numericValue = parseInt(graceInputValue, 10);
      const clampedValue = Math.max(
        1,
        Math.min(14, isNaN(numericValue) ? 1 : numericValue)
      );
      setGraceInputValue(clampedValue.toString());
      updateField(
        "grace_period_for_editing_meetings_before_billing_and_payroll",
        clampedValue
      );
    }
  };

  return (
    <>
      <div className={styles.infoItem}>
        <p className={styles.label}>Tutors Can :</p>
        <div className={styles.radioContainer}>
          <RadioInput
            id={"book-sessions-with-all-students"}
            name={"book-sessions-with-all-students"}
            value={"true"}
            label="See and book sessions with all students."
            setValue={() =>
              updateField("tutors_can_view_unassigned_students", true)
            }
            checked={tutors_can_view_unassigned_students === true}
          />
        </div>
        <div className={styles.radioContainer}>
          <RadioInput
            id={"see-sessions-with-paired-students"}
            name={"see-sessions-with-paired-students"}
            value={"false"}
            label="Only see and book sessions with students they are paired with."
            setValue={() =>
              updateField("tutors_can_view_unassigned_students", false)
            }
            checked={tutors_can_view_unassigned_students === false}
          />
        </div>
      </div>

      <div className={styles.infoItem}>
        <p className={styles.label}>
          Auto review and approve meetings for admin:
        </p>
        <div className={styles.radioContainer}>
          <RadioInput
            id={"auto-approve-admin-yes"}
            name={"auto-approve-admin"}
            value={"true"}
            label="Yes"
            setValue={() =>
              updateField("auto_review_and_approve_meetings_for_admin", true)
            }
            checked={auto_review_and_approve_meetings_for_admin === true}
          />
        </div>
        <div className={styles.radioContainer}>
          <RadioInput
            id={"auto-approve-admin-no"}
            name={"auto-approve-admin"}
            value={"false"}
            label="No"
            setValue={() =>
              updateField("auto_review_and_approve_meetings_for_admin", false)
            }
            checked={auto_review_and_approve_meetings_for_admin === false}
          />
        </div>
      </div>

      <div className={styles.infoItem}>
        <p className={styles.label}>
          Auto review and approve meetings for tutors:
        </p>
        <div className={styles.radioContainer}>
          <RadioInput
            id={"auto-approve-tutors-yes"}
            name={"auto-approve-tutors"}
            value={"true"}
            label="Yes"
            setValue={() =>
              updateField("auto_review_and_approve_meetings_for_tutors", true)
            }
            checked={auto_review_and_approve_meetings_for_tutors === true}
          />
        </div>
        <div className={styles.radioContainer}>
          <RadioInput
            id={"auto-approve-tutors-no"}
            name={"auto-approve-tutors"}
            value={"false"}
            label="No"
            setValue={() =>
              updateField("auto_review_and_approve_meetings_for_tutors", false)
            }
            checked={auto_review_and_approve_meetings_for_tutors === false}
          />
        </div>
      </div>

      <div className={styles.infoItem}>
        <p className={styles.label}>
          Require tutors to accept meetings booked by admins:
        </p>
        <div className={styles.radioContainer}>
          <RadioInput
            id={"require-admin-yes"}
            name={"require-admin"}
            value={"true"}
            label="Yes"
            setValue={() =>
              updateField(
                "require_tutors_to_accept_meetings_booked_by_admins",
                true
              )
            }
            checked={
              require_tutors_to_accept_meetings_booked_by_admins === true
            }
          />
        </div>
        <div className={styles.radioContainer}>
          <RadioInput
            id={"require-admin-no"}
            name={"require-admin"}
            value={"false"}
            label="No"
            setValue={() =>
              updateField(
                "require_tutors_to_accept_meetings_booked_by_admins",
                false
              )
            }
            checked={
              require_tutors_to_accept_meetings_booked_by_admins === false
            }
          />
        </div>
      </div>

      <div className={styles.infoItem}>
        <p className={styles.label}>
          Require tutors to accept meetings booked by tutors:
        </p>
        <div className={styles.radioContainer}>
          <RadioInput
            id={"require-tutor-yes"}
            name={"require-tutor"}
            value={"true"}
            label="Yes"
            setValue={() =>
              updateField(
                "require_tutors_to_accept_meetings_booked_by_tutors",
                true
              )
            }
            checked={
              require_tutors_to_accept_meetings_booked_by_tutors === true
            }
          />
        </div>
        <div className={styles.radioContainer}>
          <RadioInput
            id={"require-tutor-no"}
            name={"require-tutor"}
            value={"false"}
            label="No"
            setValue={() =>
              updateField(
                "require_tutors_to_accept_meetings_booked_by_tutors",
                false
              )
            }
            checked={
              require_tutors_to_accept_meetings_booked_by_tutors === false
            }
          />
        </div>
      </div>

      <div className={styles.infoItem}>
        <p className={styles.label}>
          Require tutors to accept meetings booked by students or parents:
        </p>
        <div className={styles.radioContainer}>
          <RadioInput
            id={"require-students-yes"}
            name={"require-students"}
            value={"true"}
            label="Yes"
            setValue={() =>
              updateField(
                "require_tutors_to_accept_meetings_booked_by_students_or_parents",
                true
              )
            }
            checked={
              require_tutors_to_accept_meetings_booked_by_students_or_parents ===
              true
            }
          />
        </div>
        <div className={styles.radioContainer}>
          <RadioInput
            id={"require-students-no"}
            name={"require-students"}
            value={"false"}
            label="No"
            setValue={() =>
              updateField(
                "require_tutors_to_accept_meetings_booked_by_students_or_parents",
                false
              )
            }
            checked={
              require_tutors_to_accept_meetings_booked_by_students_or_parents ===
              false
            }
          />
        </div>
      </div>

      <div className={styles.infoItem}>
        <p className={styles.label}>
          Grace period for editing meetings before billing and payroll (must be
          between 1 and 14 days):
        </p>
        <input
          type="number"
          style={graceInputStyle}
          value={graceInputValue}
          onChange={(e) => {
            setGraceInputValue(e.target.value);
          }}
          onBlur={handleGracePeriodBlur}
        />
      </div>

      {/* The following block remains commented as requested */}
      {/* <div>
        <p className="text-sm font-bold mb-1">Make a Tutor an Admin</p>
        <div className="flex gap-3">
          <div className="w-1/4">
            <Select
              id="tutors-up-for-promotion"
              options={tutors}
              value={tutorUpForPromotion as TutorLimitedResponse}
              getOptionLabel={(t) =>
                t.account.first_name + " " + t.account.last_name
              }
              getOptionValue={(t) => t.account.reference_id}
              placeholder="Tutor..."
              isDisabled={tutors?.length === 0}
              onChange={handleTutorUpForPromotionChange}
            />
          </div>
          <div>
            <Button
              color={
                tutorUpForPromotion === undefined ||
                tutorUpForPromotion === null ||
                dontSpamAdminPromotion
                  ? ButtonColor.GRAY
                  : ButtonColor.GREEN
              }
              disabled={
                tutorUpForPromotion === undefined ||
                tutorUpForPromotion === null ||
                dontSpamAdminPromotion
              }
              onClick={addAccountRole}
              size={ButtonSize.DEFAULT}
            >
              Make Admin
            </Button>
          </div>
        </div>
      </div>
      <div>
        <p className="text-sm font-bold mt-5 mb-1">Remove a Tutor</p>
        <div className="flex gap-3">
          <div className="w-1/4">
            <Select
              id="tutors"
              options={tutors}
              value={tutor as TutorLimitedResponse}
              getOptionLabel={(t) =>
                t.account.first_name + " " + t.account.last_name
              }
              getOptionValue={(t) => t.account.reference_id}
              placeholder="Tutor..."
              isDisabled={tutors?.length === 0}
              onChange={handleTutorChange}
            />
          </div>
          <div>
            <Button
              color={
                tutor === undefined ||
                tutor === null ||
                dontSpamRemoveTutorButton
                  ? ButtonColor.GRAY
                  : ButtonColor.GREEN
              }
              disabled={
                tutor === undefined ||
                tutor === null ||
                dontSpamRemoveTutorButton
              }
              onClick={removeTutor}
              size={ButtonSize.DEFAULT}
            >
              Remove Tutor
            </Button>
          </div>
        </div>
      </div>
      <div>
        <p className="text-sm font-bold mt-5 mb-1">Remove a Student</p>
        <div className="flex gap-3">
          <div className="w-1/4">
            <Select
              id="students"
              options={students}
              value={student as StudentLimitedResponse}
              getOptionLabel={(s) =>
                s.account.first_name + " " + s.account.last_name
              }
              getOptionValue={(s) => s.account.reference_id}
              placeholder="Student..."
              isDisabled={students?.length === 0}
              onChange={handleStudentChange}
            />
          </div>
          <div>
            <Button
              color={
                student === undefined ||
                student === null ||
                dontSpamRemoveStudentButton
                  ? ButtonColor.GRAY
                  : ButtonColor.GREEN
              }
              disabled={
                student === undefined ||
                student === null ||
                dontSpamRemoveStudentButton
              }
              onClick={removeStudent}
              size={ButtonSize.DEFAULT}
            >
              Remove Student
            </Button>
          </div>
        </div>
      </div> */}
    </>
  );
};
