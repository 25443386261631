import { ArrowRightIcon } from "@radix-ui/react-icons";
import {
  ApiError,
  Auth0AccountRole,
  InviteType,
  OrganizationResponseForAdmins,
  ParentAdminResponse,
  StudentLimitedResponse,
  StudentAdminResponse,
  StudentsService,
  TutorAdminResponse,
  ParentLimitedResponse,
  TutorLimitedResponse,
} from "client/openapi";
import Avatar from "components/Avatar";
import {
  Button,
  ButtonColor,
  ButtonFill,
  ButtonSize,
  LinkButton,
} from "components/Button";
import ComposeMessageDialog from "components/Dashboard/ComposeMessageDialog";
import { Dialog, DialogTrigger } from "components/Dialog";
import { useCallback, useContext, useEffect, useState } from "react";
import { AccountStatus } from "types";
import { concatenateName } from "util/concatenateName";
import { getButtonRoleColor, getRoleColor } from "util/contextColor";
import { generateInitials } from "util/generateInitials";
import EmptyState from "../EmptyState";
import PageNav from "../PageNav";
import UserListActions from "../UserListActions";
import "./index.css";
import Notifications from "util/notifications";
import { Tag } from "components/Tag/TagChip";
import { TagColor, TagSize } from "components/Tag";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

export enum StudentDashboardAction {
  MESSAGE = "message",
  NOTE = "note",
  DATA = "data",
}

function StudentsList({
  role,
  parentIfOnParentDashboard,
  tutorIfOnTutorDashboard,
  organizationIfOnOrgDashboard,
  org_name,
}: {
  role: Auth0AccountRole;
  parentIfOnParentDashboard?: ParentAdminResponse | ParentLimitedResponse;
  tutorIfOnTutorDashboard?: TutorAdminResponse | TutorLimitedResponse;
  organizationIfOnOrgDashboard?: OrganizationResponseForAdmins;
  org_name?: string;
}) {
  const { currently_selected_role, organizations_available_to_role } =
    useContext(OrgRolesAndAccountContext);

  const [initialStudents, setInitialStudents] = useState<
    StudentLimitedResponse[] | StudentAdminResponse[]
  >([]);
  const [students, setStudents] = useState<
    StudentLimitedResponse[] | StudentAdminResponse[]
  >([]);
  const [phrase, setPhrase] = useState<string>("");
  const [debouncedPhrase, setDebouncedPhrase] = useState<string>(phrase);

  const [totalStudents, setTotalStudents] = useState<number>(0);
  const [numPages, setNumPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const limit = 10;

  // 1) Helper to fetch ALL matching students without limit (or just get total)
  const fetchAllStudents = useCallback(async (): Promise<
    StudentLimitedResponse[] | StudentAdminResponse[]
  > => {
    // If phrase is too short, don’t include it in the query
    const phraseIfIncluded = phrase && phrase.length > 2 ? phrase : undefined;

    // Different endpoints depending on role
    if (organizationIfOnOrgDashboard) {
      // If your data set is small enough, fetch all:
      const data = await StudentsService.getStudentsByOrganizationIfAdmin({
        orgId: organizationIfOnOrgDashboard.id,
        phrase: phraseIfIncluded,
        // NO skip or limit
      });
      return data;
    } else if (tutorIfOnTutorDashboard) {
      const data = await StudentsService.getStudentsByOrganizationIfTutor({
        orgId: tutorIfOnTutorDashboard.org_id,
        phrase: phraseIfIncluded,
      });
      return data;
    } else if (parentIfOnParentDashboard) {
      const data = await StudentsService.getStudentsByOrganizationIfParent({
        orgId: parentIfOnParentDashboard.org_id,
        phrase: phraseIfIncluded,
      });
      return data;
    }

    return [];
  }, [
    organizationIfOnOrgDashboard,
    tutorIfOnTutorDashboard,
    parentIfOnParentDashboard,
    phrase,
  ]);

  // 2) The actual fetch function that retrieves one "page" of data
  const fetchStudents = useCallback(
    async (pageNumber: number) => {
      try {
        // First fetch ALL (no skip/limit) so we can set the correct total
        const allStudents = await fetchAllStudents();
        setTotalStudents(allStudents.length);

        // If you want to store them in "initialStudents" to keep a full copy
        if (phrase === "" && pageNumber === 0) {
          setInitialStudents(allStudents);
        }

        // Now fetch ONLY the slice we need
        const skip = pageNumber * limit;
        const phraseIfIncluded =
          phrase && phrase.length > 2 ? phrase : undefined;

        let pagedData: StudentLimitedResponse[] | StudentAdminResponse[] = [];

        if (organizationIfOnOrgDashboard) {
          pagedData = await StudentsService.getStudentsByOrganizationIfAdmin({
            orgId: organizationIfOnOrgDashboard.id,
            phrase: phraseIfIncluded,
            skip,
            limit,
          });
        } else if (tutorIfOnTutorDashboard) {
          pagedData = await StudentsService.getStudentsByOrganizationIfTutor({
            orgId: tutorIfOnTutorDashboard.org_id,
            phrase: phraseIfIncluded,
            skip,
            limit,
          });
        } else if (parentIfOnParentDashboard) {
          pagedData = await StudentsService.getStudentsByOrganizationIfParent({
            orgId: parentIfOnParentDashboard.org_id,
            phrase: phraseIfIncluded,
            skip,
            limit,
          });
        }

        setStudents(pagedData);

        // Recalculate numPages based on totalStudents
        setNumPages(Math.ceil(allStudents.length / limit));
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    },
    [
      fetchAllStudents,
      organizationIfOnOrgDashboard,
      tutorIfOnTutorDashboard,
      parentIfOnParentDashboard,
      phrase,
    ]
  );

  // Update `phrase` after 1 second
  useEffect(() => {
    const timer = setTimeout(() => setPhrase(debouncedPhrase), 1000);
    return () => clearTimeout(timer);
  }, [debouncedPhrase]);

  // Whenever phrase or currentPage changes, fetch again
  useEffect(() => {
    // Start from page 0 if phrase changed
    if (phrase !== "") {
      setCurrentPage(0);
      fetchStudents(0);
    } else {
      fetchStudents(currentPage);
    }
  }, [phrase, currentPage, fetchStudents]);

  return (
    <>
      {initialStudents.length > 0 ? (
        <div className="py-6 px-7 rounded-xl bg-neutral-50">
          <div className="flex flex-col justify-between sm:flex-row sm:items-center">
            <div className="flex flex-col items-start">
              <h2 className="text-xl font-bold">Students</h2>
              {[Auth0AccountRole.ORG_TUTOR, Auth0AccountRole.PARENT].includes(
                currently_selected_role as Auth0AccountRole
              ) &&
                (organizations_available_to_role?.length as number) > 1 &&
                (tutorIfOnTutorDashboard || parentIfOnParentDashboard) &&
                org_name}
              {students.length > 0 && (
                <PageNav
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  numPages={numPages}
                  limit={limit}
                  totalUsers={totalStudents}
                  role={role}
                  fetchUsers={fetchStudents}
                />
              )}
            </div>

            {[Auth0AccountRole.ORG_ADMIN, Auth0AccountRole.ORG_TUTOR].includes(
              currently_selected_role as Auth0AccountRole
            ) && (
              <UserListActions
                users={students}
                userType={Auth0AccountRole.ME}
                buttonColor={getButtonRoleColor(role)}
                debouncedPhrase={debouncedPhrase}
                setDebouncedPhrase={setDebouncedPhrase}
                role={role}
                fetchUsers={fetchStudents}
                currentPage={currentPage}
                orgId={
                  (organizationIfOnOrgDashboard?.id ||
                    tutorIfOnTutorDashboard?.org_id ||
                    parentIfOnParentDashboard?.org_id) as number
                }
              />
            )}
          </div>

          {students.length > 0 ? (
            <div className="grid gap-8 mt-6 lg:grid-cols-2">
              {students.map((student) => (
                <StudentListItem
                  key={student.id}
                  student={student}
                  role={role}
                />
              ))}
            </div>
          ) : (
            <EmptyState
              type_of_user_being_invited={Auth0AccountRole.ME}
              includeInviteUserDialog={false}
              title="No students"
              message="Please search again"
              buttonColor={getButtonRoleColor(role)}
              fetchUsers={fetchStudents}
              currentPage={currentPage}
              orgId={
                (organizationIfOnOrgDashboard?.id ||
                  tutorIfOnTutorDashboard?.org_id ||
                  parentIfOnParentDashboard?.org_id) as number
              }
            />
          )}
        </div>
      ) : (
        <EmptyState
          type_of_user_being_invited={Auth0AccountRole.ME}
          includeInviteUserDialog={true}
          title="No students"
          message={
            currentPage === 0
              ? tutorIfOnTutorDashboard
                ? `No students from ${org_name} have been assigned to you.`
                : parentIfOnParentDashboard
                ? `You do not have any students in ${org_name}.`
                : ""
              : "There are no more students. See previous page."
          }
          buttonColor={getButtonRoleColor(role)}
          fetchUsers={fetchStudents}
          currentPage={currentPage}
          orgId={
            (organizationIfOnOrgDashboard?.id ||
              tutorIfOnTutorDashboard?.org_id ||
              parentIfOnParentDashboard?.org_id) as number
          }
        />
      )}
    </>
  );
}

function StudentListItem({
  role,
  student,
}: {
  role: Auth0AccountRole;
  student: StudentLimitedResponse | StudentAdminResponse;
}) {
  const { account } = useContext(OrgRolesAndAccountContext);
  const { currently_selected_role } = useContext(OrgRolesAndAccountContext);

  const [avoidSpammingInviteButton, setAvoidSpammingInviteButton] =
    useState<boolean>(false);

  async function reinviteStudent() {
    StudentsService.reinviteStudent({
      requestBody: {
        first_name: student.first_name,
        last_name: student.last_name,
        phone_number: student.phone_number ? student.phone_number : undefined,
        email: student.email as string,
        org_invited_by: student.org_id,
        acct_invited_by: account?.reference_id as string,
        type: InviteType.STUDENT,
        user_id: student.id,
      },
    })
      .then(() => {
        Notifications.success("Student reinvited!");
        setAvoidSpammingInviteButton(false);
      })
      .catch((e: ApiError) => {
        Notifications.error("Unable to reinvite student");
        console.error(`Error (#${e.status}): ${e.message}`);
        setAvoidSpammingInviteButton(false);
      });
  }

  return (
    <div className="flex flex-row items-center justify-between student-list-container">
      <div className="flex flex-row items-center gap-4">
        <Avatar
          fallback={generateInitials(concatenateName(student))}
          alt={generateInitials(concatenateName(student))}
          color={getRoleColor(role)}
        />

        <div>
          {(student as StudentAdminResponse).account &&
            (student as StudentAdminResponse).account?.status ===
              AccountStatus.INVITED && (
              <div className="mb-1">
                <Tag
                  size={TagSize.MINI}
                  color={TagColor.GOLD}
                  item={AccountStatus.INVITED}
                />
              </div>
            )}

          <h3 className="m-0 primsecond-text">
            {student.first_name} {student.last_name}
          </h3>

          <p className="small-text text-muted">{student.email}</p>
        </div>
      </div>

      <div className="flex flex-col gap-2 md:items-center md:flex-row student-list-buttons">
        {(student as StudentAdminResponse).account &&
          (student as StudentAdminResponse).account?.status ===
            AccountStatus.INVITED &&
          [Auth0AccountRole.ORG_ADMIN, Auth0AccountRole.ORG_TUTOR].includes(
            currently_selected_role as Auth0AccountRole
          ) && (
            <Button
              size={ButtonSize.SMALL}
              color={
                avoidSpammingInviteButton
                  ? ButtonColor.PURPLE
                  : getButtonRoleColor(role)
              }
              disabled={avoidSpammingInviteButton}
              fill={ButtonFill.DEFAULT}
              onClick={() => {
                setAvoidSpammingInviteButton(true);
                reinviteStudent();
              }}
            >
              Reinvite
            </Button>
          )}
        <Dialog>
          <DialogTrigger asChild>
            <Button
              size={ButtonSize.SMALL}
              color={getButtonRoleColor(role)}
              fill={ButtonFill.HOLLOW}
            >
              Message
            </Button>
          </DialogTrigger>

          <ComposeMessageDialog students={[student]} org_id={student.org_id} />
        </Dialog>

        <LinkButton
          to={`/manage/students/${student.id}`}
          size={ButtonSize.SMALL}
          color={getButtonRoleColor(role)}
        >
          View Details <ArrowRightIcon />
        </LinkButton>
      </div>
    </div>
  );
}

export default StudentsList;
