import {
  AccountingSystemForCalculatingFinancialStatistics,
  MeetingAdminResponse,
  PayoutTransactionResponse,
  TadpoleToTutorTransactionResponse,
  OrgToTadpoleTransactionResponse,
  StudentToOrgTransactionResponse,
  TransactionType,
  ApprovalStatus,
} from "client/openapi";
import "./TransactionSettings.css"; // Add some nice styles for the dropdowns

function TransactionSettings({
  accountingSystem,
  setAccountingSystem,
  setTransactions,
  transactionTypeFilter,
  setTransactionTypeFilter,
  adminApprovalFilter,
  setAdminApprovalFilter,
}: {
  accountingSystem: AccountingSystemForCalculatingFinancialStatistics;
  setAccountingSystem: (
    accountingSystem: AccountingSystemForCalculatingFinancialStatistics
  ) => void;
  setTransactions: (
    transactions:
      | (
          | StudentToOrgTransactionResponse
          | OrgToTadpoleTransactionResponse
          | TadpoleToTutorTransactionResponse
          | PayoutTransactionResponse
        )[]
      | MeetingAdminResponse[]
  ) => void;
  transactionTypeFilter: TransactionType | "all";
  setTransactionTypeFilter: (value: TransactionType | "all") => void;
  adminApprovalFilter: ApprovalStatus | "all";
  setAdminApprovalFilter: (value: ApprovalStatus | "all") => void;
}) {
  return (
    <div className="transaction-settings-container">
      <div className="flex items-center mx-4">
        <div className="toggle-container">
          <button
            className={`toggle-button ${
              accountingSystem ===
              AccountingSystemForCalculatingFinancialStatistics.TRANSACTIONS
                ? "active"
                : ""
            }`}
            onClick={() => {
              setAccountingSystem(
                AccountingSystemForCalculatingFinancialStatistics.TRANSACTIONS
              );
              setTransactions([]);
            }}
          >
            Transactions
          </button>
          <button
            className={`toggle-button ${
              accountingSystem ===
              AccountingSystemForCalculatingFinancialStatistics.MEETINGS
                ? "active"
                : ""
            }`}
            onClick={() => {
              setAccountingSystem(
                AccountingSystemForCalculatingFinancialStatistics.MEETINGS
              );
              setTransactions([]);
            }}
          >
            Meetings
          </button>
        </div>

        {/* Transaction type filter - now inline */}
        {accountingSystem ===
          AccountingSystemForCalculatingFinancialStatistics.TRANSACTIONS && (
          <div className="ml-4 flex items-center">
            <label className="mr-2 font-medium">Type:</label>
            <select
              className="rounded-lg py-1 pl-2 pr-8 border border-gray-300 text-sm w-50"
              value={transactionTypeFilter}
              onChange={(e) =>
                setTransactionTypeFilter(
                  e.target.value as TransactionType | "all"
                )
              }
            >
              <option value={"all"}>All Transactions</option>
              <option value={TransactionType.PAYOUT_TO_BANK}>
                Payout to Bank
              </option>
              <option value={TransactionType.STUDENT_TO_ORGANIZATION}>
                Payment from Student
              </option>
              <option value={TransactionType.TADPOLE_TO_TUTOR}>
                Payment to Tutor
              </option>
              <option value={TransactionType.ORGANIZATION_TO_TADPOLE}>
                Funding Payroll
              </option>
            </select>
          </div>
        )}

        {/* Admin approval filter - now inline */}
        {accountingSystem ===
          AccountingSystemForCalculatingFinancialStatistics.MEETINGS && (
          <div className="ml-4 flex items-center">
            <label className="mr-2 font-medium">Admin Approval Status:</label>
            <select
              className="rounded-lg py-1 px-2 border border-gray-300 text-sm w-40"
              value={adminApprovalFilter}
              onChange={(e) =>
                setAdminApprovalFilter(e.target.value as ApprovalStatus | "all")
              }
            >
              <option value={"all"}>All Meetings</option>
              <option value={ApprovalStatus.APPROVED}>Approved</option>
              <option value={ApprovalStatus.DENIED}>Denied</option>
              <option value={ApprovalStatus.PENDING}>Pending</option>
            </select>
          </div>
        )}
      </div>
    </div>
  );
}

export default TransactionSettings;
