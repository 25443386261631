import { ArrowRightIcon } from "@radix-ui/react-icons";
import {
  ApiError,
  Auth0AccountRole,
  InviteType,
  OrganizationResponseForAdmins,
  ParentAdminResponse,
  ParentLimitedResponse,
  ParentsService,
} from "client/openapi";
import Avatar from "components/Avatar";
import {
  Button,
  ButtonColor,
  ButtonFill,
  ButtonSize,
  LinkButton,
} from "components/Button";
import ComposeMessageDialog from "components/Dashboard/ComposeMessageDialog";
import { Dialog, DialogTrigger } from "components/Dialog";
import { TagColor, TagSize } from "components/Tag";
import { Tag } from "components/Tag/TagChip";
import { useContext, useEffect, useState } from "react";
import { AccountStatus } from "types";
import { concatenateName } from "util/concatenateName";
import { getButtonRoleColor, getRoleColor } from "util/contextColor";
import { generateInitials } from "util/generateInitials";
import EmptyState from "../EmptyState";
import PageNav from "../PageNav";
import "./index.css";
import UserListActions from "../UserListActions";
import Notifications from "util/notifications";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

function ParentsList({
  organization,
  role,
}: {
  organization: OrganizationResponseForAdmins;
  role: Auth0AccountRole;
}) {
  const [initialParents, setInitialParents] = useState<
    ParentAdminResponse[] | ParentLimitedResponse[]
  >([]);
  const [parents, setParents] = useState<
    ParentAdminResponse[] | ParentLimitedResponse[]
  >([]);
  const [phrase, setPhrase] = useState<string>("");
  const [debouncedPhrase, setDebouncedPhrase] = useState<string>(phrase);

  const [totalParents, setTotalParents] = useState<number>(0);
  const [numPages, setNumPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const limit = 10;

  async function fetchParents(pageNumber: number): Promise<void> {
    const skip = pageNumber * limit;
    if (organization) {
      if (phrase === "" && initialParents.length === 0) {
        await ParentsService.getParentsByOrganization({
          orgId: organization.id,
          skip: skip,
          limit: limit,
        }).then((data: ParentAdminResponse[] | ParentLimitedResponse[]) => {
          setInitialParents(data);
          setParents(data);
        });
      } else {
        await ParentsService.getParentsByOrganization({
          orgId: organization.id,
          phrase: phrase && phrase.length > 2 ? phrase : undefined,
          skip: skip,
          limit: limit,
        }).then((data: ParentAdminResponse[] | ParentLimitedResponse[]) => {
          setParents(data);
          if (phrase !== "") {
            setTotalParents(data.length);
            setNumPages(Math.ceil(data.length / limit));
          }
        });
      }
    }
  }

  // update `phrase` after 1 second
  useEffect(() => {
    const timer = setTimeout(() => setPhrase(debouncedPhrase), 1000);
    return () => clearTimeout(timer);
  }, [debouncedPhrase]);

  // submit a new search
  useEffect(() => {
    if (phrase !== "") {
      setCurrentPage(0);
      fetchParents(0);
    } else {
      fetchParents(currentPage);
      setTotalParents(organization?.parents ? organization.parents.length : 0);
      setNumPages(
        Math.ceil(
          (organization?.parents ? organization.parents.length : 0) / limit
        )
      );
    }
  }, [phrase]);

  return (
    <>
      {initialParents.length > 0 ? (
        <div className="py-6 px-7 rounded-xl bg-neutral-50">
          <div className="flex flex-col justify-between sm:flex-row sm:items-center">
            <div className="flex flex-col items-start">
              <h2 className="text-xl font-bold">Parents</h2>
              {parents.length > 0 ? (
                <PageNav
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  numPages={numPages}
                  limit={limit}
                  totalUsers={totalParents}
                  role={role}
                  fetchUsers={fetchParents}
                />
              ) : null}
            </div>

            <UserListActions
              users={parents}
              userType={Auth0AccountRole.PARENT}
              buttonColor={ButtonColor.PURPLE}
              debouncedPhrase={debouncedPhrase}
              setDebouncedPhrase={setDebouncedPhrase}
              role={role}
              fetchUsers={fetchParents}
              currentPage={currentPage}
              orgId={organization.id}
            />
          </div>

          {parents.length > 0 ? (
            <div className="grid gap-8 mt-6 lg:grid-cols-2">
              {parents.map((parent) => (
                <ParentListItem
                  key={parent.id}
                  role={role}
                  parent={parent}
                  organization={organization}
                />
              ))}
            </div>
          ) : (
            <EmptyState
              type_of_user_being_invited={Auth0AccountRole.PARENT}
              includeInviteUserDialog={false}
              title="No parents"
              message="Please search again"
              buttonColor={ButtonColor.PURPLE}
              fetchUsers={fetchParents}
              currentPage={currentPage}
              orgId={organization.id}
            />
          )}
        </div>
      ) : (
        <EmptyState
          type_of_user_being_invited={Auth0AccountRole.PARENT}
          includeInviteUserDialog={true}
          title="No parents"
          message="Your organization does not have any parents yet."
          buttonColor={ButtonColor.PURPLE}
          fetchUsers={fetchParents}
          currentPage={currentPage}
          orgId={organization.id}
        />
      )}
    </>
  );
}

function ParentListItem({
  role,
  parent,
  organization,
}: {
  role: Auth0AccountRole;
  parent: ParentAdminResponse;
  organization?: OrganizationResponseForAdmins;
}) {
  const { account } = useContext(OrgRolesAndAccountContext);

  const [avoidSpammingInviteButton, setAvoidSpammingInviteButton] =
    useState<boolean>(false);

  async function reinviteParent() {
    ParentsService.reinviteParent({
      requestBody: {
        first_name: parent.first_name,
        last_name: parent.last_name,
        phone_number: parent.phone_number ? parent.phone_number : undefined,
        email: parent.email as string,
        org_invited_by: parent.org_id,
        acct_invited_by: account?.reference_id as string,
        type: InviteType.PARENT,
        user_id: parent.id,
      },
    })
      .then((s) => {
        Notifications.success("Parent reinvited!");
        setAvoidSpammingInviteButton(false);
      })
      .catch((e: ApiError) => {
        Notifications.error("Unable to reinvite parent");
        console.error(`Error (#${e.status}): ${e.message}`);
        setAvoidSpammingInviteButton(false);
      });
  }

  return (
    <div className="flex flex-row items-center justify-between parent-list-container">
      <div className="flex flex-row items-center gap-4">
        <Avatar
          fallback={generateInitials(concatenateName(parent))}
          alt={generateInitials(concatenateName(parent))}
          color={getRoleColor(role)}
        />

        <div>
          {parent.account &&
            parent.account.status === AccountStatus.INVITED && (
              <div className="mb-1">
                <Tag
                  size={TagSize.MINI}
                  color={TagColor.GOLD}
                  item={AccountStatus.INVITED}
                />
              </div>
            )}

          <h3 className="m-0 primsecond-text">
            {parent.first_name} {parent.last_name}
          </h3>

          <p className="small-text text-muted">{parent.email}</p>
        </div>
      </div>

      <div className="flex flex-col gap-2 md:items-center md:flex-row parent-list-buttons">
        {parent.account && parent.account.status === AccountStatus.INVITED && (
          <Button
            size={ButtonSize.SMALL}
            color={
              avoidSpammingInviteButton
                ? ButtonColor.PURPLE
                : getButtonRoleColor(role)
            }
            disabled={avoidSpammingInviteButton}
            fill={ButtonFill.DEFAULT}
            onClick={() => {
              setAvoidSpammingInviteButton(true);
              reinviteParent();
            }}
          >
            Reinvite
          </Button>
        )}
        <Dialog>
          <DialogTrigger asChild>
            <Button
              size={ButtonSize.SMALL}
              color={ButtonColor.PURPLE}
              fill={ButtonFill.HOLLOW}
            >
              Message
            </Button>
          </DialogTrigger>

          <ComposeMessageDialog parents={[parent]} org_id={parent.org_id} />
        </Dialog>

        <LinkButton
          to={`/manage/parents/${parent.id}`}
          size={ButtonSize.SMALL}
          color={ButtonColor.PURPLE}
        >
          View Details <ArrowRightIcon />
        </LinkButton>
      </div>
    </div>
  );
}

export default ParentsList;
