import MeetingsStats from "components/StudentAttendance";
import PerformanceChart from "components/StudentPerformanceChart";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import {
  StudentLimitedResponse,
  ChartSubscriptionResponse,
  MeetingStats,
} from "client/openapi";
import { Dialog, DialogTrigger } from "components/Dialog";
import AddChartDialog from "components/StudentPerformanceChart/AddChartDialog";
import DeleteChartDialog from "components/StudentPerformanceChart/DeleteChartDialog";

export default function MetricTab({
  student,
  orgId,
  charts,
  meetingStats,
  updateCharts,
  setUpdateCharts,
}: {
  student: StudentLimitedResponse;
  orgId: number;
  charts: ChartSubscriptionResponse[];
  meetingStats: MeetingStats;
  updateCharts: boolean;
  setUpdateCharts: (updateCharts: boolean) => void;
}) {
  return (
    <div>
      <div className="w-full flex flex-row items-center justify-start gap-4">
        <Dialog>
          <DialogTrigger asChild>
            <Button
              color={ButtonColor.SKYBLUE}
              size={ButtonSize.SMALL}
              extraClasses="mb-2"
            >
              Add Chart
            </Button>
          </DialogTrigger>

          <AddChartDialog
            orgId={orgId}
            studentId={student.id}
            updateCharts={updateCharts}
            setUpdateCharts={setUpdateCharts}
          />
        </Dialog>

        <Dialog>
          <DialogTrigger asChild>
            <Button
              color={ButtonColor.SKYBLUE}
              size={ButtonSize.SMALL}
              extraClasses="mb-2"
            >
              Delete Chart
            </Button>
          </DialogTrigger>

          <DeleteChartDialog
            studentId={student.id}
            updateCharts={updateCharts}
            setUpdateCharts={setUpdateCharts}
          />
        </Dialog>
      </div>

      <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-14 gap-y-8 mx-5 md:mx-0">
        <div className="h-80">
          <MeetingsStats student={student} meetings={meetingStats} />
        </div>

        {charts &&
          charts.map((chart, index) => (
            <div className="h-80" key={chart.id}>
              <PerformanceChart
                id={index}
                studentId={student.id}
                initialChart={chart}
                updateCharts={updateCharts}
                setUpdateCharts={setUpdateCharts}
              />
            </div>
          ))}
      </div>
    </div>
  );
}
