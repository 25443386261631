import MeetingHistory from "./MeetingHistory";
import Students from "./Students";
import {
  MeetingAdminResponse,
  TutorAdminResponse,
  StudentLimitedResponse,
} from "client/openapi";
import { APIResponse, PageStatus } from "types";

export default function TutorInfoTab({
  meetings,
  exportingMeetings,
  setExportingMeetings,
  tutor,
  exportingData,
  organization_id,
  orgStudents,
  setStatus,
  setError,
}: {
  meetings: MeetingAdminResponse[];
  exportingMeetings: boolean;
  setExportingMeetings: (boolean) => void;
  tutor: TutorAdminResponse;
  exportingData: boolean;
  organization_id: number;
  orgStudents: StudentLimitedResponse[];
  setStatus: (PageStatus) => void;
  setError: (APIResponse) => void;
}) {
  return (
    <div className="grid grid-cols-1 gap-y-5 md:grid-cols-2 h-full">
      <div className="flex justify-center mx-5 mt-5 md:justify-end md:mx-0 md:mt-0">
        <MeetingHistory
          meetings={meetings}
          exportingMeetings={exportingMeetings}
          setExportingMeetings={setExportingMeetings}
          tutor={tutor}
          exportingData={exportingData}
          organization_id={organization_id}
          setStatus={setStatus}
          setError={setError}
        />
      </div>

      <div className="flex justify-center mx-3 mt-5 md:justify-end md:mx-0 md:mt-0">
        <Students tutor={tutor} orgStudents={orgStudents} />
      </div>
    </div>
  );
}
