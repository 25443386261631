import {
  MeetingStatus,
  ApprovalStatus,
  Auth0AccountRole,
} from "client/openapi";
import { useContext } from "react";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

export function MeetingFilters({
  meetingStatusFilter,
  setMeetingStatusFilter,
  adminApprovalFilter,
  setAdminApprovalFilter,
  tutorApprovalFilter,
  setTutorApprovalFilter,
}: {
  meetingStatusFilter: MeetingStatus | undefined;
  setMeetingStatusFilter: (val: MeetingStatus | undefined) => void;
  adminApprovalFilter: ApprovalStatus | undefined;
  setAdminApprovalFilter: (val: ApprovalStatus | undefined) => void;
  tutorApprovalFilter: ApprovalStatus | undefined;
  setTutorApprovalFilter: (val: ApprovalStatus | undefined) => void;
}) {
  const selectClasses =
    "border rounded-md p-1 text-sm bg-gray-100 focus:bg-white focus:outline-none focus:ring-1 focus:ring-gray-300 w-32";

  const labelClasses = "mr-2 text-sm font-medium";

  const { currently_selected_role } = useContext(OrgRolesAndAccountContext);

  const isAdmin = currently_selected_role === Auth0AccountRole.ORG_ADMIN;

  return (
    <div className="flex gap-4 items-center">
      <div className="flex items-center gap-2">
        <label className={labelClasses}>Reviewed by Admin:</label>
        <select
          className={selectClasses}
          value={adminApprovalFilter ?? ""}
          onChange={(e) =>
            setAdminApprovalFilter(
              e.target.value ? (e.target.value as ApprovalStatus) : undefined
            )
          }
        >
          <option value="">Any</option>
          <option value={ApprovalStatus.APPROVED}>Approved</option>
          <option value={ApprovalStatus.PENDING}>Pending</option>
          <option value={ApprovalStatus.DENIED}>Denied</option>
        </select>
      </div>

      <div className="flex items-center gap-2">
        <label className={labelClasses}>
          {isAdmin
            ? "Meeting Accepted by All Tutors:"
            : "Meeting Accepted by You:"}
        </label>
        <select
          className={selectClasses}
          value={meetingStatusFilter ?? ""}
          onChange={(e) =>
            setMeetingStatusFilter(
              e.target.value ? (e.target.value as MeetingStatus) : undefined
            )
          }
        >
          <option value="">Any</option>
          <option value={MeetingStatus.ACCEPTED}>Accepted</option>
          <option value={MeetingStatus.PROPOSED}>Proposed</option>
          <option value={MeetingStatus.REJECTED}>Rejected</option>
        </select>
      </div>

      <div className="flex items-center gap-2">
        <label className={labelClasses}>
          {isAdmin ? "Reviewed by All Tutors:" : "Reviewed by You:"}
        </label>
        <select
          className={selectClasses}
          value={tutorApprovalFilter ?? ""}
          onChange={(e) =>
            setTutorApprovalFilter(
              e.target.value ? (e.target.value as ApprovalStatus) : undefined
            )
          }
        >
          <option value="">Any</option>
          <option value={ApprovalStatus.APPROVED}>Approved</option>
          <option value={ApprovalStatus.PENDING}>Pending</option>
          <option value={ApprovalStatus.DENIED}>Denied</option>
        </select>
      </div>
    </div>
  );
}
