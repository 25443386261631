/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents the type of a student-to-organization transaction.
 */
export enum StudentToOrgTransactionType {
  DIRECT = "DIRECT",
  UPFRONT = "UPFRONT",
  AUTOMATIC_CHARGE_FOR_A_GROUP_OF_MEETINGS = "AUTOMATIC_CHARGE_FOR_A_GROUP_OF_MEETINGS",
  MEMBER_OF_AUTOMATIC_CHARGE_GROUP = "MEMBER_OF_AUTOMATIC_CHARGE_GROUP",
  MEETING_PAID_WITH_CREDITS = "MEETING_PAID_WITH_CREDITS",
  CANCELLATION_FEE = "CANCELLATION_FEE",
  RESCHEDULING_FEE = "RESCHEDULING_FEE",
  ABSENCE_FEE = "ABSENCE_FEE",
  REFUND = "REFUND",
}
