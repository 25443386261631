import { ArrowRightIcon } from "@radix-ui/react-icons";
import { Button, ButtonColor } from "components/Button";
import { useNavigate } from "react-router-dom";

function SignupSuccess({ type }: { type: string }) {
  const navigate = useNavigate();

  return (
    <div
      className="flex items-center justify-center"
      style={{ height: "75vh" }}
    >
      <div>
        <h1 className="headline-9 mt-5 mb-3">Success!</h1>
        <div className="signup_text mt-2 mb-2">
          Your {type.charAt(0).toUpperCase() + type.slice(1)} account has been
          created and is now active.
        </div>
        <div className="d-flex mt-4">
          <Button
            color={ButtonColor.GREEN}
            block={true}
            type="button"
            onClick={() => navigate("/KYC/organization")}
          >
            Complete KYC <ArrowRightIcon />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SignupSuccess;
