import {
  CalendarIcon,
  ClockIcon,
  PersonIcon,
  RocketIcon,
  SewingPinIcon,
} from "@radix-ui/react-icons";
import {
  MeetingAdminResponse,
  MeetingLimitedResponse,
  SessionFrequency,
} from "client/openapi";
import MeetingInfoItem from "./meetingInfoItem";
import { TagColor, TagSize } from "components/Tag";
import { Tag } from "components/Tag/TagChip";
import moment from "moment";
import { WEEKDAYS } from "..";
import { useEffect, useState } from "react";
import { parseFrequency } from "util/parseRecurrencePattern";

const TIME_FORMAT = "h:mm A";

export default function ViewMeetingDetails({
  event,
}: {
  event: MeetingAdminResponse | MeetingLimitedResponse;
}) {
  const [meetingDays, setMeetingDays] = useState<string[]>([]);

  async function getWeekdays() {
    if (!event?.start) {
      return;
    } else {
      const meeting_day = moment(event.start)
        .format("ddd")
        .toUpperCase()
        .substring(0, 2);
      setMeetingDays([meeting_day]);
    }
  }

  useEffect(() => {
    getWeekdays();
  }, [event]);

  return (
    <>
      <div>
        <div className="grid grid-cols-12 p-2 pr-2">
          <div className="pt-1 col-span-1">
            <CalendarIcon />
          </div>
          <div className="col-span-11 sm:col-span-6 pb-4 grid-rows-3 sm:border-b-2 sm:border-b-slate-300 space-y-2">
            <div className="block md:flex">
              <p className="font-bold">
                {moment(event.start).format("ddd, MMM Do, YYYY")},&nbsp;
              </p>
              {moment(event.start).format(TIME_FORMAT)} -{" "}
              {moment(event.start)
                .add(event.duration, "minutes")
                .format(TIME_FORMAT)}
            </div>
            <p>
              {event.session.frequency === SessionFrequency.SINGLE
                ? "does not repeat"
                : `repeats ${parseFrequency(
                    event.session.frequency
                  )} until ${moment(event.session.ends_on_or_before).format(
                    "MM/DD/YY"
                  )}`}
            </p>
          </div>
          <div className="col-span-11 col-start-2 sm:col-span-5 grid-rows-3 border-b-2 border-b-slate-300 space-y-2 pb-4">
            <p className="font-bold">This sequence is on:</p>
            {WEEKDAYS.map((day, index) => {
              return (
                <span key={index} className="mr-2">
                  <Tag
                    item={day}
                    color={
                      meetingDays.includes(day) ? TagColor.GREEN : TagColor.GRAY
                    }
                    size={TagSize.SQUARE}
                  />
                </span>
              );
            })}
          </div>
        </div>
        <MeetingInfoItem icon={<SewingPinIcon />}>
          <p>
            {event.other_location ? event.other_location : event.location?.name}
          </p>
        </MeetingInfoItem>
        <MeetingInfoItem icon={<ClockIcon />}>
          <p>{event.duration} minutes</p>
        </MeetingInfoItem>
        <MeetingInfoItem icon={<RocketIcon />}>
          <p>{event.students.length} student(s)</p>
        </MeetingInfoItem>
        <MeetingInfoItem icon={<PersonIcon />}>
          <p>{event.tutors.length} tutors(s)</p>
        </MeetingInfoItem>
      </div>
    </>
  );
}
