import Buttons from "./Buttons";
import Statistics from "./Statistics";
import Transactions from "./Transactions";
import "./TutorPaymentTab.css";
import { useCallback, useEffect, useState } from "react";
import {
  MeetingAdminResponse,
  MeetingsService,
  TadpoleToTutorTransactionResponse,
  MeetingStatus,
  PaymentsService,
  AccountingSystemForCalculatingFinancialStatistics,
  TutorAdminResponse,
  ApprovalStatus,
  TadpoleToTutorTransactionType,
} from "client/openapi";
import moment from "moment";
import PaymentPlanAndAccountingSystem from "./PaymentPlanAndAccountingSystem/TutorPayrollPlan";
import TransactionSettings from "./Transactions/TransactionSettings/TransactionSettings";

function TutorPaymentTab({
  tutor,
  orgId,
}: {
  tutor: TutorAdminResponse;
  orgId: number;
}) {
  let currentDate = new Date(); // Get the current date and time
  let pastDate = new Date(currentDate); // Create a new Date object based on the current date
  pastDate.setMonth(pastDate.getMonth() - 6);

  const [startDate, setStartDate] = useState(pastDate.toISOString());
  const [endDate, setEndDate] = useState(currentDate.toISOString());
  const [accountingSystem, setAccountingSystem] =
    useState<AccountingSystemForCalculatingFinancialStatistics>(
      AccountingSystemForCalculatingFinancialStatistics.TRANSACTIONS
    );

  const [transactionTypeFilter, setTransactionTypeFilter] = useState<
    TadpoleToTutorTransactionType | "all"
  >("all");

  const [adminApprovalFilter, setAdminApprovalFilter] = useState<
    ApprovalStatus | "all"
  >("all");

  const [transactions, setTransactions] = useState<
    MeetingAdminResponse[] | TadpoleToTutorTransactionResponse[]
  >([]);

  const getTutorTransactions = useCallback(async () => {
    if (
      accountingSystem ===
      AccountingSystemForCalculatingFinancialStatistics.TRANSACTIONS
    ) {
      const transactions_response =
        await PaymentsService.getTransactionsByTutor({
          tutorId: tutor.id,
          accountingSystem: accountingSystem,
          start: moment.utc(startDate).startOf("day").toISOString(),
          until: moment.utc(endDate).endOf("day").toISOString(),
        });

      const filteredTransactions =
        transactionTypeFilter === "all"
          ? transactions_response
          : transactions_response.filter(
              (txn) =>
                txn.tadpole_to_tutor_transaction_type === transactionTypeFilter
            );

      setTransactions(
        filteredTransactions.sort((a, b) =>
          moment(b.timestamp).diff(moment(a.timestamp))
        )
      );
    } else if (
      accountingSystem ===
      AccountingSystemForCalculatingFinancialStatistics.MEETINGS
    ) {
      const meetings_response = await MeetingsService.getMeetingsByTutor({
        tutorId: tutor.id,
        start: moment.utc(startDate).startOf("day").toISOString(),
        until: moment.utc(endDate).endOf("day").toISOString(),
        meetingAcceptedByAllTutors: MeetingStatus.ACCEPTED,
      });

      const filteredMeetings =
        adminApprovalFilter === "all"
          ? meetings_response
          : meetings_response.filter(
              (meeting) =>
                meeting.reviewed_and_approved_by_admin === adminApprovalFilter
            );

      setTransactions(
        (filteredMeetings as MeetingAdminResponse[]).sort((a, b) =>
          moment(b.start).diff(moment(a.start))
        )
      );
    }
  }, [
    accountingSystem,
    adminApprovalFilter,
    endDate,
    startDate,
    transactionTypeFilter,
    tutor.id,
  ]);

  useEffect(() => {
    getTutorTransactions();
  }, [endDate, getTutorTransactions, startDate, accountingSystem]);

  return (
    <div>
      <div className="top-third mx-3 mt-4 border-gray-300 border-b-2 rounded-t pb-4">
        <div>
          <PaymentPlanAndAccountingSystem tutor={tutor} />
        </div>
        <div>
          <Buttons transactions={transactions} tutor={tutor} orgId={orgId} />
        </div>
      </div>
      <div className="rounded-xl bg-neutral-50 pb-4">
        <div className="mt-4">
          <Statistics
            tutor={tutor}
            orgId={orgId}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            accountingSystem={accountingSystem}
          />
        </div>
        <div className="my-4">
          <TransactionSettings
            accountingSystem={accountingSystem}
            setAccountingSystem={setAccountingSystem}
            setTransactions={setTransactions}
            transactionTypeFilter={transactionTypeFilter}
            setTransactionTypeFilter={setTransactionTypeFilter}
            adminApprovalFilter={adminApprovalFilter}
            setAdminApprovalFilter={setAdminApprovalFilter}
          />
        </div>
        <div className="mt-4 mx-4">
          <Transactions
            transactions={transactions}
            accountingSystem={accountingSystem}
            tutor={tutor}
          />
        </div>
      </div>
    </div>
  );
}
export default TutorPaymentTab;
