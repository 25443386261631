import { DialogContent } from "components/Dialog";
import "./index.css";
import { useCallback, useEffect, useState } from "react";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import {
  ACHResponse,
  CreditCardResponse,
  PaymentPlanFrequency,
  PaymentPlanResponse,
  StudentLimitedResponse,
  PaymentsService,
  StudentsService,
  PaymentMethodType,
} from "client/openapi";
import PaymentMethods from "../../../../../../../../components/PaymentMethods/PaymentMethods";
import { APIResponse, PageStatus } from "types";
import Notifications from "util/notifications";
import Cards from "../../../../../../../../components/PaymentMethods/cards";
import Banks from "../../../../../../../../components/PaymentMethods/bank";

export default function AutomaticPaymentsModal({
  student,
  orgId,
  paymentPlan,
  setPaymentPlan,
}: {
  student: StudentLimitedResponse;
  orgId: number;
  paymentPlan?: PaymentPlanResponse;
  setPaymentPlan: (paymentPlan: PaymentPlanResponse) => void;
}) {
  const [isPaymentPlan, setIsPaymentPlan] = useState<boolean>(false);

  const [editing, setEditing] = useState<boolean>(false);
  const [checked, setChecked] = useState([false, true]);
  const [interval, setInterval] = useState<number>();

  const [newInterval, setNewInterval] = useState<number>();
  const [invalidInterval, setInvalidInterval] = useState<boolean>(false);

  type AllPaymentMethods = CreditCardResponse | ACHResponse;
  const [paymentMethodsOnFile, setPaymentMethodsOnFile] = useState<
    AllPaymentMethods[]
  >([]);
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<
    CreditCardResponse | ACHResponse
  >();
  const [newPaymentMethod, setNewPaymentMethod] = useState<string>("");
  const [paymentEmail, setPaymentEmail] = useState<string>();

  const [status, setStatus] = useState<PageStatus>(PageStatus.EDITING);
  const [error, setError] = useState<APIResponse>();

  const [daysInterval, setDaysInterval] = useState<string>("");

  const getAndSetPaymentMethodsOnFile = useCallback(async () => {
    let newMethods: AllPaymentMethods[] = [];

    try {
      const existing_cards = await PaymentsService.getStudentCards({
        studentId: student.id,
      });
      if (existing_cards) {
        newMethods = [...newMethods, ...existing_cards];
      }
    } catch (error: any) {
      setError(error);
      setStatus(PageStatus.ERROR);
      console.error("Error:", error);
    }

    try {
      const existing_banks = await PaymentsService.getStudentAchAccounts({
        studentId: student.id,
      });
      if (existing_banks) {
        newMethods = [...newMethods, ...existing_banks];
      }
    } catch (error: any) {
      setError(error);
      setStatus(PageStatus.ERROR);
      console.error("Error:", error);
    }

    setPaymentMethodsOnFile(newMethods);
  }, [orgId, student.id]);

  useEffect(() => {
    getAndSetPaymentMethodsOnFile();
  }, [getAndSetPaymentMethodsOnFile]);

  const findPaymentMethodById = useCallback(
    (paymentMethodId) => {
      return Array.from(paymentMethodsOnFile).find(
        (paymentMethod) => paymentMethod.payment_method_id === paymentMethodId
      );
    },
    [paymentMethodsOnFile]
  );

  useEffect(() => {
    if (student) {
      if (paymentPlan && paymentPlan.frequency === PaymentPlanFrequency.DAILY) {
        setChecked([true, false]);
        setInterval(paymentPlan.interval);
        setCurrentPaymentMethod(
          paymentPlan
            ? findPaymentMethodById(paymentPlan.payment_method_id)
            : undefined
        );
        setPaymentEmail(paymentPlan.invoice_email as string | undefined);
        setIsPaymentPlan(true);
        setEditing(false);

        // Pre-fill daysInterval if interval exists
        if (paymentPlan.interval) {
          setDaysInterval(String(paymentPlan.interval));
          setNewInterval(paymentPlan.interval);
          setInvalidInterval(false);
        }
      } else {
        setChecked([false, true]);
        setIsPaymentPlan(false);
      }
    }
  }, [findPaymentMethodById, paymentMethodsOnFile, paymentPlan, student]);

  const updatePaymentPlan = async () => {
    if (
      checked[0] === true &&
      (newPaymentMethod === "" || newPaymentMethod === null)
    ) {
      setError({
        message: "Invalid Payment Method",
      });
      return;
    }

    if (checked[0] === true && (invalidInterval || !daysInterval)) {
      setError({
        message: "Please enter a valid number of days (1-84)",
      });
      return;
    }

    let selectedPaymentMethodType: PaymentMethodType;
    let selectedPaymentMethodId = "";
    let selectedPaymentMethodInvoiceEmail = "";

    // Handle the autopay = YES scenario
    if (checked[0] === true) {
      if (newPaymentMethod.includes("@")) {
        // Autopay with INVOICE email
        selectedPaymentMethodType = PaymentMethodType.INVOICE;
        selectedPaymentMethodInvoiceEmail = newPaymentMethod;
        selectedPaymentMethodId = "";
      } else {
        // Autopay with a known payment method ID
        const selectedMethod = findPaymentMethodById(newPaymentMethod);

        if (selectedMethod && "brand" in selectedMethod) {
          // It's a card
          selectedPaymentMethodType = PaymentMethodType.CARD;
          selectedPaymentMethodId = selectedMethod.payment_method_id;
        } else if (selectedMethod && "bank_name" in selectedMethod) {
          // It's ACH
          selectedPaymentMethodType = PaymentMethodType.ACH;
          selectedPaymentMethodId = selectedMethod.payment_method_id;
        } else {
          setError({
            message: "Unable to determine the selected payment method type.",
          });
          return;
        }
      }
    } else {
      // Autopay = NO scenario
      selectedPaymentMethodType = PaymentMethodType.INVOICE;
      selectedPaymentMethodId = "";
      selectedPaymentMethodInvoiceEmail = "";
    }

    StudentsService.updatePaymentPlan({
      studentId: student.id,
      requestBody: {
        payment_method_id: selectedPaymentMethodId,
        invoice_email: selectedPaymentMethodInvoiceEmail,
        payment_method_type: selectedPaymentMethodType,
        frequency:
          checked[0] === true
            ? PaymentPlanFrequency.DAILY
            : PaymentPlanFrequency.NONE,
        interval: checked[0] === true ? newInterval : 1,
      },
    })
      .then((plan) => {
        setStatus(PageStatus.SUCCESS);
        setPaymentPlan(plan);
        setEditing(false);
        Notifications.success(`Student payment plan updated!`);
      })
      .catch((err) => {
        setError(err);
        setStatus(PageStatus.ERROR);
      });
  };

  const handleSelectPaymentMethod = (id) => {
    if (typeof id === "string" && id.includes("@")) {
      setNewPaymentMethod(id);
    } else if (typeof id === "object") {
      setNewPaymentMethod(id.payment_method_id);
    } else {
      console.error("Unexpected payment method type", id);
    }
  };

  const handleDaysIntervalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    // Remove all non-digit characters (this removes decimals)
    value = value.replace(/\D/g, "");

    if (value === "") {
      // Empty allowed, invalid interval and no error
      setDaysInterval("");
      setNewInterval(undefined);
      setInvalidInterval(true); // Can't save when empty
      // Clear any correction errors if they exist
      if (error?.message?.includes("Corrected")) {
        setError(undefined);
      }
      return;
    }

    let num = Number(value);

    if (num < 1) {
      // Correct to 1
      num = 1;
      setError({ message: "Days must be greater than zero. Corrected to 1." });
      setInvalidInterval(false);
    } else if (num > 84) {
      // Correct to 84
      num = 84;
      setError({
        message:
          "Please bundle no more than three months of charges (max 84). Corrected to 84.",
      });
      setInvalidInterval(false);
    } else {
      // Valid value
      // Clear any correction errors if exist
      if (error?.message?.includes("Corrected")) {
        setError(undefined);
      }
      setInvalidInterval(false);
    }

    setDaysInterval(String(num));
    setNewInterval(num);
  };

  // Prevent typing decimals or scientific notation keys
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "." || e.key === "e" || e.key === "E") {
      e.preventDefault();
    }
  };

  function clearPaymentPlan() {
    setNewInterval(undefined);
    setInvalidInterval(true);
    setNewPaymentMethod("");
    setDaysInterval("");
    // Clear errors if any
    if (error?.message?.includes("Corrected")) {
      setError(undefined);
    }
  }

  useEffect(() => {
    if (error) {
      Notifications.error(error?.message || "An unexpected error occurred.");
    }
  }, [error]);

  const saveDisabled =
    editing && (newPaymentMethod === "" || invalidInterval || !daysInterval);

  return (
    <DialogContent className="dialog-content max-w-[800px]">
      <div className="mt-3 font-semibold text-center header text-lg">
        Automatic Billing
      </div>

      <div className="mt-3 border border-gray-300 border-2 rounded-lg p-4">
        <p className="header font-medium text-sm">
          <div className="mb-2 font-bold">
            Auto bill student on a per-session basis:
          </div>
          <input
            type="radio"
            className={`!border-black ml-2 rounded !bg-none ${
              checked[0] && "!bg-green-400"
            } focus:!outline-none`}
            checked={checked[0]}
            onChange={() => setChecked([true, false])}
          />{" "}
          &nbsp;Yes
          <input
            type="radio"
            className={`!border-black ml-2 rounded !bg-none ${
              checked[1] && "!bg-green-400"
            } focus:!outline-none`}
            checked={checked[1]}
            onChange={() => setChecked([false, true])}
          />{" "}
          &nbsp;No
        </p>

        {checked[0] &&
          (!editing ? (
            <div>
              <div className="header text-sm mt-3">
                {isPaymentPlan ? (
                  <div>
                    <div className="font-bold header mb-2 text-sm">
                      Payment Plan:
                    </div>
                    Student will be charged every {interval} day(s) using:
                    <div className="mt-2 mb-2">
                      {currentPaymentMethod || paymentEmail ? (
                        <div>
                          {currentPaymentMethod &&
                          "brand" in currentPaymentMethod ? (
                            <Cards
                              key={currentPaymentMethod.payment_method_id}
                              card={currentPaymentMethod as CreditCardResponse}
                            />
                          ) : currentPaymentMethod &&
                            "bank_name" in currentPaymentMethod ? (
                            <Banks
                              key={currentPaymentMethod.payment_method_id}
                              bank={currentPaymentMethod as ACHResponse}
                            />
                          ) : (
                            <span className="text-sm font-bold">
                              {paymentEmail}
                            </span>
                          )}
                        </div>
                      ) : (
                        <span className="font-semibold text-sm">
                          No payment method has been set. Add one:
                        </span>
                      )}
                    </div>
                    <div className="text-xs">
                      Note: For any given subject, auto-pay will use all credits
                      a student has on file before billing with their payment
                      method.
                    </div>
                  </div>
                ) : (
                  "No payment plan on file."
                )}
              </div>
              <div className="mt-4">
                <Button
                  color={ButtonColor.PURPLE}
                  size={ButtonSize.SMALL}
                  onClick={() => setEditing(!editing)}
                >
                  Edit Payment Plan
                </Button>
              </div>
            </div>
          ) : (
            <>
              <p className="mt-4 mb-2 text-sm font-bold">Bill every:</p>
              <input
                type="number"
                className="block w-11/12 h-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="days"
                name="days"
                placeholder="14 days"
                step={1}
                value={daysInterval}
                onKeyDown={handleKeyDown}
                onChange={handleDaysIntervalChange}
              />
              <p className="mt-4 mb-2 text-sm font-bold">Bill to:</p>
              <div className="px-2">
                <PaymentMethods
                  showAddCardButton
                  selectable
                  studentIfInitiatedByAdmin={student}
                  handleSelectPaymentMethod={handleSelectPaymentMethod}
                />
              </div>
              <div className="mt-4 ml-1">
                <Button
                  color={ButtonColor.PURPLE}
                  size={ButtonSize.SMALL}
                  onClick={() => {
                    setEditing(!editing);
                    clearPaymentPlan();
                  }}
                >
                  Cancel Editing
                </Button>
              </div>
            </>
          ))}
      </div>

      <div className="w-full text-center mt-6">
        <Button
          color={saveDisabled ? ButtonColor.GRAY : ButtonColor.GREEN}
          size={ButtonSize.SMALL}
          onClick={updatePaymentPlan}
          disabled={saveDisabled}
        >
          Save
        </Button>
      </div>
    </DialogContent>
  );
}
