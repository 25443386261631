import "./index.css";
import { VscSettings } from "react-icons/vsc";
// import { LuLineChart } from "react-icons/lu";
import { Dialog, DialogTrigger } from "components/Dialog";
// import Trends from "../Modals/Trends";
import {
  MeetingAdminResponse,
  TadpoleToTutorTransactionResponse,
  TutorLimitedResponse,
} from "client/openapi";
import CustomRatesModal from "../Modals/CustomRates";
import PayMeetingTutorModal from "../Modals/PayMeetingTutor";
import RepeatOnce from "pages/manage/students/[id]/PaymentsTab/Buttons/repeatOnce";

function Buttons({
  transactions,
  tutor,
  orgId,
}: {
  transactions: MeetingAdminResponse[] | TadpoleToTutorTransactionResponse[];
  tutor: TutorLimitedResponse;
  orgId: number;
}) {
  return (
    <div className="flex md:grid mx-4 md:grid-cols-2 grid-cols-2 lg:gap-8 md:gap-6">
      <Dialog>
        <DialogTrigger className="payments-button col-span-1 m-auto">
          <VscSettings
            color="black"
            size="42"
            style={{ transform: "rotate(90deg)" }}
          />
          <span className="mt-2">Set custom tutor pay</span>
        </DialogTrigger>
        <CustomRatesModal tutor={tutor} orgId={orgId} />
      </Dialog>
      <Dialog>
        <DialogTrigger className="payments-button col-span-1 m-auto">
          <RepeatOnce />
          <span className="mt-2">Make a one-time payment</span>
        </DialogTrigger>
        <PayMeetingTutorModal tutor={tutor} orgId={orgId} />
      </Dialog>
      {/* <Dialog>
        <DialogTrigger className="payments-button col-span-1 m-auto">
          <LuLineChart color="black" size="42" />
          <span className="mt-2">View payment trends</span>
        </DialogTrigger>
        <Trends transactions={transactions as MeetingAdminResponse[]} />
      </Dialog> */}
    </div>
  );
}
export default Buttons;
