import Notes from "./Notes";
import {
  Auth0AccountRole,
  StudentLimitedResponse,
  OrganizationResponseForTutors,
  NoteResponse,
  AccountResponse,
  StudentContactResponse,
} from "client/openapi";
import { useContext } from "react";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

export default function NotesTab({
  newInternalNoteOpen,
  setNewInternalNoteOpen,
  newParentNoteOpen,
  setNewParentNoteOpen,
  student,
  organization,
  internalNotes,
  setInternalNotes,
  parentNotes,
  setParentNotes,
  account,
  studentContacts,
}: {
  newInternalNoteOpen: boolean;
  setNewInternalNoteOpen: (open: boolean) => void;
  newParentNoteOpen: boolean;
  setNewParentNoteOpen: (open: boolean) => void;
  student: StudentLimitedResponse;
  organization: OrganizationResponseForTutors | undefined;
  internalNotes: NoteResponse[];
  setInternalNotes: React.Dispatch<React.SetStateAction<NoteResponse[]>>;
  parentNotes: NoteResponse[];
  setParentNotes: React.Dispatch<React.SetStateAction<NoteResponse[]>>;
  account: AccountResponse;
  studentContacts: StudentContactResponse[];
}) {
  const { currently_selected_role } = useContext(OrgRolesAndAccountContext);

  return (
    <div className="grid grid-cols-1 gap-y-5 md:grid-cols-2 h-full">
      {currently_selected_role &&
        [Auth0AccountRole.ORG_ADMIN, Auth0AccountRole.ORG_TUTOR].includes(
          currently_selected_role
        ) && (
          <div className="flex justify-center mx-5 mt-5 md:justify-end md:mx-0 md:mt-0">
            <Notes
              title="Internal Notes"
              newNoteOpen={newInternalNoteOpen}
              setNewNoteOpen={setNewInternalNoteOpen}
              student={student}
              organization={organization}
              notes={internalNotes}
              setNotes={setInternalNotes}
              account={account}
              toolTipMessage="These notes are private to this student’s profile."
              studentContacts={studentContacts}
              text_parent_or_student_parents={false}
            />
          </div>
        )}

      <div className="flex justify-center mx-5 mt-5 md:justify-end md:mx-0 md:mt-0">
        <Notes
          title="Parent Notes"
          newNoteOpen={newParentNoteOpen}
          setNewNoteOpen={setNewParentNoteOpen}
          student={student}
          organization={organization}
          notes={parentNotes}
          setNotes={setParentNotes}
          account={account}
          toolTipMessage="Notes created by tutors or adminstrators are automatically texted and emailed to parents."
          studentContacts={studentContacts}
          text_parent_or_student_parents={true}
        />
      </div>
    </div>
  );
}
