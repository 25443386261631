import { forwardRef, ReactNode, CSSProperties } from "react";
import { Link } from "react-router-dom";
import { LinkProps } from "react-router-dom";
import "./index.css";

export enum ButtonColor {
  BLUE = "button--blue",
  DARK_BLUE = "button--dark-blue",
  SKYBLUE = "button--skyblue",
  LIGHT_BLUE = "button--light-blue",
  GRAY = "button--gray",
  DARK_GRAY = "button--dark-gray",
  GREEN = "button--green",
  ORANGE = "button--orange",
  PURPLE = "button--purple",
  RED = "button--red",
  BLACK = "button--black",
  WHITE = "button--white",
  LIGHT_PURPLE = "button--plum",
  DEFAULT = "",
}

export enum ButtonFill {
  HOLLOW = "button--hollow",
  FLOAT = "button--float",
  NO_BORDER = "button--no-border",
  DEFAULT = "",
}

export enum ButtonSize {
  EXTRA_SMALL = "button--xs",
  SMALL = "button--small",
  DEFAULT = "",
  LARGE = "button--large",
}

export enum ButtonShape {
  CIRCLE = "button--square button--circle",
  SQUARE = "button--square",
  CHIP = "button--chip",
  DEFAULT = "",
}

/**
 * If this is recognized in ButtonColor, we'll add the matching class
 * If it's a valid hex (like #123ABC), we'll override the CSS variable
 * Otherwise, no special color handling occurs.
 */
type ButtonColorProp = ButtonColor | string;

type ButtonProps = {
  size?: ButtonSize;
  color?: ButtonColorProp;
  fill?: ButtonFill;
  block?: boolean;
  round?: boolean;
  extraClasses?: string;
  children: ReactNode;
  [x: string]: any;
};

/** Check if a string is a valid #RRGGBB or #RGB */
function isHexColor(value: string): boolean {
  return /^#([\da-f]{3}|[\da-f]{6})$/i.test(value);
}

function stripCustomProps(
  props: any,
  customProps: string[]
): { [key: string]: any } {
  let rest = {};
  Object.keys(props).forEach((key) => {
    if (!customProps.includes(key)) {
      rest[key] = props[key];
    }
  });
  return rest;
}

/**
 * Build a space-delimited class string based on
 * ButtonSize, ButtonFill, etc.
 */
function generateClassString(props: ButtonProps): string {
  const {
    size = ButtonSize.DEFAULT,
    color = ButtonColor.DEFAULT,
    shape = ButtonShape.DEFAULT,
    fill = ButtonFill.DEFAULT,
    block = false,
    extraClasses = "",
  } = props;

  let classes: string[] = [];
  size && classes.push(size);
  shape && classes.push(shape);
  fill && classes.push(fill);

  // If color is one of the known enum values, push it as a CSS class
  if (Object.values(ButtonColor).includes(color as ButtonColor)) {
    classes.push(color as string);
  }

  block && classes.push("button--block");
  extraClasses && classes.push(extraClasses);

  return classes.join(" ").trim();
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const { children, color } = props;

    const filteredProps = [
      "size",
      "color",
      "shape",
      "fill",
      "block",
      "extraClasses",
    ];
    const rest = stripCustomProps(props, filteredProps);
    const classString = generateClassString(props);

    /** We will store inline styles or custom variables here */
    let style: CSSProperties = rest.style || {};

    // If the color is a valid hex, override the CSS variable in-line
    if (typeof color === "string" && isHexColor(color)) {
      (style as any)["--button-color"] = color;
      (style as any)["--button-color-contrast"] = "#fff";
    }

    return (
      <button
        ref={ref}
        className={`button ${classString}`}
        {...rest}
        style={style}
      >
        {children}
      </button>
    );
  }
);

const linkFilteredProps = [
  "size",
  "color",
  "shape",
  "fill",
  "block",
  "to",
  "extraClasses",
];

export const LinkButton = (props: LinkProps & ButtonProps) => {
  const { to, children, color } = props;

  const rest = stripCustomProps(props, linkFilteredProps);
  const classString = generateClassString(props);
  let style: CSSProperties = rest.style || {};

  if (typeof color === "string" && isHexColor(color)) {
    (style as any)["--button-color"] = color;
    (style as any)["--button-color-contrast"] = "#fff";
  }

  return (
    <Link to={to} className={`button ${classString}`} {...rest} style={style}>
      {children}
    </Link>
  );
};

export const ExternalLinkButton = (props: ButtonProps) => {
  const { href, children, color } = props;

  const rest = stripCustomProps(props, linkFilteredProps);
  const classString = generateClassString(props);
  let style: CSSProperties = rest.style || {};

  if (typeof color === "string" && isHexColor(color)) {
    (style as any)["--button-color"] = color;
    (style as any)["--button-color-contrast"] = "#fff";
  }

  return (
    <a href={href} className={`button ${classString}`} {...rest} style={style}>
      {children}
    </a>
  );
};
