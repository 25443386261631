import {
  ApiError,
  ApprovalStatus,
  ExportsService,
  GroupBy,
  MeetingStatus,
  SortBy,
} from "client/openapi";
import { ButtonColor } from "components/Button";
import {
  DialogActions,
  DialogAction,
  DialogContent,
  DialogClose,
} from "components/Dialog";
import moment from "moment";
import { useState, useRef } from "react";
import { DatePicker, InputGroup } from "rsuite";
import "./index.css";
import Notifications from "util/notifications";
import { saveAs } from "file-saver";

function ExportDialog({ organizationId }: { organizationId: number }) {
  const [startDate, setStartDate] = useState(moment().subtract(1, "month"));
  const [endDate, setEndDate] = useState(moment());

  // Sort or Group By
  const [exportOption, setExportOption] = useState("group-tutor");

  // Filters for approvals/acceptance:
  // We'll store their states as strings ("", "Approved", "Pending", "Denied" or "", "Accepted", "Proposed", "Rejected")
  const [filterByAdminApproval, setFilterByAdminApproval] = useState<
    ApprovalStatus | ""
  >("");
  const [filterByTutorApproval, setFilterByTutorApproval] = useState<
    ApprovalStatus | ""
  >("");
  const [filterByTutorAcceptance, setFilterByTutorAcceptance] = useState<
    MeetingStatus | ""
  >("");

  // For locked/unlocked tri-state: boolean | undefined
  // undefined => all
  // true => locked only
  // false => unlocked only
  const [lockedState, setLockedState] = useState<boolean | undefined>(
    undefined
  );

  // For billing/payroll tri-state: boolean | undefined
  // undefined => all
  // true => needing billing/payment
  // false => fully billed/paid
  const [billingState, setBillingState] = useState<boolean | undefined>(
    undefined
  );

  const dialogRef = useRef<HTMLDivElement>(null);

  function exportData() {
    ExportsService.exportGroupedData({
      orgId: organizationId,
      start: startDate.format("YYYY-MM-DD"),
      until: endDate.format("YYYY-MM-DD"),
      tz: moment.tz.guess(),

      sortBy:
        exportOption === "date"
          ? SortBy.DATE
          : exportOption === "tutor"
          ? SortBy.TUTOR
          : exportOption === "student"
          ? SortBy.STUDENT
          : undefined,
      groupBy:
        exportOption === "group-tutor"
          ? GroupBy.TUTOR
          : exportOption === "group-student"
          ? GroupBy.STUDENT
          : undefined,

      filterByMeetingsReviewedAndApprovedByAnAdmin:
        filterByAdminApproval === "" ? undefined : filterByAdminApproval,
      filterByMeetingsReviewedAndApprovedByAllTutors:
        filterByTutorApproval === "" ? undefined : filterByTutorApproval,
      filterByMeetingsAcceptedByAllTutors:
        filterByTutorAcceptance === "" ? undefined : filterByTutorAcceptance,

      getLockedMeetingsOrMeetingsReadyToBeLocked: lockedState,
      onlyIncludeMeetingsNeedingBillingOrPayment: billingState,
    })
      .then((data) => {
        try {
          const blob = new Blob([data], { type: "text/csv" });
          const fileName = "export.csv";
          saveAs(blob, fileName);
          Notifications.success(`Meetings have been saved to your computer!`);
        } catch (error) {
          console.error(error);
          Notifications.error("Unable to save meetings to your computer.");
        }
      })
      .catch((e: ApiError) => {
        Notifications.error("Unable to get meetings.");
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  const handleExportChange = (value: string) => {
    setExportOption(value);
  };

  // A helper to render a set of mutually exclusive checkboxes
  function renderCheckboxGroup<T extends string | boolean | undefined>(
    labelOptions: { label: string; value: T }[],
    selectedValue: T,
    onChange: (val: T) => void
  ) {
    return labelOptions.map((opt) => (
      <div key={String(opt.value)}>
        <input
          type="checkbox"
          className={`!border-black ml-2 rounded ${
            selectedValue === opt.value ? "!bg-green-400" : "!bg-none"
          } focus:!outline-none`}
          checked={selectedValue === opt.value}
          onChange={() => onChange(opt.value)}
        />
        &nbsp;<span className="text-sm">{opt.label}</span>
      </div>
    ));
  }

  return (
    <>
      <DialogContent ref={dialogRef} className="export-dialog">
        <h2 className="invite-dialog--title">Export Data</h2>
        <span className="font-bold">Date</span>
        <InputGroup style={{ width: "350px" }}>
          <DatePicker
            oneTap
            defaultValue={startDate.toDate()}
            onChange={(value) => setStartDate(moment.utc(value as Date))}
            onKeyDown={(e) => e.preventDefault()}
            format="yyyy-MM-dd "
            size="xs"
            cleanable={false}
            container={() => dialogRef.current || document.body}
            block
          />
          <InputGroup.Addon>to</InputGroup.Addon>
          <DatePicker
            oneTap
            defaultValue={endDate.toDate()}
            onChange={(value) => setEndDate(moment.utc(value as Date))}
            onKeyDown={(e) => e.preventDefault()}
            format="yyyy-MM-dd"
            size="xs"
            cleanable={false}
            container={() => dialogRef.current || document.body}
            block
          />
        </InputGroup>

        <h3 className="font-bold mt-4">Sort or Group By</h3>
        <div>
          <input
            type="radio"
            name="sortOrGroup"
            className={`!border-black ml-2 rounded ${
              exportOption === "group-tutor" ? "!bg-green-400" : "!bg-none"
            } focus:!outline-none`}
            checked={exportOption === "group-tutor"}
            onChange={() => handleExportChange("group-tutor")}
          />
          &nbsp;<span className="text-sm">Group by tutor</span>
        </div>
        <div>
          <input
            type="radio"
            name="sortOrGroup"
            className={`!border-black ml-2 rounded ${
              exportOption === "group-student" ? "!bg-green-400" : "!bg-none"
            } focus:!outline-none`}
            checked={exportOption === "group-student"}
            onChange={() => handleExportChange("group-student")}
          />
          &nbsp;<span className="text-sm">Group by student</span>
        </div>
        <div>
          <input
            type="radio"
            name="sortOrGroup"
            className={`!border-black ml-2 rounded ${
              exportOption === "date" ? "!bg-green-400" : "!bg-none"
            } focus:!outline-none`}
            checked={exportOption === "date"}
            onChange={() => handleExportChange("date")}
          />
          &nbsp;<span className="text-sm">Sort by date</span>
        </div>
        <div>
          <input
            type="radio"
            name="sortOrGroup"
            className={`!border-black ml-2 rounded ${
              exportOption === "tutor" ? "!bg-green-400" : "!bg-none"
            } focus:!outline-none`}
            checked={exportOption === "tutor"}
            onChange={() => handleExportChange("tutor")}
          />
          &nbsp;<span className="text-sm">Sort by tutor</span>
        </div>
        <div>
          <input
            type="radio"
            name="sortOrGroup"
            className={`!border-black ml-2 rounded ${
              exportOption === "student" ? "!bg-green-400" : "!bg-none"
            } focus:!outline-none`}
            checked={exportOption === "student"}
            onChange={() => handleExportChange("student")}
          />
          &nbsp;<span className="text-sm">Sort by student</span>
        </div>

        <h3 className="font-bold mt-4">Filter By</h3>
        <div className="mb-3">
          <span className="text-sm">Admin Approval Status:</span>
          {renderCheckboxGroup(
            [
              { label: "All", value: "" as ApprovalStatus | "" },
              { label: "Approved", value: ApprovalStatus.APPROVED },
              { label: "Pending", value: ApprovalStatus.PENDING },
              { label: "Denied", value: ApprovalStatus.DENIED },
            ],
            filterByAdminApproval,
            (val) => setFilterByAdminApproval(val)
          )}
        </div>

        <div className="mb-3">
          <span className="text-sm">Tutor Approval Status:</span>
          {renderCheckboxGroup(
            [
              { label: "All", value: "" as ApprovalStatus | "" },
              { label: "Approved", value: ApprovalStatus.APPROVED },
              { label: "Pending", value: ApprovalStatus.PENDING },
              { label: "Denied", value: ApprovalStatus.DENIED },
            ],
            filterByTutorApproval,
            (val) => setFilterByTutorApproval(val)
          )}
        </div>

        <div className="mb-3">
          <span className="text-sm">Tutor Acceptance Status:</span>
          {renderCheckboxGroup(
            [
              { label: "All", value: "" as MeetingStatus | "" },
              { label: "Accepted", value: MeetingStatus.ACCEPTED },
              { label: "Proposed", value: MeetingStatus.PROPOSED },
              { label: "Rejected", value: MeetingStatus.REJECTED },
            ],
            filterByTutorAcceptance,
            (val) => setFilterByTutorAcceptance(val)
          )}
        </div>

        <h3 className="font-bold mt-4">Locked/Unlocked Meetings</h3>
        {renderCheckboxGroup(
          [
            { label: "All meetings", value: undefined },
            { label: "Only locked meetings", value: true },
            { label: "Only unlocked meetings", value: false },
          ],
          lockedState,
          (val) => setLockedState(val)
        )}

        <h3 className="font-bold mt-4">Billing/Payment Status</h3>
        {renderCheckboxGroup(
          [
            { label: "All meetings", value: undefined },
            { label: "Meetings with upcoming billing/payment", value: true },
            { label: "Meetings fully billed/paid for", value: false },
          ],
          billingState,
          (val) => setBillingState(val)
        )}

        <DialogActions>
          <DialogClose asChild>
            <DialogAction
              primary={true}
              color={ButtonColor.GREEN}
              type="submit"
              onClick={exportData}
            >
              Export
            </DialogAction>
          </DialogClose>

          <DialogClose asChild>
            <DialogAction color={ButtonColor.BLACK}>Close</DialogAction>
          </DialogClose>
        </DialogActions>
      </DialogContent>
    </>
  );
}

export default ExportDialog;
