import { Auth0Provider } from "@auth0/auth0-react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import GoogleOAuthHandler from "util/googleURLRouter";

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <BrowserRouter>
    {/*
    The GoogleOAuthHandler component checks if the current URL path is "/availability" and
    contains the "state" parameter and the "code" parameter. This means the user has just completed
    the Google OAuth authentication process and has been redirected back to the availability page
    to complete linking their google calendar to Tadpole.

    Because Auth0 also offers a feature which uses the "state" query parameter for its own
    authentication flow, the use of Google's "state" parameter can cause unintentional
    Auth0 redirects.

    To prevent this issue, GoogleOAuthHandler removes the "state" parameter from the URL
    while retaining the "code" parameter. This ensures that the user stays on the
    "/availability" page and can complete linking their calendar with no issues.*/}
    <GoogleOAuthHandler />
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
      authorizationParams={{
        responseType: "token id_token",
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope:
          "user_metadata app_metadata openid email profile create:tutors update:tutors delete:tutors update:organizations delete:organizations offline_access",
        ignoreCache: true,
      }}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
    >
      <App />
    </Auth0Provider>
  </BrowserRouter>
);
