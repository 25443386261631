/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InviteCreate } from "../models/InviteCreate";
import type { ParentAdminResponse } from "../models/ParentAdminResponse";
import type { PaymentPlanResponse } from "../models/PaymentPlanResponse";
import type { PaymentPlanUpdate } from "../models/PaymentPlanUpdate";
import type { ReInviteCreate } from "../models/ReInviteCreate";
import type { StudentAdminResponse } from "../models/StudentAdminResponse";
import type { StudentCreate } from "../models/StudentCreate";
import type { StudentLimitedResponse } from "../models/StudentLimitedResponse";
import type { StudentUpdate } from "../models/StudentUpdate";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class StudentsService {
  /**
   * Get Students Me
   * Get students for the currently authenticated user from the database.
   * @returns StudentLimitedResponse Successful Response
   * @throws ApiError
   */
  public static getStudentsMe(): CancelablePromise<
    Array<StudentLimitedResponse>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/students/me",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Student Me By Org
   * Get student for the currently authenticated user from the database for
   * a given org.
   * @returns StudentLimitedResponse Successful Response
   * @throws ApiError
   */
  public static getStudentMeByOrg({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<StudentLimitedResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/students/me/by_org",
      query: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Student
   * Get a student by its ID from the database.
   * @returns StudentAdminResponse Successful Response
   * @throws ApiError
   */
  public static getStudent({
    studentId,
  }: {
    studentId: number;
  }): CancelablePromise<StudentAdminResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/students/{student_id}",
      path: {
        student_id: studentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Student
   * Update a student by its ID using specified attributes
   * within StudentUpdate to update the database and return
   * the updated student.
   * @returns StudentAdminResponse Successful Response
   * @throws ApiError
   */
  public static updateStudent({
    studentId,
    requestBody,
  }: {
    studentId: number;
    requestBody: StudentUpdate;
  }): CancelablePromise<StudentAdminResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/students/{student_id}",
      path: {
        student_id: studentId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Parents By Student
   * Get parents by student id.
   * @returns ParentAdminResponse Successful Response
   * @throws ApiError
   */
  public static getParentsByStudent({
    studentId,
  }: {
    studentId: number;
  }): CancelablePromise<Array<ParentAdminResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/studentsparents/{student_id}",
      path: {
        student_id: studentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Students By Organization If Admin
   * Get students by their organization id from the database.
   * @returns StudentAdminResponse Successful Response
   * @throws ApiError
   */
  public static getStudentsByOrganizationIfAdmin({
    orgId,
    skip,
    limit = 1000,
    phrase,
  }: {
    orgId: number;
    skip?: number;
    limit?: number;
    phrase?: string | null;
  }): CancelablePromise<Array<StudentAdminResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/students/by_organization/user_is_admin/{org_id}",
      path: {
        org_id: orgId,
      },
      query: {
        skip: skip,
        limit: limit,
        phrase: phrase,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Students By Organization If Tutor
   * Get students by their organization id from the database.
   * @returns StudentLimitedResponse Successful Response
   * @throws ApiError
   */
  public static getStudentsByOrganizationIfTutor({
    orgId,
    skip,
    limit = 1000,
    phrase,
  }: {
    orgId: number;
    skip?: number;
    limit?: number;
    phrase?: string | null;
  }): CancelablePromise<Array<StudentLimitedResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/students/by_organization/user_is_tutor/{org_id}",
      path: {
        org_id: orgId,
      },
      query: {
        skip: skip,
        limit: limit,
        phrase: phrase,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Students By Organization If Parent
   * Get students by their organization id from the database.
   * @returns StudentLimitedResponse Successful Response
   * @throws ApiError
   */
  public static getStudentsByOrganizationIfParent({
    orgId,
    skip,
    limit = 1000,
    phrase,
  }: {
    orgId: number;
    skip?: number;
    limit?: number;
    phrase?: string | null;
  }): CancelablePromise<Array<StudentLimitedResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/students/by_organization/user_is_parent/{org_id}",
      path: {
        org_id: orgId,
      },
      query: {
        skip: skip,
        limit: limit,
        phrase: phrase,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Students By Organization If Student
   * Get students by their organization id from the database.
   * @returns StudentLimitedResponse Successful Response
   * @throws ApiError
   */
  public static getStudentsByOrganizationIfStudent({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<Array<StudentLimitedResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/students/by_organization/user_is_student/{org_id}",
      path: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Students By Tutor
   * Get all students that a tutor has taught.
   * @returns StudentLimitedResponse Successful Response
   * @throws ApiError
   */
  public static getStudentsByTutor({
    tutorId,
  }: {
    tutorId: number;
  }): CancelablePromise<Array<StudentLimitedResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/students/by_tutor/{tutor_id}",
      path: {
        tutor_id: tutorId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Student
   * Create a new student and returns the resulting student from the database.
   * @returns StudentLimitedResponse Successful Response
   * @throws ApiError
   */
  public static createStudent({
    requestBody,
  }: {
    requestBody: StudentCreate;
  }): CancelablePromise<StudentLimitedResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/students",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Invite Student To Org
   * Create a new account/student for an invited user and sends an invite email to sign up.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static inviteStudentToOrg({
    requestBody,
    createAccount = true,
  }: {
    requestBody: InviteCreate;
    createAccount?: boolean;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/students/invite",
      query: {
        create_account: createAccount,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Reinvite Student
   * Reinvite a previously invited student.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static reinviteStudent({
    requestBody,
  }: {
    requestBody: ReInviteCreate;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/students/invite/reinvite",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Payment Plan
   * Get a payment plan from the database.
   * @returns PaymentPlanResponse Successful Response
   * @throws ApiError
   */
  public static getPaymentPlan({
    studentId,
  }: {
    studentId: number;
  }): CancelablePromise<PaymentPlanResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/students/{student_id}/payment_plan",
      path: {
        student_id: studentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Payment Plan
   * Update a payment plan.
   * @returns PaymentPlanResponse Successful Response
   * @throws ApiError
   */
  public static updatePaymentPlan({
    studentId,
    requestBody,
  }: {
    studentId: number;
    requestBody: PaymentPlanUpdate;
  }): CancelablePromise<PaymentPlanResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/students/{student_id}/payment_plan",
      path: {
        student_id: studentId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
