import { useCallback, useContext, useEffect, useState } from "react";
import Cards from "./cards";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import {
  ACHResponse,
  PaymentsService,
  CreditCardResponse,
  ParentLimitedResponse,
  StudentContactsService,
  StudentLimitedResponse,
} from "client/openapi";
import { APIResponse, PageStatus } from "types";
import Notifications from "util/notifications";
import Banks from "./bank";
import Emails from "./emails";

import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

export default function PaymentMethods({
  showAddCardButton,
  selectable,
  showAction = false,
  handleSelectPaymentMethod,
  studentIfInitiatedByAdmin,
  parentIfInitiatedByAdmin,
  initiatedByStudentOrParentDirectly,
}: {
  showAddCardButton?: boolean;
  selectable?: boolean;
  showAction?: boolean;
  handleSelectPaymentMethod?: (
    method: CreditCardResponse | ACHResponse | string
  ) => void;
  studentIfInitiatedByAdmin?: StudentLimitedResponse;
  parentIfInitiatedByAdmin?: ParentLimitedResponse;
  initiatedByStudentOrParentDirectly?: boolean;
}) {
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<APIResponse>();

  const stripe = useStripe();
  const elements = useElements();

  const [userCards, setUserCards] = useState<CreditCardResponse[]>();
  const [userBanks, setUserBanks] = useState<ACHResponse[]>();
  const [invoiceEmails, setInvoiceEmails] = useState<string[]>();

  const [showAddCard, setShowAddCard] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const { account } = useContext(OrgRolesAndAccountContext);

  const getAndSetCards = useCallback(async () => {
    setStatus(PageStatus.LOADING);

    try {
      let cards;
      if (initiatedByStudentOrParentDirectly) {
        cards = await PaymentsService.getAccountCards();
      } else if (studentIfInitiatedByAdmin) {
        cards = await PaymentsService.getStudentCards({
          studentId: studentIfInitiatedByAdmin.id,
        });
      } else if (parentIfInitiatedByAdmin) {
        cards = await PaymentsService.getParentCards({
          parentId: parentIfInitiatedByAdmin.id,
        });
      }
      setUserCards(cards);
      setStatus(PageStatus.SUCCESS);
    } catch (error: any) {
      setError(error);
      setStatus(PageStatus.ERROR);
      console.error(`Error (#${error.status}): ${error.message}`);
    }
  }, [
    account?.reference_id,
    initiatedByStudentOrParentDirectly,
    parentIfInitiatedByAdmin,
    studentIfInitiatedByAdmin,
  ]);

  const getAndSetBanks = useCallback(async () => {
    setStatus(PageStatus.LOADING);

    try {
      let banks;
      if (initiatedByStudentOrParentDirectly) {
        banks = await PaymentsService.getAccountAch();
      } else if (studentIfInitiatedByAdmin) {
        banks = await PaymentsService.getStudentAchAccounts({
          studentId: studentIfInitiatedByAdmin.id,
        });
      } else if (parentIfInitiatedByAdmin) {
        banks = await PaymentsService.getParentAchAccounts({
          parentId: parentIfInitiatedByAdmin.id,
        });
      }
      setUserBanks(banks);
      setStatus(PageStatus.SUCCESS);
    } catch (error: any) {
      setError(error);
      setStatus(PageStatus.ERROR);
      console.error(`Error (#${error.status}): ${error.message}`);
    }
  }, [
    account?.reference_id,
    initiatedByStudentOrParentDirectly,
    parentIfInitiatedByAdmin,
    studentIfInitiatedByAdmin,
  ]);

  const getAndSetEmails = useCallback(async () => {
    if (parentIfInitiatedByAdmin) {
      setInvoiceEmails(
        parentIfInitiatedByAdmin.email ? [parentIfInitiatedByAdmin.email] : []
      );
    } else if (studentIfInitiatedByAdmin) {
      setStatus(PageStatus.LOADING);
      StudentContactsService.getStudentContactsByStudent({
        studentId: studentIfInitiatedByAdmin.id,
      })
        .then((contacts) => {
          setInvoiceEmails(
            contacts
              .filter((c) => c.email && c.email.includes("@"))
              .map((c) => c.email as string)
          );
          setStatus(PageStatus.SUCCESS);
        })
        .catch((error) => {
          setError(error);
          setStatus(PageStatus.ERROR);
          console.error(`Error (#${error.status}): ${error.message}`);
        });
    }
  }, [parentIfInitiatedByAdmin, studentIfInitiatedByAdmin]);

  useEffect(() => {
    if (account) {
      getAndSetCards();
      getAndSetBanks();
    }
    if (account && (parentIfInitiatedByAdmin || studentIfInitiatedByAdmin)) {
      getAndSetEmails();
    }
  }, [account, parentIfInitiatedByAdmin, studentIfInitiatedByAdmin]);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return null;
    }

    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: studentIfInitiatedByAdmin
          ? `${process.env.REACT_APP_FRONTEND_DOMAIN}/manage/students/payment-status?user_id=${studentIfInitiatedByAdmin.id}`
          : parentIfInitiatedByAdmin
          ? `${process.env.REACT_APP_FRONTEND_DOMAIN}/manage/parents/payment-status?user_id=${parentIfInitiatedByAdmin.id}`
          : `${process.env.REACT_APP_FRONTEND_DOMAIN}/dashboard/payment-status`,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setError(error);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  return (
    <>
      <div className="rounded border mt-2 p-4 border-gray-300">
        <table className="w-full border-spacing-0.5">
          <thead>
            <tr className="text-left text-xs text-gray-500">
              <th className="pb-2 whitespace-nowrap font-bold">
                Charge to Card{" "}
                <span style={{ marginLeft: "290px" }}>Expires On</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {userCards && userCards.length > 0 ? (
              userCards.map((card) => (
                <Cards
                  key={card.payment_method_id}
                  card={card}
                  showAction={showAction}
                  selectable={selectable}
                  handleSelectPaymentMethod={handleSelectPaymentMethod}
                />
              ))
            ) : (
              <tr>
                <td>No cards on file</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="rounded border mt-2 p-4 border-gray-300">
        <table className="w-full border-spacing-0.5">
          <thead>
            <tr className="text-left text-xs text-gray-500">
              <th className="pb-2 whitespace-nowrap font-bold">
                Charge to Bank
              </th>
            </tr>
          </thead>
          <tbody>
            {userBanks && userBanks.length > 0 ? (
              userBanks.map((bank) => (
                <Banks
                  key={bank.payment_method_id}
                  bank={bank}
                  showAction={showAction}
                  selectable={selectable}
                  handleSelectPaymentMethod={handleSelectPaymentMethod}
                />
              ))
            ) : (
              <tr>
                <td>No banks on file</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {showAddCardButton && !showAddCard && (
        <button
          className="mt-4 underline text-xs font-light header text-blue-600 font-semibold"
          onClick={() => setShowAddCard(true)}
        >
          add new card or bank
        </button>
      )}
      {showAddCard && (
        <>
          <form
            onSubmit={handleSubmit}
            className="rounded border mt-4 p-4 border-gray-300"
          >
            <PaymentElement />
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                disabled={!stripe}
                color={ButtonColor.GREEN}
                size={ButtonSize.DEFAULT}
                style={{ marginRight: "10px" }}
              >
                Add
              </Button>
              <Button
                color={ButtonColor.PURPLE}
                size={ButtonSize.DEFAULT}
                fill={ButtonFill.HOLLOW}
                onClick={() => setShowAddCard(false)}
              >
                Cancel
              </Button>
            </div>

            {/* Show error message to your customers */}
            {errorMessage && <div>{errorMessage}</div>}
          </form>
        </>
      )}
      <div className="rounded border mt-2 p-4 border-gray-300">
        <table className="w-full border-spacing-0.5">
          <thead>
            <tr className="text-left text-xs text-gray-500">
              <th className="pb-2 whitespace-nowrap font-bold">
                Send Invoice To
              </th>
            </tr>
          </thead>
          <tbody>
            {invoiceEmails && invoiceEmails.length > 0 ? (
              invoiceEmails.map((email, index) => (
                <Emails
                  key={`${email}-${index}`}
                  email={email}
                  showAction={showAction}
                  selectable={selectable}
                  handleSelectPaymentMethod={handleSelectPaymentMethod}
                />
              ))
            ) : (
              <tr>
                <td>No parents on file</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
