/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookingVerifcationResponse } from "../models/BookingVerifcationResponse";
import type { MessageCreateExternal } from "../models/MessageCreateExternal";
import type { MessageResponse } from "../models/MessageResponse";
import type { MessageType } from "../models/MessageType";
import type { OrgMessageCreate } from "../models/OrgMessageCreate";
import type { TutorMessageCreate } from "../models/TutorMessageCreate";
import type { VerificationCodeResponse } from "../models/VerificationCodeResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class MessagesService {
  /**
   * Get Messages Received By Account
   * Get messages received by an account in a specified timeframe from the database.
   * @returns MessageResponse Successful Response
   * @throws ApiError
   */
  public static getMessagesReceivedByAccount({
    start,
    until,
    email,
    inApp,
    skip,
    limit = 1000,
    requestBody,
  }: {
    start?: string | null;
    until?: string | null;
    email?: boolean | null;
    inApp?: boolean | null;
    skip?: number;
    limit?: number;
    requestBody?: Array<MessageType> | null;
  }): CancelablePromise<Array<MessageResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/messages/by_account/{account_id}",
      query: {
        start: start,
        until: until,
        email: email,
        in_app: inApp,
        skip: skip,
        limit: limit,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Message To Recipients
   * Create a new message to specified recipients
   * and return the resulting message from the database.
   * @returns MessageResponse Successful Response
   * @throws ApiError
   */
  public static createMessageToRecipients({
    requestBody,
  }: {
    requestBody: MessageCreateExternal;
  }): CancelablePromise<MessageResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/messages",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Message To Org
   * Create a new message to an organization's members
   * and return the resulting message from the database.
   * @returns MessageResponse Successful Response
   * @throws ApiError
   */
  public static createMessageToOrg({
    requestBody,
  }: {
    requestBody: OrgMessageCreate;
  }): CancelablePromise<MessageResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/messages/organizations",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Message To Org Tutors
   * Create a new message to an organization's tutors
   * and return the resulting message from the database.
   * @returns MessageResponse Successful Response
   * @throws ApiError
   */
  public static createMessageToOrgTutors({
    requestBody,
  }: {
    requestBody: OrgMessageCreate;
  }): CancelablePromise<MessageResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/messages/organizations/tutors",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Message To Org Students
   * Create a new message to an organization's students
   * and return the resulting message from the database.
   * @returns MessageResponse Successful Response
   * @throws ApiError
   */
  public static createMessageToOrgStudents({
    requestBody,
  }: {
    requestBody: OrgMessageCreate;
  }): CancelablePromise<MessageResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/messages/organizations/students",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Message To Org Parents
   * Create a new message to an organization's parents
   * and return the resulting message from the database.
   * @returns MessageResponse Successful Response
   * @throws ApiError
   */
  public static createMessageToOrgParents({
    requestBody,
  }: {
    requestBody: OrgMessageCreate;
  }): CancelablePromise<MessageResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/messages/organizations/parents",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Message To Tutor Students
   * Create a new message to a tutor's students
   * and return the resulting message from the database.
   * @returns MessageResponse Successful Response
   * @throws ApiError
   */
  public static createMessageToTutorStudents({
    requestBody,
  }: {
    requestBody: TutorMessageCreate;
  }): CancelablePromise<MessageResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/messages/by_tutor/{tutor_id}/students",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Request Verification Code
   * Send a verification code to the provided phone number.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static requestVerificationCode({
    phoneNumber,
  }: {
    phoneNumber: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/messages/request_verification_code",
      query: {
        phone_number: phoneNumber,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Validate Verification Code
   * Check if the provided verification code is valid for the phone number.
   * @returns VerificationCodeResponse Successful Response
   * @throws ApiError
   */
  public static validateVerificationCode({
    phoneNumber,
    code,
  }: {
    phoneNumber: string;
    code: string;
  }): CancelablePromise<VerificationCodeResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/messages/validate_verification_code",
      query: {
        phone_number: phoneNumber,
        code: code,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Validate Verification Code And Return Students
   * Check if the provided verification code is valid for the phone number.
   * @returns BookingVerifcationResponse Successful Response
   * @throws ApiError
   */
  public static validateVerificationCodeAndReturnStudents({
    phoneNumber,
    code,
    orgId,
  }: {
    phoneNumber: string;
    code: string;
    orgId: number;
  }): CancelablePromise<BookingVerifcationResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/messages/validate_verification_code_and_return_students",
      query: {
        phone_number: phoneNumber,
        code: code,
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
