import { ProtectedRoute } from "components/ProtectedRoute";
import Dashboard from "pages/Dashboard";
import OrgPerformance from "pages/org-performance";
import StudentDetail from "pages/manage/students/[id]";
import TutorDetail from "pages/manage/tutors/[id]";
import TutorAvailability from "pages/tutors/availability";
import { Route, Routes } from "react-router-dom";
import StripeKYC from "components/Signup/StripeKYC";
import PaymentStatus from "components/PaymentMethods/PaymentMethodStatus";
import ParentDetail from "pages/manage/parents/[id]";
import MainLayout from "components/Routes/MainLayout";
import Error404 from "pages/Error404";
import PaymentMethodSuccess from "components/RequestPaymentMethod/PaymentMethodSuccess";
import { AccountRoles } from "types";
import Signup from "pages/Signup";
import PrivacyPolicy from "pages/legal/PrivacyPolicy";
import TermsOfService from "pages/legal/TermsOfService";
import LandingPage from "pages/Landing";
import AcceptInvite from "pages/invite/accept/auth0Secret";
import InviteToken from "pages/invite/[token]";
import OrganizationWebPage from "./pages/WebPages/OrganizationWebPage";
import PublicBookingLayout from "components/Routes/PublicBookingLayout";
import ManageWebPage from "pages/WebPages/ManageWebPage";
import TutorWebPage from "pages/WebPages/TutorWebPage";

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        {/* Dashboard */}
        <Route
          path="/dashboard"
          element={<ProtectedRoute component={Dashboard} />}
        />

        {/* Organization Performance */}
        <Route
          path="/performance"
          element={<ProtectedRoute component={OrgPerformance} />}
        />

        {/* Student, Tutor, and Parent Management */}
        <Route
          path="/manage/students/:id"
          element={<ProtectedRoute component={StudentDetail} />}
        />

        <Route
          path="/manage/tutors/:id"
          element={<ProtectedRoute component={TutorDetail} />}
        />

        <Route
          path="/manage/parents/:id"
          element={<ProtectedRoute component={ParentDetail} />}
        />

        {/* Edit Organization and Tutor Webpages */}
        <Route
          path="/manage/webpage"
          element={<ProtectedRoute component={ManageWebPage} />}
        />

        {/* Edit Tutor Availability */}
        <Route
          path="/availability"
          element={<ProtectedRoute component={TutorAvailability} />}
        />

        {/* KYC */}
        <Route
          path="/KYC/:type"
          element={<ProtectedRoute component={StripeKYC} />}
        />

        {/* Payment Status */}
        <Route
          path="/manage/students/payment-status"
          element={
            <ProtectedRoute
              component={PaymentStatus}
              componentProps={{
                initiatedByAdminFromOrgDashboardForStudent: true,
              }}
            />
          }
        />

        <Route
          path="/manage/parents/payment-status"
          element={
            <ProtectedRoute
              component={PaymentStatus}
              componentProps={{
                initiatedByAdminFromOrgDashboardForParent: true,
              }}
            />
          }
        />

        <Route
          path="/dashboard/payment-status"
          element={
            <ProtectedRoute
              component={PaymentStatus}
              componentProps={{ addedByStudentOrParentDirectly: true }}
            />
          }
        />

        {/*Marketing*/}
        <Route path="/" element={<LandingPage />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        {/* Sign up */}
        <Route path="/signup" element={<Signup />} />
        <Route
          path="/signup/student"
          element={<Signup accountType={AccountRoles.STUDENT} />}
        />
        <Route
          path="/signup/tutor"
          element={<Signup accountType={AccountRoles.TUTOR} />}
        />
        <Route
          path="/signup/organization"
          element={<Signup accountType={AccountRoles.ORGANIZATION} />}
        />

        {/* Invites */}
        <Route path="/invites/:token" element={<InviteToken />} />
        <Route path="/invites/accept/:auth0Secret" element={<AcceptInvite />} />

        {/* Payment Method Success */}
        <Route
          path="/payment_method_success"
          element={<PaymentMethodSuccess />}
        />

        {/* ERRORS */}
        <Route path="*" element={<Error404 />} />
      </Route>
      <Route element={<PublicBookingLayout />}>
        <Route path="/organizations/:id" element={<OrganizationWebPage />} />
        <Route path="/tutors/:id" element={<TutorWebPage />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
