import Header from "Header";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import { Outlet } from "react-router-dom";

export default function MainLayout() {
  return (
    <>
      <Header />
      <Navbar />
      {/* Renders whatever child route is active */}
      <Outlet />
      <Footer />
    </>
  );
}
