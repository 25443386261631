import React, { useEffect } from "react";
import moment from "moment";

export function DateRangeSelector({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: {
  startDate: Date;
  endDate: Date;
  setStartDate: (d: Date) => void;
  setEndDate: (d: Date) => void;
}) {
  // Enforce constraints
  useEffect(() => {
    if (moment(startDate).isAfter(moment(endDate))) {
      setStartDate(moment(endDate).subtract(1, "day").toDate());
    }

    const now = moment();
    if (moment(endDate).isAfter(now)) {
      setEndDate(now.toDate());
    }
  }, [startDate, endDate, setStartDate, setEndDate]);

  const inputClasses =
    "border rounded-md p-1 text-sm bg-gray-100 focus:bg-white focus:outline-none focus:ring-1 focus:ring-gray-300";

  const onStartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = moment(e.target.value).startOf("day").toDate();
    if (!isNaN(val.getTime())) {
      // Don't allow selecting future dates
      if (moment(val).isAfter(moment().endOf("day"))) {
        setStartDate(moment().startOf("day").toDate());
      } else {
        setStartDate(val);
      }
    }
  };

  const onEndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = moment(e.target.value).endOf("day").toDate();
    if (!isNaN(val.getTime())) {
      // Don't allow selecting future dates
      if (moment(val).isAfter(moment().endOf("day"))) {
        setEndDate(moment().endOf("day").toDate());
      } else {
        setEndDate(val);
      }
    }
  };

  return (
    <div className="flex items-center gap-2">
      <label className="text-sm font-medium">From:</label>
      <input
        type="date"
        className={inputClasses}
        value={moment(startDate).format("YYYY-MM-DD")}
        onChange={onStartChange}
        max={moment().format("YYYY-MM-DD")}
      />

      <label className="text-sm font-medium">To:</label>
      <input
        type="date"
        className={inputClasses}
        value={moment(endDate).format("YYYY-MM-DD")}
        onChange={onEndChange}
        max={moment().format("YYYY-MM-DD")}
      />
    </div>
  );
}
