import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  FileTextIcon,
} from "@radix-ui/react-icons";
import {
  ApiError,
  Auth0AccountRole,
  MeetingLimitedResponse,
  MeetingsService,
  MeetingStatus,
  ApprovalStatus,
} from "client/openapi";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import CalendarWrapper from "components/Dashboard/Calendar";
import { ErrorBlock } from "components/StatusBlock";
import moment from "moment";
import { ReactNode, useContext, useEffect, useState } from "react";
import { APIResponse, PageStatus } from "types";
import "./index.css";
import TutoringHours from "../TutoringHours";
import { Dialog, DialogTrigger } from "components/Dialog";
import ExportDialog from "../ExportDialog";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";
import { DateRangeSelector } from "./Components/dateRangeSelector";
import { MeetingFilters } from "../TutoringHours/Components/meetingFilters";

type CalendarViewType = "week" | "day";

const CalendarView = {
  WEEK: "week" as CalendarViewType,
  DAY: "day" as CalendarViewType,
};

function UpcomingMeetings({
  children,
  studentFilters,
  tutorFilters,
  subjectFilters,
  addedStart,
}: {
  children?: ReactNode;
  studentFilters?: number[];
  tutorFilters?: number[];
  subjectFilters?: number[];
  addedStart?: Date;
}) {
  // Tracks whether we are showing 7 days (week) or 1 day
  const [calendarViewType, setCalendarViewType] = useState<CalendarViewType>(
    CalendarView.WEEK
  );

  // Single anchor date for day or week view
  // (if in week mode, we display "Week of startOfWeek(selectedDate)",
  //  if in day mode, we display the exact date)
  const [selectedDate, setSelectedDate] = useState<Date>(
    moment(addedStart).startOf("week").toDate()
  );

  // For Tutoring Hours view (date range):
  const [startDate, setStartDate] = useState<Date>(
    moment().subtract(1, "month").toDate()
  );
  const [endDate, setEndDate] = useState<Date>(new Date());

  // Filters for tutoring hours view
  const [meetingStatusFilter, setMeetingStatusFilter] = useState<
    MeetingStatus | undefined
  >(undefined);
  const [adminApprovalFilter, setAdminApprovalFilter] = useState<
    ApprovalStatus | undefined
  >(undefined);
  const [tutorApprovalFilter, setTutorApprovalFilter] = useState<
    ApprovalStatus | undefined
  >(undefined);

  const [meetings, setMeetings] = useState<MeetingLimitedResponse[]>([]);
  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING);
  const [error, setError] = useState<APIResponse>();

  const { account, currently_selected_role, currently_selected_organization } =
    useContext(OrgRolesAndAccountContext);

  const [showTutoringHours, setShowTutoringHours] = useState<boolean>(false);

  async function fetchMeetings() {
    setStatus(PageStatus.LOADING);

    try {
      let fetchedMeetings: MeetingLimitedResponse[] = [];

      if (!showTutoringHours) {
        // CALENDAR VIEW: only accepted-by-all-tutors meetings
        const start = moment(selectedDate)
          .startOf(calendarViewType === CalendarView.WEEK ? "week" : "day")
          .toISOString();

        const until = moment(selectedDate)
          .startOf(calendarViewType === CalendarView.WEEK ? "week" : "day")
          .add(1, calendarViewType === CalendarView.WEEK ? "week" : "day")
          .toISOString();

        if (currently_selected_role === Auth0AccountRole.ORG_ADMIN) {
          fetchedMeetings = await MeetingsService.getMeetingsByOrg({
            orgId: currently_selected_organization as number,
            start,
            until,
            meetingAcceptedByAllTutors: MeetingStatus.ACCEPTED,
          });
        } else if (currently_selected_role === Auth0AccountRole.ORG_TUTOR) {
          fetchedMeetings =
            await MeetingsService.getMeetingsForAllTutorsForAccount({
              start,
              until,
              meetingAcceptedByAllTutors: MeetingStatus.ACCEPTED,
            });
        } else if (currently_selected_role === Auth0AccountRole.ME) {
          fetchedMeetings =
            await MeetingsService.getMeetingsForAllStudentsForAccount({
              start,
              until,
              meetingAcceptedByAllTutors: MeetingStatus.ACCEPTED,
            });
        } else if (currently_selected_role === Auth0AccountRole.PARENT) {
          fetchedMeetings =
            await MeetingsService.getMeetingsForAllParentsForAccount({
              start,
              until,
              meetingAcceptedByAllTutors: MeetingStatus.ACCEPTED,
            });
        }
      } else {
        // TUTORING HOURS VIEW:
        const start = moment(startDate).startOf("day").toISOString();
        const until = moment(endDate).endOf("day").toISOString();

        // If date is invalid or start > end, handle that
        if (moment(startDate).isAfter(moment(endDate))) {
          setError({ error: "Start date cannot be after end date." });
          setStatus(PageStatus.ERROR);
          return;
        }

        // No future date beyond today:
        if (moment(endDate).isAfter(moment())) {
          setEndDate(new Date());
        }

        if (currently_selected_role === Auth0AccountRole.ORG_ADMIN) {
          fetchedMeetings = await MeetingsService.getMeetingsByOrg({
            orgId: currently_selected_organization as number,
            start,
            until,
            reviewedAndApprovedByAdmin: adminApprovalFilter,
            meetingAcceptedByAllTutors: meetingStatusFilter,
            reviewedAndApprovedByAllTutors: tutorApprovalFilter,
          });
        } else if (currently_selected_role === Auth0AccountRole.ORG_TUTOR) {
          fetchedMeetings =
            await MeetingsService.getMeetingsForAllTutorsForAccount({
              start,
              until,
              reviewedAndApprovedByAdmin: adminApprovalFilter,
              meetingAcceptedByThisTutor: meetingStatusFilter,
              meetingReviewedAndApprovedByThisTutor: tutorApprovalFilter,
            });
        }
        // ME and PARENT do not have access to tutoring hours.
      }

      // Filter by students/tutors/subjects if present
      const filteredMeetings = fetchedMeetings.filter((meeting) => {
        const subjectMatch =
          !subjectFilters?.length ||
          subjectFilters.includes(meeting.org_subject.id);

        const tutorMatch =
          !tutorFilters?.length ||
          meeting.tutors.some((t) => tutorFilters.includes(t.tutor.id));

        const studentMatch =
          !studentFilters?.length ||
          meeting.students.some((s) => studentFilters.includes(s.student.id));

        return subjectMatch && tutorMatch && studentMatch;
      });

      setMeetings(filteredMeetings);
      setStatus(PageStatus.SUCCESS);
    } catch (e: any) {
      const err = e as ApiError;
      console.error(`Error (#${err.status}): ${err.message}`);
      setStatus(PageStatus.ERROR);
      setError({ error: "An unexpected error occurred", message: err.message });
    }
  }

  useEffect(() => {
    if (account && currently_selected_role) {
      fetchMeetings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedDate,
    account,
    studentFilters,
    tutorFilters,
    subjectFilters,
    currently_selected_role,
    calendarViewType,
    startDate,
    endDate,
    meetingStatusFilter,
    adminApprovalFilter,
    tutorApprovalFilter,
  ]);

  // If addedStart changes from above, default the user’s anchor date to that week’s Sunday
  useEffect(() => {
    if (addedStart) {
      setSelectedDate(moment(addedStart).startOf("week").toDate());
    }
  }, [addedStart]);

  // Responsive behavior for smaller screens: automatically show "Day" view if width < 1024
  let onWindowResize = () => {
    if (window.innerWidth < 1024) {
      setCalendarViewType(CalendarView.DAY);
    } else {
      setCalendarViewType(CalendarView.WEEK);
    }
  };

  useEffect(() => {
    onWindowResize();
    window.addEventListener("resize", onWindowResize);
    return () => window.removeEventListener("resize", onWindowResize);
  }, []);

  // Disable tutoring hours for ME/PARENT
  const tutoringHoursDisabled =
    currently_selected_role === Auth0AccountRole.ME ||
    currently_selected_role === Auth0AccountRole.PARENT;

  // Helper: move backward one unit (day/week) depending on view
  const goPrevious = () => {
    setSelectedDate(
      moment(selectedDate)
        .subtract(1, calendarViewType === CalendarView.DAY ? "day" : "week")
        .toDate()
    );
  };

  // Helper: move forward one unit (day/week) depending on view
  const goNext = () => {
    setSelectedDate(
      moment(selectedDate)
        .add(1, calendarViewType === CalendarView.DAY ? "day" : "week")
        .toDate()
    );
  };

  // Handle toggling to Week view
  const toggleToWeekView = () => {
    // If we’re currently in day mode, jump to the Sunday for that selected day
    if (calendarViewType === CalendarView.DAY) {
      setSelectedDate(moment(selectedDate).startOf("week").toDate());
    }
    setCalendarViewType(CalendarView.WEEK);
  };

  // Handle toggling to Day view
  const toggleToDayView = () => {
    // If we’re currently in week mode, pick “today’s weekday”
    // offset within the *displayed* week. E.g. if today is Wednesday,
    // that is dayOfWeek=3. So we add 3 days to the Sunday of the displayed week.
    if (calendarViewType === CalendarView.WEEK) {
      const dayOfWeek = moment().day(); // 0=Sunday, 1=Monday, ...
      const newDate = moment(selectedDate)
        .startOf("week")
        .add(dayOfWeek, "days");
      setSelectedDate(newDate.toDate());
    }
    setCalendarViewType(CalendarView.DAY);
  };

  return (
    <div className="py-6 px-7 rounded-xl bg-neutral-50">
      <div className="grid grid-cols-8 pb-4 border-b-2">
        <div className="col-span-8 md:col-span-5">{children}</div>
        <div className="flex flex-row items-center gap-2 col-span-8 md:col-span-3 justify-end mt-4 md:mt-0">
          {/* If we're NOT in tutoring hours mode, show date/week toggles */}
          {!showTutoringHours ? (
            <>
              <Button
                size={ButtonSize.SMALL}
                fill={ButtonFill.HOLLOW}
                color={ButtonColor.GREEN}
                onClick={
                  calendarViewType === CalendarView.DAY
                    ? toggleToWeekView
                    : toggleToDayView
                }
                extraClasses="ml-2 px-3 py-1 border rounded-md bg-green-50"
              >
                {" "}
                {calendarViewType === CalendarView.WEEK ? "W" : "D"}{" "}
              </Button>
              {calendarViewType === CalendarView.DAY ? (
                <span className="font-bold ml-2">
                  {moment(selectedDate).format("ddd, MMMM Do")}
                </span>
              ) : (
                <span className="font-bold ml-2">
                  Week of{" "}
                  {moment(selectedDate).startOf("week").format("MMMM Do")}
                </span>
              )}

              {/* Left arrow */}
              <Button
                size={ButtonSize.SMALL}
                fill={ButtonFill.NO_BORDER}
                onClick={goPrevious}
                extraClasses="p-0"
              >
                <ChevronLeftIcon width="20" height="20" />
              </Button>

              {/* Right arrow */}
              <Button
                size={ButtonSize.SMALL}
                fill={ButtonFill.NO_BORDER}
                onClick={goNext}
                extraClasses="p-0"
              >
                <ChevronRightIcon width="20" height="20" />
              </Button>
            </>
          ) : (
            <DateRangeSelector
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          )}

          {/* Calendar vs. Tutoring Hours icons */}
          <Button
            size={ButtonSize.SMALL}
            fill={!showTutoringHours ? ButtonFill.HOLLOW : ButtonFill.NO_BORDER}
            color={!showTutoringHours ? ButtonColor.GREEN : ButtonColor.BLACK}
            onClick={() => {
              setShowTutoringHours(false);
              // Always default to a “Week” when returning to the calendar
              toggleToWeekView();
            }}
            extraClasses={`ml-4 border-2 ${
              !showTutoringHours ? "p-1" : "p-1.5"
            } !text-black`}
          >
            <CalendarIcon width="25" height="25" />
          </Button>

          <Button
            size={ButtonSize.SMALL}
            fill={showTutoringHours ? ButtonFill.HOLLOW : ButtonFill.NO_BORDER}
            color={showTutoringHours ? ButtonColor.GREEN : ButtonColor.BLACK}
            disabled={tutoringHoursDisabled}
            onClick={() => {
              setShowTutoringHours(true);
              // “Tutoring hours” is forced to day mode in the original code,
              // but you could omit this if you prefer your own logic.
              setCalendarViewType(CalendarView.DAY);
            }}
            extraClasses={`ml-2 border-2 ${
              showTutoringHours ? "p-1" : "p-1.5"
            } !text-black`}
          >
            <FileTextIcon width="25" height="25" />
          </Button>
        </div>
      </div>

      {showTutoringHours && !tutoringHoursDisabled && (
        <div className="mt-2">
          <MeetingFilters
            meetingStatusFilter={meetingStatusFilter}
            setMeetingStatusFilter={setMeetingStatusFilter}
            adminApprovalFilter={adminApprovalFilter}
            setAdminApprovalFilter={setAdminApprovalFilter}
            tutorApprovalFilter={tutorApprovalFilter}
            setTutorApprovalFilter={setTutorApprovalFilter}
          />
        </div>
      )}

      {status !== PageStatus.ERROR ? (
        <>
          <div
            className={`mt-3 max-h-[75vh] overflow-y-scroll ${
              showTutoringHours ? "border-2 border-gray-300 rounded-lg" : ""
            }`}
          >
            {!showTutoringHours && currently_selected_role ? (
              <CalendarWrapper
                role={currently_selected_role}
                // Pass in Sunday-of-selectedDate if in week mode, or the exact day if in day mode.
                weekStart={moment(selectedDate)
                  .startOf(
                    calendarViewType === CalendarView.WEEK ? "week" : "day"
                  )
                  .toDate()}
                events={meetings}
                setEvents={fetchMeetings}
                days={calendarViewType === CalendarView.DAY ? 1 : 7}
                status={status}
                calendarViewType={calendarViewType}
              />
            ) : (
              !tutoringHoursDisabled && (
                <TutoringHours
                  startingMeetings={meetings}
                  status={status}
                  isAdmin={
                    currently_selected_role === Auth0AccountRole.ORG_ADMIN
                  }
                />
              )
            )}
          </div>
          <div className="w-full text-right mt-3">
            {currently_selected_role === Auth0AccountRole.ORG_ADMIN && (
              <Dialog>
                <DialogTrigger asChild>
                  <Button color={ButtonColor.GREEN}>Export</Button>
                </DialogTrigger>

                <ExportDialog
                  organizationId={currently_selected_organization as number}
                />
              </Dialog>
            )}
          </div>
        </>
      ) : (
        <ErrorBlock status={status} title="Oh no!" message={error?.message} />
      )}
    </div>
  );
}

export default UpcomingMeetings;
