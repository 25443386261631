import { useState, useMemo, useEffect } from "react";
import {
  OrganizationPublicResponse,
  OrganizationWebPageResponse,
  TutorPublicResponse,
} from "client/openapi";
import { concatenateName } from "util/concatenateName";
import { Select } from "components/Select";
import "./index.css";
import { Button, LinkButton, ButtonColor, ButtonSize } from "components/Button";

type SubjectOption = {
  id: number;
  name: string;
};

export default function TutorsGrid({
  tutors,
  orgWebPage,
  organization,
}: {
  tutors: TutorPublicResponse[];
  orgWebPage: OrganizationWebPageResponse;
  organization: OrganizationPublicResponse;
}) {
  const [selectedSubject, setSelectedSubject] = useState<SubjectOption | null>(
    null
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 600);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const tutorsPerPage = isMobile ? 5 : 10;

  const subjectOptions = [
    { id: 0, name: "All" },
    ...organization.subjects.filter((s) => s.active),
  ];

  const filteredTutors = useMemo(() => {
    let result = tutors;
    if (selectedSubject && selectedSubject.id !== 0) {
      result = result.filter((t) =>
        t.subjects?.some((s) => s.org_subject?.id === selectedSubject.id)
      );
    }
    const term = searchTerm.trim().toLowerCase();
    if (term) {
      result = result.filter((t) => {
        const fullName = t.first_name + " " + t.last_name;
        return fullName.toLowerCase().includes(term);
      });
    }
    return result;
  }, [tutors, selectedSubject, searchTerm]);

  const maxPage = Math.max(
    0,
    Math.ceil(filteredTutors.length / tutorsPerPage) - 1
  );
  const currentPage = Math.min(page, maxPage);
  const displayedTutors = useMemo(() => {
    const start = currentPage * tutorsPerPage;
    return filteredTutors.slice(start, start + tutorsPerPage);
  }, [filteredTutors, currentPage, tutorsPerPage]);

  const handlePrev = () => setPage((p) => Math.max(0, p - 1));
  const handleNext = () => setPage((p) => Math.min(maxPage, p + 1));

  const truncateBio = (bio: string, length: number) =>
    bio.length > length ? bio.slice(0, length) + "..." : bio;

  const getInitials = (firstName: string, lastName: string) => {
    const f = firstName?.[0]?.toUpperCase() ?? "";
    const l = lastName?.[0]?.toUpperCase() ?? "";
    return f + l;
  };

  return (
    <div>
      <div className="tutors--header">
        <div className={`tutors--header-left ${isMobile ? "mx-auto" : ""}`}>
          <input
            type="text"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setPage(0);
            }}
            className="input"
            style={{ maxHeight: "42px", fontSize: "14px" }}
          />
          <Select
            className="subject-select mt-2"
            id="subject"
            options={subjectOptions}
            value={selectedSubject || undefined}
            getOptionLabel={(s) => s.name}
            getOptionValue={(s) => s.id.toString()}
            placeholder="Filter by subject..."
            onChange={(val) => {
              setSelectedSubject(val as SubjectOption);
              setPage(0);
            }}
            styles={{
              control: (base) => ({ ...base, minHeight: 42 }),
            }}
          />
        </div>
        {!isMobile && (
          <div className="tutors--header-right mt-3">
            <Button
              color={
                currentPage === 0
                  ? ButtonColor.GRAY
                  : orgWebPage.secondary_color
              }
              size={ButtonSize.SMALL}
              onClick={handlePrev}
              disabled={currentPage === 0}
            >
              ←
            </Button>
            <Button
              color={
                currentPage === maxPage
                  ? ButtonColor.GRAY
                  : orgWebPage.secondary_color
              }
              size={ButtonSize.SMALL}
              onClick={handleNext}
              disabled={currentPage === maxPage}
            >
              →
            </Button>
          </div>
        )}
      </div>

      <div className="tutors--grid">
        {displayedTutors.map((tutor) => {
          const photoUrl = tutor.tutor_web_page?.photo_url;
          const bio = tutor.tutor_web_page?.bio || "";
          const fullName = concatenateName(tutor);

          return (
            <div className="tutor-card" key={tutor.id}>
              <div className="tutor-card--top">
                <div className="tutor-card--image">
                  {photoUrl ? (
                    <img
                      src={photoUrl}
                      alt={fullName}
                      className="tutor-photo"
                    />
                  ) : (
                    <div
                      className="tutor-initials"
                      style={{ backgroundColor: orgWebPage.primary_color }}
                    >
                      <span className="tutor-initials--text">
                        {getInitials(tutor.first_name, tutor.last_name)}
                      </span>
                    </div>
                  )}
                </div>
                <h3
                  className="tutor--name"
                  style={{ color: orgWebPage.text_color }}
                >
                  {fullName}
                </h3>
              </div>

              <div className="tutor--details">
                {bio && <p className="tutor--bio">{truncateBio(bio, 200)}</p>}
              </div>

              <div className="tutor-card--footer">
                <LinkButton
                  to={`/tutors/${tutor.id}`}
                  size={ButtonSize.SMALL}
                  color={orgWebPage.secondary_color}
                >
                  View Profile
                </LinkButton>
              </div>
            </div>
          );
        })}
      </div>

      {isMobile && (
        <div style={{ textAlign: "center", marginTop: "1rem" }}>
          <Button
            color={
              currentPage === 0 ? ButtonColor.GRAY : orgWebPage.secondary_color
            }
            size={ButtonSize.SMALL}
            onClick={handlePrev}
            disabled={currentPage === 0}
          >
            ←
          </Button>
          <Button
            color={
              currentPage === maxPage
                ? ButtonColor.GRAY
                : orgWebPage.secondary_color
            }
            size={ButtonSize.SMALL}
            onClick={handleNext}
            disabled={currentPage === maxPage}
            style={{ marginLeft: "0.5rem" }}
          >
            →
          </Button>
        </div>
      )}
    </div>
  );
}
