import {
  ApiError,
  OrgNotesService,
  OrganizationNoteKeyResponse,
  OrganizationNameResponse,
} from "client/openapi";
import { ButtonColor } from "components/Button";
import {
  DialogAction,
  DialogActions,
  DialogClose,
  DialogContent,
} from "components/Dialog";
import { useEffect, useState } from "react";
import { APIResponse, PageStatus } from "types";
import Notifications from "util/notifications";
import "../index.css";

function NewOrgNoteKeyDialog({
  organization,
  keys,
  setKeys,
}: {
  organization: OrganizationNameResponse;
  keys: OrganizationNoteKeyResponse[];
  setKeys: React.Dispatch<React.SetStateAction<OrganizationNoteKeyResponse[]>>;
}) {
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<APIResponse>();
  const [name, setName] = useState<string>("");

  async function createKey() {
    await OrgNotesService.createOrgNoteKey({
      requestBody: {
        org_id: organization.id,
        name,
      },
    })
      .then((key) => {
        setStatus(PageStatus.SUCCESS);
        setKeys([...keys, key]);
        Notifications.success(`Field created!`);
        setName("");
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ error: "Unable to create field." });
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  function handleUpdate(ev) {
    setName(ev.target.value);
  }

  async function handleSubmit(ev) {
    ev.preventDefault();
    await createKey();
  }

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  return (
    <>
      <DialogContent className="dialog-content">
        <div className="new-org-note-key--dialog">
          <div className="new-org-note-key--dialog-header">
            <h2 className="new-org-note-key--title">New Field</h2>
          </div>

          <form onSubmit={handleSubmit}>
            <label htmlFor="name" hidden>
              Name
            </label>
            <input
              id="name"
              name="name"
              type="text"
              className="input"
              placeholder="Textbook"
              value={name || ""}
              maxLength={50}
              onChange={handleUpdate}
            />

            <DialogActions>
              <DialogAction
                primary={true}
                color={ButtonColor.PURPLE}
                type="submit"
                disabled={status === PageStatus.LOADING}
              >
                Save Field &rarr;
              </DialogAction>

              <DialogClose asChild>
                <DialogAction
                  color={ButtonColor.PURPLE}
                  disabled={status === PageStatus.LOADING}
                >
                  Cancel
                </DialogAction>
              </DialogClose>
            </DialogActions>
          </form>
        </div>
      </DialogContent>
    </>
  );
}

export default NewOrgNoteKeyDialog;
