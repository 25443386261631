import { CopyIcon } from "@radix-ui/react-icons";
import { Auth0AccountRole } from "client/openapi";
import { Button, LinkButton } from "components/Button";
import EditProfileDialog from "components/Dashboard/EditProfileDialog";
import { getButtonRoleColor } from "util/contextColor";
import Notifications from "util/notifications";
import "./index.css";

type ProfileType = Auth0AccountRole.ORG_ADMIN | Auth0AccountRole.ORG_TUTOR;

function ProfileData({
  context,
  tutor_id,
  organization_id,
}: {
  context: ProfileType;
  tutor_id?: number;
  organization_id?: number;
}) {
  const renderProfileContent = () => {
    if (context === Auth0AccountRole.ORG_ADMIN && organization_id) {
      return (
        <>
          <div className="profile-link--wrapper">
            <div className="link">{`/organizations/${organization_id}`}</div>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(
                  `${process.env.REACT_APP_FRONTEND_DOMAIN}/organizations/${organization_id}`
                );
                Notifications.success("Link copied to clipboard!");
              }}
              className="icon"
            >
              <CopyIcon />
            </Button>
          </div>

          <LinkButton
            color={getButtonRoleColor(context)}
            to={`/organizations/${organization_id}`}
            target="_blank"
          >
            View Profile
          </LinkButton>
        </>
      );
    }

    if (context === Auth0AccountRole.ORG_TUTOR && tutor_id) {
      return (
        <>
          <div className="profile-link--wrapper">
            <div className="link">{`/tutors/${tutor_id}`}</div>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(
                  `${process.env.REACT_APP_FRONTEND_DOMAIN}/tutors/${tutor_id}`
                );
                Notifications.success("Link copied to clipboard!");
              }}
              className="icon"
            >
              <CopyIcon />
            </Button>
          </div>

          <LinkButton
            color={getButtonRoleColor(context)}
            to={`/tutors/${tutor_id}`}
            target="_blank"
          >
            View Profile
          </LinkButton>
        </>
      );
    }

    return null;
  };

  return (
    <div className="container mx-auto mt-4">
      <div className="profile__header">
        <div className="headline-9">Create Webpage</div>

        <div className="profile-edit--actions organization">
          {renderProfileContent()}
        </div>
      </div>

      <div className="mt-4 mb-5">
        <EditProfileDialog
          context={context}
          organization_id={organization_id}
          tutor_id={tutor_id}
        />
      </div>
    </div>
  );
}

export default ProfileData;
