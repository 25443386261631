/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LocationAvailabilityResponse } from "../models/LocationAvailabilityResponse";
import type { OrganizationMeetingLocationCreate } from "../models/OrganizationMeetingLocationCreate";
import type { OrganizationMeetingLocationResponse } from "../models/OrganizationMeetingLocationResponse";
import type { OrganizationMeetingLocationUpdate } from "../models/OrganizationMeetingLocationUpdate";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class LocationsService {
  /**
   * Get Locations By Org
   * Get all organization meeting locations for a specified organization from the database.
   * @returns OrganizationMeetingLocationResponse Successful Response
   * @throws ApiError
   */
  public static getLocationsByOrg({
    orgId,
    skip,
    limit = 1000,
  }: {
    orgId: number;
    skip?: number;
    limit?: number;
  }): CancelablePromise<Array<OrganizationMeetingLocationResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/locations/organizations/{org_id}",
      path: {
        org_id: orgId,
      },
      query: {
        skip: skip,
        limit: limit,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Location
   * Create a new organization meeting location and
   * returns the resulting location from the database.
   * @returns OrganizationMeetingLocationResponse Successful Response
   * @throws ApiError
   */
  public static createLocation({
    requestBody,
  }: {
    requestBody: OrganizationMeetingLocationCreate;
  }): CancelablePromise<OrganizationMeetingLocationResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/locations",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Location
   * Update an organization meeting location by its ID using specified attributes
   * within OrganizationMeetingLocationUpdate to update the database and returns
   * the updated location.
   * @returns OrganizationMeetingLocationResponse Successful Response
   * @throws ApiError
   */
  public static updateLocation({
    locationId,
    requestBody,
  }: {
    locationId: number;
    requestBody: OrganizationMeetingLocationUpdate;
  }): CancelablePromise<OrganizationMeetingLocationResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/locations/{location_id}",
      path: {
        location_id: locationId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Location Availability
   * Determine if an OrganizationMeetingLocation is being used by another Meeting at any point
   * during the provided start and end time. If so, return an object with all the start and end
   * times it is being used for, and other meeting locations that are available during the
   * original start and end time. Only meetings that have been accepted by all tutors are included.
   * @returns LocationAvailabilityResponse Successful Response
   * @throws ApiError
   */
  public static getLocationAvailability({
    desiredLocationId,
    orgId,
    desiredStart,
    desiredEnd,
    editingMeetingId,
  }: {
    desiredLocationId: number;
    orgId: number;
    desiredStart: string;
    desiredEnd: string;
    editingMeetingId?: number | null;
  }): CancelablePromise<LocationAvailabilityResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/locations/location/availability{desired_location_id}",
      path: {
        desired_location_id: desiredLocationId,
      },
      query: {
        org_id: orgId,
        desired_start: desiredStart,
        desired_end: desiredEnd,
        editing_meeting_id: editingMeetingId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
