/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InviteCreate } from "../models/InviteCreate";
import type { ReInviteCreate } from "../models/ReInviteCreate";
import type { TutorAdminResponse } from "../models/TutorAdminResponse";
import type { TutorLimitedResponse } from "../models/TutorLimitedResponse";
import type { TutorUpdate } from "../models/TutorUpdate";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class TutorsService {
  /**
   * Invite Tutor
   * Create a new account/tutor for an invited user and sends an invite email to sign up.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static inviteTutor({
    requestBody,
    createAccount = true,
  }: {
    requestBody: InviteCreate;
    createAccount?: boolean;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/tutors/invite",
      query: {
        create_account: createAccount,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Reinvite Tutor
   * Reinvite a previously invited tutor.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static reinviteTutor({
    requestBody,
  }: {
    requestBody: ReInviteCreate;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/tutors/invite/reinvite/",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Tutors Me
   * Get all tutors for the currently authenticated user from the database.
   * @returns TutorLimitedResponse Successful Response
   * @throws ApiError
   */
  public static getTutorsMe(): CancelablePromise<Array<TutorLimitedResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tutors/me",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Tutor Me By Org
   * Get tutor for the currently authenticated user from the database.
   * @returns TutorLimitedResponse Successful Response
   * @throws ApiError
   */
  public static getTutorMeByOrg({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<TutorLimitedResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tutors/me/by_org",
      query: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Tutor
   * Get a tutor by its ID from the database.
   * @returns TutorAdminResponse Successful Response
   * @throws ApiError
   */
  public static getTutor({
    tutorId,
  }: {
    tutorId: number;
  }): CancelablePromise<TutorAdminResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tutors/{tutor_id}",
      path: {
        tutor_id: tutorId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Tutor
   * Update a tutor by its ID using specified attributes
   * within TutorUpdate to update the database and
   * return the updated tutor.
   * @returns TutorAdminResponse Successful Response
   * @throws ApiError
   */
  public static updateTutor({
    tutorId,
    requestBody,
  }: {
    tutorId: number;
    requestBody: TutorUpdate;
  }): CancelablePromise<TutorAdminResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/tutors/{tutor_id}",
      path: {
        tutor_id: tutorId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Tutors By Organization
   * Get tutors by their organization id from the database.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getTutorsByOrganization({
    orgId,
    phrase,
    skip,
    limit = 1000,
  }: {
    orgId: number;
    phrase?: string | null;
    skip?: number;
    limit?: number;
  }): CancelablePromise<
    Array<TutorAdminResponse> | Array<TutorLimitedResponse>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tutors/by_organization/{org_id}",
      path: {
        org_id: orgId,
      },
      query: {
        phrase: phrase,
        skip: skip,
        limit: limit,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Tutors By Subject
   * Get tutors who teach a particular subject from the database.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getTutorsBySubject({
    subjectId,
  }: {
    subjectId: number;
  }): CancelablePromise<
    Array<TutorAdminResponse> | Array<TutorLimitedResponse>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tutors/by_subject/{subject_id}",
      path: {
        subject_id: subjectId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Make Tutor Admin
   * Make a tutor an admin in the specified org.
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static makeTutorAdmin({
    tutorId,
    orgId,
  }: {
    tutorId: number;
    orgId: number;
  }): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/tutors/make-org-admin/{tutor_id}",
      path: {
        tutor_id: tutorId,
      },
      query: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
