import { ArrowRightIcon } from "@radix-ui/react-icons";
import {
  AccountAvailabilityUpdate,
  ApiError,
  AvailabilitiesService,
} from "client/openapi";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import { DialogClose, DialogContent } from "components/Dialog";
import { useContext, useEffect, useState } from "react";
import { APIResponse, PageStatus } from "types";
import Notifications from "util/notifications";
import parseAvailability from "util/parseAvailability";
import "./index.css";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

function getFullDayOfWeek(day: string): string {
  switch (day) {
    case "mon":
      return "Monday";
    case "tue":
      return "Tuesday";
    case "wed":
      return "Wednesday";
    case "thu":
      return "Thursday";
    case "fri":
      return "Friday";
    case "sat":
      return "Saturday";
    case "sun":
      return "Sunday";
    default:
      return "";
  }
}

function ClearAvailability({
  day,
  timezone,
  setAvailability,
}: {
  day: string;
  timezone: string;
  setAvailability: React.Dispatch<React.SetStateAction<boolean[]>>;
}) {
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<APIResponse>();
  const { account } = useContext(OrgRolesAndAccountContext);

  async function clearAvailability() {
    setStatus(PageStatus.LOADING);

    const clearAvailabilities: AccountAvailabilityUpdate = {
      day_of_week: day,
      start_time: "00:00",
      end_time: "23:59",
      tz: timezone,
      is_available: false,
    };

    await AvailabilitiesService.updateAccountAvailabilities({
      requestBody: clearAvailabilities,
    })
      .then((availabilities) => {
        setStatus(PageStatus.SUCCESS);
        const avails = parseAvailability(availabilities, timezone);
        setAvailability(avails);
        Notifications.success(`Availability cleared!`);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ error: "Unable to clear availability." });
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  function reset() {
    setStatus(undefined);
    setError(undefined);
  }

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  return (
    <>
      <DialogContent className="dialog-content" onClose={reset}>
        {account && (
          <div className="invite-dialog">
            <h2 className="invite-dialog--title">
              Clear All Availability on {getFullDayOfWeek(day)}s
            </h2>

            {status !== PageStatus.SUCCESS ? (
              <p>
                This action is irreversible. Are you sure you want to continue?
              </p>
            ) : (
              <p>
                Your {getFullDayOfWeek(day)} availabilities have been
                successfully cleared.
              </p>
            )}

            <div className="mt-4 clear-availability--actions">
              {status !== PageStatus.SUCCESS && (
                <Button
                  size={ButtonSize.SMALL}
                  color={ButtonColor.RED}
                  type="submit"
                  disabled={status === PageStatus.LOADING}
                  onClick={() => clearAvailability()}
                >
                  {status === PageStatus.LOADING ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <>
                      Clear {getFullDayOfWeek(day)}s <ArrowRightIcon />
                    </>
                  )}
                </Button>
              )}

              <DialogClose asChild>
                <Button
                  size={ButtonSize.SMALL}
                  color={ButtonColor.GREEN}
                  fill={status !== PageStatus.SUCCESS && ButtonFill.HOLLOW}
                  disabled={status === PageStatus.LOADING}
                >
                  {status !== PageStatus.SUCCESS ? "Cancel" : "Close"}
                </Button>
              </DialogClose>
            </div>
          </div>
        )}
      </DialogContent>
    </>
  );
}

export default ClearAvailability;
