/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApprovalStatus } from "../models/ApprovalStatus";
import type { GroupBy } from "../models/GroupBy";
import type { MeetingStatus } from "../models/MeetingStatus";
import type { MeetingStudentBillingStatus } from "../models/MeetingStudentBillingStatus";
import type { MeetingTutorPaymentStatus } from "../models/MeetingTutorPaymentStatus";
import type { SortBy } from "../models/SortBy";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ExportsService {
  /**
   * Export Students
   * Get CSV export of student account data and admin info
   * for all students in the given organization.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static exportStudents({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/exports/students",
      query: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Export Student
   * Get CSV export of student account data and org notes
   * for the given student in the given organization.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static exportStudent({
    studentId,
    orgId,
  }: {
    studentId: number;
    orgId: number;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/exports/students/{student_id}",
      path: {
        student_id: studentId,
      },
      query: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Export Parents
   * Get CSV export of parent account data including payment method info
   * for all parents in the given organization.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static exportParents({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/exports/parents",
      query: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Export Student Meetings
   * Export all the meetings of a particular student
   * @returns any Successful Response
   * @throws ApiError
   */
  public static exportStudentMeetings({
    studentId,
    start,
    until,
    tz = "UTC",
    meetingAcceptedByAllTutors,
    reviewedAndApprovedByAdmin,
    reviewedAndApprovedByAllTutors,
    attended,
    studentBilled,
  }: {
    studentId: number;
    start?: string | null;
    until?: string | null;
    tz?: string;
    meetingAcceptedByAllTutors?: MeetingStatus | null;
    reviewedAndApprovedByAdmin?: ApprovalStatus | null;
    reviewedAndApprovedByAllTutors?: ApprovalStatus | null;
    attended?: boolean | null;
    studentBilled?: MeetingStudentBillingStatus | null;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/exports/meetings/students/{student_id}",
      path: {
        student_id: studentId,
      },
      query: {
        start: start,
        until: until,
        tz: tz,
        meeting_accepted_by_all_tutors: meetingAcceptedByAllTutors,
        reviewed_and_approved_by_admin: reviewedAndApprovedByAdmin,
        reviewed_and_approved_by_all_tutors: reviewedAndApprovedByAllTutors,
        attended: attended,
        student_billed: studentBilled,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Export Tutor Meetings
   * Export all the meetings of a particular tutor
   * @returns any Successful Response
   * @throws ApiError
   */
  public static exportTutorMeetings({
    tutorId,
    start,
    until,
    tz = "UTC",
    meetingAcceptedByAllTutors,
    reviewedAndApprovedByAdmin,
    reviewedAndApprovedByAllTutors,
    tutorPaid,
  }: {
    tutorId: number;
    start?: string | null;
    until?: string | null;
    tz?: string;
    meetingAcceptedByAllTutors?: MeetingStatus | null;
    reviewedAndApprovedByAdmin?: ApprovalStatus | null;
    reviewedAndApprovedByAllTutors?: ApprovalStatus | null;
    tutorPaid?: MeetingTutorPaymentStatus | null;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/exports/meetings/tutors/{tutor_id}",
      path: {
        tutor_id: tutorId,
      },
      query: {
        start: start,
        until: until,
        tz: tz,
        meeting_accepted_by_all_tutors: meetingAcceptedByAllTutors,
        reviewed_and_approved_by_admin: reviewedAndApprovedByAdmin,
        reviewed_and_approved_by_all_tutors: reviewedAndApprovedByAllTutors,
        tutor_paid: tutorPaid,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Export Grouped Data
   * Get CSV export of sessions/payroll with the given organization with filters.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static exportGroupedData({
    orgId,
    start,
    until,
    tz = "UTC",
    sortBy,
    groupBy,
    filterByMeetingsReviewedAndApprovedByAnAdmin,
    filterByMeetingsReviewedAndApprovedByAllTutors,
    filterByMeetingsAcceptedByAllTutors,
    getLockedMeetingsOrMeetingsReadyToBeLocked,
    onlyIncludeMeetingsNeedingBillingOrPayment,
  }: {
    orgId: number;
    start?: string | null;
    until?: string | null;
    tz?: string;
    sortBy?: SortBy;
    groupBy?: GroupBy;
    filterByMeetingsReviewedAndApprovedByAnAdmin?: ApprovalStatus | null;
    filterByMeetingsReviewedAndApprovedByAllTutors?: ApprovalStatus | null;
    filterByMeetingsAcceptedByAllTutors?: MeetingStatus | null;
    getLockedMeetingsOrMeetingsReadyToBeLocked?: boolean | null;
    onlyIncludeMeetingsNeedingBillingOrPayment?: boolean | null;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/exports/grouped/{org_id}",
      path: {
        org_id: orgId,
      },
      query: {
        start: start,
        until: until,
        tz: tz,
        sort_by: sortBy,
        group_by: groupBy,
        filter_by_meetings_reviewed_and_approved_by_an_admin:
          filterByMeetingsReviewedAndApprovedByAnAdmin,
        filter_by_meetings_reviewed_and_approved_by_all_tutors:
          filterByMeetingsReviewedAndApprovedByAllTutors,
        filter_by_meetings_accepted_by_all_tutors:
          filterByMeetingsAcceptedByAllTutors,
        get_locked_meetings_or_meetings_ready_to_be_locked:
          getLockedMeetingsOrMeetingsReadyToBeLocked,
        only_include_meetings_needing_billing_or_payment:
          onlyIncludeMeetingsNeedingBillingOrPayment,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Export Student Transactions
   * Export all transactions a student has with the given org.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static exportStudentTransactions({
    studentId,
    orgId,
    start,
    until,
  }: {
    studentId: number;
    orgId: number;
    start?: string | null;
    until?: string | null;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/exports/transactions/students/{student_id}",
      path: {
        student_id: studentId,
      },
      query: {
        org_id: orgId,
        start: start,
        until: until,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Export Tutor Transactions
   * Export all transactions a student has with the given org.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static exportTutorTransactions({
    tutorId,
    orgId,
    start,
    until,
  }: {
    tutorId: number;
    orgId: number;
    start?: string | null;
    until?: string | null;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/exports/transactions/tutors/{tutor_id}",
      path: {
        tutor_id: tutorId,
      },
      query: {
        org_id: orgId,
        start: start,
        until: until,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Export Organization Transactions
   * Export all transactions given an org.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static exportOrganizationTransactions({
    orgId,
    start,
    until,
  }: {
    orgId: number;
    start?: string | null;
    until?: string | null;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/exports/transactions/organizations/{org_id}",
      path: {
        org_id: orgId,
      },
      query: {
        start: start,
        until: until,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
