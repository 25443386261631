import { DialogClose } from "@radix-ui/react-dialog";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import { ApiError, InviteType, ParentsService } from "client/openapi";
import { DialogAction, DialogActions, DialogContent } from "components/Dialog";
import { useContext, useEffect, useState } from "react";
import { APIResponse, PageStatus } from "types";

import Notifications from "util/notifications";
import "./index.css";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";
import { ButtonColor } from "components/Button";
import PhoneInput from "react-phone-input-2";

const emptyAccount = {
  email: "",
  phone_number: "",
  first_name: "",
  last_name: "",
};

function InviteParentDialog({
  fetchParents,
  currentPage,
}: {
  fetchParents: (page: number) => Promise<void>;
  currentPage: number;
}) {
  const { currently_selected_organization, account } = useContext(
    OrgRolesAndAccountContext
  );

  const [newParent, setNewParent] = useState(emptyAccount);
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<APIResponse>();
  const [createAccount, setCreateAccount] = useState(false);

  function inviteParent() {
    setStatus(PageStatus.LOADING);

    ParentsService.inviteParent({
      requestBody: {
        first_name: newParent.first_name,
        last_name: newParent.last_name,
        phone_number: newParent.phone_number
          ? newParent.phone_number
          : undefined,
        email: newParent.email,
        org_invited_by: currently_selected_organization as number,
        acct_invited_by: account?.reference_id as string,
        type: InviteType.PARENT,
      },
      createAccount: createAccount,
    })
      .then(() => {
        setStatus(PageStatus.SUCCESS);
        Notifications.success(`Parent invited!`);
        fetchParents(currentPage);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError(e);
      });
  }

  const handlePhoneChange = (newPhone: string) => {
    setNewParent({
      ...newParent,
      phone_number: newPhone,
    });
  };

  function handleUpdate(ev) {
    const value = ev.target.value;

    setNewParent({
      ...newParent,
      [ev.target.name]: value,
    });
  }

  function handleSubmit() {
    return async (ev) => {
      ev.preventDefault();
      await inviteParent();
    };
  }

  function clearForm() {
    setNewParent(emptyAccount);
    setStatus(undefined);
    setCreateAccount(false);
  }

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  return (
    <>
      <DialogContent className="dialog-content" onClose={clearForm}>
        <div className="invite-dialog">
          <h2 className="invite-dialog--title">Invite a Parent</h2>

          {status === PageStatus.SUCCESS ? (
            <>
              <p className="mb-4">
                <b>
                  {newParent.first_name} {newParent.last_name}
                </b>{" "}
                has been successfully invited!
              </p>

              <DialogActions>
                <DialogAction
                  primary={true}
                  color={ButtonColor.SKYBLUE}
                  onClick={clearForm}
                >
                  Invite Another <ArrowRightIcon />
                </DialogAction>

                <DialogClose asChild>
                  <DialogAction color={ButtonColor.SKYBLUE} onClick={clearForm}>
                    Close
                  </DialogAction>
                </DialogClose>
              </DialogActions>
            </>
          ) : (
            <>
              <form onSubmit={handleSubmit()}>
                <label htmlFor="first_name" className="input-label">
                  First Name
                  <input
                    id="first_name"
                    name="first_name"
                    type="text"
                    className="input"
                    placeholder="Amy"
                    value={newParent.first_name}
                    onChange={handleUpdate}
                    disabled={status === PageStatus.LOADING}
                    required
                  />
                </label>

                <label htmlFor="last_name" className="mt-4 input-label">
                  Last Name
                  <input
                    id="last_name"
                    name="last_name"
                    type="text"
                    className="input"
                    placeholder="Smith"
                    value={newParent.last_name}
                    onChange={handleUpdate}
                    disabled={status === PageStatus.LOADING}
                    required
                  />
                </label>

                <label htmlFor="email" className="mt-4 input-label">
                  Email Address
                  <input
                    id="email"
                    name="email"
                    type="email"
                    className="input"
                    placeholder="amy@example.com"
                    value={newParent.email}
                    onChange={handleUpdate}
                    disabled={status === PageStatus.LOADING}
                    required
                  />
                </label>

                <label htmlFor="phone_number" className="mt-4 input-label">
                  Phone Number (optional)
                  <PhoneInput
                    country={"us"}
                    value={newParent.phone_number}
                    onChange={handlePhoneChange}
                    prefix="+"
                    inputClass="input"
                    placeholder="+1 (555) 555-5555"
                    dropdownStyle={{ display: "none" }}
                    countryCodeEditable={false}
                  />
                </label>

                <div className="mt-4 flex items-center">
                  <input
                    type="checkbox"
                    id="create-account"
                    checked={createAccount}
                    onChange={() => setCreateAccount(!createAccount)}
                    disabled={status === PageStatus.LOADING}
                    className="mr-2"
                  />
                  <label htmlFor="create-account">
                    Invite user to create an account
                  </label>
                </div>

                <DialogActions>
                  <DialogAction
                    primary={true}
                    color={ButtonColor.SKYBLUE}
                    type="submit"
                    disabled={status === PageStatus.LOADING}
                  >
                    {status === PageStatus.LOADING ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      <>
                        Invite <ArrowRightIcon />
                      </>
                    )}
                  </DialogAction>

                  <DialogClose asChild>
                    <DialogAction
                      color={ButtonColor.SKYBLUE}
                      onClick={clearForm}
                    >
                      Cancel
                    </DialogAction>
                  </DialogClose>
                </DialogActions>
              </form>
            </>
          )}
        </div>
      </DialogContent>
    </>
  );
}

export default InviteParentDialog;
