import {
  Dialog,
  DialogAction,
  DialogActions,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "components/Dialog";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import {
  Auth0AccountRole,
  MeetingLimitedResponse,
  MeetingsService,
  MeetingStatus,
  TutorLimitedResponse,
} from "client/openapi";
import Notifications from "util/notifications";
import MeetingDialog from "components/MeetingDialog";
import moment from "moment";
import { concatenateMeetingStudents } from "util/concatenateSubject";

export default function PendingMeetings({
  meetings,
  setMeetings,
  tutors,
}: {
  meetings: MeetingLimitedResponse[];
  setMeetings: (meetings: MeetingLimitedResponse[]) => void;
  tutors: TutorLimitedResponse[];
}) {
  function resolveMeeting({
    meeting,
    decision,
  }: {
    meeting: MeetingLimitedResponse;
    decision: MeetingStatus;
  }) {
    MeetingsService.editMeetingTutor({
      meetingTutorId:
        meeting.tutors.find((mt) => tutors.some((t) => t.id === mt.tutor.id))
          ?.id || -1,
      requestBody: {
        tutor_accepted_meeting: decision,
      },
    })
      .then(async () => {
        Notifications.success(
          `Meeting ${
            decision === MeetingStatus.ACCEPTED
              ? "accepted! Reload the page for changes to take effect!"
              : "rejected!"
          }`
        );

        setMeetings(
          meetings.filter(
            (remaining_meeting) => remaining_meeting.id !== meeting.id
          )
        );
      })
      .catch((error) => {
        Notifications.error("Unable to respond to invite.");
      });
  }

  return (
    <DialogContent className="dialog-content max-w-[800px]">
      <div className="mt-3 font-semibold text-center header text-lg">
        Pending Meetings
      </div>

      <div className="border rounded-lg border-gray-400 px-6 py-4 mt-6">
        <div className="w-full border-spacing-0.5">
          <div>
            {meetings.map((meeting) => (
              <div className="header text-sm align-top mb-2" key={meeting.id}>
                <div className="flex justify-between gap-2 font-bold mr-2 items-center">
                  <div className="flex gap-2 items-center mr-2">
                    <p className="text-nowrap whitespace-nowrap font-normal">
                      {moment(meeting.start).format("ddd MMM Do")}{" "}
                      {moment(meeting.start).format("h:mma")}-
                      {moment(meeting.start)
                        .add(meeting.duration, "minutes")
                        .format("h:mma")}{" "}
                      | {meeting.name}
                      {" with "}
                      {concatenateMeetingStudents(meeting.students)}
                    </p>
                  </div>

                  <div
                    className="flex flex-row justify-end"
                    style={{ paddingTop: "2px" }}
                  >
                    <div style={{ marginRight: "10px" }}>
                      <Dialog>
                        <DialogTrigger>
                          <Button
                            color={ButtonColor.SKYBLUE}
                            size={ButtonSize.EXTRA_SMALL}
                            fill={ButtonFill.HOLLOW}
                          >
                            View
                          </Button>
                        </DialogTrigger>
                        <MeetingDialog
                          isCreate={false}
                          role={Auth0AccountRole.ORG_TUTOR}
                          event={meeting}
                          setEvents={() => Promise.resolve()}
                        />
                      </Dialog>
                    </div>
                    <div style={{ marginRight: "10px" }}>
                      <Button
                        color={ButtonColor.RED}
                        size={ButtonSize.EXTRA_SMALL}
                        onClick={() => {
                          resolveMeeting({
                            meeting: meeting,
                            decision: MeetingStatus.REJECTED,
                          });
                        }}
                      >
                        reject
                      </Button>
                    </div>
                    <div>
                      <Button
                        color={ButtonColor.GREEN}
                        size={ButtonSize.EXTRA_SMALL}
                        onClick={() => {
                          resolveMeeting({
                            meeting: meeting,
                            decision: MeetingStatus.ACCEPTED,
                          });
                        }}
                      >
                        accept
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <DialogActions>
        <DialogClose asChild>
          <DialogAction color={ButtonColor.PURPLE}>Close</DialogAction>
        </DialogClose>
      </DialogActions>
    </DialogContent>
  );
}
