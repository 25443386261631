/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enum for meeting approval- after the meeting has was supposed to take place,
 * indicates whether an admin/tutor has confirmed all the details are correct and if
 * the meeting is ready to be factoredinto billing and payroll.
 */
export enum ApprovalStatus {
  APPROVED = "Approved",
  PENDING = "Pending",
  DENIED = "Denied",
}
