import { Auth0AccountRole, OrganizationsService } from "client/openapi";
import { Select } from "components/Select";
import { useContext, useEffect, useState } from "react";
import Notifications from "util/notifications";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

type Organization = {
  label: string;
  value: number;
};

export default function SelectOrganization() {
  const {
    currently_selected_role,
    currently_selected_organization,
    organizations_available_to_role,
    updateOrgRolesAndAccount,
  } = useContext(OrgRolesAndAccountContext);

  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [currentOrg, setCurrentOrg] = useState<Organization>();

  function handleOrganizationChange(organization: Organization) {
    setCurrentOrg(organization);

    updateOrgRolesAndAccount({
      currently_selected_organization: organization.value,
    });
  }

  useEffect(() => {
    if (
      organizations_available_to_role &&
      organizations_available_to_role.length > 1 &&
      currently_selected_role !== Auth0AccountRole.ORG_ADMIN
    ) {
      OrganizationsService.getOrganizationNames({
        requestBody: organizations_available_to_role,
      })
        .then((orgs) => {
          const mappedOrgs = orgs.map((org) => ({
            label: org.name,
            value: org.id,
          }));
          setOrganizations(mappedOrgs);
          setCurrentOrg(
            mappedOrgs.find(
              (org) => org.value === currently_selected_organization
            )
          );
        })
        .catch((err) => {
          Notifications.error(
            "Could not get organizations available to user for booking"
          );
        });
    }
  }, [organizations_available_to_role, currently_selected_role]);

  return organizations_available_to_role &&
    organizations_available_to_role.length > 1 &&
    currently_selected_role !== Auth0AccountRole.ORG_ADMIN ? (
    <div className="flex flex-row justify-between">
      <span className="mt-2 font-extrabold">Organization:</span>
      <div className="w-8/12">
        <Select
          name="dashboard-selector--dropdown"
          value={currentOrg}
          onChange={handleOrganizationChange}
          isSearchable={false}
          options={organizations}
        />
      </div>
    </div>
  ) : null;
}
