import Buttons from "./Buttons";
import Credit from "./Credit";
import Statistics from "./Statistics";
import Transactions from "./Transactions";
import "./PaymentsTab.css";
import { useCallback, useEffect, useState } from "react";
import {
  StudentLimitedResponse,
  PaymentsService,
  StudentToOrgTransactionResponse,
  MeetingsService,
  MeetingAdminResponse,
  AccountingSystemForCalculatingFinancialStatistics,
  MeetingStatus,
  ApprovalStatus,
  StudentToOrgTransactionType,
} from "client/openapi";
import moment from "moment";
import { ErrorBlock, LoadingBlock } from "components/StatusBlock";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { APIResponse, PageStatus } from "types";
import Notifications from "util/notifications";
import TransactionSettings from "./Transactions/TransactionSettings/TransactionSettings";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TADPOLE_BILLING as string
);

function Payments({
  student,
  orgId,
}: {
  student: StudentLimitedResponse;
  orgId: number;
}) {
  const [clientSecret, setClientSecret] = useState<string>();
  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING);
  const [error, setError] = useState<APIResponse>();

  let currentDate = new Date(); // Get the current date and time
  let pastDate = new Date(currentDate); // Create a new Date object based on the current date
  pastDate.setMonth(pastDate.getMonth() - 6);

  const [startDate, setStartDate] = useState(pastDate.toISOString());
  const [endDate, setEndDate] = useState(currentDate.toISOString());
  const [accountingSystem, setAccountingSystem] =
    useState<AccountingSystemForCalculatingFinancialStatistics>(
      AccountingSystemForCalculatingFinancialStatistics.TRANSACTIONS
    );

  const [transactionTypeFilter, setTransactionTypeFilter] = useState<
    StudentToOrgTransactionType | "all"
  >("all");

  const [adminApprovalFilter, setAdminApprovalFilter] = useState<
    ApprovalStatus | "all"
  >("all");

  const [transactions, setTransactions] = useState<
    StudentToOrgTransactionResponse[] | MeetingAdminResponse[]
  >([]);

  useEffect(() => {
    const getClientSecretForSetupIntent = async () => {
      setStatus(PageStatus.LOADING);
      try {
        const clientSecret = await PaymentsService.addPaymentMethodStudent({
          studentId: student.id,
        });
        setStatus(PageStatus.SUCCESS);
        setClientSecret(clientSecret);
      } catch (e: any) {
        setStatus(PageStatus.ERROR);
        setError({ error: "Could not get student payment details." });
        console.error(`Error (#${e.status}): ${e.message}`);
      }
    };

    getClientSecretForSetupIntent();
  }, [orgId, student.id]);

  const options = { clientSecret: clientSecret };

  const getTransactions = useCallback(async () => {
    if (
      accountingSystem ===
      AccountingSystemForCalculatingFinancialStatistics.TRANSACTIONS
    ) {
      const transactions_response =
        await PaymentsService.getTransactionsByStudent({
          studentId: student.id,
          start: moment.utc(startDate).startOf("day").toISOString(),
          until: moment.utc(endDate).endOf("day").toISOString(),
          accountingSystem: accountingSystem,
        });

      const filteredTransactions =
        transactionTypeFilter === "all"
          ? transactions_response
          : transactions_response.filter(
              (txn) =>
                txn.student_to_org_transaction_type === transactionTypeFilter
            );

      setTransactions(
        filteredTransactions.sort((a, b) =>
          moment(b.timestamp).diff(moment(a.timestamp))
        )
      );
    } else if (
      accountingSystem ===
      AccountingSystemForCalculatingFinancialStatistics.MEETINGS
    ) {
      const meetings_response = await MeetingsService.getMeetingsByStudent({
        studentId: student.id,
        start: moment.utc(startDate).startOf("day").toISOString(),
        until: moment.utc(endDate).endOf("day").toISOString(),
        meetingAcceptedByAllTutors: MeetingStatus.ACCEPTED,
        attended: true,
      });

      const filteredMeetings =
        adminApprovalFilter === "all"
          ? meetings_response
          : meetings_response.filter(
              (meeting) =>
                meeting.reviewed_and_approved_by_admin === adminApprovalFilter
            );

      setTransactions(
        (filteredMeetings as MeetingAdminResponse[]).sort((a, b) =>
          moment(b.start).diff(moment(a.start))
        )
      );
    }
  }, [
    accountingSystem,
    adminApprovalFilter,
    endDate,
    startDate,
    student.id,
    transactionTypeFilter,
  ]);

  useEffect(() => {
    getTransactions();
  }, [endDate, getTransactions, startDate, accountingSystem]);

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  if (!options.clientSecret) {
    if (status === PageStatus.LOADING) {
      return <LoadingBlock status={status} />;
    }

    if (status === PageStatus.ERROR) {
      return <ErrorBlock status={status} message={error?.message} />;
    }
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      <div>
        <div className="flex flex-col lg:flex-row lg:justify-between border-gray-300 border-b-2 rounded-t pb-4">
          <div>
            <Credit student={student} orgId={orgId} />
          </div>
          <div className="mt-5 lg:mt-0">
            <Buttons
              transactions={transactions}
              student={student}
              orgId={orgId}
            />
          </div>
        </div>
        <div className="rounded-xl bg-neutral-50 pb-4">
          <div className="mt-4">
            <Statistics
              student={student}
              orgId={orgId}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              accountingSystem={accountingSystem}
            />
          </div>
          <div className="my-4">
            <TransactionSettings
              accountingSystem={accountingSystem}
              setAccountingSystem={setAccountingSystem}
              setTransactions={setTransactions}
              transactionTypeFilter={transactionTypeFilter}
              setTransactionTypeFilter={setTransactionTypeFilter}
              adminApprovalFilter={adminApprovalFilter}
              setAdminApprovalFilter={setAdminApprovalFilter}
            />
          </div>
          <div className="mt-4 mx-4">
            <Transactions
              student={student}
              transactions={transactions}
              accountingSystem={accountingSystem}
            />
          </div>
        </div>
      </div>
    </Elements>
  );
}
export default Payments;
