import { MeetingTutorLimitedResponse } from "client/openapi";
import { Link } from "react-router-dom";
import { concatenateName } from "util/concatenateName";
import "./index.css";

export default function TutorCard({
  tutor,
}: {
  tutor: MeetingTutorLimitedResponse;
}) {
  const maxBioChars = 100;

  return (
    <div className="tutor-card">
      <div className="tutor--details">
        <h3 className="tutor--name">{concatenateName(tutor)}</h3>
        {/* {tutor.bio && (
          <div className="tutor--bio">
            {tutor.bio.substring(0, Math.min(maxBioChars, tutor.bio.length))}
            {tutor.bio.length > maxBioChars ? "..." : ""}
          </div>
        )} */}
      </div>

      <div className="tutor-card--footer">
        <Link
          to={`/tutors/${tutor.id}`}
          className="button button--small button--green"
        >
          View Profile
        </Link>{" "}
        <Link
          to={`/tutors/${tutor.id}/book`}
          className="button button--small button--hollow button--green"
        >
          Book
        </Link>
      </div>
    </div>
  );
}
