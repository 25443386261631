import React, { useCallback, useEffect, useState } from "react";
import "./index.css";
import moment from "moment";
import {
  AccountingSystemForCalculatingFinancialStatistics,
  ApiError,
  ExportsService,
  PaymentsService,
} from "client/openapi";
import { APIResponse, PageStatus } from "types";
import Notifications from "util/notifications";
import { saveAs } from "file-saver";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import { DatePicker, InputGroup } from "rsuite";

function Statistics({
  orgId,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  accountingSystem,
}: {
  orgId: number;
  startDate: string;
  endDate: string;
  setStartDate: (startDate: string) => void;
  setEndDate: (endDate: string) => void;
  accountingSystem: AccountingSystemForCalculatingFinancialStatistics;
}) {
  const [revenue, setRevenue] = useState<number>(0);
  const [cost, setCost] = useState<number>(0);
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<APIResponse>();
  const [dontSpamExport, setDontSpamExport] = useState<boolean>(false);

  const getAndSetRevenue = useCallback(async () => {
    setStatus(PageStatus.LOADING);
    PaymentsService.getOrgRevenue({
      orgId: orgId,
      start: moment.utc(startDate).format("YYYY-MM-DD"),
      until: moment.utc(endDate).add(1, "days").format("YYYY-MM-DD"),
      accountingSystem: accountingSystem,
    })
      .then((revenue) => {
        setRevenue(revenue / 100);
        setStatus(PageStatus.SUCCESS);
      })
      .catch((error) => {
        setError(error);
        setStatus(PageStatus.ERROR);
      });
  }, [accountingSystem, endDate, orgId, startDate]);

  const getAndSetCost = useCallback(async () => {
    setStatus(PageStatus.LOADING);
    PaymentsService.getOrgCostOfTutoring({
      orgId: orgId,
      start: moment.utc(startDate).format("YYYY-MM-DD"),
      until: moment.utc(endDate).add(1, "days").format("YYYY-MM-DD"),
      accountingSystem: accountingSystem,
    })
      .then((cost) => {
        setCost(cost / 100);
        setStatus(PageStatus.SUCCESS);
      })
      .catch((error) => {
        setError(error);
        setStatus(PageStatus.ERROR);
      });
  }, [accountingSystem, endDate, orgId, startDate]);

  useEffect(() => {
    getAndSetRevenue();
    getAndSetCost();
  }, [getAndSetCost, getAndSetRevenue]);

  function ExportTransactions() {
    ExportsService.exportOrganizationTransactions({
      orgId: orgId,
    })
      .then((data) => {
        try {
          const blob = new Blob([data], { type: "text/csv" });
          const fileName = "export.csv";
          saveAs(blob, fileName);
          Notifications.success(
            `Transactions have been saved to your computer!`
          );
        } catch (error) {
          console.error(error);
          Notifications.error("Unable to save file.");
        }
        setDontSpamExport(false);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({
          message: `Unable to export transactions`,
        });
        console.error(`Error (#${e.status}): ${e.message}`);
        setDontSpamExport(false);
      });
  }

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  return (
    <div className="StatisticsContainer flex flex-col w-full lg:h-20 lg:flex-row border-b-2">
      <div
        className="w-full lg:w-1/4 mt-2"
        style={{ fontSize: "12px", fontWeight: "700" }}
      >
        <span className="ml-10 lg:ml-4 mt-1">Dates</span>
        <div className="flex ml-6 lg:ml-0">
          <InputGroup style={{ height: "35px" }} className="w-11/12 ml-4 mr-4">
            <DatePicker
              oneTap
              defaultValue={new Date(startDate)}
              onChange={(value) => setStartDate((value as Date).toString())}
              onKeyDown={(e) => e.preventDefault()}
              format="yyyy-MM-dd "
              size="xs"
              cleanable={false}
              style={{ paddingTop: "4px" }}
              className="w-11/12"
              block
            />
            <InputGroup.Addon>to</InputGroup.Addon>
            <DatePicker
              oneTap
              defaultValue={new Date(endDate)}
              onChange={(value) => setEndDate((value as Date).toString())}
              onKeyDown={(e) => e.preventDefault()}
              format="yyyy-MM-dd"
              size="xs"
              cleanable={false}
              style={{ paddingTop: "4px" }}
              className="w-11/12"
              block
            />
          </InputGroup>

          <div className=" block ml-auto mr-8 lg:hidden">
            <Button
              onClick={() => {
                setDontSpamExport(true);
                ExportTransactions();
              }}
              disabled={dontSpamExport}
              color={dontSpamExport ? ButtonColor.GRAY : ButtonColor.SKYBLUE}
              size={ButtonSize.SMALL}
            >
              Export Data
            </Button>
          </div>
        </div>
      </div>
      <div className="flex w-full pl-10 pr-10 justify-between lg:w-3/4 ">
        <div className="StatisticsBox">
          Revenue <div style={{ color: "#00C56B" }}> ${revenue.toFixed(2)}</div>
        </div>
        <div className="StatisticsBox">
          Cost of Tutoring
          <div style={{ color: "#FFB341" }}>${cost.toFixed(2)}</div>
        </div>
        <div className="StatisticsBox">
          Net Income
          <div style={{ color: "#809BF7" }}>${(revenue - cost).toFixed(2)}</div>
        </div>
        <div className="StatisticsBox hidden lg:block lg:mt-5 ml-8">
          <div className="mt-5">
            <Button
              onClick={() => {
                setDontSpamExport(true);
                ExportTransactions();
              }}
              disabled={dontSpamExport}
              color={dontSpamExport ? ButtonColor.GRAY : ButtonColor.SKYBLUE}
              size={ButtonSize.SMALL}
            >
              Export Data
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Statistics;
