/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { TransactionStatus } from "client/openapi";

export function isPaymentSuccessful(status: TransactionStatus): boolean {
  return [
    TransactionStatus.UNKNOWN_PAYMENT_METHOD_CHARGE_SUCCESSFUL,
    TransactionStatus.CARD_CHARGE_SUCCESSFUL,
    TransactionStatus.ACH_CHARGE_SUCCESSFUL,
    TransactionStatus.INVOICE_SUCCESSFUL,
    TransactionStatus.TRANSFER_SUCCESSFUL,
    TransactionStatus.PAYOUT_SUCCESSFUL,
    TransactionStatus.CREDITS_PAYMENT_SUCCESSFUL,
  ].includes(status);
}

export function isRefundSuccessful(status: TransactionStatus): boolean {
  return [
    TransactionStatus.UNKNOWN_PAYMENT_METHOD_REFUND_SUCCESSFUL,
    TransactionStatus.CARD_REFUND_SUCCESSFUL,
    TransactionStatus.ACH_REFUND_SUCCESSFUL,
    TransactionStatus.INVOICE_REFUND_SUCCESSFUL,
    TransactionStatus.TRANSFER_REFUND_SUCCESSFUL,
    TransactionStatus.CREDITS_REFUND_SUCCESSFUL,
  ].includes(status);
}

export function isFailed(status: TransactionStatus): boolean {
  return [
    TransactionStatus.UNKNOWN_PAYMENT_METHOD_CHARGE_FAILED,
    TransactionStatus.CARD_CHARGE_FAILED,
    TransactionStatus.ACH_CHARGE_FAILED,
    TransactionStatus.INVOICE_FAILED,
    TransactionStatus.TRANSFER_FAILED,
    TransactionStatus.PAYOUT_FAILED,
    TransactionStatus.CARD_REFUND_FAILED,
    TransactionStatus.ACH_REFUND_FAILED,
    TransactionStatus.INVOICE_REFUND_FAILED,
    TransactionStatus.UNKNOWN_PAYMENT_METHOD_REFUND_FAILED,
    TransactionStatus.TRANSFER_REFUND_FAILED,
  ].includes(status);
}

export function isPending(status: TransactionStatus): boolean {
  return [
    TransactionStatus.UNKNOWN_PAYMENT_METHOD_CHARGE_PENDING,
    TransactionStatus.CARD_CHARGE_PENDING,
    TransactionStatus.ACH_CHARGE_PENDING,
    TransactionStatus.INVOICE_PENDING,
    TransactionStatus.TRANSFER_PENDING,
    TransactionStatus.PAYOUT_PENDING,
    TransactionStatus.CARD_REFUND_PENDING,
    TransactionStatus.ACH_REFUND_PENDING,
    TransactionStatus.INVOICE_REFUND_PENDING,
    TransactionStatus.UNKNOWN_PAYMENT_METHOD_REFUND_PENDING,
    TransactionStatus.TRANSFER_REFUND_PENDING,
    TransactionStatus.TRANSFER_AWAITING_ORG_TO_TADPOLE_PAYMENT,
    TransactionStatus.CHARGE_QUEUED,
    TransactionStatus.TRANSFER_QUEUED,
    TransactionStatus.TRANSFER_FACTORED_INTO_PAYROLL,
    TransactionStatus.TRANSFER_NOT_YET_FACTORED_INTO_PAYROLL,
    TransactionStatus.CHARGE_FACTORED_INTO_BILLING,
    TransactionStatus.CHARGE_NOT_YET_FACTORED_INTO_BILLING,
  ].includes(status);
}

/**
 * Returns a color category for the given transaction status:
 * - Green = successful payment
 * - Purple = refund successful
 * - Orange = pending
 * - Red = failed
 * - Blue = everything else
 */
export function getTransactionStatusColor(status: TransactionStatus): string {
  if (isRefundSuccessful(status)) {
    return "purple";
  } else if (isPaymentSuccessful(status)) {
    return "green";
  } else if (isFailed(status)) {
    return "red";
  } else if (isPending(status)) {
    return "orange";
  } else {
    return "blue";
  }
}
