/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StudentSubjectAdminResponse } from "../models/StudentSubjectAdminResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class StudentSubjectsService {
  /**
   * Get Student Subject
   * Retrieve a student subject.
   * @returns StudentSubjectAdminResponse Successful Response
   * @throws ApiError
   */
  public static getStudentSubject({
    studentId,
    orgSubjectId,
  }: {
    studentId: number;
    orgSubjectId: number;
  }): CancelablePromise<StudentSubjectAdminResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/student_subjects",
      query: {
        student_id: studentId,
        org_subject_id: orgSubjectId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Student Subjects By Student Id
   * Retrieve a student subject.
   * @returns StudentSubjectAdminResponse Successful Response
   * @throws ApiError
   */
  public static getStudentSubjectsByStudentId({
    studentId,
  }: {
    studentId: number;
  }): CancelablePromise<Array<StudentSubjectAdminResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/student_subjects/student/{student_id}",
      path: {
        student_id: studentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Student Subjects By Org
   * Get all student subjects by org.
   * @returns StudentSubjectAdminResponse Successful Response
   * @throws ApiError
   */
  public static getStudentSubjectsByOrg({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<Array<StudentSubjectAdminResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/student_subjects/organization/{org_id}",
      path: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Set Custom Subject Bill Rate For Student
   * Set a custom pricing rate for a student on a given subject.
   * @returns StudentSubjectAdminResponse Successful Response
   * @throws ApiError
   */
  public static setCustomSubjectBillRateForStudent({
    studentId,
    orgSubjectId,
    customBillRate,
  }: {
    studentId: any;
    orgSubjectId: number;
    customBillRate: number;
  }): CancelablePromise<StudentSubjectAdminResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/student_subjects/set_custom_subject_bill_rate_for_student/{student_id}",
      path: {
        student_id: studentId,
      },
      query: {
        org_subject_id: orgSubjectId,
        custom_bill_rate: customBillRate,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
