/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrganizationWebPageResponse } from "../models/OrganizationWebPageResponse";
import type { OrganizationWebPageUpdate } from "../models/OrganizationWebPageUpdate";
import type { TutorWebPageResponse } from "../models/TutorWebPageResponse";
import type { TutorWebPageUpdate } from "../models/TutorWebPageUpdate";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class WebPageService {
  /**
   * Get Organization Web Page
   * Get all organization subjects for a specified organization from the database.
   * @returns OrganizationWebPageResponse Successful Response
   * @throws ApiError
   */
  public static getOrganizationWebPage({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<OrganizationWebPageResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/web_page/organizations/{org_id}",
      path: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Organization Web Page By Org Id
   * Update an organization web page by its ID and return the updated web page.
   * @returns OrganizationWebPageResponse Successful Response
   * @throws ApiError
   */
  public static updateOrganizationWebPageByOrgId({
    orgId,
    requestBody,
  }: {
    orgId: number;
    requestBody: OrganizationWebPageUpdate;
  }): CancelablePromise<OrganizationWebPageResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/web_page/organizations/{org_id}",
      path: {
        org_id: orgId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Tutor Web Page
   * Get tutor web page for a specified tutor from the database.
   * @returns TutorWebPageResponse Successful Response
   * @throws ApiError
   */
  public static getTutorWebPage({
    tutorId,
  }: {
    tutorId: number;
  }): CancelablePromise<TutorWebPageResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/web_page/tutors/{tutor_id}",
      path: {
        tutor_id: tutorId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Tutor Web Page By Tutor Id
   * Update a tutor web page by its ID and return the updated web page.
   * @returns TutorWebPageResponse Successful Response
   * @throws ApiError
   */
  public static updateTutorWebPageByTutorId({
    tutorId,
    requestBody,
  }: {
    tutorId: number;
    requestBody: TutorWebPageUpdate;
  }): CancelablePromise<TutorWebPageResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/web_page/tutors/{tutor_id}",
      path: {
        tutor_id: tutorId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
