/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents what type a payment method is (ACH, card, invoice, tadpole credits,
 * stripe transfer, unknown etc.).
 */
export enum PaymentMethodType {
  CARD = "CARD",
  INVOICE = "INVOICE",
  ACH = "ACH",
  TADPOLE_CREDITS = "TADPOLE_CREDITS",
  TRANSFER = "TRANSFER",
  UNKNOWN = "UNKNOWN",
}
