/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InviteCreate } from "../models/InviteCreate";
import type { ParentAdminResponse } from "../models/ParentAdminResponse";
import type { ParentCreate } from "../models/ParentCreate";
import type { ParentLimitedResponse } from "../models/ParentLimitedResponse";
import type { ParentStudentInviteCreate } from "../models/ParentStudentInviteCreate";
import type { ParentUpdate } from "../models/ParentUpdate";
import type { ReInviteCreate } from "../models/ReInviteCreate";
import type { StudentCreate } from "../models/StudentCreate";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ParentsService {
  /**
   * Get Parents By Organization
   * Get all parents associated with the given org.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getParentsByOrganization({
    orgId,
    phrase,
    skip,
    limit = 1000,
  }: {
    orgId: number;
    phrase?: string | null;
    skip?: number;
    limit?: number;
  }): CancelablePromise<
    Array<ParentAdminResponse> | Array<ParentLimitedResponse>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/parents/org",
      query: {
        org_id: orgId,
        phrase: phrase,
        skip: skip,
        limit: limit,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Parents Me
   * Get all parents assosciated with a given account.
   * @returns ParentLimitedResponse Successful Response
   * @throws ApiError
   */
  public static getParentsMe(): CancelablePromise<
    Array<ParentLimitedResponse>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/parents/me",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Parent
   * Get a parent.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getParent({
    parentId,
  }: {
    parentId: number;
  }): CancelablePromise<ParentAdminResponse | ParentLimitedResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/parents/{parent_id}",
      path: {
        parent_id: parentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Parent
   * Update a parent by its ID.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static updateParent({
    parentId,
    requestBody,
  }: {
    parentId: number;
    requestBody: ParentUpdate;
  }): CancelablePromise<ParentAdminResponse | ParentLimitedResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/parents/{parent_id}",
      path: {
        parent_id: parentId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Parent
   * Create a new parent and returns the resulting parent from the database.
   * @returns ParentLimitedResponse Successful Response
   * @throws ApiError
   */
  public static createParent({
    requestBody,
  }: {
    requestBody: ParentCreate;
  }): CancelablePromise<ParentLimitedResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/parents/create",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Remove Existing Student From Parent
   * Remove an existing student from a parent.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static removeExistingStudentFromParent({
    parentId,
    studentId,
  }: {
    parentId: number;
    studentId: number;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/parents/remove_student",
      query: {
        parent_id: parentId,
        student_id: studentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Add Existing Student To Parent
   * Add an existing student to a parent.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static addExistingStudentToParent({
    parentId,
    studentId,
  }: {
    parentId: number;
    studentId: number;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/parents/add_student",
      query: {
        parent_id: parentId,
        student_id: studentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Add New Student To Parent
   * Add a new student to a parent account.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static addNewStudentToParent({
    parentId,
    requestBody,
  }: {
    parentId: any;
    requestBody: StudentCreate;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/parents/add_new_student",
      query: {
        parent_id: parentId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Invite Parent
   * Invite a parent.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static inviteParent({
    requestBody,
    createAccount = true,
  }: {
    requestBody: InviteCreate;
    createAccount?: boolean;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/parents/invite",
      query: {
        create_account: createAccount,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Reinvite Parent
   * Reinvite a parent.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static reinviteParent({
    requestBody,
  }: {
    requestBody: ReInviteCreate;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/parents/reinvite",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Invite Parent Student
   * Invite a parent student.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static inviteParentStudent({
    requestBody,
    createAccount = true,
  }: {
    requestBody: ParentStudentInviteCreate;
    createAccount?: boolean;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/parents/student/invite",
      query: {
        create_account: createAccount,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
