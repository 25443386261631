/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents the type of a tadpole-to-tutor transaction.
 */
export enum TadpoleToTutorTransactionType {
  AUTOMATIC_PAYMENT_FOR_A_GROUP_OF_MEETINGS = "AUTOMATIC_PAYMENT_FOR_A_GROUP_OF_MEETINGS",
  MEMBER_OF_AUTOMATIC_PAYMENT_GROUP = "MEMBER_OF_AUTOMATIC_PAYMENT_GROUP",
}
