import { ArrowRightIcon } from "@radix-ui/react-icons";
import {
  ApiError,
  Auth0AccountRole,
  OrganizationWebPageResponse,
  TutorWebPageResponse,
  WebPageService,
} from "client/openapi";
import { Button } from "components/Button";
import { DialogActions } from "components/Dialog";
import { Select } from "components/Select";
import { useEffect, useState } from "react";
import { APIResponse, PageStatus, WaveType } from "types";
import { getButtonRoleColor } from "util/contextColor";
import Notifications from "util/notifications";
import "./index.css";

function EditProfileDialog({
  context,
  organization_id,
  tutor_id,
}: {
  context: Auth0AccountRole;
  organization_id?: number;
  tutor_id?: number;
}) {
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<APIResponse>();

  const [tutorWebPage, setTutorWebPage] = useState<TutorWebPageResponse>();

  const [organizationWebPage, setOrganizationWebPage] =
    useState<OrganizationWebPageResponse>();

  let waveTypes = Object.entries(WaveType).map(([value, label]) => ({
    value,
    label:
      label.toString().substring(0, 1).toUpperCase() +
      label.toString().substring(1).toLowerCase(),
  }));
  waveTypes = waveTypes.splice(0, waveTypes.length / 2);

  async function updateTutorWebPage() {
    if (!tutor_id || !tutorWebPage) {
      return;
    }

    setStatus(PageStatus.LOADING);

    WebPageService.updateTutorWebPageByTutorId({
      tutorId: tutor_id,
      requestBody: {
        bio: tutorWebPage.bio,
        photo_url: tutorWebPage.photo_url,
      },
    })
      .then(() => {
        setStatus(PageStatus.SUCCESS);
        Notifications.success(`Profile updated!`);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ error: "An unexpected error occurred" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  async function updateOrganizationWebPage() {
    if (!organization_id || !organizationWebPage) {
      return;
    }

    setStatus(PageStatus.LOADING);

    WebPageService.updateOrganizationWebPageByOrgId({
      orgId: organization_id,
      requestBody: {
        bio: organizationWebPage.bio,
        header: organizationWebPage.header,
        wave_type: organizationWebPage.wave_type,
        photo_url: organizationWebPage.photo_url,
        primary_color: organizationWebPage.primary_color,
        secondary_color: organizationWebPage.secondary_color,
        text_color: organizationWebPage.text_color,
      },
    })
      .then(() => {
        setStatus(PageStatus.SUCCESS);
        Notifications.success(`Organization updated!`);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ error: "An unexpected error occurred" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  function handleTutorUpdate(ev) {
    const value = ev.target.value;

    setTutorWebPage(
      tutorWebPage && {
        ...tutorWebPage,
        [ev.target.name]: value,
      }
    );
  }

  function handleOrgUpdate(ev) {
    const value = ev.target.value;

    setOrganizationWebPage(
      organizationWebPage && {
        ...organizationWebPage,
        [ev.target.name]: value,
      }
    );
  }

  function handleSubmit() {
    return async (ev) => {
      ev.preventDefault();
      context === Auth0AccountRole.ORG_TUTOR && (await updateTutorWebPage());
      context === Auth0AccountRole.ORG_ADMIN &&
        (await updateOrganizationWebPage());
    };
  }

  let handleOrgWaveType = (ev) => {
    if (organizationWebPage) {
      const currentVals = { ...organizationWebPage };
      currentVals.wave_type = parseInt(ev.value, 10);
      setOrganizationWebPage(currentVals);
    }
  };

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  useEffect(() => {
    if (context === Auth0AccountRole.ORG_ADMIN && organization_id) {
      WebPageService.getOrganizationWebPage({ orgId: organization_id })
        .then((page) => setOrganizationWebPage(page))
        .catch((e: ApiError) =>
          console.error(`Error (#${e.status}): ${e.message}`)
        );
    }

    if (context === Auth0AccountRole.ORG_TUTOR && tutor_id) {
      WebPageService.getTutorWebPage({ tutorId: tutor_id })
        .then((page) => setTutorWebPage(page))
        .catch((e: ApiError) =>
          console.error(`Error (#${e.status}): ${e.message}`)
        );
    }
  }, [organization_id, tutor_id, context]);

  return (
    <>
      <div className={`profile--dialog ${getButtonRoleColor(context)}`}>
        <form onSubmit={handleSubmit()}>
          {context === Auth0AccountRole.ORG_TUTOR && tutorWebPage && (
            <>
              <label htmlFor="photo_url" className="mt-3 input-label">
                Profile Image (URL)
                <input
                  id="photo_url"
                  name="photo_url"
                  type="url"
                  className="input"
                  value={tutorWebPage.photo_url || ""}
                  onChange={handleTutorUpdate}
                  disabled={status === PageStatus.LOADING}
                />
              </label>

              <label htmlFor="bio" className="mt-3 input-label">
                Bio
                <textarea
                  id="bio"
                  name="bio"
                  className="input"
                  value={tutorWebPage.bio || ""}
                  onChange={handleTutorUpdate}
                  disabled={status === PageStatus.LOADING}
                  style={{ minHeight: "200px", resize: "vertical" }}
                />
              </label>
            </>
          )}

          {context === Auth0AccountRole.ORG_ADMIN && organizationWebPage && (
            <>
              <label htmlFor="photo_url" className="mt-3 input-label">
                Page Image (URL)
                <input
                  id="photo_url"
                  name="photo_url"
                  type="url"
                  className="input"
                  value={organizationWebPage.photo_url || ""}
                  onChange={handleOrgUpdate}
                  disabled={status === PageStatus.LOADING}
                />
              </label>
              <label htmlFor="header" className="mt-3 input-label">
                Header
                <input
                  id="header"
                  name="header"
                  type="text"
                  className="input"
                  value={organizationWebPage.header}
                  onChange={handleOrgUpdate}
                  disabled={status === PageStatus.LOADING}
                />
              </label>
              <label htmlFor="bio" className="mt-3 input-label">
                Bio
                <textarea
                  id="bio"
                  name="bio"
                  className="input"
                  value={organizationWebPage.bio || ""}
                  onChange={handleOrgUpdate}
                  disabled={status === PageStatus.LOADING}
                  style={{ minHeight: "200px", resize: "vertical" }}
                />
              </label>

              <label htmlFor="wave_type" className="mt-3 input-label">
                Profile Wave Type
                <Select
                  id="wave_type"
                  name="wave_type"
                  className="mt-2"
                  options={waveTypes}
                  value={waveTypes.find(
                    (w) => w.value === organizationWebPage.wave_type.toString()
                  )}
                  onChange={handleOrgWaveType}
                />
              </label>

              <label htmlFor="primary_color" className="mt-3 input-label">
                Primary Color
                <div>
                  <input
                    id="primary_color"
                    name="primary_color"
                    type="color"
                    value={organizationWebPage.primary_color}
                    onChange={handleOrgUpdate}
                    disabled={status === PageStatus.LOADING}
                  />
                </div>
              </label>

              <label htmlFor="secondary_color" className="mt-3 input-label">
                Secondary Color
                <div>
                  <input
                    id="secondary_color"
                    name="secondary_color"
                    type="color"
                    value={organizationWebPage.secondary_color}
                    onChange={handleOrgUpdate}
                    disabled={status === PageStatus.LOADING}
                  />
                </div>
              </label>

              <label htmlFor="text_color" className="mt-3 input-label">
                Text Color
                <div>
                  <input
                    id="text_color"
                    name="text_color"
                    type="color"
                    value={organizationWebPage.text_color}
                    onChange={handleOrgUpdate}
                    disabled={status === PageStatus.LOADING}
                  />
                </div>
              </label>
            </>
          )}

          <DialogActions>
            <Button
              color={getButtonRoleColor(context)}
              extraClasses="mt-3"
              type="submit"
              disabled={status === PageStatus.LOADING}
            >
              {status === PageStatus.LOADING ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <>
                  Save Changes <ArrowRightIcon />
                </>
              )}
            </Button>
          </DialogActions>
        </form>
      </div>
    </>
  );
}

export default EditProfileDialog;
