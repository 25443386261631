import { useState } from "react";
import { TutorAdminResponse, TutorsService } from "client/openapi";

import Notifications from "util/notifications";

function TutorPayrollPlan({ tutor }: { tutor: TutorAdminResponse }) {
  const [payrollEnabled, setPayrollEnabled] = useState<boolean>(
    tutor.payroll_enabled
  );

  const updateTutorPayrollStatus = async (newValue: boolean) => {
    TutorsService.updateTutor({
      tutorId: tutor.id,
      requestBody: { payroll_enabled: newValue },
    })
      .then(() => {
        Notifications.success("Tutor payroll status updated successfully");
      })
      .catch(() => {
        Notifications.error("Failed to update tutor payroll status");
        setPayrollEnabled(!newValue);
      });
  };

  return (
    <div className="credit-container mx-3">
      <div className="flex items-center gap-2 mb-4">
        <span>Enable Automatic Payroll:</span>
        <button
          onClick={() => {
            const newVal = !payrollEnabled;
            setPayrollEnabled(newVal);
            updateTutorPayrollStatus(newVal);
          }}
          className={`relative inline-flex items-center h-6 w-11 rounded-full transition-colors
            duration-200 focus:outline-none ${
              payrollEnabled ? "bg-blue-400" : "bg-gray-300"
            }`}
        >
          <span
            className={`inline-block w-4 h-4 transform bg-white rounded-full
              transition-transform duration-200 ${
                payrollEnabled ? "translate-x-6" : "translate-x-1"
              }`}
          />
        </button>
      </div>
    </div>
  );
}

export default TutorPayrollPlan;
