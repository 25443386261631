import { TagSize } from "components/Tag";
import { TagList } from "components/Tag/TagList";
import { Wave } from "components/Wave";
import { returnSubjectName } from "util/concatenateSubject";
import { isValidUrl } from "util/isValidUrl";
import "./index.css";

export function ProfileHeader({
  tags,
  greeting,
  bio,
  initials,
  waveType,
}: {
  tags?: any[];
  greeting: string;
  bio: string;
  initials: string;
  waveType: number;
}) {
  return (
    <header className="profile">
      <div className="container mx-auto profile_header">
        <div className="flex flex-row md:justify-between">
          <div>
            {tags && tags.length > 0 && (
              <TagList
                list={tags.map((s) => returnSubjectName(s))}
                size={TagSize.LARGE}
              />
            )}

            <h1 className="tutor_name">
              <b>{greeting}</b>
            </h1>

            <p className="tutor_bio">{bio}</p>
          </div>

          {/* Profile Image */}
          <div className="mt-4">
            <div className="profile_circle_img">
              <span
                className={`profile_img_initials ${
                  initials.length > 2 ? "small" : ""
                }`}
              >
                {initials}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="profile_header_border">
        <Wave waveType={waveType} />
      </div>
    </header>
  );
}
