import { Dialog, DialogContent } from "components/Dialog";
import SuccessDialog from "components/SuccessDialog";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function PaymentMethodSuccess() {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 2500);

    return () => clearTimeout(timer); // Cleanup timeout on unmount
  }, [navigate]);
  return (
    <>
      <Dialog open={true}>
        <DialogContent>
          <div style={{ height: "1000px" }}>
            <SuccessDialog message="Payment method added successfully" />
          </div>
        </DialogContent>
      </Dialog>
      <div style={{ height: "1000px" }}></div>
    </>
  );
}

export default PaymentMethodSuccess;
