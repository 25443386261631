import { useEffect } from "react";

// New component to handle URL redirection
const GoogleOAuthHandler = () => {
  useEffect(() => {
    // Check if the current path is "/availability"
    if (window.location.pathname === "/availability") {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      const googleState = urlParams.get("state");

      if (code && googleState) {
        // If Google OAuth parameters are present, remove only the "state" parameter from the URL
        urlParams.delete("state");
        const newURL = `${window.location.origin}${
          window.location.pathname
        }?${urlParams.toString()}`;
        window.history.replaceState({}, document.title, newURL); // Update URL without reloading
      }
    }
  }, []);

  return null;
};

export default GoogleOAuthHandler;
