import { ProtectedRoute } from "components/ProtectedRoute";
import Dashboard from "pages/Dashboard";
import OrgPerformance from "pages/org-performance";
import InviteToken from "pages/invite/[token]";
import AcceptInvite from "pages/invite/accept/auth0Secret";
import StudentDetail from "pages/manage/students/[id]";
import TutorDetail from "pages/manage/tutors/[id]";
import TutorAvailability from "pages/tutors/availability";
// import ManageTutorProfile from "pages/tutors/profile";
import { Route, Routes } from "react-router-dom";
import { AccountRoles } from "types";
import Error404 from "./pages/Error404";
import LandingPage from "./pages/Landing";
import OrganizationProfile from "./pages/OrganizationProfile";
import Signup from "./pages/Signup";
import StripeKYC from "components/Signup/StripeKYC";
import PaymentStatus from "components/PaymentMethods/PaymentMethodStatus";
import ParentDetail from "pages/manage/parents/[id]";
// import RequestPaymentMethod from "components/RequestPaymentMethod/RequestPaymentMethod";
import PaymentMethodSuccess from "components/RequestPaymentMethod/PaymentMethodSuccess";
import PrivacyPolicy from "pages/legal/PrivacyPolicy";
import TermsOfService from "pages/legal/TermsOfService";

const AppRoutes = () => {
  return (
    <Routes>
      {/* GENERAL / MARKETING */}
      <Route path="/" element={<LandingPage />} />
      <Route
        path="/dashboard"
        element={<ProtectedRoute component={Dashboard} />}
      />
      {/* AUTHENTICATION */}
      <Route path="/signup" element={<Signup />} />
      <Route
        path="/signup/student"
        element={<Signup accountType={AccountRoles.STUDENT} />}
      />
      <Route
        path="/signup/tutor"
        element={<Signup accountType={AccountRoles.TUTOR} />}
      />
      <Route
        path="/signup/organization"
        element={<Signup accountType={AccountRoles.ORGANIZATION} />}
      />
      <Route
        path="/KYC/:type"
        element={<ProtectedRoute component={StripeKYC} />}
      />
      <Route path="/invites/:token" element={<InviteToken />} />
      <Route path="/invites/accept/:auth0Secret" element={<AcceptInvite />} />
      {/* ORGANIZATIONS */}
      {/* <Route
        path="/organizations/profile"
        element={<ProtectedRoute component={ManageOrganizationProfile} />}
      /> */}
      <Route path="/organizations/:id" element={<OrganizationProfile />} />
      <Route
        path="/performance"
        element={<ProtectedRoute component={OrgPerformance} />}
      />
      {/* TUTORS */}
      {/* <Route
        path="/tutors/profile"
        element={<ProtectedRoute component={ManageTutorProfile} />}
      /> */}
      <Route
        path="/availability"
        element={<ProtectedRoute component={TutorAvailability} />}
      />
      {/* <Route path="/tutors/:id" element={<TutorProfile />} /> */}
      {/* <Route
        path="/tutors/:id/book"
        element={<ProtectedRoute component={BookTutor} />}
      /> */}
      <Route
        path="/manage/tutors/:id"
        element={<ProtectedRoute component={TutorDetail} />}
      />
      {/* STUDENTS */}
      <Route
        path="/manage/students/:id"
        element={<ProtectedRoute component={StudentDetail} />}
      />

      <Route
        path="/manage/students/payment-status"
        element={
          <ProtectedRoute
            component={PaymentStatus}
            componentProps={{
              initiatedByAdminFromOrgDashboardForStudent: true,
            }}
          />
        }
      />

      <Route
        path="/manage/parents/payment-status"
        element={
          <ProtectedRoute
            component={PaymentStatus}
            componentProps={{ initiatedByAdminFromOrgDashboardForParent: true }}
          />
        }
      />

      <Route
        path="/dashboard/payment-status"
        element={
          <ProtectedRoute
            component={PaymentStatus}
            componentProps={{ addedByStudentOrParentDirectly: true }}
          />
        }
      />

      {/* Parents */}
      <Route
        path="/manage/parents/:id"
        element={<ProtectedRoute component={ParentDetail} />}
      />

      {/* Add a payment method using two factor authentication */}
      {/* <Route
        path="/add_payment_method/:token"
        element={<RequestPaymentMethod />}
      /> */}

      <Route
        path="/payment_method_success"
        element={<PaymentMethodSuccess />}
      />

      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      {/* ERRORS */}
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default AppRoutes;
