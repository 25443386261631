import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import {
  loadConnectAndInitialize,
  StripeConnectInstance,
} from "@stripe/connect-js";
import { PaymentsService } from "client/openapi";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

function StripeKYC() {
  const navigate = useNavigate();
  const { currently_selected_organization } = useContext(
    OrgRolesAndAccountContext
  );

  const { type } = useParams();

  const [stripeConnectInstance, setStripeConnectInstance] =
    useState<StripeConnectInstance>();

  useEffect(() => {
    if (
      (currently_selected_organization && type === "organization") ||
      type === "tutor"
    ) {
      setStripeConnectInstance(() => {
        const fetchClientSecret = async () => {
          try {
            if (type === "organization") {
              // Fetch the AccountSession client secret
              const response =
                await PaymentsService.getStripeAccountSessionByOrg({
                  currentOrg: currently_selected_organization as number,
                });

              return response.client_secret;
            } else if (type === "tutor") {
              const response =
                await PaymentsService.getStripeAccountSessionByAccount();

              return response.client_secret;
            } else {
              return "";
            }
          } catch (error) {
            console.log("An error occurred: ", error);
            throw error;
          }
        };

        return loadConnectAndInitialize({
          publishableKey:
            type === "organization"
              ? (process.env
                  .REACT_APP_STRIPE_PUBLISHABLE_KEY_TADPOLE_BILLING as string)
              : (process.env
                  .REACT_APP_STRIPE_PUBLISHABLE_KEY_TADPOLE_PAYROLL as string),
          fetchClientSecret: fetchClientSecret,
          appearance: {
            overlays: "dialog",
            variables: {
              colorPrimary: "#625afa",
            },
          },
        });
      });
    }
  }, [currently_selected_organization, type]);

  return stripeConnectInstance ? (
    <div className="container mx-auto px-4">
      <div className="min-h-[950px] mb-6">
        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
          <ConnectAccountOnboarding
            onExit={() => {
              console.log("The account has exited onboarding");
              navigate("/dashboard");
            }}
          />
        </ConnectComponentsProvider>
      </div>
    </div>
  ) : (
    <div className="text-center">Can not configure Stripe at this time!</div>
  );
}

export default StripeKYC;
