import {
  AvailabilityMessage,
  MeetingTutorLimitedResponse,
  MeetingAdminResponse,
  MeetingLimitedResponse,
  MeetingTutorAdminResponse,
  TutorLimitedResponse,
  TutorAdminResponse,
} from "client/openapi";
import moment from "moment-timezone";
import { useState, useEffect } from "react";
import { DayPicker } from "react-day-picker";
import { AvailabilitiesService } from "client/openapi";
import { CalendarIcon } from "@radix-ui/react-icons";
import "react-day-picker/dist/style.css";
import "./index.css";
import { MeetingDetails } from "components/MeetingDialog";
import {
  getStartOfMonth,
  getStartOfNextMonth,
  getIndexOfDayInMonth,
  formatDateToMMDDYYYY,
  isPast,
  calculateEndTime,
} from "util/time";

const timezone = moment.tz.guess(true);

/**
 * Returns the index of a 15-minute block in the day (0-based).
 */
function getBlockIndex(start: string, interval: number): number {
  const m = moment.utc(start);
  return Math.floor((m.hour() * 60 + m.minute()) / interval);
}

export default function AvailabilityDayPicker({
  id,
  event,
  handleChangeStartDate,
  setIsAvailabilitySidebarOpen,
  tutors,
  meetingDetails,
  required,
  classes,
}: {
  id: string;
  event: MeetingAdminResponse | MeetingLimitedResponse | undefined;
  handleChangeStartDate: (date: Date) => void;
  setIsAvailabilitySidebarOpen: (open: boolean) => void;
  tutors: (
    | MeetingTutorLimitedResponse
    | MeetingTutorAdminResponse
    | TutorLimitedResponse
    | TutorAdminResponse
  )[];
  meetingDetails: MeetingDetails;
  required?: boolean;
  classes?: string;
}) {
  const [isDayPickerOpen, setIsDayPickerOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState(
    new Date(meetingDetails.start)
  );
  const [currentTutors, setCurrentTutors] = useState(tutors);
  const [duration, setDuration] = useState<number>(meetingDetails.duration);
  const [availabilities, setAvailabilities] = useState<
    Array<Array<Array<AvailabilityMessage>>>
  >(() =>
    Array.from({ length: 31 }, () => Array.from({ length: 96 }, () => []))
  );
  const [isLoading, setIsLoading] = useState(false);
  const [displayedMonth, setDisplayedMonth] = useState<Date>(selectedDate);

  // New state for mode: "any" (default) or "overlap"
  const [mode, setMode] = useState<"any" | "overlap">("any");

  const fetchAvailabilities = async (start: string, end: string) => {
    try {
      setIsLoading(true);
      const newAvailabilities: Array<Array<Array<AvailabilityMessage>>> =
        Array.from({ length: 31 }, () => Array.from({ length: 96 }, () => []));

      for (const tutor of currentTutors) {
        try {
          const tutorId = "tutor" in tutor ? tutor.tutor.id : tutor.id;
          // Using a fixed 15-minute duration for fetching as intended
          const tutorsAvailabilities =
            await AvailabilitiesService.getAvailabilitiesByTutor({
              tutorId: tutorId,
              start: start,
              until: end,
              duration: 15,
            });

          tutorsAvailabilities.forEach((availability: AvailabilityMessage) => {
            const dayIndex = getIndexOfDayInMonth(availability.start);
            const timeIndex = getBlockIndex(availability.start, 15);

            if (!newAvailabilities[dayIndex][timeIndex]) {
              newAvailabilities[dayIndex][timeIndex] = [];
            }

            newAvailabilities[dayIndex][timeIndex].push(availability);
          });
        } catch (error) {
          console.error(`${error}: Error fetching availability for tutor`);
        }
      }

      // If there's an event, we mimic its occupied times as availabilities if needed
      if (event) {
        const dayIndex = new Date(event.start).getDate() - 1;
        const startIndex = getBlockIndex(event.start, 15);
        const dayUntil = calculateEndTime(
          event.start,
          event.duration,
          timezone
        );
        const untilIndex = getBlockIndex(dayUntil, 15);
        let blockStart = event.start;
        for (let i = startIndex; i <= untilIndex; i++) {
          const availability: AvailabilityMessage = {
            start: blockStart,
            duration: 15,
          };
          currentTutors.forEach(() => {
            newAvailabilities[dayIndex][i].push(availability);
          });
          blockStart = calculateEndTime(blockStart, 15, timezone);
        }
      }

      return newAvailabilities;
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // -----------------------------
  // Overlap logic (original)
  // -----------------------------

  /**
   All tutors are available at the same time for the reuqested duration starting at the exact requested time.
   */
  function exactOverlap(dayAvail: AvailabilityMessage[][]): boolean {
    if (!dayAvail || dayAvail.length === 0) return false;

    const startIndex = getBlockIndex(meetingDetails.start, 15);
    const numBlocks = duration / 15;
    const endIndex = startIndex + numBlocks - 1;

    if (startIndex < 0 || endIndex >= dayAvail.length) return false;

    for (let i = startIndex; i <= endIndex; i++) {
      if (dayAvail[i].length !== currentTutors.length) {
        return false;
      }
    }
    return true;
  }

  /**
   * All tutors are available at the same time for the requested duration at some time during the day.
   */
  function allOverlap(dayAvail: AvailabilityMessage[][]): boolean {
    if (!dayAvail || dayAvail.length === 0) return false;
    const numBlocks = duration / 15;

    for (let i = 0; i <= dayAvail.length - numBlocks; i++) {
      let allTutorsForAllBlocks = true;
      for (let j = i; j < i + numBlocks; j++) {
        if (dayAvail[j].length !== currentTutors.length) {
          allTutorsForAllBlocks = false;
          break;
        }
      }
      if (allTutorsForAllBlocks) return true;
    }
    return false;
  }

  /**
   * Some tutors are available at the same time for the requested duration at some time during the day.
   */
  function someOverlap(dayAvail: AvailabilityMessage[][]): boolean {
    if (!dayAvail || dayAvail.length === 0) return false;
    const numBlocks = duration / 15;

    for (let i = 0; i <= dayAvail.length - numBlocks; i++) {
      let everyIntervalHasAtLeastOneTutor = true;
      let anyIntervalHasAllTutors = false;

      for (let j = i; j < i + numBlocks; j++) {
        const blockLength = dayAvail[j].length;
        if (blockLength === 0) {
          everyIntervalHasAtLeastOneTutor = false;
          break;
        }
        if (blockLength === currentTutors.length) {
          anyIntervalHasAllTutors = true;
          break;
        }
      }

      if (everyIntervalHasAtLeastOneTutor && !anyIntervalHasAllTutors) {
        return true;
      }
    }

    return false;
  }

  /**
   * No tutor availability for the requested duration all day.
   */
  function noOverlap(dayAvail: AvailabilityMessage[][]): boolean {
    if (!dayAvail || dayAvail.length === 0) return true;
    const numBlocks = duration / 15;

    for (let i = 0; i <= dayAvail.length - numBlocks; i++) {
      let allNonEmpty = true;
      for (let j = i; j < i + numBlocks; j++) {
        if (dayAvail[j].length === 0) {
          allNonEmpty = false;
          break;
        }
      }
      if (allNonEmpty) {
        return false;
      }
    }
    return true;
  }

  // -----------------------------
  // "Any" logic
  // -----------------------------
  // At least one tutor is available at the requested duration at the exact requested time.
  function exactAny(dayAvail: AvailabilityMessage[][]): boolean {
    if (!dayAvail || dayAvail.length === 0) return false;
    const startIndex = getBlockIndex(meetingDetails.start, 15);
    const numBlocks = duration / 15;
    const endIndex = startIndex + numBlocks - 1;

    if (startIndex < 0 || endIndex >= dayAvail.length) return false;

    for (let i = startIndex; i <= endIndex; i++) {
      // We just need at least one tutor availability in every block
      // to form a continuous exact match.
      if (dayAvail[i].length === 0) return false;
    }
    return true;
  }

  // At least one tutor is available for the requested duration at some time during the day.
  function allAny(dayAvail: AvailabilityMessage[][]): boolean {
    if (!dayAvail || dayAvail.length === 0) return false;
    const numBlocks = duration / 15;

    // Check if there's any continuous set of numBlocks where dayAvail has at least one tutor in each block
    for (let i = 0; i <= dayAvail.length - numBlocks; i++) {
      let allNonEmpty = true;
      for (let j = i; j < i + numBlocks; j++) {
        if (dayAvail[j].length === 0) {
          allNonEmpty = false;
          break;
        }
      }
      if (allNonEmpty) return true;
    }
    return false;
  }

  // At least one tutor is available for any duration at some time during the day.
  function someAny(dayAvail: AvailabilityMessage[][]): boolean {
    // If there's any availability at all in the day
    return dayAvail.some((block) => block.length > 0);
  }

  // no availability for any tutor
  function noneAny(dayAvail: AvailabilityMessage[][]): boolean {
    return !someAny(dayAvail);
  }

  // -----------------------------
  // Determine day status based on mode
  // -----------------------------
  function getDayAvailabilityStatus(
    dayAvail: AvailabilityMessage[][]
  ): "exact" | "all" | "some" | "none" {
    if (!dayAvail) return "none";

    if (mode === "overlap") {
      // Priority: exact > all > some > none
      if (exactOverlap(dayAvail)) return "exact";
      if (allOverlap(dayAvail)) return "all";
      if (someOverlap(dayAvail)) return "some";
      if (noOverlap(dayAvail)) return "none";
      return "none";
    } else {
      // mode === "any"
      // Priority: exact > all > some > none
      if (exactAny(dayAvail)) return "exact";
      if (allAny(dayAvail)) return "all";
      if (someAny(dayAvail)) return "some";
      if (noneAny(dayAvail)) return "none";
      return "none";
    }
  }

  useEffect(() => {
    setSelectedDate(new Date(meetingDetails.start));
    setDuration(meetingDetails.duration);
    setCurrentTutors(tutors);

    const start = getStartOfMonth(displayedMonth, timezone);
    const end = getStartOfNextMonth(displayedMonth, timezone);

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const newAvailabilities = await fetchAvailabilities(start, end);
        if (newAvailabilities) setAvailabilities(newAvailabilities);
      } catch (error) {
        console.error("Error fetching availabilities:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [displayedMonth, tutors, meetingDetails.duration, isDayPickerOpen]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const calendarContainer = document.getElementById(id);
      if (calendarContainer && !calendarContainer.contains(event.target)) {
        setIsDayPickerOpen(false);
      }
    };
    window.addEventListener("click", handleOutsideClick);
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [id, setIsDayPickerOpen]);

  const handleDateChange = (date: Date | undefined) => {
    if (!date) return;
    setSelectedDate(date);
    handleChangeStartDate(date);
    setDisplayedMonth(date);
    setIsAvailabilitySidebarOpen(true);
    setIsDayPickerOpen(false);
  };

  const handleMonthChange = (newMonth: Date) => {
    setDisplayedMonth(newMonth);
  };

  useEffect(() => {
    // If duration changes, we refresh availability by reopening the picker.
    setIsDayPickerOpen(true);
  }, [duration, setIsDayPickerOpen]);

  return (
    <div
      className={`cursor-pointer relative ${classes}`}
      id={id}
      onClick={() => {
        setIsDayPickerOpen(true);
      }}
    >
      <div className="grid grid-cols-[1fr,auto] gap-5 items-center">
        <div>{formatDateToMMDDYYYY(selectedDate)}</div>
        <CalendarIcon />
      </div>
      {isDayPickerOpen && (
        <DayPicker
          id={id}
          mode="single"
          className="absolute availability-daypicker"
          selected={selectedDate}
          onSelect={handleDateChange}
          required={required}
          month={displayedMonth}
          onMonthChange={handleMonthChange}
          modifiers={
            isLoading || tutors.length === 0
              ? {}
              : {
                  past: (day) => isPast(day),
                  exactOverlap: (day) => {
                    if (isPast(day)) return false;
                    return (
                      getDayAvailabilityStatus(
                        availabilities[day.getDate() - 1]
                      ) === "exact"
                    );
                  },
                  allOverlap: (day) => {
                    if (isPast(day)) return false;
                    return (
                      getDayAvailabilityStatus(
                        availabilities[day.getDate() - 1]
                      ) === "all"
                    );
                  },
                  someOverlap: (day) => {
                    if (isPast(day)) return false;
                    return (
                      getDayAvailabilityStatus(
                        availabilities[day.getDate() - 1]
                      ) === "some"
                    );
                  },
                  noOverlap: (day) => {
                    if (isPast(day)) return false;
                    return (
                      getDayAvailabilityStatus(
                        availabilities[day.getDate() - 1]
                      ) === "none"
                    );
                  },
                }
          }
          modifiersClassNames={{
            past: "availability-daypicker-gray",
            // The naming of these classes can remain the same;
            // their meaning changes based on mode. You can rename if desired.
            exactOverlap: "availability-daypicker-darkgreen",
            allOverlap: "availability-daypicker-green",
            someOverlap: "availability-daypicker-yellow",
            noOverlap: "availability-daypicker-red",
            selected: "availability-daypicker-selected",
            today: "availability-daypicker-today",
          }}
          captionLayout="dropdown"
          footer={
            tutors.length === 0 ? (
              "Add tutors to see their availabilities."
            ) : tutors.length > 1 ? (
              <div style={{ marginTop: "5px" }}>
                <span style={{ marginRight: "10px" }}>Mode:</span>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setMode(mode === "any" ? "overlap" : "any");
                  }}
                  style={{
                    padding: "4px 10px",
                    borderRadius: "12px",
                    backgroundColor: "#4caf50",
                    color: "white",
                    border: "none",
                    cursor: "pointer",
                    fontSize: "0.8rem",
                  }}
                >
                  {mode === "any"
                    ? "At least one tutor free"
                    : "Overlap across all tutors"}
                </button>
              </div>
            ) : null
          }
          style={{ left: "-1rem" }}
        />
      )}
    </div>
  );
}
