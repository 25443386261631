import {
  MeetingLimitedResponse,
  MeetingAdminResponse,
  Auth0AccountRole,
  StudentLimitedResponse,
  ExportsService,
  ApiError,
} from "client/openapi";
import { Button, ButtonSize } from "components/Button";
import { getButtonRoleColor } from "util/contextColor";
import Notifications from "util/notifications";
import moment from "moment";
import { concatenateName } from "util/concatenateName";
import { saveAs } from "file-saver";
import { APIResponse, PageStatus } from "types";
import { DownloadIcon } from "@radix-ui/react-icons";
import Avatar from "components/Avatar";
import { generateInitials } from "util/generateInitials";
import { returnSubjectName } from "util/concatenateSubject";
import "./index.css";
import { useContext } from "react";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";
import { concatenateSubject } from "util/concatenateSubject";

export default function MeetingHistory({
  meetings,
  exportingMeetings,
  setExportingMeetings,
  student,
  exportingData,
  setStatus,
  setError,
}: {
  meetings: MeetingLimitedResponse[] | MeetingAdminResponse[];
  exportingMeetings: boolean;
  setExportingMeetings: (boolean) => void;
  student: StudentLimitedResponse;
  exportingData: boolean;
  setStatus: (PageStatus) => void;
  setError: (APIResponse) => void;
}) {
  const { currently_selected_role } = useContext(OrgRolesAndAccountContext);

  async function exportStudentMeetings() {
    if (!student) {
      return Notifications.error("Unable to export meeting data.");
    }

    if (exportingData) {
      return Notifications.error("Please wait for other export to complete.");
    }

    setExportingMeetings(true);

    await ExportsService.exportStudentMeetings({
      studentId: student.id,
      until: moment().toISOString(),
      tz: moment.tz.guess(true),
    })
      .then((data) => {
        try {
          const blob = new Blob([data], { type: "text/csv" });
          const fileName = "export.csv";
          saveAs(blob, fileName);
          Notifications.success(
            `${student.first_name}'s meeting export has been saved to your computer!`
          );
        } catch (error) {
          console.error(error);
          Notifications.error("Unable to save file.");
        }
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ message: "Unable to export student's meetings" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });

    setExportingMeetings(false);
  }

  return (
    <section className="student-profile--section" id="student-meeting-history">
      <div className="student-profile--section-header">
        <h2>Meeting History</h2>

        <div className="flex flex-row gap-1.5 items-center">
          {meetings.length > 0 && (
            <Button
              color={getButtonRoleColor(
                currently_selected_role
                  ? currently_selected_role
                  : Auth0AccountRole.ME
              )}
              size={ButtonSize.SMALL}
              onClick={() => exportStudentMeetings()}
            >
              Export{" "}
              {exportingMeetings ? (
                <span
                  className="spinner-border spinner-border-square"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <DownloadIcon aria-label="Export Meetings" />
              )}
            </Button>
          )}
        </div>
      </div>

      <div className="student-profile--section-items">
        {meetings.length > 0 ? (
          meetings
            .sort(
              (a, b) => moment(b.start).valueOf() - moment(a.start).valueOf()
            )
            .map((m, i) => (
              <div
                key={i}
                className="student-profile--section-item student-detail--historical-meeting"
              >
                <Avatar
                  alt={`${m.tutors[0].tutor.first_name} ${m.tutors[0].tutor.last_name}`}
                  fallback={generateInitials(
                    `${m.tutors[0].tutor.first_name} ${m.tutors[0].tutor.last_name}`,
                    2
                  )}
                />
                <div className="student-detail--historical-meeting--details">
                  <p>
                    <b>{moment(m.start).format("ddd, MMM D, YYYY")}</b> &middot;{" "}
                    {m.duration}m
                  </p>
                  <p>
                    {returnSubjectName(m.org_subject)} &middot;{" "}
                    {concatenateName(m.tutors[0])}
                  </p>
                </div>
              </div>
            ))
        ) : (
          <div className="student-profile--section-item">No meetings.</div>
        )}
      </div>
    </section>
  );
}
