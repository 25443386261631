import { ArrowRightIcon } from "@radix-ui/react-icons";
import { ApiError, AvailabilitiesService } from "client/openapi";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import { DialogContent } from "components/Dialog";
import { useContext, useEffect, useState } from "react";
import { APIResponse, PageStatus } from "types";
import Notifications from "util/notifications";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";
import parseAvailability from "util/parseAvailability";
import { AccountAvailabilityUpdate } from "client/openapi";

function getFullDayOfWeek(day: string): string {
  switch (day) {
    case "mon":
      return "Monday";
    case "tue":
      return "Tuesday";
    case "wed":
      return "Wednesday";
    case "thu":
      return "Thursday";
    case "fri":
      return "Friday";
    case "sat":
      return "Saturday";
    case "sun":
      return "Sunday";
    default:
      return "";
  }
}

function AddAvailability({
  day,
  timezone,
  setAvailability,
}: {
  day: string;
  timezone: string;
  setAvailability: React.Dispatch<React.SetStateAction<boolean[]>>;
}) {
  const emptyAvailability = {
    start: "",
    end: "",
  };

  const [newAvailability, setNewAvailability] = useState(emptyAvailability);
  const { account } = useContext(OrgRolesAndAccountContext);
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<APIResponse>();

  console.log("day", day);

  async function updateAvailability() {
    if (!newAvailability) {
      return;
    }

    if (newAvailability.start > newAvailability.end) {
      setStatus(PageStatus.ERROR);
      setError({
        message: "End Time must be after Start Time. Double-check AM/PM.",
      });
      return;
    }

    setStatus(PageStatus.LOADING);

    const newAvailabilityUpdate: AccountAvailabilityUpdate = {
      day_of_week: day,
      start_time: newAvailability.start,
      end_time: newAvailability.end,
      tz: timezone,
      is_available: true,
    };

    await AvailabilitiesService.updateAccountAvailabilities({
      requestBody: newAvailabilityUpdate,
    })
      .then((availabilities) => {
        const avails = parseAvailability(availabilities, timezone);
        setAvailability(avails);

        setStatus(PageStatus.SUCCESS);
        clearForm();
        Notifications.success(`Availability updated!`);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ error: "Unable to update availability." });
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  function handleUpdate(ev) {
    const value = ev.target.value;

    setNewAvailability({
      ...newAvailability,
      [ev.target.name]: value,
    });
  }

  function handleSubmit() {
    return async (ev) => {
      ev.preventDefault();
      await updateAvailability();
    };
  }

  function clearForm() {
    setNewAvailability(emptyAvailability);
    setStatus(undefined);
  }

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  return (
    <>
      <DialogContent className="dialog-content">
        {account && (
          <div className="invite-dialog">
            <h2 className="invite-dialog--title">
              Set Availability on {getFullDayOfWeek(day)}s
            </h2>

            <form onSubmit={handleSubmit()}>
              <label htmlFor="start" className="mt-3 input-label">
                Start Time
                <input
                  id="start"
                  name="start"
                  type="time"
                  className="input"
                  value={newAvailability.start || ""}
                  onChange={handleUpdate}
                  disabled={status === PageStatus.LOADING}
                  required
                />
              </label>

              <label htmlFor="end" className="mt-3 input-label">
                End Time
                <input
                  id="end"
                  name="end"
                  type="time"
                  className="input"
                  value={newAvailability.end || ""}
                  onChange={handleUpdate}
                  disabled={status === PageStatus.LOADING}
                  required
                />
              </label>

              <Button
                size={ButtonSize.SMALL}
                color={ButtonColor.ORANGE}
                extraClasses="mt-3"
                type="submit"
                disabled={status === PageStatus.LOADING}
              >
                {status === PageStatus.LOADING ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  <>
                    Add <ArrowRightIcon />
                  </>
                )}
              </Button>
            </form>
          </div>
        )}
      </DialogContent>
    </>
  );
}

export default AddAvailability;
