import {
  ApiError,
  OrganizationPublicResponse,
  OrganizationsService,
  OrganizationWebPageResponse,
  TutorPublicResponse,
  TutorsService,
  TutorWebPageResponse,
  WebPageService,
} from "client/openapi";
import { TutorPageContent } from "components/Profile/PageContent/TutorPageContent";
import { ErrorBlock, LoadingBlock } from "components/StatusBlock";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { PageStatus } from "types";

function TutorWebPage() {
  // obtain search parameters
  const params = useParams();
  const tutorId = params.id;

  const [tutor, setTutor] = useState<TutorPublicResponse>();
  const [tutorWebPage, setTutorWebPage] = useState<TutorWebPageResponse>();
  const [orgWebPage, setOrgWebPage] = useState<OrganizationWebPageResponse>();
  const [organization, setOrganization] =
    useState<OrganizationPublicResponse>();

  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING);
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (!tutorId) {
      return;
    }

    const tutId = Number.parseInt(tutorId);

    WebPageService.getTutorWebPage({ tutorId: tutId })
      .then((t) => {
        setTutorWebPage(t);
        setStatus(PageStatus.SUCCESS);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError("Unable to get tutor details.");
        console.error(`Error (#${e.status}): ${e.message}`);
      });

    TutorsService.getTutorForWebPage({ tutorId: tutId })
      .then((t) => {
        setTutor(t);
      })
      .catch((e: ApiError) => {
        setError("Unable to get tutor details.");
      });
  }, [tutorId]);

  useEffect(() => {
    if (tutor) {
      WebPageService.getOrganizationWebPage({ orgId: tutor.org_id })
        .then((orgWebPage) => {
          setOrgWebPage(orgWebPage);
        })
        .catch((e: ApiError) => {
          setStatus(PageStatus.ERROR);
          setError("Unable to fetch organization details.");
        });

      OrganizationsService.getOrganizationForWebPage({ orgId: tutor.org_id })
        .then((org) => {
          setOrganization(org);
        })
        .catch((e: ApiError) => {
          setStatus(PageStatus.ERROR);
          setError("Unable to fetch organization details.");
        });
    }
  }, [tutor]);

  return (
    <div>
      <LoadingBlock status={status} />

      <ErrorBlock status={status} message={error} />

      {status === PageStatus.SUCCESS &&
        tutor &&
        tutorWebPage &&
        orgWebPage &&
        organization && (
          <>
            <Helmet>
              <title>{tutor.first_name + " " + tutor.last_name}</title>
            </Helmet>

            <TutorPageContent
              tutorWebPage={tutorWebPage}
              tutor={tutor}
              orgWebPage={orgWebPage}
              organization={organization}
            />
          </>
        )}
    </div>
  );
}

export default TutorWebPage;
