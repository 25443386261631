/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents the type of a user a payment link is sent to to add a payment method.
 */
export enum PaymentLinkUserType {
  STUDENT = "Student",
  PARENT = "Parent",
}
