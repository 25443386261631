import { Auth0AccountRole, MeetingLimitedResponse } from "client/openapi";
import pluralize from "./pluralize";
import { createSentenceList } from "./concatenateName";

export function getAttendees(
  role: Auth0AccountRole,
  event: MeetingLimitedResponse
): string {
  let attendees: string[] = [];

  switch (role) {
    case Auth0AccountRole.ME:
      event.tutors.map((t) => attendees.push(t.tutor.first_name));
      break;

    case Auth0AccountRole.ORG_TUTOR:
      if (event.students.length > 2) {
        event.students
          .slice(-2)
          .map((s) => attendees.push(s.student.first_name));
        attendees.push(
          `${event.students.length - 2} ${pluralize(
            "other",
            "others",
            event.students.length - 2
          )}`
        );
      } else {
        event.students.map((s) => attendees.push(s.student.first_name));
      }
      break;

    case Auth0AccountRole.ORG_ADMIN:
      event.tutors.map((t) => attendees.push(t.tutor.first_name));

      if (event.students.length > 1) {
        attendees.push(`${event.students.length} students`);
      } else {
        attendees.push(event.students[0].student.first_name);
      }
      break;

    default:
      break;
  }

  return createSentenceList(attendees);
}

export function getTutors(event: MeetingLimitedResponse): string {
  let attendees: string[] = [];
  event.tutors.map((t) => attendees.push(t.tutor.first_name));
  return createSentenceList(attendees);
}

export function getStudents(event: MeetingLimitedResponse): string {
  let attendees: string[] = [];
  if (event.students.length > 2) {
    event.students.slice(-2).map((s) => attendees.push(s.student.first_name));
    attendees.push(
      `${event.students.length - 2} ${pluralize(
        "more",
        "more",
        event.students.length - 2
      )}`
    );
  } else {
    event.students.map((s) => attendees.push(s.student.first_name));
  }
  return createSentenceList(attendees);
}
