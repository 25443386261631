import { useAuth0 } from "@auth0/auth0-react";
import {
  AccountResponse,
  AccountsService,
  ApiError,
  Auth0AccountRole,
  PermissionsService,
} from "client/openapi";
import {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
} from "react";
import {
  getAccountRoles,
  getHighestRole,
  findOrgIdsByRole,
} from "util/accounts";
import _ from "lodash";

interface OrgRolesAndAccountState {
  all_account_permissions: any | null | undefined;
  account: AccountResponse | null;
  roles_available_to_account: Auth0AccountRole[] | null | undefined;
  currently_selected_role: Auth0AccountRole | null | undefined;
  organizations_available_to_role: number[] | null | undefined;
  currently_selected_organization: number | null | undefined;
}

type OrgRolesAndAccountContextInterface = OrgRolesAndAccountState & {
  updateOrgRolesAndAccount: (updates: Partial<OrgRolesAndAccountState>) => void;
};

const defaultOrgRolesAndAccountState: OrgRolesAndAccountState = {
  all_account_permissions: null,
  account: null,
  roles_available_to_account: null,
  currently_selected_role: null,
  organizations_available_to_role: null,
  currently_selected_organization: null,
};

export const OrgRolesAndAccountContext =
  createContext<OrgRolesAndAccountContextInterface>({
    ...defaultOrgRolesAndAccountState,
    updateOrgRolesAndAccount: () => {},
  });

export const OrgRolesAndAccountProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [account, setAccount] = useState<AccountResponse | null>(null);
  const [permissions, setPermissions] =
    useState<Record<number, Auth0AccountRole[]>>();
  const [existingOrgRolesAndAccount, setExistingOrgRolesAndAccount] =
    useState<OrgRolesAndAccountState>(defaultOrgRolesAndAccountState);

  const getCurrentAccount = useCallback(() => {
    AccountsService.getCurrentAccount()
      .then((a) => {
        setAccount(a);
      })
      .catch((e: ApiError) => {
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }, []);

  const getAccountPermissions = useCallback(() => {
    PermissionsService.getUserPermissions()
      .then((p) => {
        setPermissions(p);
      })
      .catch((e: ApiError) => {
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }, []);

  const getExistingUserInfo = useCallback(() => {
    const storedState = localStorage.getItem(
      user?.sub ? user.sub : "orgRolesAndAccountState"
    );
    setExistingOrgRolesAndAccount(() => {
      return storedState
        ? JSON.parse(storedState)
        : defaultOrgRolesAndAccountState;
    });
  }, [user?.sub]);

  useEffect(() => {
    if (user && isAuthenticated && !isLoading) {
      getCurrentAccount();
      getAccountPermissions();
      getExistingUserInfo();
    }
  }, [
    getCurrentAccount,
    getAccountPermissions,
    getExistingUserInfo,
    user,
    isAuthenticated,
    isLoading,
  ]);

  useEffect(() => {
    if (account && !_.isEqual(account, existingOrgRolesAndAccount.account)) {
      const newState = {
        all_account_permissions:
          existingOrgRolesAndAccount.all_account_permissions,
        account: account,
        roles_available_to_account:
          existingOrgRolesAndAccount.roles_available_to_account,
        currently_selected_role:
          existingOrgRolesAndAccount.currently_selected_role,
        organizations_available_to_role:
          existingOrgRolesAndAccount.organizations_available_to_role,
        currently_selected_organization:
          existingOrgRolesAndAccount.currently_selected_organization,
      };

      setExistingOrgRolesAndAccount(newState);

      localStorage.setItem(
        user?.sub ? user.sub : "orgRolesAndAccountState",
        JSON.stringify(newState)
      );
    }

    if (
      permissions &&
      !_.isEqual(
        permissions,
        existingOrgRolesAndAccount.all_account_permissions
      )
    ) {
      const available_roles = getAccountRoles(permissions);
      const starting_role = getHighestRole(available_roles);
      const available_orgs = starting_role
        ? findOrgIdsByRole(permissions, starting_role)
        : [];
      const starting_org = available_orgs.length > 0 ? available_orgs[0] : null;

      const newState = {
        all_account_permissions: permissions,
        account: existingOrgRolesAndAccount.account,
        roles_available_to_account: available_roles,
        currently_selected_role: starting_role,
        organizations_available_to_role: available_orgs,
        currently_selected_organization: starting_org,
      };

      setExistingOrgRolesAndAccount(newState);

      localStorage.setItem(
        user?.sub ? user.sub : "orgRolesAndAccountState",
        JSON.stringify(newState)
      );
    }
  }, [permissions, account]);

  const updateOrgRolesAndAccount = (
    updates: Partial<OrgRolesAndAccountState>
  ) => {
    setExistingOrgRolesAndAccount((prevState) => {
      const newState = { ...prevState, ...updates };
      localStorage.setItem(
        user?.sub ? user.sub : "orgRolesAndAccountState",
        JSON.stringify(newState)
      );
      return newState;
    });
  };

  return (
    <OrgRolesAndAccountContext.Provider
      value={{
        ...existingOrgRolesAndAccount,
        updateOrgRolesAndAccount: updateOrgRolesAndAccount,
      }}
    >
      {children}
    </OrgRolesAndAccountContext.Provider>
  );
};
