/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Auth0AccountRole } from "../models/Auth0AccountRole";
import type { OrganizationCreate } from "../models/OrganizationCreate";
import type { OrganizationNameResponse } from "../models/OrganizationNameResponse";
import type { OrganizationResponseForAdmins } from "../models/OrganizationResponseForAdmins";
import type { OrganizationResponseForParents } from "../models/OrganizationResponseForParents";
import type { OrganizationResponseForStudents } from "../models/OrganizationResponseForStudents";
import type { OrganizationResponseForTutors } from "../models/OrganizationResponseForTutors";
import type { OrganizationSettingsResponse } from "../models/OrganizationSettingsResponse";
import type { OrganizationSettingsUpdate } from "../models/OrganizationSettingsUpdate";
import type { OrganizationUpdate } from "../models/OrganizationUpdate";
import type { OrgReschedCancAttenPolicyCreate } from "../models/OrgReschedCancAttenPolicyCreate";
import type { OrgReschedCancAttenPolicyResponse } from "../models/OrgReschedCancAttenPolicyResponse";
import type { OrgReschedCancAttenPolicyUpdate } from "../models/OrgReschedCancAttenPolicyUpdate";
import type { StudentReschedCancAttenPolicyCreate } from "../models/StudentReschedCancAttenPolicyCreate";
import type { StudentReschedCancAttenPolicyResponse } from "../models/StudentReschedCancAttenPolicyResponse";
import type { StudentReschedCancAttenPolicyUpdate } from "../models/StudentReschedCancAttenPolicyUpdate";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class OrganizationsService {
  /**
   * Create Organization
   * Create a new organization and returns the resulting organization from the database.
   * @returns OrganizationResponseForAdmins Successful Response
   * @throws ApiError
   */
  public static createOrganization({
    requestBody,
  }: {
    requestBody: OrganizationCreate;
  }): CancelablePromise<OrganizationResponseForAdmins> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organizations",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Organizations Me By Role
   * Get all organizations affiliated with the currently authorized user
   * as the specifed role from the database.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getOrganizationsMeByRole({
    role,
  }: {
    role: Auth0AccountRole;
  }): CancelablePromise<
    Array<
      | OrganizationResponseForStudents
      | OrganizationResponseForTutors
      | OrganizationResponseForParents
      | OrganizationResponseForAdmins
    >
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/me/role",
      query: {
        role: role,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Organization
   * Get an organization by its org_id from the database.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getOrganization({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<
    | OrganizationResponseForStudents
    | OrganizationResponseForTutors
    | OrganizationResponseForParents
    | OrganizationResponseForAdmins
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{org_id}",
      path: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Organization
   * Update an organization by its ID using specified attributes
   * within OrganizationUpdate to update the database.
   * @returns OrganizationResponseForAdmins Successful Response
   * @throws ApiError
   */
  public static updateOrganization({
    orgId,
    requestBody,
  }: {
    orgId: number;
    requestBody: OrganizationUpdate;
  }): CancelablePromise<OrganizationResponseForAdmins> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/organizations/{org_id}",
      path: {
        org_id: orgId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Org Resched Canc Attend Policies
   * Create an org's initial pricing conditions (may be a partial create).
   * @returns OrgReschedCancAttenPolicyResponse Successful Response
   * @throws ApiError
   */
  public static createOrgReschedCancAttendPolicies({
    orgId,
    requestBody,
  }: {
    orgId: number;
    requestBody: OrgReschedCancAttenPolicyCreate;
  }): CancelablePromise<OrgReschedCancAttenPolicyResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organizations/pricing/{org_id}",
      path: {
        org_id: orgId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Org Resched Canc Attend Policies
   * Get an org's pricing conditions.
   * @returns OrgReschedCancAttenPolicyResponse Successful Response
   * @throws ApiError
   */
  public static getOrgReschedCancAttendPolicies({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<OrgReschedCancAttenPolicyResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/pricing/{org_id}",
      path: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Org Resched Canc Attend Policies
   * Update an org's initial pricing conditions.
   * @returns OrgReschedCancAttenPolicyResponse Successful Response
   * @throws ApiError
   */
  public static updateOrgReschedCancAttendPolicies({
    orgId,
    requestBody,
  }: {
    orgId: number;
    requestBody: OrgReschedCancAttenPolicyUpdate;
  }): CancelablePromise<OrgReschedCancAttenPolicyResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/organizations/pricing/{org_id}",
      path: {
        org_id: orgId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Student Resched Canc Atten Policy
   * Create a student's pricing conditions.
   * @returns OrgReschedCancAttenPolicyResponse Successful Response
   * @throws ApiError
   */
  public static createStudentReschedCancAttenPolicy({
    requestBody,
  }: {
    requestBody: StudentReschedCancAttenPolicyCreate;
  }): CancelablePromise<OrgReschedCancAttenPolicyResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organizations/student_pricing/{org_id}",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Student Resched Canc Atten Policy
   * Get a student's pricing conditions.
   * @returns StudentReschedCancAttenPolicyResponse Successful Response
   * @throws ApiError
   */
  public static getStudentReschedCancAttenPolicy({
    orgId,
    studentId,
  }: {
    orgId: number;
    studentId: number;
  }): CancelablePromise<StudentReschedCancAttenPolicyResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/student_pricing/{org_id}",
      path: {
        org_id: orgId,
      },
      query: {
        student_id: studentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Student Resched Canc Atten Policy
   * Update a student's pricing conditions.
   * @returns StudentReschedCancAttenPolicyResponse Successful Response
   * @throws ApiError
   */
  public static updateStudentReschedCancAttenPolicy({
    orgId,
    studentId,
    requestBody,
  }: {
    orgId: number;
    studentId: number;
    requestBody: StudentReschedCancAttenPolicyUpdate;
  }): CancelablePromise<StudentReschedCancAttenPolicyResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/organizations/student_pricing/{org_id}",
      path: {
        org_id: orgId,
      },
      query: {
        student_id: studentId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Organization Settings
   * Get an organization's settings.
   * @returns OrganizationSettingsResponse Successful Response
   * @throws ApiError
   */
  public static getOrganizationSettings({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<OrganizationSettingsResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/settings/{org_id}",
      path: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Organization Settings
   * Update an organization's settings.
   * @returns OrganizationSettingsResponse Successful Response
   * @throws ApiError
   */
  public static updateOrganizationSettings({
    orgId,
    requestBody,
  }: {
    orgId: number;
    requestBody: OrganizationSettingsUpdate;
  }): CancelablePromise<OrganizationSettingsResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/organizations/settings/{org_id}",
      path: {
        org_id: orgId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Organization Names
   * Given a list of organization ids, get their corresponding names, and return a list of OrganizationNameResponse,
   * representing org names mapped to their ids.
   * @returns OrganizationNameResponse Successful Response
   * @throws ApiError
   */
  public static getOrganizationNames({
    requestBody,
  }: {
    requestBody: Array<number>;
  }): CancelablePromise<Array<OrganizationNameResponse>> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organizations/org_names",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
