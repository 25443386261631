import Buttons from "./Buttons";
import Credit from "./Balance";
import Statistics from "./Statistics";
import Transactions from "./Transactions";
import "./PaymentsTab.css";
import { useCallback, useEffect, useState } from "react";
import {
  ApprovalStatus,
  PaymentsService,
  TransactionType,
} from "client/openapi";
import moment from "moment";
import { ErrorBlock, LoadingBlock } from "components/StatusBlock";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { APIResponse, PageStatus } from "types";
import {
  StudentToOrgTransactionResponse,
  OrgToTadpoleTransactionResponse,
  TadpoleToTutorTransactionResponse,
  PayoutTransactionResponse,
  AccountingSystemForCalculatingFinancialStatistics,
  MeetingsService,
  MeetingAdminResponse,
  MeetingStatus,
} from "client/openapi";
import TransactionSettings from "./TransactionSettings/TransactionSettings";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TADPOLE_BILLING as string
);

function OrgPayments({ orgId }: { orgId: number }) {
  const [clientSecret, setClientSecret] = useState<string>();
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<APIResponse>();

  let currentDate = new Date();
  let pastDate = new Date(currentDate);
  pastDate.setMonth(pastDate.getMonth() - 6);

  const [startDate, setStartDate] = useState(pastDate.toISOString());
  const [endDate, setEndDate] = useState(currentDate.toISOString());
  const [accountingSystem, setAccountingSystem] =
    useState<AccountingSystemForCalculatingFinancialStatistics>(
      AccountingSystemForCalculatingFinancialStatistics.TRANSACTIONS
    );

  const [transactionTypeFilter, setTransactionTypeFilter] = useState<
    TransactionType | "all"
  >("all");

  const [adminApprovalFilter, setAdminApprovalFilter] = useState<
    ApprovalStatus | "all"
  >("all");

  const options = { clientSecret: clientSecret };

  const [transactions, setTransactions] = useState<
    | (
        | StudentToOrgTransactionResponse
        | OrgToTadpoleTransactionResponse
        | TadpoleToTutorTransactionResponse
        | PayoutTransactionResponse
      )[]
    | MeetingAdminResponse[]
  >([]);

  const getTransactions = useCallback(async () => {
    if (
      accountingSystem ===
      AccountingSystemForCalculatingFinancialStatistics.TRANSACTIONS
    ) {
      const transactions_response = await PaymentsService.getTransactionsByOrg({
        orgId: orgId,
        start: moment.utc(startDate).startOf("day").toISOString(),
        until: moment.utc(endDate).endOf("day").toISOString(),
        accountingSystem: accountingSystem,
      });

      const filteredTransactions =
        transactionTypeFilter === "all"
          ? transactions_response
          : transactions_response.filter(
              (txn) => txn.transaction_type === transactionTypeFilter
            );

      setTransactions(
        filteredTransactions.sort((a, b) =>
          moment(b.timestamp).diff(moment(a.timestamp))
        )
      );
    } else if (
      accountingSystem ===
      AccountingSystemForCalculatingFinancialStatistics.MEETINGS
    ) {
      const meetings_response = await MeetingsService.getMeetingsByOrg({
        orgId: orgId,
        start: moment.utc(startDate).startOf("day").toISOString(),
        until: moment.utc(endDate).endOf("day").toISOString(),
        meetingAcceptedByAllTutors: MeetingStatus.ACCEPTED,
      });

      const filteredMeetings =
        adminApprovalFilter === "all"
          ? meetings_response
          : meetings_response.filter(
              (meeting) =>
                meeting.reviewed_and_approved_by_admin === adminApprovalFilter
            );

      setTransactions(
        (filteredMeetings as MeetingAdminResponse[]).sort((a, b) =>
          moment(b.start).diff(moment(a.start))
        )
      );
    }
  }, [
    accountingSystem,
    orgId,
    startDate,
    endDate,
    transactionTypeFilter,
    adminApprovalFilter,
  ]);

  useEffect(() => {
    getTransactions();
  }, [endDate, getTransactions, startDate]);

  if (!options.clientSecret) {
    if (status === PageStatus.LOADING) {
      return <LoadingBlock status={status} />;
    }

    if (status === PageStatus.ERROR) {
      return <ErrorBlock status={status} message={error?.message} />;
    }
  }

  return (
    // <Elements stripe={stripePromise} options={options}>
    <div>
      <div className="flex flex-col lg:flex-row lg:justify-between border-gray-300 border-b-2 rounded-t pb-4">
        <div>
          <Credit orgId={orgId} />
        </div>
        {/* <div>
          <Buttons orgId={orgId} />
        </div> */}
      </div>
      <div className="rounded-xl bg-neutral-50 pb-4">
        <div className="mt-4">
          <Statistics
            orgId={orgId}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            accountingSystem={accountingSystem}
          />
        </div>
        <div className="my-4">
          <TransactionSettings
            accountingSystem={accountingSystem}
            setAccountingSystem={setAccountingSystem}
            setTransactions={setTransactions}
            transactionTypeFilter={transactionTypeFilter}
            setTransactionTypeFilter={setTransactionTypeFilter}
            adminApprovalFilter={adminApprovalFilter}
            setAdminApprovalFilter={setAdminApprovalFilter}
          />
        </div>
        <div className="mt-4 mx-4">
          <Transactions
            transactions={transactions}
            accountingSystem={accountingSystem}
          />
        </div>
      </div>
    </div>
    // </Elements>
  );
}
export default OrgPayments;
