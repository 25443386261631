import { MeetingStats, TutorLimitedResponse } from "client/openapi";
import TutorAttendance from "components/TutorAttendance";

export default function MetricsTab({
  tutor,
  meetingStats,
}: {
  tutor: TutorLimitedResponse;
  meetingStats: MeetingStats;
}) {
  return (
    <div className="mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-14 gap-y-8 mx-5 md:mx-0">
      <div className="h-80">
        <TutorAttendance tutor={tutor} meetings={meetingStats} />
      </div>
    </div>
  );
}
