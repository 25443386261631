import React from "react";
import PrivacyPolicyImage from "./PrivacyPolicyImage.png";
export default function PrivacyPolicyPage() {
  return (
    <div
      style={{
        padding: "2rem",
        fontFamily: "sans-serif",
        maxWidth: "1200px",
        margin: "0 auto",
      }}
    >
      <h1>Privacy Policy</h1>
      <p>
        <strong>EFFECTIVE: Jan 1, 2025</strong>
      </p>

      <p>
        This Privacy Policy (the “Privacy Policy”) governs the manner in which
        Tadpole Tutoring Inc. (“Tadpole”) collects, uses, maintains, and
        discloses information collected from users (each, a “User”) of its
        tadpoletutoring.org website, applications, and technology platform, and
        any derivative or affiliated websites on which this Privacy Policy is
        posted, linked, or referenced (collectively, the “Site”). The Site is
        owned and operated by Tadpole. Please note that this Privacy Policy
        covers Tadpole’s practices regarding information collected from its
        Site. Please read this Privacy Policy carefully to understand how we
        handle your information. By using the Site, you agree to the terms of
        this Privacy Policy. You shouldn’t use the Site if you don’t agree with
        this Privacy Policy or any other agreement that governs your use of the
        Site.
      </p>

      <h2>The Information Tadpole Collects and Receives</h2>

      <p>
        The categories of information that are collected will depend upon the
        nature of your interaction(s) with Tadpole and the Site. Below are
        examples to help you better understand the data we collect.
      </p>

      <p>
        We collect contact information. When you submit an inquiry through the
        Site, we collect your name, email address, and phone number if provided,
        along with general information about the nature of your inquiry. When
        you create an account through the Site, we will collect the
        aforementioned information and also collect your username, password,
        mailing address, and other information related to providing you services
        through the Site.
      </p>

      <p>
        We collect professional and education-related information from tutors
        and tutoring groups using the Site. Tutors and tutoring groups
        (“Providers”, included within the category of “Users”) who use the Site
        or its related and/or affiliated entities are asked to provide
        additional personal information in support of the Provider’s respective
        skills and experience, as well as a social security number or taxpayer
        identification number for tax purposes, and other information as
        necessary to facilitate payment and to comply with legal obligations.
        Providers also have choices about the information the Provider chooses
        to include on the Provider’s profile on the Site such as education, work
        experience, skills, city or area, likes or interests, photo, etc.
      </p>

      <p>
        We collect payment information for both Providers and their customers.
        If you are a Provider, we (or our payment-processing partners) will
        gather the financial details necessary to pay you for charges submitted
        through the Site, or to withdraw funds when you need to pay other
        Providers (such as for payroll) through the Site. If you are a customer
        making a purchase from a Provider, we (or our payment-processing
        partners) may collect your billing or other financial information to
        process the transaction.
      </p>

      <p>
        We collect information you submit or otherwise provide or authorize us
        to collect. This may include information about students that is required
        to facilitate tutoring or work with a Provider. We collect the
        information you post on our Site. This includes comments, reviews,
        documents, questions, chats, messages, assessments, videos, or photos.
        Such shared content may be publicly viewable by others depending on
        where it is posted, shared, or viewed. We also collect any other
        information you may provide to us or authorize us to collect, including
        any other identifier that permits the ability to contact you. If you
        communicate with Tadpole by email; post messages to forums; make
        telephone or cell phone calls or texts; communicate through electronic
        devices or other interactive devices; complete online forms, surveys,
        sweepstakes, or contest entries; or communicate with Tadpole by any
        means, any information provided in such communication may be collected
        by Tadpole.
      </p>

      <p>
        We collect demographic information and information about your interests.
        We may collect information such as your zip code, gender, age, income,
        household or lifestyle information, and information about your interests
        and preferences.
      </p>

      <p>
        We collect information about third parties that you provide to us. A
        User may also provide personally identifiable information about others,
        such as emergency contact information for notification in the event a
        situation requires it. If you choose to use our referral service to
        refer a friend to our Site, then you may provide your referral’s name
        and email address, as further detailed in the Refer-A-Friend section
        below. If you submit any personal information about another individual
        to us, then you are responsible for making sure that you have the
        authority to do so and to allow us to use their personal information in
        accordance with this Privacy Policy.
      </p>

      <p>
        We collect information through surveys, testimonials, comments, reviews,
        and blogs. You may decide to participate in a survey, to provide a
        review, to provide a testimonial, to comment on a blog or social media
        post, etc. If you decide to participate or comment, then you may provide
        certain information that may include personal information. In addition,
        we may post customer testimonials/comments/reviews on our Site that may
        contain personally identifiable information. We may invite you to
        complete a survey or participate in a promotion, either through the Site
        or through a third-party platform. If you participate, we will collect
        and store the data you provide as part of participating. That data is
        subject to this Privacy Policy unless otherwise stated in the official
        rules of the promotion or survey, including notifying winners and
        distributing rewards. Where we use a third-party platform to administer
        a survey or promotion, the third party’s privacy policy will apply.
      </p>

      <p>
        We collect internet and electronic network activity information, such as
        device and usage information. We may collect information about your
        browser type, language preference, referring site, subsequent site, and
        date/time of each visitor request. The purpose in collecting
        non-personally identifying information is to better understand how Users
        use the Site. We may collect information about your operating system,
        Internet Service provider information and IP addresses and other device
        identifiers, such as mobile device information. In addition, we may
        collect information about your use of the Site, unique views,
        originating sites, etc., including through the use of tracking
        technologies (See section pertaining to Tracking Technologies for more
        information). A User browsing the Site without registering an account or
        affirmatively providing personally identifiable information to Tadpole
        does so anonymously.
      </p>

      <p>
        We collect analytics information. We use certain third-party services to
        assist us in analysis, auditing, research, and reporting regarding the
        Site. These third parties may use web logs or web beacons, and they may
        set and access cookies on your computer or other device. In particular,
        we use Google Analytics to help collect and analyze certain information
        for the purposes discussed in this Policy. You may opt out of the use of
        cookies by Google Analytics at{" "}
        <a href="https://tools.google.com/dlpage/gaoptout">
          https://tools.google.com/dlpage/gaoptout
        </a>
        .
      </p>

      <h2>How Tadpole Uses Information</h2>

      <p>
        Tadpole may use personally identifiable information collected through
        the Site for the following reasons:
      </p>
      <ul>
        <li>
          • for the specific purposes for which the information was collected;
        </li>
        <li>• to fulfill a contractual obligation;</li>
        <li>
          • to communicate to Users regarding products and services offered
          through Tadpole, its parent, subsidiaries, and trusted affiliates;
        </li>
        <li>• to maintain and improve its products, services, and sites;</li>
        <li>• to provide support;</li>
        <li>• to enhance Users’ experiences with Tadpole;</li>
        <li>• to inform Users of offerings that may be of interest;</li>
        <li>• to process payments;</li>
        <li>
          • for business planning, marketing, advertising and sales purposes;
        </li>
        <li>• for information security purposes;</li>
        <li>• to defend its legal rights;</li>
        <li>• to efficiently maintain its business; and</li>
        <li>• to comply with applicable law.</li>
      </ul>

      <h2>Information Sharing</h2>

      <p>
        We may share information that we collect about you in the following
        ways:
      </p>

      <p>
        <strong>
          We share information with our service providers and third-party
          vendors.
        </strong>{" "}
        We contract with select third party vendors to provide services on our
        behalf, for example, services such as email, hosting, cloud storage,
        maintenance, backup and analysis, communications software, marketing,
        business strategy, banking, payment processing, professional services,
        background checks, etc. We also share non-personal information (such as
        aggregate user statistics, de-identified information, demographic
        information, etc.) with third parties such as advertisers, content
        providers, and analytics providers.
      </p>

      <p>
        <strong>
          We share information within the Tadpole family of companies and
          brands.
        </strong>{" "}
        Tadpole may disclose personally identifiable information collected from
        Users to its parent, subsidiaries and other related companies, trusted
        affiliates, independent contractors and business partners who will use
        the information for the purposes outlined in this Privacy Policy, as
        necessary to provide the services offered by or through Tadpole and to
        provide the Site itself, and for the specific purposes for which the
        information was collected. These companies are not permitted to use your
        personally identifiable information in any other way.
      </p>

      <p>
        <strong>
          Tadpole Tutoring is committed to maintaining the confidentiality of
          your Private Information. We do not share, sell or rent your Personal
          Information for marketing purposes. Any sharing of data with third
          parties is strictly limited to performing tasks on our behalf, such as
          processing transactions or providing services (or anything applicable
          to your business). We ensure that these third parties are
          contractually obligated to comply with our privacy standards and treat
          your Personal Information with the same level of security and
          confidentiality as we do.
        </strong>
      </p>

      <p>
        <strong>
          We may share information with a Provider related to a student.
        </strong>{" "}
        In each case, we collect such information only insofar as is necessary
        or appropriate to fulfill the purpose of the User’s interaction with
        Tadpole.
      </p>

      <p>
        <strong>
          We will share information to the extent that we are required to do so
          by law or to protect the company or others.
        </strong>{" "}
        Tadpole may disclose personally identifiable information at the request
        of law enforcement or governmental agencies or in response to subpoenas,
        court orders, or other legal process, to establish, protect, or exercise
        Tadpole’ legal or other rights or to defend against a legal claim or as
        otherwise required or allowed by law. Tadpole may disclose personally
        identifiable information in order to protect the rights, property, or
        safety of a User or any other person. Tadpole may disclose personally
        identifiable information to investigate or prevent a violation by User
        of any contractual or other relationship with Tadpole or the
        perpetration of any illegal or harmful activity.
      </p>

      <p>
        <strong>
          We may share information in connection with any business acquisition,
          sale, or merger, or in connection with investor relations.
        </strong>{" "}
        Tadpole may disclose or transfer personally identifiable information
        collected from Users in connection with or in contemplation of a sale of
        its assets or business or a merger, consolidation, or other
        reorganization of its business. Tadpole may also disclose aggregate,
        anonymous data based on information collected from Users to investors
        and potential partners.
      </p>

      <p>
        Users may always refuse to supply personally-identifying information,
        with the caveat that it may prevent them from engaging in certain
        Site-related activities.
      </p>

      <h2>Cookies and Tracking Technologies</h2>

      <p>
        Tadpole and our analytics and marketing providers may use technologies
        such as cookies, beacons, tags, and scripts (collectively “cookies”), to
        analyze trends, administer the Site, track Users’ movements around the
        Site, and to gather demographic information about our User base as a
        whole. We may receive reports based on the use of these technologies by
        these companies on an individual and aggregated basis.
      </p>

      <p>
        We may use cookies for tracking page view/usage (log data), estimating
        audience size and usage patterns, services metadata, device information,
        location information, saving Users’ preferences and settings
        information, advertising new content that relate to your interests,
        keeping track of advertisements and search engine results, and for
        authentication. Each User can control the use of cookies at the
        individual browser level by adjusting your browser settings. If you
        choose to block all cookies (including essential cookies), you may still
        use our Site, but your ability to use some or all features or areas of
        our Site may be limited.
      </p>

      <p>
        As is true of most websites, we gather certain information automatically
        and store it in log files. This information may include Internet
        protocol (IP) addresses, browser type, internet service provider (ISP),
        referring/exit pages, operating system, date/time stamp, and/or
        clickstream data. We may combine this automatically collected log
        information with other information we collect about you. We do this to
        improve services we offer you and to improve marketing, analytics, or
        site functionality.
      </p>

      <p>
        We implement Google Analytics features that use Display Advertising
        information for Google Analytics Demographics and Interest Reporting.
        You can read more about the cookies used by Google Analytics advertising
        features, by going to the Google Analytics Privacy Policy page. You can
        opt-out of Google Analytics for Display Advertising, to prevent your
        data from being used by Google Analytics, by going to the Google
        Analytics opt-out page. You can opt out of Facebook Analytics by
        following the instructions in its Data Policy and Cookies & Other
        Storage Technologies section.
      </p>

      <p>
        We, along with third-party vendors (including Google), use first-party
        cookies (such as the Google Analytics cookies) and third-party cookies
        (such as the DoubleClick cookies) to report how your ad impressions,
        other uses of ad services, and interactions with these ad impressions
        and ad services are related to visits to our website.
      </p>

      <h3>LSOs</h3>
      <p>
        Third Parties, with whom we partner to provide certain features on our
        Site or to display advertising based upon your Web browsing activity,
        use LSOs such as HTML 5 to collect and store information. Various
        browsers may offer their own management tools for removing HTML5 LSOs.
      </p>

      <h2>Your Choices Regarding Your Information</h2>

      <p>
        <strong>Updating your account information:</strong> If you have an
        account, you may be able to review the information you provided to us by
        logging into your account page and adjusting the settings under the
        “Account” tab.
      </p>

      <p>
        <strong>Email communications:</strong> If you receive an unwanted email
        from us, you can update your preferences by accessing the email
        preferences in your account settings page, or you can use the
        unsubscribe link found at the bottom of the email to opt out of future
        emails; however, please note that you will continue to receive
        transaction-related emails regarding products or services you have
        requested or emails sent as required to fulfill tutoring related
        services on the Site.
      </p>

      <p>
        <strong>Telephone/Text communications:</strong> If you receive an
        unwanted call or message from us, you can update your phone preferences
        by accessing your phone preferences in your account settings page, or
        you can send a Do Not Call request to us at the following email address:{" "}
        <a href="mailto:help@tadpoletutoring.org">help@tadpoletutoring.org</a>.
        Regarding text messages, you may opt out of SMS by replying “STOP” to
        the message received.
      </p>

      <p>
        <strong>Do Not Track and other similar signals:</strong> Some internet
        browsers incorporate a “Do Not Track” or other similar feature; however,
        given that there is not a uniform way for browsers to communicate this
        signal, the Site does not currently support these signals.
      </p>

      <p>
        <strong>Cookies and Internet-based Advertising:</strong> You may stop,
        restrict, or remove the placement of cookies on your device by adjusting
        your preferences as your browser or device permits. The online
        advertising industry also provides websites from which you may opt out
        of receiving targeted ads from data partners and other advertising
        partners. These may be accessed at sites such as{" "}
        <a href="https://www.aboutads.info/choices">
          www.aboutads.info/choices
        </a>
        .
      </p>

      <p>
        <strong>Deletion or Other Request:</strong> To request deletion of your
        information or for another request regarding your information, please
        send a request to{" "}
        <a href="mailto:help@tadpoletutoring.org">help@tadpoletutoring.org</a>.
        We will respond to your request within a reasonable timeframe, or
        otherwise in accordance with applicable law. We will retain your
        information for as long as your account is active or as needed to
        provide you services. We will retain and use your information as
        necessary to comply with our legal obligations, resolve disputes, and
        enforce our agreements. For state-specific information or related
        rights, please see the state-specific sections below.
      </p>

      <h2>Data Retention</h2>

      <p>
        We store the information collected as described in this Privacy Policy
        for as long as you are a User of the Site and/or as necessary to fulfill
        the purpose(s) for which the information was collected, to provide
        services through the Site, to resolve disputes, to pursue legitimate
        business purposes, to enforce our agreements, to establish legal
        defenses, to prevent fraud, and to comply with applicable laws.
      </p>

      <h2>Security of Information</h2>

      <p>
        Tadpole discloses potentially personally-identifying information only to
        those of its employees, contractors, and affiliated organizations that
        (i) need to know that information in order to process transactions on
        our behalf or to provide tutoring services, and (ii) that have agreed
        not to disclose it to others. We take all measures reasonably necessary
        to protect against the unauthorized access, use, alteration, or
        destruction of potentially personally-identifying and
        personally-identifying information.
      </p>

      <p>
        Information about each User that is maintained on Tadpole’ systems is
        protected using industry standard security measures. We follow generally
        accepted standards to protect the personal information submitted to us,
        both during transmission and once it is received. If you have any
        questions about the security of your personal information, you can
        contact us at{" "}
        <a href="mailto:help@tadpoletutoring.org">help@tadpoletutoring.org</a>.
        However, no security measures are perfect or impenetrable, and Tadpole
        cannot guarantee that the information submitted to, maintained on, or
        transmitted from its systems will be completely secure. Tadpole is not
        responsible for the circumvention of any privacy settings or security
        measures relating to the Site by any User or third parties.
      </p>

      <p>
        Your security is a priority and we strive to provide a safe and secure
        environment for our customers; however, please note that we cannot
        guarantee that the information submitted to, maintained on, or
        transmitted from our systems will be completely secure. If you do not
        feel comfortable sending your financial/credit card over the Internet,
        do not use the Site.
      </p>

      <h2>Links to 3rd Party Sites</h2>

      <p>
        Please note that the Site may contain links to other Sites. These linked
        sites may not be operated or controlled by Tadpole. Tadpole is not
        responsible for the privacy practices of these or any other Sites, and
        you access these Sites entirely at your own risk. Tadpole recommends
        that you review the privacy practices of any other Sites that you choose
        to visit.
      </p>

      <p>
        Tadpole is based, and this Site is hosted, in the United States of
        America. If User is from the European Union or other regions of the
        world with laws governing data collection and use that may differ from
        U.S. law and User is visiting, accessing, or otherwise using the Site,
        please note that any personally identifiable information that User
        provides to Tadpole will be transferred to the United States. Any such
        personally identifiable information provided will be processed and
        stored in the United States by Tadpole or a service provider acting on
        its behalf. By providing personally identifiable information to Tadpole,
        User hereby specifically and expressly consents to such transfer and
        processing and the uses and disclosures set forth herein.
      </p>

      <h2>Social Media Widgets</h2>

      <p>
        Our Site includes Social Media Features, such as the Facebook Like
        button and Widgets, such as the Share this button or interactive
        mini-programs that run on our Site. These Features may collect your IP
        address, which page you are visiting on our Site, and may set a cookie
        to enable the Feature to function properly. Social Media Features and
        Widgets are either hosted by a third party or hosted directly on our
        Site. Your interactions with these Features are governed by the privacy
        policy of the company providing it.
      </p>

      <h2>Blogs</h2>

      <p>
        Anytime you post on our blog please be aware that you are posting using
        a third-party application, and we have no access or control over this
        information. To request removal of your personal information from any
        blog, you can either log into the third-party application and remove
        your comment or you can contact the appropriate third party application.
        Your interaction with these features is governed by the privacy policy
        of the company providing it.
      </p>

      <h2>Providers’ Profiles/Bios</h2>

      <p>
        If you are operating as a Provider through the Site, then the profile
        you create on our Site will be publicly accessible. You may delete your
        profile to remove your visibility from the Site, or follow protocols as
        otherwise set forth in the terms of your independent contractor
        agreement.
      </p>

      <h2>Children’s Privacy</h2>

      <p>
        Our Site does not collect or retain any personal information from
        children except as needed to facilitate tutoring for that student, who
        may be a minor. Tadpole is committed to complying fully with the
        Children’s Online Privacy Protection Act of 1998 (“COPPA”). This Site
        assures the privacy of collected information in accordance with Tadpole'
        Privacy Policy.
      </p>

      <p>
        Tadpole is committed to safeguarding the information each User entrusts
        to Tadpole and believes that every User should know how it utilizes the
        information collected from each User. Other than free information and
        materials that do not require any registrations or identifying
        information, the Site is not directed at children under 13 years of age,
        and Tadpole does not knowingly collect personally identifiable
        information from children under 13 years of age online. Parents or
        Providers on behalf of Parents may supply information such as name or
        age of children in order for Tadpole to assist in 1) helping Providers
        operate their business and facilitate tutoring or 2) helping parents
        find tutoring from Providers on the Site. Parents or guardians of
        children under 13 years of age may review or have deleted any personal
        information previously provided by the child, parent, or guardian that
        is ultimately collected by the Provider or Tadpole. If you seek to
        review or delete such information, contact the Provider directly or
        Tadpole at the below phone number or email address indicating what you
        would like to have done and Tadpole will contact you for purposes of
        taking the requested action, including but not limited to refusing to
        permit the information’s further collection or use.
      </p>

      <h2>Refer-A-Friend</h2>

      <p>
        If you choose to use our referral service to refer a friend about our
        Site, we will ask you for your friend’s name and email address. We will
        automatically send your friend a one-time email inviting the referral to
        visit the Site. Tadpole stores this information for the sole purpose of
        sending this one-time email and tracking the success of our referral
        program.
      </p>

      <h2>Changes to this Policy</h2>

      <p>
        We may update this Privacy Policy to reflect changes to our information
        practices. If we make any material changes, then we may notify you by
        email (sent to the e-mail address specified in your account) or by means
        of a notice on this Site prior to the change becoming effective. Please
        review this Privacy Policy periodically. Your continued use of the Site
        after any such change(s) signifies your acceptance of any changes.
      </p>

      <h2>Contacting Tadpole</h2>

      <p>
        If you have any questions or comments about this Privacy Policy, you may
        contact Tadpole via any of the following methods:
      </p>

      <p>
        Phone: 781 - 971 - 1600
        <br />
        Email:{" "}
        <a href="mailto:help@tadpoletutoring.org">help@tadpoletutoring.org</a>
      </p>

      <h2>Your Nevada Privacy Rights</h2>

      <p>
        Nevada Revised Statute Section 603A permits a User that resides in
        Nevada the right to submit a verified request that Tadpole not sell such
        User’s covered information. Nevada law defines “sale” to mean the
        exchange of certain types of personal information for monetary
        consideration to a person for the person to license or sell the
        information to additional persons. We do not currently sell personal
        information as defined under Nevada Revised Statute Section 603A.
        However, if you are a Nevada resident, you may still submit a verified
        request by emailing{" "}
        <a href="mailto:help@tadpoletutoring.org">help@tadpoletutoring.org</a>{" "}
        to opt out of sales and we will record your instructions and incorporate
        them in the future if our policy changes. We will verify your request
        using information associated with your account, including email address,
        and may need to request additional information.
      </p>

      <h2>Your California Privacy Rights</h2>

      <p>
        Subject to certain limitations, the California Consumer Privacy Act
        (CCPA) provides California consumers the right to: request disclosure
        about what personal information we collect, use, disclose, and sell;
        request to delete their personal information; opt out of any “sale” of
        personal information; and not be discriminated against for exercising
        such rights.
      </p>

      <p>
        California consumers may make a request pursuant to their rights under
        the CCPA by contacting{" "}
        <a href="mailto:help@tadpoletutoring.org">help@tadpoletutoring.org</a>.
      </p>

      <p>
        We will verify your request using information provided in the request,
        as compared against information associated with your account, including
        verification of the email address that was listed in the request form.
        We may need to request additional information in order to verify your
        identity. Depending upon the type of request you make, we may require a
        signed declaration under penalty of perjury stating that you are the
        individual whose personal information is being requested. If you use an
        authorized agent to submit a request, we may require that the authorized
        agent provide proof of your written permission, and that you verify your
        identity directly with us.
      </p>

      <p>
        We are committed to ensuring this Privacy Policy is accessible to
        individuals with disabilities. If you wish to access this Privacy Policy
        in an alternative format, please contact us as described above.
      </p>

      <p>
        We have explained our privacy practices in full in the foregoing
        sections of this Privacy Policy. Below we have provided a list of the
        categories of personal information as set forth in the CCPA that have
        been collected, disclosed for business purposes, or “sold” for
        commercial purposes (as defined by the CCPA) during the preceding 12
        months, as well as certain other information. Please see the other
        sections of the Privacy Policy above for additional information and
        context related to the sharing of information. We do not consider the
        ways we share your personal information to comprise the sale of your
        information. Within the past 12 months, we may have disclosed the
        following categories of information about consumers for a business
        purpose:
      </p>
      <img
        src={PrivacyPolicyImage}
        alt="Privacy Policy Illustration"
        style={{
          display: "block",
          maxWidth: "60%",
          height: "auto",
          margin: "2rem 0",
          borderRadius: "8px",
        }}
      />
    </div>
  );
}
