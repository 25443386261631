import "./index.css";
import { IoMdInformationCircleOutline } from "react-icons/io";

import { Dialog, DialogTrigger } from "components/Dialog";

import PaymentInformationModal from "../../../../../../components/PaymentMethods";

import { ParentLimitedResponse } from "client/openapi";

function Buttons({ parent }: { parent: ParentLimitedResponse }) {
  return (
    <div className="flex md:grid mx-4 md:grid-cols-6 grid-cols-3 lg:gap-8 md:gap-6">
      <Dialog>
        <span className="flex flex-row col-span-3">
          <DialogTrigger className="payments-button m-auto">
            <IoMdInformationCircleOutline color="black" size="42" />
            <span className="mt-2">Payment information</span>
          </DialogTrigger>
          <span className="mt-6 ml-3">
            <span className="font-bold mr-1">Note: </span> Payment methods added
            to a parent will be made automatically available to all students of
            that parent.
          </span>
        </span>
        <PaymentInformationModal parentIfInitiatedByAdmin={parent} />
      </Dialog>
    </div>
  );
}
export default Buttons;
