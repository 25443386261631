import { ChevronDownIcon } from "@radix-ui/react-icons";
import { clsx } from "clsx";
import { DropdownIndicatorProps, OptionProps, components } from "react-select";
import Creatable from "react-select/creatable";
import "./index.css";
import CreatableSelect from "react-select/creatable";
import "./index.css";
import { useState } from "react";

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon />
    </components.DropdownIndicator>
  );
};

const Option = (props: OptionProps) => {
  return (
    <components.Option {...props}>
      <div className="flex flex-row items-center gap-2">{props.children}</div>
    </components.Option>
  );
};

const controlStyles = {
  base: "border-green-300 border-b-2 hover:cursor-pointer",
  disabled: "cursor-not-allowed",
};
const placeholderStyles = "text-gray-400 pl-2 py-0.5";
const selectInputStyles = "py-0.5";
const valueContainerStyles = "p-1 gap-1 w-[4.5rem]";
const singleValueStyles = "leading-7 ml-1";
const dropdownIndicatorStyles =
  "p-1 hover:bg-gray-100 text-gray-500 rounded-md hover:text-black";

const menuStyles =
  "p-1 mt-2 border border-gray-200 bg-white rounded-lg max-h-[10rem] overflow-y-scroll react-select__menu";
const groupHeadingStyles = "ml-3 mt-2 mb-1 text-gray-500 text-sm";
const optionStyles = {
  base: "hover:cursor-pointer px-3 py-2 rounded",
  focus: "bg-gray-100 active:bg-gray-200",
  selected: "text-gray-400 is-selected",
  disabled: "cursor-not-allowed",
};
const noOptionsMessageStyles =
  "text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm";

export default function SimpleSelect(props) {
  const onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName("is-selected")[0];
      const dropdownMenu = document.querySelector(
        ".MyDropdown .react-select__menu"
      ) as HTMLElement;

      if (selectedEl && dropdownMenu.firstElementChild) {
        const selectedRect = selectedEl.getBoundingClientRect();
        const dropdownRect =
          dropdownMenu.firstElementChild.getBoundingClientRect();

        const offsetTop = selectedRect.top - dropdownRect.top;
        dropdownMenu.firstElementChild.scrollTop = offsetTop;
      }
    }, 0);
  };

  // if a user has partially typed a time, autofill the rest with a suggestion
  const autofillTime = (inputValue) => {
    const isValid = /^[0-9]?[0-9]:[0-5][0-9](am|pm)$/i.test(inputValue);
    if (isValid) return inputValue;

    if (inputValue.length === 0) return "Invalid time";
    if (inputValue.length === 1) {
      if (parseInt(inputValue[0])) return `${inputValue}:00am`;
      else return "Invalid time";
    }
    if (inputValue.length === 2) {
      if (inputValue[1] === ":") return `${inputValue}00am`;
      else if (parseInt(inputValue[1])) return `${inputValue}:00am`;
      else return "Invalid time";
    }
    if (inputValue.length === 3) {
      if (inputValue[2] === ":") return `${inputValue}00am`;
      else if (parseInt(inputValue[2])) return `${inputValue}0am`;
      else return "Invalid time";
    }
    if (inputValue.length === 4) {
      if (inputValue[1] === ":" && parseInt(inputValue[3]) < 10)
        return `${inputValue}am`;
      else if (inputValue[2] === ":") return `${inputValue}0am`;
      else return "Invalid time";
    }
    if (inputValue.length === 5) {
      if (inputValue[4] === "a" || inputValue[4] === "p")
        return `${inputValue}m`;
      else if (parseInt(inputValue[4])) return `${inputValue}am`;
      else return "Invalid time";
    }
    if (inputValue.length === 6) {
      if (inputValue[5] === "m") return inputValue;
      else if (inputValue[5] === "a" || inputValue[5] === "p")
        return `${inputValue}m`;
      else return "Invalid time";
    }

    return "Invalid time";
  };

  const checkValidTime = (inputValue) => {
    const isValid = /^(1[0-2]|0?[1-9]):[0-5][0-9](am|pm)$/i.test(inputValue);
    if (isValid) return true;
    if (inputValue.length === 0) return true;
    if (inputValue.length === 1) {
      return /^\d$/.test(inputValue);
    }
    if (inputValue.length === 2) {
      return (
        /^(1[0-2]|0?[1-9])$/i.test(inputValue) ||
        /^(1[0-2]|0?[1-9]):$/i.test(inputValue)
      );
    }
    if (inputValue.length === 3) {
      return (
        /^(1[0-2]|0?[1-9]):$/i.test(inputValue) ||
        /^(1[0-2]|0?[1-9]):[0-5]$/i.test(inputValue)
      );
    }
    if (inputValue.length === 4) {
      return (
        /^(1[0-2]|0?[1-9]):[0-5]$/i.test(inputValue) ||
        /^(1[0-2]|0?[1-9]):[0-5][0-9]$/i.test(inputValue)
      );
    }
    if (inputValue.length === 5) {
      if (inputValue[4] === "a" || inputValue[4] === "p") {
        return /^(1[0-2]|0?[1-9]):[0-5][0-9][ap]$/i.test(inputValue);
      } else if (inputValue[4] === " ") {
        return /^(1[0-2]|0?[1-9]):[0-5][0-9] $/i.test(inputValue);
      } else {
        return /^(1[0-2]|0?[1-9]):[0-5][0-9]$/i.test(inputValue);
      }
    }
    if (inputValue.length === 6) {
      return /^(1[0-2]|0?[1-9]):[0-5][0-9][ap]$/i.test(inputValue);
    }
    return false;
  };

  return (
    <Creatable
      isClearable={false}
      closeMenuOnSelect={!props.isMulti}
      hideSelectedOptions={false}
      unstyled
      styles={{
        input: (base) => ({
          ...base,
          "input:focus": {
            boxShadow: "none",
          },
        }),
        // On mobile, the label will truncate automatically, so we want to
        // override that behaviour.
        multiValueLabel: (base) => ({
          ...base,
          whiteSpace: "normal",
          overflow: "visible",
        }),
        control: (base) => ({
          ...base,
          transition: "none",
        }),
      }}
      menuShouldScrollIntoView={false}
      onMenuOpen={onMenuOpen}
      formatCreateLabel={autofillTime}
      allowCreateWhileLoading={true}
      isValidNewOption={(inputValue) => {
        return (
          !props.options.some((option) => option.value.includes(inputValue)) &&
          checkValidTime(inputValue)
        );
      }}
      className="MyDropdown"
      components={{ DropdownIndicator, Option }}
      classNames={{
        control: ({ isDisabled }) =>
          clsx(isDisabled && controlStyles.disabled, controlStyles.base),
        placeholder: () => placeholderStyles,
        input: () => selectInputStyles,
        valueContainer: () => valueContainerStyles,
        singleValue: () => singleValueStyles,
        dropdownIndicator: () => dropdownIndicatorStyles,
        menu: () => menuStyles,
        groupHeading: () => groupHeadingStyles,
        option: ({ isDisabled, isFocused, isSelected }) =>
          clsx(
            isDisabled && optionStyles.disabled,
            isFocused && optionStyles.focus,
            isSelected && optionStyles.selected,
            optionStyles.base
          ),
        noOptionsMessage: () => noOptionsMessageStyles,
        menuList: () => "max-h-inherit",
      }}
      {...props}
    />
  );
}
export function SimpleNumericCreatableSelect(props) {
  const [inputValue, setInputValue] = useState("");

  const onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName("is-selected")[0];
      const dropdownMenu = document.querySelector(
        ".MyDropdown .react-select__menu"
      ) as HTMLElement;

      if (selectedEl && dropdownMenu?.firstElementChild) {
        const selectedRect = selectedEl.getBoundingClientRect();
        const dropdownRect =
          dropdownMenu.firstElementChild.getBoundingClientRect();
        const offsetTop = selectedRect.top - dropdownRect.top;
        (dropdownMenu.firstElementChild as HTMLElement).scrollTop = offsetTop;
      }
    }, 0);
  };

  // A simple validation to ensure only numeric values can be created.
  const isValidNewOption = (val: string) => {
    return (
      !props.options.some(
        (option: { value: string }) => option.value === val
      ) && /^[0-9]+(\.[0-9]+)?$/.test(val)
    );
  };

  const handleCreateOption = (val: string) => {
    const newOption = { label: val, value: val };
    // Call parent's onChange if provided
    if (props.onChange) {
      props.onChange(newOption);
    }
  };

  const handleBlur = () => {
    // If the user typed a new valid numeric value but didn't press Enter or select,
    // we automatically create and select it on blur.
    if (isValidNewOption(inputValue)) {
      handleCreateOption(inputValue);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    // If user presses Enter and the inputValue is a valid new option,
    // create the option instead of selecting a closest match.
    if (event.key === "Enter" && isValidNewOption(inputValue)) {
      event.preventDefault();
      handleCreateOption(inputValue);
    }
  };

  return (
    <CreatableSelect
      isClearable={false}
      closeMenuOnSelect={!props.isMulti}
      hideSelectedOptions={false}
      unstyled
      styles={{
        input: (base) => ({
          ...base,
          "input:focus": {
            boxShadow: "none",
          },
        }),
        multiValueLabel: (base) => ({
          ...base,
          whiteSpace: "normal",
          overflow: "visible",
        }),
        control: (base) => ({
          ...base,
          transition: "none",
        }),
      }}
      menuShouldScrollIntoView={false}
      onMenuOpen={onMenuOpen}
      formatCreateLabel={(val) => `Create "${val}"`}
      allowCreateWhileLoading={true}
      isValidNewOption={isValidNewOption}
      onInputChange={(val) => setInputValue(val)}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      onCreateOption={handleCreateOption}
      className="MyDropdown"
      components={{ DropdownIndicator, Option }}
      classNames={{
        control: ({ isDisabled }) =>
          clsx(isDisabled && controlStyles.disabled, controlStyles.base),
        placeholder: () => placeholderStyles,
        input: () => selectInputStyles,
        valueContainer: () => valueContainerStyles,
        singleValue: () => singleValueStyles,
        dropdownIndicator: () => dropdownIndicatorStyles,
        menu: () => menuStyles,
        groupHeading: () => groupHeadingStyles,
        option: ({ isDisabled, isFocused, isSelected }) =>
          clsx(
            isDisabled && optionStyles.disabled,
            isFocused && optionStyles.focus,
            isSelected && optionStyles.selected,
            optionStyles.base
          ),
        noOptionsMessage: () => noOptionsMessageStyles,
        menuList: () => "max-h-inherit",
      }}
      {...props}
    />
  );
}
