/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents the initiator of a payout.
 */
export enum PayoutInitatior {
  INDIVIDUAL_ACCOUNT = "Individual Account",
  ORGANIZATION = "Organization",
}
