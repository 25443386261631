import { forwardRef, ReactNode } from "react";
import { Link } from "react-router-dom";
import { LinkProps } from "react-router-dom";
import "./index.css";

export enum ButtonColor {
  BLUE = "button--blue",
  DARK_BLUE = "button--dark-blue",
  SKYBLUE = "button--skyblue",
  LIGHT_BLUE = "button--light-blue",
  GRAY = "button--gray",
  DARK_GRAY = "button--dark-gray",
  GREEN = "button--green",
  ORANGE = "button--orange",
  PURPLE = "button--purple",
  RED = "button--red",
  BLACK = "button--black",
  WHITE = "button--white",
  LIGHT_PURPLE = "button--plum",
  DEFAULT = "",
}

export enum ButtonFill {
  HOLLOW = "button--hollow",
  FLOAT = "button--float",
  NO_BORDER = "button--no-border",
  DEFAULT = "",
}

export enum ButtonSize {
  EXTRA_SMALL = "button--xs",
  SMALL = "button--small",
  DEFAULT = "",
  LARGE = "button--large",
}

export enum ButtonShape {
  CIRCLE = "button--square button--circle",
  SQUARE = "button--square",
  CHIP = "button--chip",
  DEFAULT = "",
}

type ButtonProps = {
  size?: ButtonSize;
  color?: ButtonColor;
  fill?: ButtonFill;
  block?: boolean;
  round?: boolean;
  extraClasses?: string;
  children: ReactNode;
  [x: string]: any;
};

// Remove all custom props from a given set of props
function stripCustomProps(props, customProps: string[]): Object {
  let rest = {};
  Object.keys(props).forEach((key) => {
    if (!customProps.includes(key)) {
      rest[key] = props[key];
    }
  });
  return rest;
}

// Generate a class string from a given set of button props
function generateClassString(props): string {
  const {
    size = ButtonSize.DEFAULT,
    color = ButtonColor.DEFAULT,
    shape = ButtonShape.DEFAULT,
    fill = ButtonFill.DEFAULT,
    block = false,
    extraClasses = "",
  } = props;

  let classes: string[] = [];
  size && classes.push(size);
  shape && classes.push(shape);
  fill && classes.push(fill);
  color && classes.push(color);
  block && classes.push("button--block");
  extraClasses && classes.push(extraClasses);

  return classes.join(" ").trim();
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const { children } = props;

    const customProps = [
      "size",
      "color",
      "shape",
      "fill",
      "block",
      "extraClasses",
    ];

    const rest = stripCustomProps(props, customProps);
    const classes = generateClassString(props);

    return (
      <button ref={ref} className={`button ${classes}`} {...rest}>
        {children}
      </button>
    );
  }
);

const customLinkProps = [
  "size",
  "color",
  "shape",
  "fill",
  "block",
  "to",
  "extraClasses",
];

export const LinkButton = (props: LinkProps & ButtonProps) => {
  const { to, children } = props;

  const rest = stripCustomProps(props, customLinkProps);
  const classes = generateClassString(props);

  return (
    <Link to={to} className={`button ${classes}`} {...rest}>
      {children}
    </Link>
  );
};

export const ExternalLinkButton = (props: ButtonProps) => {
  const { href, children } = props;

  const rest = stripCustomProps(props, customLinkProps);
  const classes = generateClassString(props);

  return (
    <a href={href} className={`button ${classes}`} {...rest}>
      {children}
    </a>
  );
};
