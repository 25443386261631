import { DialogContent } from "components/Dialog";
import { AccountResponse, CalendarService } from "client/openapi";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import { useContext, useEffect, useState } from "react";
import "./index.css";
import Notifications from "util/notifications";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

export default function GoogleCalendarDialog() {
  const { account, updateOrgRolesAndAccount } = useContext(
    OrgRolesAndAccountContext
  );
  const [actuallyRemoveAccount, setActuallyRemoveAccount] =
    useState<boolean>(false);
  const [attemptingUnlink, setAttemptingUnlink] = useState<boolean>(false);

  // const [googleAccountName, setGoogleAccountName] = useState<string>(
  //   "Loading account name..."
  // );

  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");

  async function initiateGoogleCalendarAuthFlow() {
    const auth_url = await CalendarService.getGoogleAuthUrl();
    window.location.href = auth_url;
  }

  async function removeAccount() {
    setAttemptingUnlink(true);
    CalendarService.revokeGoogleCalendar()
      .then(() => {
        Notifications.success("Google calendar unlinked!");
        // Update the account state to reflect the change
        updateOrgRolesAndAccount({
          account: {
            ...(account as AccountResponse),
            google_calendar_enabled: false,
          },
        });
        setAttemptingUnlink(false);
      })
      .catch((e: any) => {
        console.error(e);
        Notifications.error("Unable to unlink google calendar at this time");
        setAttemptingUnlink(false);
      });
  }

  return (
    <DialogContent
      className="google-dialog-content"
      onClose={() => {
        setActuallyRemoveAccount(false);
      }}
    >
      {account && (
        <div>
          <h1 className="heading">Google Calendar</h1>
          {account.google_calendar_enabled || code ? (
            <div>
              <div>
                Your account is synced with a Google Calendar account.
                {/* <span style={{ fontWeight: "bolder" }}> {googleAccountName} </span> */}
              </div>
              <div className="mt-5">
                {actuallyRemoveAccount ? (
                  <Button
                    color={
                      attemptingUnlink ? ButtonColor.GRAY : ButtonColor.RED
                    }
                    size={ButtonSize.SMALL}
                    disabled={attemptingUnlink}
                    onClick={() => removeAccount()}
                  >
                    Confirm account removal
                  </Button>
                ) : (
                  <Button
                    color={ButtonColor.SKYBLUE}
                    size={ButtonSize.SMALL}
                    onClick={() => {
                      setActuallyRemoveAccount(true);
                    }}
                  >
                    Remove account?
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <div className="mt-3">
              <Button
                color={ButtonColor.PURPLE}
                size={ButtonSize.SMALL}
                onClick={initiateGoogleCalendarAuthFlow}
              >
                Sync google calendar
              </Button>
            </div>
          )}
        </div>
      )}
    </DialogContent>
  );
}
