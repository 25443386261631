import { useAuth0 } from "@auth0/auth0-react";
import { DialogClose } from "@radix-ui/react-dialog";
import {
  ApiError,
  MessageType,
  MessagesService,
  OrgMessageRecipient,
  ParentLimitedResponse,
  StudentLimitedResponse,
  MeetingTutorLimitedResponse,
  TutorLimitedResponse,
  MeetingStudentLimitedResponse,
} from "client/openapi";
import { ButtonColor } from "components/Button";
import { DialogAction, DialogActions, DialogContent } from "components/Dialog";
import { useEffect, useState } from "react";
import { Select } from "components/Select";
import { APIResponse, PageStatus } from "types";
import { concatenateName } from "util/concatenateName";
import Notifications from "util/notifications";
import "./index.css";
import SuccessDialog from "components/SuccessDialog";
import TextInput from "components/Inputs/TextInput";

type MessageDraft = {
  students:
    | (StudentLimitedResponse | MeetingStudentLimitedResponse)[]
    | undefined;
  tutors: (TutorLimitedResponse | MeetingTutorLimitedResponse)[] | undefined;
  parents: ParentLimitedResponse[] | undefined;
  subject: string;
  content: string;
};

function ComposeMessageDialog({
  students,
  tutors,
  parents,
  messageAllStudents,
  messageAllTutors,
  messageAllParents,
  org_id,
}: {
  students?: (StudentLimitedResponse | MeetingStudentLimitedResponse)[];
  tutors?: (TutorLimitedResponse | MeetingTutorLimitedResponse)[];
  parents?: ParentLimitedResponse[];
  messageAllStudents?: boolean;
  messageAllTutors?: boolean;
  messageAllParents?: boolean;
  org_id: number;
}) {
  const { user } = useAuth0();

  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const [sendSMS, setSendSMS] = useState<boolean>(true);

  const emptyDraft: MessageDraft = {
    subject: "",
    content: "",
    students: students ? students : undefined,
    tutors: tutors ? tutors : undefined,
    parents: parents ? parents : undefined,
  };

  const [newMessage, setNewMessage] = useState<MessageDraft>(emptyDraft);
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<APIResponse>();

  // Check if recipients are empty *when not* messaging entire org/tutors/parents/students
  // (i.e., for createMessageToRecipients).
  // This ensures we disable the button if we’d produce an empty array.
  const recipientsAreEmpty =
    !(messageAllStudents || messageAllTutors || messageAllParents) &&
    !(
      (newMessage.students && newMessage.students.length > 0) ||
      (newMessage.tutors && newMessage.tutors.length > 0) ||
      (newMessage.parents && newMessage.parents.length > 0)
    );

  async function createMessage() {
    if (!newMessage || !user?.sub) {
      return;
    }

    setStatus(PageStatus.LOADING);

    if (messageAllStudents && messageAllTutors && messageAllParents) {
      MessagesService.createMessageToOrg({
        requestBody: {
          m_type: MessageType.DIRECT,
          subject: newMessage.subject,
          content: newMessage.content,
          sender_id: user?.sub,
          email: sendEmail,
          sms: sendSMS,
          in_app: true,
          org_id: org_id,
        },
      })
        .then(() => {
          setStatus(PageStatus.SUCCESS);
        })
        .catch((e: ApiError) => {
          setStatus(PageStatus.ERROR);
          setError({ error: "Unable to send message." });
          console.error(`Error (#${e.status}): ${e.message}`);
        });
    } else if (messageAllStudents) {
      MessagesService.createMessageToOrgStudents({
        requestBody: {
          m_type: MessageType.DIRECT,
          subject: newMessage.subject,
          content: newMessage.content,
          sender_id: user?.sub,
          email: sendEmail,
          sms: sendSMS,
          in_app: true,
          org_id: org_id,
        },
      })
        .then(() => {
          setStatus(PageStatus.SUCCESS);
        })
        .catch((e: ApiError) => {
          setStatus(PageStatus.ERROR);
          setError({ error: "Unable to send message." });
          console.error(`Error (#${e.status}): ${e.message}`);
        });
    } else if (messageAllTutors) {
      MessagesService.createMessageToOrgTutors({
        requestBody: {
          m_type: MessageType.DIRECT,
          subject: newMessage.subject,
          content: newMessage.content,
          sender_id: user?.sub,
          email: sendEmail,
          sms: sendSMS,
          in_app: true,
          org_id: org_id,
        },
      })
        .then(() => {
          setStatus(PageStatus.SUCCESS);
        })
        .catch((e: ApiError) => {
          setStatus(PageStatus.ERROR);
          setError({ error: "Unable to send message." });
          console.error(`Error (#${e.status}): ${e.message}`);
        });
    } else if (messageAllParents) {
      MessagesService.createMessageToOrgParents({
        requestBody: {
          m_type: MessageType.DIRECT,
          subject: newMessage.subject,
          content: newMessage.content,
          sender_id: user?.sub,
          email: sendEmail,
          sms: sendSMS,
          in_app: true,
          org_id: org_id,
        },
      })
        .then(() => {
          setStatus(PageStatus.SUCCESS);
        })
        .catch((e: ApiError) => {
          setStatus(PageStatus.ERROR);
          setError({ error: "Unable to send message." });
          console.error(`Error (#${e.status}): ${e.message}`);
        });
    } else if (
      (students && students.length > 0) ||
      (tutors && tutors.length > 0) ||
      (parents && parents.length > 0)
    ) {
      let studentIds: number[] = [];
      let tutorIds: number[] = [];
      let parentIds: number[] = [];

      newMessage.students &&
        newMessage.students.forEach((s) => {
          studentIds.push("student" in s ? s.student.id : s.id);
        });

      newMessage.tutors &&
        newMessage.tutors.forEach((t) => {
          tutorIds.push("tutor" in t ? t.tutor.id : t.id);
        });

      newMessage.parents &&
        newMessage.parents.forEach((p) => {
          parentIds.push(p.id);
        });

      MessagesService.createMessageToRecipients({
        requestBody: {
          m_type: MessageType.DIRECT,
          subject: newMessage.subject,
          content: newMessage.content,
          sender_id: user?.sub,
          org_id: org_id,
          email: sendEmail,
          sms: sendSMS,
          in_app: true,
          recipient_types:
            newMessage.students &&
            newMessage.students.length > 0 &&
            newMessage.tutors &&
            newMessage.tutors.length > 0 &&
            newMessage.parents &&
            newMessage.parents.length > 0
              ? [
                  OrgMessageRecipient.STUDENTS,
                  OrgMessageRecipient.TUTORS,
                  OrgMessageRecipient.PARENTS,
                ]
              : newMessage.students &&
                newMessage.students.length > 0 &&
                newMessage.tutors &&
                newMessage.tutors.length > 0
              ? [OrgMessageRecipient.STUDENTS, OrgMessageRecipient.TUTORS]
              : newMessage.students &&
                newMessage.students.length > 0 &&
                newMessage.parents &&
                newMessage.parents.length > 0
              ? [OrgMessageRecipient.STUDENTS, OrgMessageRecipient.PARENTS]
              : newMessage.tutors &&
                newMessage.tutors.length > 0 &&
                newMessage.parents &&
                newMessage.parents.length > 0
              ? [OrgMessageRecipient.TUTORS, OrgMessageRecipient.PARENTS]
              : newMessage.students && newMessage.students.length > 0
              ? [OrgMessageRecipient.STUDENTS]
              : newMessage.tutors && newMessage.tutors.length > 0
              ? [OrgMessageRecipient.TUTORS]
              : newMessage.parents && newMessage.parents.length > 0
              ? [OrgMessageRecipient.PARENTS]
              : [],
          student_recipients: studentIds.length > 0 ? studentIds : undefined,
          tutor_recipients: tutorIds.length > 0 ? tutorIds : undefined,
          parent_recipients: parentIds.length > 0 ? parentIds : undefined,
        },
      })
        .then(() => {
          setStatus(PageStatus.SUCCESS);
        })
        .catch((e: ApiError) => {
          setStatus(PageStatus.ERROR);
          setError({ error: "Unable to send message." });
          console.error(`Error (#${e.status}): ${e.message}`);
        });
    }
  }

  function handleUpdate(
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = ev.target.value;
    setNewMessage({
      ...newMessage,
      [ev.target.name]: value,
    });
  }

  function handleUpdateStudentRecipients(options: any) {
    setNewMessage({
      ...newMessage,
      students: options,
    });
  }

  function handleUpdateTutorRecipients(options: any) {
    setNewMessage({
      ...newMessage,
      tutors: options,
    });
  }

  function handleUpdateParentRecipients(options: any) {
    setNewMessage({
      ...newMessage,
      parents: options,
    });
  }

  function handleSubmit() {
    return async (ev: React.FormEvent) => {
      ev.preventDefault();
      await createMessage();
    };
  }

  function clearForm() {
    setNewMessage(emptyDraft);
    setStatus(undefined);
    setSendEmail(false);
    setSendSMS(true);
  }

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  if (status === PageStatus.SUCCESS) {
    return <SuccessDialog message="Message Sent!" onClose={clearForm} />;
  }

  const disableSendButton =
    (!sendEmail && !sendSMS) ||
    recipientsAreEmpty ||
    status === PageStatus.LOADING;

  return (
    <>
      <DialogContent className="dialog-content">
        <div className="invite-dialog">
          {messageAllStudents && messageAllTutors && messageAllParents ? (
            <h2 className="compose-msg-dialog--title">Message Organization</h2>
          ) : (students && tutors) ||
            (students && parents) ||
            (parents && tutors) ? (
            <h2 className="compose-msg-dialog--title">Message Attendees</h2>
          ) : messageAllStudents || students ? (
            <h2 className="compose-msg-dialog--title">Message Students</h2>
          ) : messageAllTutors || tutors ? (
            <h2 className="compose-msg-dialog--title">Message Tutors</h2>
          ) : messageAllParents || parents ? (
            <h2 className="compose-msg-dialog--title">Message Parents</h2>
          ) : (
            <h2 className="compose-msg-dialog--title">Send a Message</h2>
          )}

          <form onSubmit={handleSubmit()}>
            {/* STUDENT SELECT */}
            {students &&
              !(
                messageAllStudents ||
                messageAllTutors ||
                messageAllParents
              ) && (
                <div className="mb-3">
                  <label
                    htmlFor="student-recipients"
                    className="input-label font-bold"
                  >
                    Students
                  </label>
                  <Select
                    id="student-recipients"
                    name="student-recipients"
                    options={students}
                    defaultValue={students}
                    getOptionLabel={(s) => `${concatenateName(s)}`}
                    getOptionValue={(s) => s.id.toString()}
                    onChange={handleUpdateStudentRecipients}
                    isMulti
                  />
                </div>
              )}

            {/* TUTOR SELECT */}
            {tutors &&
              !(
                messageAllStudents ||
                messageAllTutors ||
                messageAllParents
              ) && (
                <div className="mb-3">
                  <label
                    htmlFor="tutor-recipients"
                    className="input-label font-bold"
                  >
                    Tutors
                  </label>
                  <Select
                    id="tutor-recipients"
                    name="tutor-recipients"
                    options={tutors}
                    defaultValue={tutors}
                    getOptionLabel={(t) => `${concatenateName(t)}`}
                    getOptionValue={(t) => t.id.toString()}
                    onChange={handleUpdateTutorRecipients}
                    isMulti
                  />
                </div>
              )}

            {/* PARENT SELECT */}
            {parents &&
              !(
                messageAllStudents ||
                messageAllTutors ||
                messageAllParents
              ) && (
                <div className="mb-3">
                  <label
                    htmlFor="parent-recipients"
                    className="input-label font-bold"
                  >
                    Parents
                  </label>
                  <Select
                    id="parent-recipients"
                    name="parent-recipients"
                    options={parents}
                    defaultValue={parents}
                    getOptionLabel={(p) => `${concatenateName(p)}`}
                    getOptionValue={(p) => p.id.toString()}
                    onChange={handleUpdateParentRecipients}
                    isMulti
                  />
                </div>
              )}
            <label htmlFor="subject" className="input-label font-bold">
              Subject
            </label>
            <TextInput
              id="subject"
              name="subject"
              placeholder="Subject"
              value={newMessage.subject}
              setValue={handleUpdate}
              disabled={status === PageStatus.LOADING}
              required
              classes="rounded-lg"
            />

            <label htmlFor="content" className="mt-3 input-label font-bold">
              Content
            </label>
            <textarea
              id="content"
              name="content"
              className="input bg-gray-100 border-0 mt-0 rounded-lg"
              placeholder="Your message here..."
              rows={5}
              value={newMessage.content}
              onChange={handleUpdate}
              disabled={status === PageStatus.LOADING}
              required
            />

            <div
              style={{
                display: "flex",
                gap: "1rem",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            >
              <label style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="checkbox"
                  checked={sendSMS}
                  onChange={(e) => setSendSMS(e.target.checked)}
                  style={{
                    appearance: "none",
                    width: "18px",
                    height: "18px",
                    border: "2px solid #4ade80",
                    borderRadius: "4px",
                    backgroundColor: sendSMS ? "#4ade80" : "#fff",
                    cursor: "pointer",
                  }}
                />
                <span style={{ marginLeft: 8 }}>SMS</span>
              </label>

              <label style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="checkbox"
                  checked={sendEmail}
                  onChange={(e) => setSendEmail(e.target.checked)}
                  style={{
                    appearance: "none",
                    width: "18px",
                    height: "18px",
                    border: "2px solid #4ade80",
                    borderRadius: "4px",
                    backgroundColor: sendEmail ? "#4ade80" : "#fff",
                    cursor: "pointer",
                  }}
                />
                <span style={{ marginLeft: 8 }}>Email</span>
              </label>
            </div>

            <DialogActions>
              <DialogAction
                primary={true}
                color={disableSendButton ? ButtonColor.GRAY : ButtonColor.GREEN}
                type="submit"
                disabled={disableSendButton}
              >
                {status === PageStatus.LOADING ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  <>Send Message</>
                )}
              </DialogAction>

              <DialogClose asChild>
                <DialogAction
                  color={ButtonColor.BLACK}
                  disabled={status === PageStatus.LOADING}
                >
                  Discard
                </DialogAction>
              </DialogClose>
            </DialogActions>
          </form>
        </div>
      </DialogContent>
    </>
  );
}

export default ComposeMessageDialog;
