import {
  OrganizationSubjectPublicResponse,
  MeetingTutorLimitedResponse,
  TutorTestScoreResponse,
} from "client/openapi";
import { Helmet } from "react-helmet";
import { ProfileBody } from "./ProfileBody";
import { ProfileHeader } from "./ProfileHeader";

export function Profile({
  pageTitle,
  tags,
  greeting,
  bio,
  initials,
  waveType,
  id,

  subjects,
  testScores,
  tutors,
}: {
  pageTitle: string;
  // profile header props
  tags?: any[];
  greeting: string;
  bio: string;
  initials: string;
  waveType: number;
  // profile body props
  id?: number;
  subjects: OrganizationSubjectPublicResponse[];
  testScores?: TutorTestScoreResponse[];
  tutors?: MeetingTutorLimitedResponse[];
}) {
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <ProfileHeader
        tags={tags}
        greeting={greeting}
        bio={bio}
        initials={initials}
        waveType={waveType}
      />
      <ProfileBody
        id={id}
        subjects={subjects}
        testScores={testScores}
        tutors={tutors}
      />
    </>
  );
}
