import { SessionFrequency } from "client/openapi";

export function parseFrequency(sessionFrequency: SessionFrequency): string {
  if (sessionFrequency === SessionFrequency.SINGLE) {
    return "does not repeat";
  }

  if (sessionFrequency === SessionFrequency.EVERY_7_DAYS) {
    return "every 7 days";
  }

  if (sessionFrequency === SessionFrequency.EVERY_14_DAYS) {
    return "every 14 days";
  }

  if (sessionFrequency === SessionFrequency.EVERY_21_DAYS) {
    return "every 21 days";
  }

  if (sessionFrequency === SessionFrequency.EVERY_28_DAYS) {
    return "every 28 days";
  }

  return "every unknown number of days";
}
