import { useAuth0 } from "@auth0/auth0-react";
import {
  Auth0AccountRole,
  OrganizationNameResponse,
  OrganizationsService,
} from "client/openapi";
import AuthenticationButton from "components/AuthenticationButton/AuthenticationButton";
import { ButtonColor, ButtonFill, LinkButton } from "components/Button";
import logoGreen from "images/logo-green.png";
import { Fragment, useContext, useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "./index.css";
import logoWhite from "images/logoWhite.png";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

type NavbarLink = {
  name: string;
  link: string;
  roles: string[];
};

export default function DefaultNavbar() {
  const { isAuthenticated, user } = useAuth0();

  const { currently_selected_role, currently_selected_organization } =
    useContext(OrgRolesAndAccountContext);
  const location = useLocation();

  const [org, setOrg] = useState<OrganizationNameResponse>();
  const [navbarOpen, setNavbarOpen] = useState<boolean>(false);

  const isLandingPage = location.pathname === "/";

  async function getOrg() {
    if (!isAuthenticated || !user || !currently_selected_organization) {
      setOrg(undefined);
    } else if (!org || org?.id !== currently_selected_organization) {
      await OrganizationsService.getOrganization({
        orgId: currently_selected_organization,
      }).then((o) => setOrg(o));
    }
  }

  const hashLinks: NavbarLink[] = [
    {
      name: "About",
      link: "#about",
      roles: [],
    },
    {
      name: "Features",
      link: "/#features",
      roles: [],
    },
    {
      name: "Contact Us",
      link: "/#contact",
      roles: [],
    },
  ];

  const primaryLinks: NavbarLink[] = [
    {
      name: "Dashboard",
      link: "/dashboard",
      roles: [
        Auth0AccountRole.ME,
        Auth0AccountRole.PARENT,
        Auth0AccountRole.ORG_TUTOR,
        Auth0AccountRole.ORG_ADMIN,
      ],
    },

    {
      name: "Performance",
      link: "/performance",
      roles: [Auth0AccountRole.ORG_ADMIN],
    },
    //  *******
    //  Commenting out to to temporarily remove fom webpage
    //  uncomment when ready to be developed
    //  *******
    // {
    //   name: "Edit Webpage",
    //   link: "/tutors/profile",
    //   roles: [Auth0AccountRole.ORG_TUTOR],
    // },
  ];

  useEffect(() => {
    getOrg();
  }, [isAuthenticated, location]);

  useEffect(() => {
    setNavbarOpen(false);
  }, [location]);

  return (
    <nav className={isLandingPage ? "nav__landing" : "nav"}>
      <div className="container-fluid px-4">
        <div className="nav__body">
          <Link to={isAuthenticated ? "/dashboard" : "/"} className="nav__logo">
            {isLandingPage ? (
              <img src={logoWhite} alt="Tadpole" />
            ) : (
              <img src={logoGreen} alt="Tadpole" />
            )}
            <span
              className={`nav--org-name ${
                isLandingPage ? " nav--org-name-landing" : ""
              }`}
            >
              {org
                ? org.name
                : "Trusted by Schools, Businesses, and Nonprofits."}
            </span>
          </Link>

          <div
            className={`nav__burger ${navbarOpen && "active"}`}
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <span></span>
          </div>

          <div className={`nav__menu ${navbarOpen && "active"}`}>
            <ul className="nav__list">
              {currently_selected_role === null
                ? hashLinks.map((l, i) => (
                    <li key={i}>
                      <HashLink
                        smooth
                        to={l.link}
                        className={`nav__link ${
                          isLandingPage ? "nav__link_landing" : ""
                        }`}
                      >
                        {l.name}
                      </HashLink>
                    </li>
                  ))
                : null}

              {primaryLinks.map((l, i) => (
                <Fragment key={i}>
                  {currently_selected_role &&
                    l.roles.includes(currently_selected_role) && (
                      <li>
                        <NavLink
                          to={l.link}
                          className={({ isActive }) =>
                            `nav__link ${isActive ? "active" : ""}`
                          }
                        >
                          {l.name}
                        </NavLink>
                      </li>
                    )}
                </Fragment>
              ))}

              {!isLandingPage ? (
                <>
                  <li>
                    {!isAuthenticated ? (
                      <LinkButton
                        to="/signup"
                        color={ButtonColor.GREEN}
                        fill={ButtonFill.HOLLOW}
                        extraClasses="nav__button"
                      >
                        Sign Up
                      </LinkButton>
                    ) : null}
                  </li>

                  <li>
                    <AuthenticationButton isLandingPage={isLandingPage} />
                  </li>
                </>
              ) : (
                // landing page authentication and sign up buttons
                <>
                  <li
                    style={
                      navbarOpen && "active"
                        ? {
                            marginLeft: "-5px",
                          }
                        : {
                            // marginTop: "30px",
                          }
                    }
                  >
                    <AuthenticationButton isLandingPage={isLandingPage} />
                  </li>

                  <li
                    style={
                      navbarOpen && "active"
                        ? {
                            marginLeft: "-5px",
                          }
                        : {
                            // marginTop: "30px",
                          }
                    }
                  >
                    {!isAuthenticated ? (
                      <LinkButton
                        to="/signup"
                        color={ButtonColor.WHITE}
                        fill={ButtonFill.DEFAULT}
                        extraClasses="nav__button"
                        style={{
                          paddingLeft: isLandingPage ? "5px" : "0",
                          paddingRight: isLandingPage ? "5px" : "0",
                        }}
                      >
                        Sign Up
                      </LinkButton>
                    ) : null}
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}
