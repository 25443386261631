import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import {
  DialogAction,
  DialogActions,
  DialogClose,
  DialogContent,
} from "components/Dialog";
import { useState } from "react";
import RadioInput from "components/Inputs/RadioInput";
import { CHANGE_OPTIONS } from "../Popups/changeOptions";
import {
  ApiError,
  MeetingAdminResponse,
  MeetingLimitedResponse,
  MeetingsService,
  SessionFrequency,
} from "client/openapi";
import { PageStatus } from "types";
import Notifications from "util/notifications";
import SuccessDialog from "components/SuccessDialog";

export default function CancelDialog({
  event,
  continueFunction,
}: {
  event: MeetingAdminResponse | MeetingLimitedResponse;
  continueFunction: () => void;
}) {
  const [changeType, setChangeType] = useState<CHANGE_OPTIONS>(
    CHANGE_OPTIONS.JUST_THIS_SESSION
  );

  const [status, setStatus] = useState<PageStatus>();

  const handleUpdateApplyChanges = (ev) => {
    setChangeType(ev.target.value);
  };

  async function cancelMeeting() {
    setStatus(PageStatus.LOADING);

    MeetingsService.editMeeting({
      meetingId: event.id,
      requestBody: {
        cancel_meeting: true,
        apply_to_all_future_meetings:
          changeType === CHANGE_OPTIONS.ALL_FUTURE_OCCURRENCES ? true : false,
      },
    })
      .then(() => {
        setStatus(PageStatus.SUCCESS);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        Notifications.error("Error: " + e.message + ". Please try again.");
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  if (status === PageStatus.SUCCESS) {
    return (
      <SuccessDialog message="Meeting cancelled!" onClose={continueFunction} />
    );
  }

  return (
    <>
      <DialogContent
        className="dialog-content dialog-content--left wide h-fit max-h-[85vh] max-w-[56vh] bg-neutral-50 font-montserrat pb-4 overflow-hidden"
        alignLeft={true}
        showClose={false}
      >
        <div className="meeting-dialog-new--title pt-4">
          <span>Cancel Meeting?</span>
        </div>

        <div>
          {event ? (
            <div>
              <div className="font-bold mb-2">Delete:</div>
              <div className="flex flex-row items-center gap-2 mb-2">
                <RadioInput
                  id={CHANGE_OPTIONS.JUST_THIS_SESSION}
                  name={CHANGE_OPTIONS.JUST_THIS_SESSION}
                  value={CHANGE_OPTIONS.JUST_THIS_SESSION}
                  label="Just this session"
                  setValue={handleUpdateApplyChanges}
                  checked={changeType === CHANGE_OPTIONS.JUST_THIS_SESSION}
                  required
                />
              </div>

              {event.session.frequency !== SessionFrequency.SINGLE && (
                <div className="flex flex-row items-center gap-2 mb-2">
                  <RadioInput
                    id={CHANGE_OPTIONS.ALL_FUTURE_OCCURRENCES}
                    name={CHANGE_OPTIONS.ALL_FUTURE_OCCURRENCES}
                    value={CHANGE_OPTIONS.ALL_FUTURE_OCCURRENCES}
                    label="All future occurrences of this session"
                    setValue={handleUpdateApplyChanges}
                    checked={
                      changeType === CHANGE_OPTIONS.ALL_FUTURE_OCCURRENCES
                    }
                    required
                  />
                </div>
              )}

              {/* {changeType === CHANGE_OPTIONS.FUTURE_OCCURRENCES_AFTER && (
                <div className="text-center my-2">
                  <DateInput
                    id="future-occurrences-after"
                    name="future-occurrences-after"
                    value={date}
                    placeholder="Date"
                    setValue={handleUpdateFutureOccurrencesAfter}
                    required
                  />
                </div>
              )} */}

              {/* {allSessions.length > 1 && (
                <div className="flex flex-row gap-2 mb-2">
                  <RadioInput
                    id={CHANGE_OPTIONS.ALL_SESSIONS_IN_SEQUENCE}
                    name={CHANGE_OPTIONS.ALL_SESSIONS_IN_SEQUENCE}
                    value={CHANGE_OPTIONS.ALL_SESSIONS_IN_SEQUENCE}
                    label="All future occurrences of this session and future occurrences of other sessions in this sequence:"
                    setValue={handleUpdateApplyChanges}
                    checked={
                      changeType === CHANGE_OPTIONS.ALL_SESSIONS_IN_SEQUENCE
                    }
                    required
                    classes={"mt-1"}
                  />
                </div>
              )} */}

              {/* {changeType === CHANGE_OPTIONS.ALL_SESSIONS_IN_SEQUENCE &&
                allSessions
                  ?.filter((s) => s.id !== event.session_id)
                  .sort((a, b) => moment(a.start).day() - moment(b.start).day())
                  .map((session, index) => {
                    return (
                      <div
                        key={index}
                        className="m-2 cursor-pointer"
                        onClick={() => {
                          selectSession(session);
                        }}
                      >
                        <Tag
                          item={WEEKDAYS[moment(session.start).day()]}
                          color={
                            selectedSessions.includes(session)
                              ? TagColor.GREEN
                              : TagColor.GRAY
                          }
                          size={TagSize.SQUARE}
                        />
                        <span className="text-xs ml-2">
                          Starts{" "}
                          {moment
                            .utc(session.start)
                            .tz(moment.tz.guess())
                            .format("MMM Do @ h:mma")}
                          , Ends{" "}
                          {moment
                            .utc(session.until)
                            .tz(moment.tz.guess())
                            .format("MMM Do")}
                        </span>
                      </div>
                    );
                  })} */}
            </div>
          ) : (
            <div>
              Are you sure you want to cancel this meeting? This cannot be
              undone.
            </div>
          )}
        </div>
        <div>
          <DialogActions>
            <DialogAction
              size={ButtonSize.SMALL}
              color={ButtonColor.GREEN}
              fill={ButtonFill.DEFAULT}
              onClick={cancelMeeting}
            >
              {status === PageStatus.LOADING ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <>Confirm</>
              )}
            </DialogAction>
            <DialogClose asChild>
              <Button
                size={ButtonSize.SMALL}
                color={ButtonColor.BLACK}
                fill={ButtonFill.HOLLOW}
              >
                Back
              </Button>
            </DialogClose>
          </DialogActions>
        </div>
      </DialogContent>
    </>
  );
}
