/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountAvailabilityResponse } from "../models/AccountAvailabilityResponse";
import type { AccountAvailabilityUpdate } from "../models/AccountAvailabilityUpdate";
import type { AvailabilityMessage } from "../models/AvailabilityMessage";
import type { AvailableTutorsMessage } from "../models/AvailableTutorsMessage";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class AvailabilitiesService {
  /**
   * Get Availabilities By Account
   * Get the base "hours" an account has selected they are available for tutoring.
   * @returns AccountAvailabilityResponse Successful Response
   * @throws ApiError
   */
  public static getAvailabilitiesByAccount(): CancelablePromise<AccountAvailabilityResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/availabilities",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Account Availabilities
   * Update an account's daily availabilities by their id using specified attributes
   * within AccountAvailabilityUpdate to update the database and return the account's updated
   * daily availabilities.
   * @returns AccountAvailabilityResponse Successful Response
   * @throws ApiError
   */
  public static updateAccountAvailabilities({
    requestBody,
  }: {
    requestBody: AccountAvailabilityUpdate;
  }): CancelablePromise<AccountAvailabilityResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/availabilities",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Availabilities By Tutor
   * Between `start` and `until`, get the times a tutor's account has indicated they are
   * available for tutoring, excluding existing meetings that tutor's account has (across
   * all students and tutors linked to the account) and google calendar events.
   * @returns AvailabilityMessage Successful Response
   * @throws ApiError
   */
  public static getAvailabilitiesByTutor({
    tutorId,
    until,
    start,
    duration = 60,
  }: {
    tutorId: number;
    until: string;
    start: string;
    duration?: number;
  }): CancelablePromise<Array<AvailabilityMessage>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/availabilities/tutor",
      query: {
        tutor_id: tutorId,
        until: until,
        start: start,
        duration: duration,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Tutors By Availability
   * For all tutors in an organization, categorize them based on their availability:
   * - exact_availability: Tutors available at `exact_start` for `duration`.
   * - general_availability: Tutors available at some time between `start` and `until`
   * for `duration`, but not at `exact_start`.
   *
   * All availabilities are filtered to exclude events/meetings.
   * @returns AvailableTutorsMessage Successful Response
   * @throws ApiError
   */
  public static getTutorsByAvailability({
    orgId,
    start,
    until,
    exactStart,
    duration,
  }: {
    orgId: number;
    start: string;
    until: string;
    exactStart: string;
    duration: number;
  }): CancelablePromise<AvailableTutorsMessage> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/availabilities/tutors_by_availability",
      query: {
        org_id: orgId,
        start: start,
        until: until,
        exact_start: exactStart,
        duration: duration,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
