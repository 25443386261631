import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LoadingBlock, ErrorBlock } from "components/StatusBlock";
import { PageStatus } from "types";

import {
  ApiError,
  OrganizationPublicResponse,
  OrganizationsService,
  OrganizationWebPageResponse,
  WebPageService,
} from "client/openapi";
import { Helmet } from "react-helmet";
import { OrganizationPageContent } from "components/Profile/PageContent/OrganizationPageContent";

function OrganizationWebPage() {
  // obtain search parameters
  const params = useParams();
  const organizationId = params.id;

  const [organization, setOrganization] =
    useState<OrganizationPublicResponse>();

  const [orgWebPage, setOrgWebPage] = useState<OrganizationWebPageResponse>();

  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING);
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (!organizationId) {
      return;
    }

    const orgId = Number.parseInt(organizationId);

    OrganizationsService.getOrganizationForWebPage({ orgId })
      .then((org) => {
        setOrganization(org);
        setStatus(PageStatus.SUCCESS);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError("Unable to fetch organization details.");
        console.error(`Error (#${e.status}): ${e.message}`);
      });

    WebPageService.getOrganizationWebPage({ orgId })
      .then((orgWebPage) => {
        setOrgWebPage(orgWebPage);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError("Unable to fetch organization details.");
      });
  }, [organizationId]);

  useEffect(() => {
    if (status === PageStatus.ERROR) {
      setStatus(PageStatus.ERROR);
      setError("Unable to fetch organization details.");
    } else {
      setStatus(status);
    }
  }, [organization]);

  return (
    <div>
      <LoadingBlock status={status} />

      <ErrorBlock status={status} message={error} />

      {status === PageStatus.SUCCESS && organization && orgWebPage && (
        <>
          <Helmet>
            <title>{organization.name}</title>
          </Helmet>

          <OrganizationPageContent
            orgWebPage={orgWebPage}
            organization={organization}
          />
        </>
      )}
    </div>
  );
}

export default OrganizationWebPage;
