import { useAuth0 } from "@auth0/auth0-react";
import { Button, ButtonColor, ButtonFill } from "components/Button";
import { useNavigate } from "react-router-dom";

const LoginButton = ({ isLandingPage }: { isLandingPage: boolean }) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  return (
    <Button
      color={isLandingPage ? ButtonColor.BLACK : ButtonColor.GREEN}
      fill={isLandingPage ? ButtonFill.FLOAT : ButtonFill.DEFAULT}
      extraClasses={"nav__button"}
      style={{
        paddingLeft: "5px",
        paddingRight: "5px",
      }}
      onClick={() => {
        if (isAuthenticated) {
          navigate("/dashboard");
        } else {
          loginWithRedirect({
            authorizationParams: {
              redirect_uri: `${process.env.REACT_APP_FRONTEND_DOMAIN}/dashboard`,
            },
          });
        }
      }}
    >
      Log In
    </Button>
  );
};

export default LoginButton;
