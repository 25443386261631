/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represent the types of organization transactions.
 */
export enum TransactionType {
  PAYOUT_TO_BANK = "payout_to_bank",
  STUDENT_TO_ORGANIZATION = "student_to_organization",
  ORGANIZATION_TO_TADPOLE = "organization_to_tadpole",
  TADPOLE_TO_TUTOR = "tadpole_to_tutor",
}
