import { useAuth0 } from "@auth0/auth0-react";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { OrgRolesAndAccountProvider } from "util/OrgRolesAccountContext";
import { OpenAPI } from "./client/openapi";
import { getAccountMetadata } from "util/accounts";
import jwtDecode from "jwt-decode";
import isEqual from "lodash/isEqual";
import { useEffect, useState } from "react";
import Notifications from "util/notifications";
import AppRoutes from "AppRoutes";

function App() {
  // Auth0
  const { getAccessTokenSilently, isLoading, isAuthenticated } = useAuth0();
  const [AppCanNowRender, setAppCanNowRender] = useState<boolean>(false);

  useEffect(() => {
    const setToken = async () => {
      if (isLoading) return;

      // The AppCanNowRender state is only set to true when the user is either
      // authenticated with a valid token or is on the landing/signup page, which doesn't
      // require authentication. For all other pages, a valid auth token is necessary
      // for the app to function correctly because API endpoints require it.
      // If the user is not authenticated, we set AppCanNowRender to true to allow
      // the landing page to render. If the user tries to access any protected pages
      // while not authenticated, they will be redirected to log in. Additionally,
      // any API calls made without authentication or with an invalid token will
      // receive a 403 Forbidden response, ensuring the application remains secure.
      if (!isAuthenticated) {
        setAppCanNowRender(true);
        return;
      }

      // If the user is authenticated and therefore intends to access protected pages, the
      // AppCanNowRender state is only set to true after we've detemined the user's auth
      // token is up to date and reflects the latest information about the user's access
      // permissions on the site.
      // For example, if the user accepts an invite to an organization or creates an organization
      // in the account sign up flow, this would result in a permissions change that may not be reflected
      // in their current auth token unless a refresh is requested.
      // To determine whether a refresh is needed, we get the actualPermissionsOfUser directly from Auth0
      // using getAccountMetadata, check that against the record of permissions in their current token,
      // and get a new token if there is a mismatch! This happens automatically on page reload, and ensures
      // endpoints never errantly return a 403 forbidden because the user has an outdated token with
      // outdated permissions!
      try {
        // Get the current access token
        const currentToken = await getAccessTokenSilently();
        const decodedToken = jwtDecode<any>(currentToken);
        const permissionsInCurrentToken =
          decodedToken["https://tadpoletutoring.org/app_metadata"]
            .organizations;

        const actualPermissionsOfUser = await getAccountMetadata(currentToken);

        // Compare permissions
        if (isEqual(permissionsInCurrentToken, actualPermissionsOfUser)) {
          OpenAPI.TOKEN = currentToken;
          setAppCanNowRender(true);
        } else {
          // Get a fresh token if permissions differ
          const newAccessToken = await getAccessTokenSilently({
            cacheMode: "off",
          });
          OpenAPI.TOKEN = newAccessToken;
          setAppCanNowRender(true);
        }
      } catch (error) {
        console.error("Error handling token permissions:", error);
        Notifications.error(
          "Try reloading or logging back out and logging back in again to continue using the site!"
        );
      }
    };

    setToken();
  }, [getAccessTokenSilently, isAuthenticated, isLoading]);

  OpenAPI.BASE = process.env.REACT_APP_BACKEND_API_DOMAIN || "";

  if (!AppCanNowRender) {
    return <></>;
  }
  return (
    <>
      <div className="wrapper">
        <OrgRolesAndAccountProvider>
          <AppRoutes />
          <NotificationContainer />
        </OrgRolesAndAccountProvider>
      </div>
    </>
  );
}

export default App;
