import {
  DialogAction,
  DialogActions,
  DialogClose,
  DialogContent,
} from "components/Dialog";
import { useCallback, useEffect, useState } from "react";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import {
  ApiError,
  OrgSubjectsService,
  OrganizationSubjectAdminResponse,
  StudentLimitedResponse,
  StudentSubjectsService,
} from "client/openapi";
import { APIResponse, PageStatus } from "types";
import Notifications from "util/notifications";
import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";
import "./index.css";

export default function CustomRatesModal({
  orgId,
  student,
}: {
  orgId: number;
  student: StudentLimitedResponse;
}) {
  const [newPrice, setNewPrice] = useState<number>();
  const [editId, setEditId] = useState<number>();
  const [subjects, setSubjects] = useState<OrganizationSubjectAdminResponse[]>(
    []
  );
  const [currentStudentPrices, setCurrentStudentPrices] = useState({});
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<APIResponse>();
  const [invalidPrice, setInvalidPrice] = useState<boolean>(false);

  async function editStudentRate() {
    if (newPrice === null || newPrice === undefined || isNaN(newPrice)) {
      return setError({ message: "Student price is a required field." });
    }

    setStatus(PageStatus.LOADING);

    await StudentSubjectsService.setCustomSubjectBillRateForStudent({
      studentId: student.id,
      orgSubjectId: editId as number,
      customBillRate: newPrice as number,
    })
      .then((editedSubject) => {
        const updatedStudentPrices = {
          ...currentStudentPrices,
          [editId as number]: editedSubject.custom_bill_rate,
        };

        setCurrentStudentPrices(updatedStudentPrices);
        setNewPrice(undefined);
        setEditId(undefined);
        setStatus(PageStatus.SUCCESS);
        Notifications.success(
          `Subject edited! Reload the page for your changes to take effect!`
        );
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ error: "Unable to edit subject." });
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  const verifyPricingEdit: CurrencyInputProps["onValueChange"] = (
    value,
    name
  ) => {
    // value is over limit
    if (Number(value) > 5000) {
      setInvalidPrice(true);
      setError({
        message: "Please enter a value less than or equal to $5000.00",
      });
      return;
    }

    // value is under limit
    if (Number(value) < 0) {
      setInvalidPrice(true);
      setError({
        message: "Please enter a value greater than or equal to $0.00",
      });
      return;
    }

    setInvalidPrice(false);

    setNewPrice(Number(value) * 100);
  };

  const getExistingPricesForStudent = useCallback(async () => {
    setStatus(PageStatus.LOADING);
    StudentSubjectsService.getStudentSubjectsByStudentId({
      studentId: student.id,
    })
      .then((studentSubjects) => {
        const prices = {};
        studentSubjects.forEach((subject) => {
          prices[subject.org_subject.id] = subject.custom_bill_rate;
        });
        setCurrentStudentPrices(prices);
        setStatus(PageStatus.SUCCESS);
      })
      .catch((error) => {
        setError(error);
        setStatus(PageStatus.ERROR);
        console.error(`Error (#${error.status}): ${error.message}`);
      });
  }, [student.id]);

  const getAndSetSubjects = useCallback(async () => {
    setStatus(PageStatus.LOADING);
    OrgSubjectsService.getSubjectsByOrg({
      orgId: orgId,
    })
      .then((subjects) => {
        setSubjects(subjects as OrganizationSubjectAdminResponse[]);
        setStatus(PageStatus.SUCCESS);
      })
      .catch((error) => {
        setError(error);
        setStatus(PageStatus.ERROR);
        console.error(`Error (#${error.status}): ${error.message}`);
      });
  }, [orgId]);

  function handleClose() {
    setNewPrice(undefined);
    setEditId(undefined);
    setStatus(undefined);
  }

  useEffect(() => {
    getAndSetSubjects();
    getExistingPricesForStudent();
  }, [getAndSetSubjects, getExistingPricesForStudent]);

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  return (
    <DialogContent className="custom-student-price-dialog">
      <div className="mt-3 font-semibold text-center header text-lg">
        Set Custom Pricing
      </div>

      {subjects.length > 0 && (
        <table className="table mt-3">
          <thead className="text-left text-xs text-gray-500 =">
            <tr>
              <th className="w-1/6">Subject/Activity</th>
              <th className="w-1/6">Students Pay (per hour)</th>
              <th className="w-1/6">{student.first_name} Pays (per hour)</th>
              <th className="w-1/12">Actions</th>
            </tr>
          </thead>
          <tbody>
            {subjects
              .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
              .map((s) => (
                <tr
                  key={s.id}
                  className="student-subject-item odd:bg-gray-50 even:bg-white"
                >
                  <td>
                    <div title={s.name} className="student-subject-title">
                      {s.name}
                    </div>
                  </td>
                  <td>
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format((s.default_price_for_students as number) / 100.0)}
                  </td>
                  <td>
                    {editId === s.id ? (
                      <CurrencyInput
                        className="input w-1/2 h-1/6"
                        id="custom_student_rate"
                        name="custom_student_rate"
                        placeholder={`$${
                          currentStudentPrices[s.id]
                            ? (currentStudentPrices[s.id] / 100).toFixed(2)
                            : (
                                (s.default_price_for_students as number) / 100
                              ).toFixed(2)
                        }`}
                        defaultValue={
                          currentStudentPrices[s.id]
                            ? (currentStudentPrices[s.id] / 100).toFixed(2)
                            : (
                                (s.default_price_for_students as number) / 100
                              ).toFixed(2)
                        }
                        prefix="$"
                        decimalsLimit={2}
                        allowNegativeValue={false}
                        onValueChange={verifyPricingEdit}
                      />
                    ) : (
                      new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(
                        currentStudentPrices[s.id]
                          ? currentStudentPrices[s.id] / 100
                          : (s.default_price_for_students as number) / 100
                      )
                    )}
                  </td>
                  <td>
                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "90%",
                      }}
                    >
                      {editId === s.id ? (
                        <Button
                          size={ButtonSize.EXTRA_SMALL}
                          color={
                            invalidPrice ? ButtonColor.GRAY : ButtonColor.GREEN
                          }
                          disabled={
                            status === PageStatus.LOADING || invalidPrice
                          }
                          onClick={editStudentRate}
                        >
                          {status === PageStatus.LOADING ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <>Save</>
                          )}
                        </Button>
                      ) : (
                        <Button
                          size={ButtonSize.EXTRA_SMALL}
                          color={ButtonColor.PURPLE}
                          onClick={() => {
                            setEditId(s.id);
                            setNewPrice(
                              Number(
                                currentStudentPrices[s.id]
                                  ? (currentStudentPrices[s.id] / 100).toFixed(
                                      2
                                    )
                                  : (
                                      (s.default_price_for_students as number) /
                                      100
                                    ).toFixed(2)
                              )
                            );
                          }}
                        >
                          Edit
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}

      <DialogActions>
        <DialogClose asChild>
          <DialogAction
            color={ButtonColor.PURPLE}
            onClick={() => handleClose()}
          >
            Close
          </DialogAction>
        </DialogClose>
      </DialogActions>
    </DialogContent>
  );
}
