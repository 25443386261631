import { Pencil1Icon } from "@radix-ui/react-icons";
import { useState } from "react";
import { Button, ButtonColor } from "components/Button";
import { TutorsService, TutorAdminResponse } from "client/openapi";
import "./index.css";
import { APIResponse, PageStatus } from "types";
import { concatenateName } from "util/concatenateName";
import { generateInitials } from "util/generateInitials";
import Notifications from "util/notifications";
import PhoneInput from "react-phone-input-2";
import { DatePicker } from "rsuite";
import moment from "moment";

export default function TutorInfo({
  tutor,
  setTutor,
  setStatus,
  setError,
}: {
  tutor: TutorAdminResponse;
  setTutor: (tutor: TutorAdminResponse) => void;
  setStatus: (status: PageStatus) => void;
  setError: (error: APIResponse) => void;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [firstNameInput, setFirstNameInput] = useState(tutor.first_name || "");
  const [lastNameInput, setLastNameInput] = useState(tutor.last_name || "");
  const [phoneInput, setPhoneInput] = useState(tutor.phone_number || "");
  const [birthdayInput, setBirthdayInput] = useState(tutor.birth_date || "");
  const [addressInput, setAddressInput] = useState(tutor.address_line_1 || "");

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter" && isEditing) {
      updateTutorInfo();
    }
  };

  const handleCancel = () => {
    setFirstNameInput(tutor.first_name || "");
    setLastNameInput(tutor.last_name || "");
    setPhoneInput(tutor.phone_number || "");
    setBirthdayInput(tutor.birth_date || "");
    setAddressInput(tutor.address_line_1 || "");
    setIsEditing(false);
  };

  async function updateTutorInfo() {
    const dataChanged =
      addressInput !== tutor.address_line_1 ||
      birthdayInput !== tutor.birth_date ||
      phoneInput !== tutor.phone_number ||
      firstNameInput !== tutor.first_name ||
      lastNameInput !== tutor.last_name;

    if (
      firstNameInput === undefined ||
      firstNameInput === null ||
      firstNameInput === ""
    ) {
      Notifications.error("Please enter a complete first and last name.");
      return;
    }

    if (
      lastNameInput === undefined ||
      lastNameInput === null ||
      lastNameInput === ""
    ) {
      Notifications.error("Please enter a complete first and last name.");
      return;
    }

    if (dataChanged) {
      let localTutor = { ...tutor };

      try {
        const updatedTutor = await TutorsService.updateTutor({
          tutorId: tutor.id,
          requestBody: {
            birth_date: birthdayInput ? birthdayInput : null,
            address_line_1: addressInput ? addressInput : null,
            phone_number: phoneInput && phoneInput !== "1" ? phoneInput : null,
            first_name: firstNameInput,
            last_name: lastNameInput,
          },
        });

        localTutor = { ...localTutor, ...updatedTutor };

        setTutor(localTutor);
      } catch (e: any) {
        setStatus(PageStatus.ERROR);
        setError({ message: "Unable to update tutor info" });
        console.error(`Error (#${e.status}): ${e.message}`);
      }
    }

    setIsEditing(false);
  }

  const handlePhoneChange = (newPhone: string) => {
    setPhoneInput(newPhone);
  };

  console.log({ tutor }, [tutor]);

  return (
    <div className="w-3/5 md:w-full h-full flex flex-col justify-start items-center">
      <div
        className="w-11/12 aspect-square rounded-2xl text-white flex justify-center items-center text-center font-bold text-8xl"
        style={{ backgroundColor: "var(--skyblue)" }}
      >
        {generateInitials(concatenateName(tutor), 2)}
      </div>
      <div className="w-11/12 flex flex-col mt-10 border-t-2 relative gap-y-2.5">
        {!isEditing && (
          <Pencil1Icon
            className="absolute mt-5 right-0 w-5 h-5 cursor-pointer"
            onClick={() => setIsEditing(true)}
          />
        )}

        <table>
          <tr>
            <td className="pt-3">First Name:</td>
            <td className="pt-3">
              {isEditing ? (
                <input
                  id="first-name"
                  value={firstNameInput}
                  className="w-7/12 border border-black rounded-md pl-1 h-6"
                  onChange={(e) => setFirstNameInput(e.target.value)}
                  onKeyDown={handleEnterKeyPress}
                />
              ) : (
                <div className="w-12/12">{firstNameInput}</div>
              )}
            </td>
          </tr>
          <tr>
            <td className="pt-3">Last Name:</td>
            <td className="pt-3">
              {isEditing ? (
                <input
                  id="last-name"
                  value={lastNameInput}
                  className="w-7/12 border border-black rounded-md pl-1 h-6"
                  onChange={(e) => setLastNameInput(e.target.value)}
                  onKeyDown={handleEnterKeyPress}
                />
              ) : (
                <div className="w-12/12">{lastNameInput}</div>
              )}
            </td>
          </tr>
          <tr>
            <td className="pt-3">Email:</td>
            <td className="pt-3">
              {<div className="w-12/12 break-words">{tutor.email}</div>}
            </td>
          </tr>
          <tr>
            <td className="pt-3">Phone:</td>
            <td className="pt-3">
              {isEditing ? (
                <PhoneInput
                  country={"us"}
                  value={phoneInput}
                  onChange={handlePhoneChange}
                  prefix="+"
                  inputClass="tutor-phone-input-edit"
                  placeholder="+1 (555) 555-5555"
                  dropdownStyle={{ display: "none" }}
                  countryCodeEditable={false}
                  onKeyDown={handleEnterKeyPress}
                />
              ) : phoneInput === null ||
                phoneInput === "1" ||
                phoneInput === "" ||
                phoneInput === undefined ? (
                ""
              ) : (
                <PhoneInput
                  country={"us"}
                  value={phoneInput}
                  onChange={handlePhoneChange}
                  prefix="+"
                  inputClass="tutor-phone-input-view"
                  placeholder="+1 (555) 555-5555"
                  dropdownStyle={{ display: "none" }}
                  countryCodeEditable={false}
                  disabled={true}
                  onKeyDown={handleEnterKeyPress}
                />
              )}
            </td>
          </tr>
          <tr>
            <td className="pt-3">Birthday:</td>
            <td className="pt-3">
              {isEditing ? (
                <DatePicker
                  oneTap
                  defaultValue={
                    new Date(
                      new Date(birthdayInput).setDate(
                        new Date(birthdayInput).getDate() + 1
                      )
                    )
                  }
                  onChange={(value) =>
                    value !== null
                      ? setBirthdayInput(moment.utc(value).format("YYYY-MM-DD"))
                      : setBirthdayInput("")
                  }
                  onKeyDown={(e) => e.preventDefault()}
                  format="yyyy-MM-dd"
                  size="xs"
                  cleanable={true}
                  className="tutorDatePicker"
                  block
                />
              ) : (
                <div className="w-12/12">
                  {birthdayInput && birthdayInput !== null ? birthdayInput : ""}
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td className="pt-3">Address:</td>
            <td className="pt-3">
              {isEditing ? (
                <input
                  id="address"
                  value={addressInput}
                  className="w-7/12 border border-black rounded-md pl-1 h-6"
                  onChange={(e) => setAddressInput(e.target.value)}
                  onKeyDown={handleEnterKeyPress}
                />
              ) : (
                <div className="w-12/12">{addressInput}</div>
              )}
            </td>
          </tr>
        </table>

        {isEditing && (
          <div className="flex flex-row gap-2">
            <Button
              color={ButtonColor.SKYBLUE}
              onClick={updateTutorInfo}
              extraClasses="w-20 h-7"
            >
              Save
            </Button>

            <Button
              color={ButtonColor.RED}
              onClick={handleCancel}
              extraClasses="w-20 h-7"
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
