import { DialogClose } from "@radix-ui/react-dialog";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import { ApiError, InviteType, TutorsService } from "client/openapi";
import { DialogAction, DialogActions, DialogContent } from "components/Dialog";
import { useContext, useEffect, useState } from "react";
import { APIResponse, AccountBase, PageStatus } from "types";
import Notifications from "util/notifications";
import "./index.css";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";
import { ButtonColor } from "components/Button";
import PhoneInput from "react-phone-input-2";

const emptyAccount = {
  email: "",
  phone_number: "",
  first_name: "",
  last_name: "",
};

function InviteTutorDialog({
  fetchTutors,
  currentPage,
}: {
  fetchTutors: (page: number) => Promise<void>;
  currentPage: number;
}) {
  const { currently_selected_organization, account } = useContext(
    OrgRolesAndAccountContext
  );

  const [newTutor, setNewTutor] = useState<AccountBase>(emptyAccount);
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<APIResponse>();

  function inviteTutor() {
    setStatus(PageStatus.LOADING);

    TutorsService.inviteTutor({
      requestBody: {
        first_name: newTutor.first_name,
        last_name: newTutor.last_name,
        phone_number: newTutor.phone_number ? newTutor.phone_number : undefined,
        email: newTutor.email,
        org_invited_by: currently_selected_organization as number,
        acct_invited_by: account?.reference_id as string,
        type: InviteType.TUTOR,
      },
    })
      .then(() => {
        setStatus(PageStatus.SUCCESS);
        Notifications.success(`Tutor invited!`);
        fetchTutors(currentPage);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError(e);
      });
  }

  const handlePhoneChange = (newPhone: string) => {
    setNewTutor({
      ...newTutor,
      phone_number: newPhone,
    });
  };

  function handleUpdate(ev) {
    const value = ev.target.value;

    setNewTutor({
      ...newTutor,
      [ev.target.name]: value,
    });
  }

  function handleSubmit() {
    return async (ev) => {
      ev.preventDefault();
      await inviteTutor();
    };
  }

  function clearForm() {
    setNewTutor(emptyAccount);
    setStatus(undefined);
  }

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  return (
    <>
      <DialogContent className="dialog-content" onClose={clearForm}>
        <div className="invite-dialog">
          <h2 className="invite-dialog--title">Invite a Tutor</h2>

          {status === PageStatus.SUCCESS ? (
            <>
              <p className="mb-4">
                <b>
                  {newTutor.first_name} {newTutor.last_name}
                </b>{" "}
                has been successfully invited!
              </p>

              <DialogActions>
                <DialogAction
                  primary={true}
                  color={ButtonColor.SKYBLUE}
                  onClick={clearForm}
                >
                  Invite Another <ArrowRightIcon />
                </DialogAction>

                <DialogClose asChild>
                  <DialogAction color={ButtonColor.SKYBLUE} onClick={clearForm}>
                    Close
                  </DialogAction>
                </DialogClose>
              </DialogActions>
            </>
          ) : (
            <>
              <form onSubmit={handleSubmit()}>
                <label htmlFor="first_name" className="input-label">
                  First Name
                  <input
                    id="first_name"
                    name="first_name"
                    type="text"
                    className="input"
                    placeholder="Amy"
                    value={newTutor.first_name}
                    onChange={handleUpdate}
                    disabled={status === PageStatus.LOADING}
                    required
                  />
                </label>

                <label htmlFor="last_name" className="mt-3 input-label">
                  Last Name
                  <input
                    id="last_name"
                    name="last_name"
                    type="text"
                    className="input"
                    placeholder="Smith"
                    value={newTutor.last_name}
                    onChange={handleUpdate}
                    disabled={status === PageStatus.LOADING}
                    required
                  />
                </label>

                <label htmlFor="email" className="mt-3 input-label">
                  Email Address
                  <input
                    id="email"
                    name="email"
                    type="email"
                    className="input"
                    placeholder="amy@example.com"
                    value={newTutor.email}
                    onChange={handleUpdate}
                    disabled={status === PageStatus.LOADING}
                    required
                  />
                </label>

                <label htmlFor="phone_number" className="mt-3 input-label">
                  Phone Number (optional)
                  <PhoneInput
                    country={"us"}
                    value={newTutor.phone_number}
                    onChange={handlePhoneChange}
                    prefix="+"
                    inputClass="input"
                    placeholder="+1 (555) 555-5555"
                    dropdownStyle={{ display: "none" }}
                    countryCodeEditable={false}
                  />
                </label>
                <DialogActions>
                  <DialogAction
                    primary={true}
                    color={ButtonColor.SKYBLUE}
                    type="submit"
                    disabled={status === PageStatus.LOADING}
                  >
                    {status === PageStatus.LOADING ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      <>
                        Invite <ArrowRightIcon />
                      </>
                    )}
                  </DialogAction>

                  <DialogClose asChild>
                    <DialogAction
                      color={ButtonColor.SKYBLUE}
                      onClick={clearForm}
                    >
                      Cancel
                    </DialogAction>
                  </DialogClose>
                </DialogActions>
              </form>
            </>
          )}
        </div>
      </DialogContent>
    </>
  );
}

export default InviteTutorDialog;
