import { ButtonColor } from "components/Button";
import {
  DialogActions,
  DialogAction,
  DialogContent,
  DialogClose,
} from "components/Dialog";
import { MultiValue } from "react-select";
import { Select } from "components/Select";
import {
  OrganizationMeetingLocationResponse,
  OrganizationSubjectPublicResponse,
  StudentLimitedResponse,
  TutorLimitedResponse,
} from "client/openapi";
import { useState } from "react";
import { concatenateSubject } from "util/concatenateSubject";
import { concatenateName } from "util/concatenateName";

function FilterCalendarDialog({
  subjects,
  tutors,
  students,
  locations,
  submitFilters,
}: {
  subjects: OrganizationSubjectPublicResponse[];
  tutors: TutorLimitedResponse[];
  students: StudentLimitedResponse[];
  locations: OrganizationMeetingLocationResponse[];
  submitFilters: (
    tutors?: TutorLimitedResponse[],
    students?: StudentLimitedResponse[],
    subjects?: OrganizationSubjectPublicResponse[],
    locations?: OrganizationMeetingLocationResponse[],
    locationAddresses?: OrganizationMeetingLocationResponse[],
    locationCities?: OrganizationMeetingLocationResponse[],
    locationStates?: OrganizationMeetingLocationResponse[]
  ) => void;
}) {
  const [selectedSubjects, setSelectedSubjects] = useState<
    OrganizationSubjectPublicResponse[]
  >([]);
  const [selectedTutors, setSelectedTutors] = useState<TutorLimitedResponse[]>(
    []
  );
  const [selectedStudents, setSelectedStudents] = useState<
    StudentLimitedResponse[]
  >([]);

  const [selectedLocations, setSelectedLocations] = useState<
    OrganizationMeetingLocationResponse[]
  >([]);

  const [selectedLocationAddresses, setSelectedLocationAddresses] = useState<
    OrganizationMeetingLocationResponse[]
  >([]);
  const [selectedLocationCities, setSelectedLocationCities] = useState<
    OrganizationMeetingLocationResponse[]
  >([]);
  const [selectedLocationStates, setSelectedLocationStates] = useState<
    OrganizationMeetingLocationResponse[]
  >([]);

  const changeTutor = (newValue: MultiValue<TutorLimitedResponse>) => {
    setSelectedTutors(newValue as TutorLimitedResponse[]);
  };

  const changeStudent = (newValue: MultiValue<StudentLimitedResponse>) => {
    students && setSelectedStudents(newValue as StudentLimitedResponse[]);
  };

  const changeSubject = (
    newValue: MultiValue<OrganizationSubjectPublicResponse>
  ) => {
    setSelectedSubjects(newValue as OrganizationSubjectPublicResponse[]);
  };

  const changeLocation = (
    newValue: MultiValue<OrganizationMeetingLocationResponse>
  ) => {
    setSelectedLocations(newValue as OrganizationMeetingLocationResponse[]);
  };

  const changeLocationAddress = (
    newValue: MultiValue<OrganizationMeetingLocationResponse>
  ) => {
    setSelectedLocationAddresses(
      newValue as OrganizationMeetingLocationResponse[]
    );
  };

  const changeLocationCity = (
    newValue: MultiValue<OrganizationMeetingLocationResponse>
  ) => {
    setSelectedLocationCities(
      newValue as OrganizationMeetingLocationResponse[]
    );
  };

  const changeLocationState = (
    newValue: MultiValue<OrganizationMeetingLocationResponse>
  ) => {
    setSelectedLocationStates(
      newValue as OrganizationMeetingLocationResponse[]
    );
  };

  const handleSubmit = () => {
    submitFilters(
      selectedTutors,
      selectedStudents,
      selectedSubjects,
      selectedLocations,
      selectedLocationAddresses,
      selectedLocationCities,
      selectedLocationStates
    );
  };

  return (
    <>
      <DialogContent className="dialog-content">
        <div className="invite-dialog">
          <h2 className="invite-dialog--title">Filter Calendar</h2>

          <label htmlFor="tutors" className="mt-4 input-label">
            Tutors
            <Select
              id="tutors"
              options={tutors}
              value={selectedTutors}
              getOptionLabel={(t) => `${concatenateName(t)}`}
              getOptionValue={(t) => t.id.toString()}
              placeholder={tutors ? "Select..." : "Loading..."}
              onChange={changeTutor}
              isMulti
            />
          </label>

          <label htmlFor="students" className="mt-4 input-label">
            Students
            <Select
              id="students"
              options={students}
              value={selectedStudents}
              getOptionLabel={(t) => `${concatenateName(t)}`}
              getOptionValue={(t) => t.id.toString()}
              placeholder={students ? "Select..." : "Loading..."}
              onChange={changeStudent}
              isMulti
            />
          </label>

          <label htmlFor="subject" className="mt-4 input-label">
            Subject
            <Select
              id="subject"
              options={subjects}
              value={selectedSubjects}
              getOptionLabel={(s) => `${concatenateSubject(s)}`}
              getOptionValue={(s) => s.id.toString()}
              placeholder="Select..."
              isMulti
              onChange={changeSubject}
            />
          </label>

          <label htmlFor="location" className="mt-4 input-label">
            Location Name
            <Select
              id="location"
              options={locations}
              value={selectedLocations}
              getOptionLabel={(l) => l.name}
              getOptionValue={(l) => l.id.toString()}
              placeholder="Select..."
              isMulti
              onChange={changeLocation}
            />
          </label>

          <label htmlFor="location-address" className="mt-4 input-label">
            Location Address
            <Select
              id="location-address"
              options={locations
                .filter(
                  (l) => l.address_line_1 && l.address_line_1.trim() !== ""
                )
                .filter(
                  (l, i, self) =>
                    i ===
                    self.findIndex((t) => t.address_line_1 === l.address_line_1)
                )}
              value={selectedLocationAddresses}
              getOptionLabel={(l) => l.address_line_1}
              getOptionValue={(l) => l.address_line_1}
              placeholder="Select..."
              isMulti
              onChange={changeLocationAddress}
            />
          </label>

          <label htmlFor="location-city" className="mt-4 input-label">
            Location City
            <Select
              id="location-city"
              options={locations
                .filter((l) => l.city && l.city.trim() !== "")
                .filter(
                  (l, i, self) => i === self.findIndex((t) => t.city === l.city)
                )}
              value={selectedLocationCities}
              getOptionLabel={(l) => l.city}
              getOptionValue={(l) => l.city}
              placeholder="Select..."
              isMulti
              onChange={changeLocationCity}
            />
          </label>

          <label htmlFor="location-state" className="mt-4 input-label">
            Location State
            <Select
              id="location-state"
              options={locations
                .filter((l) => l.state && l.state.trim() !== "")
                .filter(
                  (l, i, self) =>
                    i === self.findIndex((t) => t.state === l.state)
                )}
              value={selectedLocationStates}
              getOptionLabel={(l) => l.state}
              getOptionValue={(l) => l.state}
              placeholder="Select..."
              isMulti
              onChange={changeLocationState}
            />
          </label>

          <DialogActions>
            <DialogClose asChild>
              <DialogAction
                primary={true}
                color={ButtonColor.GREEN}
                type="submit"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Apply
              </DialogAction>
            </DialogClose>

            <DialogClose asChild>
              <DialogAction color={ButtonColor.BLACK}>Close</DialogAction>
            </DialogClose>
          </DialogActions>
        </div>
      </DialogContent>
    </>
  );
}

export default FilterCalendarDialog;
