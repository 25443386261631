import { DialogContent } from "components/Dialog";
import { useEffect, useState } from "react";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import { PaymentsService, PayoutInitatior } from "client/openapi";
import { APIResponse, PageStatus } from "types";
import Notifications from "util/notifications";

import "./index.css";
import SuccessDialog from "components/SuccessDialog";

export default function BalanceDetails({
  availableBalance,
  pendingBalance,
  orgId,
}: {
  availableBalance: number;
  pendingBalance: number;
  orgId: number;
}) {
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<APIResponse>();
  const [
    makeSureUserDoesntSpamChargeButton,
    setMakeSureUserDoesntSpamChargeButton,
  ] = useState<boolean>(false);

  const handlePayout = async () => {
    setMakeSureUserDoesntSpamChargeButton(true);

    // charging for less than 5 dollars
    if (availableBalance <= 0) {
      setError({
        message: "Payout must be greater than $0.00!",
      });
      setMakeSureUserDoesntSpamChargeButton(false);
      return;
    }

    await PaymentsService.createPayout({
      requestBody: {
        amount: availableBalance,
        payout_initiator: PayoutInitatior.ORGANIZATION,
        org_id: orgId,
      },
    })
      .then(() => {
        setStatus(PageStatus.SUCCESS);
      })
      .catch((err) => {
        setError(err);
        setStatus(PageStatus.ERROR);
        setMakeSureUserDoesntSpamChargeButton(false);
      });
  };

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  if (status === PageStatus.SUCCESS) {
    return <SuccessDialog message="Payout successful." />;
  }

  return (
    <DialogContent className="custom-student-price-dialog">
      <div className="mt-3 font-semibold text-center header text-lg">
        Your Balance
      </div>
      <div className="text-2xl font-semibold mt-7 mb-5">
        Available Balance:{" "}
        <span style={{ color: "#00A8CC" }}>
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(availableBalance / 100)}
        </span>
      </div>
      <div className="text-2xl font-semibold">
        <span>Pending Balance: </span>
        <span style={{ color: "#ADD8E6" }}>
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(pendingBalance / 100)}
        </span>
      </div>
      <div>
        <div
          style={{ display: "flex", justifyContent: "center" }}
          className="mt-10"
        >
          <Button
            color={
              availableBalance <= 0 || makeSureUserDoesntSpamChargeButton
                ? ButtonColor.GRAY
                : ButtonColor.SKYBLUE
            }
            size={ButtonSize.DEFAULT}
            disabled={
              availableBalance <= 0 || makeSureUserDoesntSpamChargeButton
            }
            onClick={handlePayout}
          >
            Payout{" "}
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(availableBalance / 100)}
          </Button>
        </div>
      </div>
    </DialogContent>
  );
}
