import moment from "moment";
import { useEffect, useState } from "react";
import "../index.css";
import SimpleSelect, {
  SimpleNumericCreatableSelect,
} from "components/Select/simple";
import { CreatableSelect, Select } from "components/Select";
import {
  MeetingAdminResponse,
  MeetingLimitedResponse,
  MeetingTutorLimitedResponse,
  MeetingTutorAdminResponse,
  SessionFrequency,
  TutorAdminResponse,
  TutorLimitedResponse,
  OrganizationMeetingLocationResponse,
} from "client/openapi";
import AvailabilityDayPicker from "components/AvailabilityDayPicker";
import DayInput from "components/DayPicker";
import { MeetingDetails } from "..";
import { LocationsService } from "client/openapi";
import { Tooltip, TooltipContent, TooltipTrigger } from "components/Tooltip";
import { InfoCircledIcon } from "@radix-ui/react-icons";

const DATE_FORMAT = "YYYY-MM-DD";
const TIME_FORMAT = "h:mma";

export default function EditMeetingDetails({
  id,
  tutors,
  event,
  currentMeetingDetails,
  orginalMeetingDetails,
  saveMeetingDetails,
  setIsAvailabilitySidebarOpen,
  setAvailabilitySidebarDate,
  setIndex,
  locationOptions,
}: {
  id: number;
  tutors: (
    | MeetingTutorLimitedResponse
    | MeetingTutorAdminResponse
    | TutorLimitedResponse
    | TutorAdminResponse
  )[];
  event?: MeetingAdminResponse | MeetingLimitedResponse;
  currentMeetingDetails: MeetingDetails;
  orginalMeetingDetails: MeetingDetails;
  saveMeetingDetails: (meeting: MeetingDetails) => void;
  setIsAvailabilitySidebarOpen: (open: boolean) => void;
  setAvailabilitySidebarDate: (date: string) => void;
  setIndex: (index: number) => void;
  locationOptions?: OrganizationMeetingLocationResponse[];
}) {
  const [endTimeOptions, setEndTimeOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [locationAvailability, setLocationAvailability] = useState<{
    available?: boolean;
    availableLocations?: OrganizationMeetingLocationResponse[];
  }>({});
  const [locationValue, setLocationValue] = useState<
    | OrganizationMeetingLocationResponse
    | { value: string; label: string; __isNew__: boolean }
    | undefined
  >();
  const [originalLocation] = useState<number | string | undefined>(
    orginalMeetingDetails.location
      ? orginalMeetingDetails.location.id
      : orginalMeetingDetails.other_location
  );
  const getStartTime = () =>
    moment(currentMeetingDetails.start).format(TIME_FORMAT);
  const getEndTime = () => {
    if (currentMeetingDetails.duration < 5) return "Choose . . .";
    return moment(handleCheckModernDate(currentMeetingDetails.start))
      .add(currentMeetingDetails.duration, "minutes")
      .format(TIME_FORMAT);
  };

  const handleCheckModernDate = (date: string) => {
    return date.startsWith("0")
      ? "2024-" + date.split("-").slice(1).join("-")
      : date;
  };

  const checkLocationAvailability = (
    selectedLocation: OrganizationMeetingLocationResponse | undefined,
    start: string,
    customEndTime?: string
  ) => {
    if (!selectedLocation || !selectedLocation.id) {
      setLocationAvailability({});
      return;
    }

    const meetingStart = moment(start);
    const meetingEnd = customEndTime
      ? moment(customEndTime)
      : moment(start).add(currentMeetingDetails.duration, "minutes");

    if (!meetingStart.isValid() || !meetingEnd.isValid()) {
      setLocationAvailability({});
      return;
    }

    LocationsService.getLocationAvailability({
      desiredLocationId: selectedLocation.id,
      orgId: currentMeetingDetails.org_id,
      desiredStart: meetingStart.toISOString(),
      desiredEnd: meetingEnd.toISOString(),
      editingMeetingId: orginalMeetingDetails.meeting_id
        ? orginalMeetingDetails.meeting_id
        : undefined,
    })
      .then((resp) => {
        setLocationAvailability({
          available: resp.desired_location_available,
          availableLocations: resp.available_locations,
        });
      })
      .catch(() => {
        setLocationAvailability({});
      });
  };

  const handleChangeStartDate = (date: Date) => {
    const startTime = currentMeetingDetails.start.split("T")[1];
    const newStart = moment(date).format(DATE_FORMAT) + "T" + startTime;
    const isOriginalDST = moment(currentMeetingDetails.start).isDST();
    const isDST = moment(newStart).isDST();
    const finalStart =
      isDST !== isOriginalDST
        ? isDST
          ? moment(newStart).subtract(1, "hour").format()
          : moment(newStart).add(1, "hour").format()
        : newStart;

    const updatedMeeting = { ...currentMeetingDetails, start: finalStart };
    saveMeetingDetails(updatedMeeting);
    setAvailabilitySidebarDate(finalStart);
    setIndex(id);
  };

  const handleChangeEndDate = (date: Date) => {
    let updatedMeeting: MeetingDetails;
    if (currentMeetingDetails.frequency === SessionFrequency.SINGLE) {
      updatedMeeting = {
        ...currentMeetingDetails,
        ends_on_or_before: undefined,
      };
    } else {
      updatedMeeting = {
        ...currentMeetingDetails,
        ends_on_or_before: moment(date).format(),
      };
    }
    saveMeetingDetails(updatedMeeting);
  };

  const handleChangeStartTime = (ev: { value: string }) => {
    // const isCurrentlyDST = moment().isDST();
    // const isMeetingDST = moment(currentMeetingDetails.start).isDST();
    // const startTime = moment(ev.value, TIME_FORMAT)
    //   .subtract(isCurrentlyDST !== isMeetingDST ? 60 : 0, "minutes")
    //   .format()
    //   .split("T")[1];
    // const newStart =
    //   moment(currentMeetingDetails.start).format(DATE_FORMAT) + "T" + startTime;

    const updatedMeeting = {
      ...currentMeetingDetails,
      start:
        moment(currentMeetingDetails.start).format(DATE_FORMAT) +
        "T" +
        moment(ev.value, TIME_FORMAT).format().split("T")[1],
    };

    saveMeetingDetails(updatedMeeting);
  };

  const handleChangeDuration = (ev: { value: number }) => {
    const updatedMeeting = {
      ...currentMeetingDetails,
      duration: ev.value,
    };
    saveMeetingDetails(updatedMeeting);
    setIsAvailabilitySidebarOpen(true);
  };

  const getTimeOptions = () => {
    const options: { label: string; value: string }[] = [];
    for (let i = 0; i < 24; i++) {
      ["00", "15", "30", "45"].forEach((m) => {
        const time = moment({ hour: i, minute: Number(m) }).format(TIME_FORMAT);
        options.push({ label: time, value: time });
      });
    }
    return options;
  };

  const getEndTimeOptions = () => {
    const startTimeIndex = getTimeOptions().findIndex(
      (time) => time.value === getStartTime()
    );
    setEndTimeOptions(getTimeOptions().slice(startTimeIndex + 1));
  };

  const getRepeatOptions = () => [
    { value: SessionFrequency.SINGLE, label: "Never" },
    { value: SessionFrequency.EVERY_7_DAYS, label: "Every 7 days" },
    { value: SessionFrequency.EVERY_14_DAYS, label: "Every 14 days" },
    { value: SessionFrequency.EVERY_21_DAYS, label: "Every 21 days" },
    { value: SessionFrequency.EVERY_28_DAYS, label: "Every 28 days" },
  ];

  const changeFrequency = (frequency: { value: SessionFrequency }) => {
    const updatedMeeting = {
      ...currentMeetingDetails,
      frequency: frequency.value,
    };
    saveMeetingDetails(updatedMeeting);
  };

  useEffect(() => {
    getEndTimeOptions();
  }, [currentMeetingDetails.start]);

  const handleLocationChange = (newValue: any) => {
    let updatedMeeting: MeetingDetails;

    // If we have an existing location selected
    if (locationOptions && newValue && !newValue.__isNew__) {
      updatedMeeting = {
        ...currentMeetingDetails,
        location: newValue,
        other_location: undefined,
      };
      saveMeetingDetails(updatedMeeting);
    } else if (newValue && newValue.__isNew__) {
      // Custom location
      updatedMeeting = {
        ...currentMeetingDetails,
        location: undefined,
        other_location: newValue.value,
      };
      saveMeetingDetails(updatedMeeting);
      setLocationAvailability({});
    } else {
      // Cleared the field
      updatedMeeting = {
        ...currentMeetingDetails,
        location: undefined,
        other_location: "",
      };
      saveMeetingDetails(updatedMeeting);
      setLocationAvailability({});
    }
  };

  useEffect(() => {
    if (currentMeetingDetails.location && currentMeetingDetails.location.id) {
      const start = currentMeetingDetails.start;
      const end = moment(start)
        .add(currentMeetingDetails.duration, "minutes")
        .toISOString();
      checkLocationAvailability(currentMeetingDetails.location, start, end);
    } else {
      setLocationAvailability({});
    }
  }, [
    currentMeetingDetails.location,
    currentMeetingDetails.start,
    currentMeetingDetails.duration,
    currentMeetingDetails.org_id,
  ]);

  useEffect(() => {
    if (currentMeetingDetails.location) {
      setLocationValue({
        value: currentMeetingDetails.location.id.toString(),
        label: currentMeetingDetails.location.name,
        __isNew__: false,
      });
    } else if (currentMeetingDetails.other_location) {
      setLocationValue({
        value: currentMeetingDetails.other_location,
        label: currentMeetingDetails.other_location,
        __isNew__: true,
      });
    } else {
      setLocationValue(undefined);
    }
  }, [currentMeetingDetails.location, currentMeetingDetails.other_location]);

  return (
    <div className="grid grid-flow-row bg-gray-100 rounded-lg p-4 mr-2">
      <div className="flex flex-wrap gap-2 text-sm">
        <div className="mr-2 mt-1">
          <p className="text-xs font-bold">Date</p>
          <AvailabilityDayPicker
            id={"start-date" + id}
            event={event}
            handleChangeStartDate={handleChangeStartDate}
            setIsAvailabilitySidebarOpen={setIsAvailabilitySidebarOpen}
            tutors={tutors}
            meetingDetails={currentMeetingDetails}
            required
            classes="py-2 px-1 m-0 border-green-300 border-0 border-b-2 bg-transparent text-sm"
          />
        </div>
        <div className="mr-2 mt-1">
          <p className="text-xs font-bold">Duration</p>
          <SimpleNumericCreatableSelect
            options={Array.from({ length: 11 }, (_, i) => ({
              value: (i + 1) * 15,
              label: `${(i + 1) * 15} min`,
            }))}
            value={{
              label: `${currentMeetingDetails.duration} min`,
              value: currentMeetingDetails.duration,
            }}
            onChange={handleChangeDuration}
          />
        </div>
        <div className="mr-2 mt-1">
          <p className="text-xs font-bold">Time</p>
          <SimpleSelect
            id="start-time"
            options={getTimeOptions()}
            value={{
              value: moment(
                handleCheckModernDate(currentMeetingDetails.start)
              ).format(TIME_FORMAT),
              label: moment(
                handleCheckModernDate(currentMeetingDetails.start)
              ).format(TIME_FORMAT),
            }}
            placeholder="XX:XXam"
            onChange={handleChangeStartTime}
            required
          />
        </div>
        <div className="mr-2 mt-1 min-w-[8rem]">
          <p className="text-xs font-bold">Repeats</p>
          <Select
            id="repeat-on"
            options={getRepeatOptions()}
            value={getRepeatOptions().find(
              (opt) => opt.value === currentMeetingDetails.frequency
            )}
            placeholder="Repeats..."
            onChange={changeFrequency}
            isDisabled={!!event}
            required
          />
        </div>
        {currentMeetingDetails.frequency !== SessionFrequency.SINGLE && (
          <div className="mt-1">
            <p className="text-xs font-bold">Ends on or before</p>
            <DayInput
              id={"end-date" + id}
              value={
                currentMeetingDetails.ends_on_or_before
                  ? moment(
                      currentMeetingDetails.ends_on_or_before
                    ).toISOString()
                  : ""
              }
              classes="py-2 px-1 m-0 border-green-300 border-0 border-b-2 bg-transparent text-sm"
              setValue={handleChangeEndDate}
              required
              min={moment(currentMeetingDetails.start)
                .add(1, "day")
                .format(DATE_FORMAT)}
            />
          </div>
        )}
      </div>
      <div className="col-span-12 grid-rows-5 mt-4 relative">
        <div className="flex items-center gap-2">
          <p className="pb-2 text-xs font-bold">Location</p>
          {currentMeetingDetails.location &&
            locationAvailability.available === true && (
              <span className="text-green-600 text-xs font-bold mb-2">
                available
              </span>
            )}
          {currentMeetingDetails.location &&
            locationAvailability.available === false && (
              <div className="flex items-center gap-1">
                <span className="text-red-600 text-xs font-bold mb-2">
                  not available
                </span>
                <Tooltip>
                  <TooltipTrigger>
                    <InfoCircledIcon className="text-gray-500 hover:text-gray-700 cursor-pointer mb-2 ml-1" />
                  </TooltipTrigger>
                  <TooltipContent>
                    Available locations:
                    <ul className="list-disc ml-1 mt-2 text-xs">
                      {locationAvailability.availableLocations?.map((loc) => (
                        <li key={loc.id} className="text-sm">
                          • {loc.name}
                        </li>
                      ))}
                    </ul>
                  </TooltipContent>
                </Tooltip>
              </div>
            )}
        </div>
        <CreatableSelect
          id="location-select"
          placeholder="Select or type a location..."
          options={locationOptions?.map((loc) => ({
            ...loc,
            value: loc.id,
            label: loc.name,
          }))}
          value={locationValue}
          onChange={handleLocationChange}
          isClearable
          isSearchable
          formatCreateLabel={(inputValue) =>
            `Use custom location "${inputValue}"`
          }
          allowCreateWhileLoading={false}
          components={{
            DropdownIndicator: undefined,
          }}
        />
      </div>
    </div>
  );
}
