import { useEffect, useState } from "react";
import moment from "moment";
import { StatisticsService, MeetingStats } from "client/openapi";
import Notifications from "util/notifications";
import { saveAs } from "file-saver";
import { DateRange } from "util/groupMeetingStats";
import { ButtonColor, ButtonSize } from "components/Button";
import {
  Dialog,
  DialogTrigger,
  DialogAction,
  DialogContent,
} from "components/Dialog";
import { SizeIcon } from "@radix-ui/react-icons";
import {
  ToggleGroupRoot,
  ToggleGroupItem,
  ToggleColor,
} from "components/ToggleGroup";
import StatsAreaChart from "components/StatsAreaChart";
import { generateAttendanceChartDPByDateRange } from "util/groupMeetingDurations";
import { Tooltip, TooltipContent, TooltipTrigger } from "components/Tooltip";
import DayInput from "components/DayInput";
import { TutorLimitedResponse } from "client/openapi";

function AttendanceChart({
  meetingsRange,
  datapoints,
}: {
  meetingsRange: string;
  datapoints: any[];
}) {
  let formatter;
  switch (meetingsRange) {
    case DateRange.WEEK:
      formatter = (value: number) => moment(value).format("ddd");
      break;
    case DateRange.MONTH:
      formatter = (value: number) => moment(value).format("DD");
      break;
    case DateRange.YEAR:
      formatter = (value: number) => moment(value).format("MMM");
      break;
    default:
      formatter = (value: number) => moment(value).format("MM/YYYY");
      break;
  }
  return (
    <StatsAreaChart
      data={datapoints}
      xKey="time"
      xDomain={[datapoints[0].time, datapoints[datapoints.length - 1].time]}
      xAxisLabel="Time"
      yKey="hours"
      yAxisLabel="Hours of Tutoring"
      formatter={formatter}
    />
  );
}

export default function TutorAttendance({
  tutor,
  meetings,
}: {
  tutor: TutorLimitedResponse;
  meetings: MeetingStats;
}) {
  const [meetingsRange, setMeetingsRange] = useState<string>(DateRange.YEAR);
  const [chartDP, setChartDP] = useState<any[]>();

  useEffect(() => {
    let start, until;
    if (meetingsRange === DateRange.WEEK) {
      start = moment().subtract(1, "week").toISOString();
      until = moment().toISOString();
    } else if (meetingsRange === DateRange.MONTH) {
      start = moment().subtract(1, "month").toISOString();
      until = moment().toISOString();
    } else if (meetingsRange === DateRange.YEAR) {
      start = moment().subtract(1, "year").toISOString();
      until = moment().toISOString();
    }

    setChartDP(
      generateAttendanceChartDPByDateRange(
        Object.values(meetings.statistics),
        meetingsRange,
        start,
        until
      )
    );
  }, [meetingsRange]);

  return (
    <div className="font-montserrat w-full h-full">
      <div className="flex flex-row items-center justify-between h-1/5">
        <h2 className="font-bold text-xl">Hours Worked</h2>

        <div className="flex flex-row items-center gap-2">
          <ToggleGroupRoot
            defaultValue="year"
            ariaLabel="Date range"
            color={ToggleColor.SKYBLUE}
            value={meetingsRange}
            setValue={setMeetingsRange}
            allowDeselect={false}
          >
            <ToggleGroupItem
              value={DateRange.WEEK}
              ariaLabel="Last week"
              className="h-8 w-8"
            >
              W
            </ToggleGroupItem>
            <ToggleGroupItem
              value={DateRange.MONTH}
              ariaLabel="Last month"
              className="h-8 w-8"
            >
              M
            </ToggleGroupItem>
            <ToggleGroupItem
              value={DateRange.YEAR}
              ariaLabel="Last year"
              className="h-8 w-8"
            >
              Y
            </ToggleGroupItem>
          </ToggleGroupRoot>

          <Dialog>
            <DialogTrigger>
              <Tooltip>
                <TooltipTrigger>
                  <SizeIcon cursor="pointer" className="h-7 w-7" />
                </TooltipTrigger>

                <TooltipContent>Expand</TooltipContent>
              </Tooltip>
            </DialogTrigger>

            {meetings && chartDP && (
              <ExpandedChartDialog
                tutor={tutor}
                initialMeetings={meetings}
                initialChartDP={chartDP}
              />
            )}
          </Dialog>
        </div>
      </div>

      <div className="h-4/5 w-full">
        {chartDP && (
          <AttendanceChart meetingsRange={meetingsRange} datapoints={chartDP} />
        )}
      </div>
    </div>
  );
}

function ExpandedChartDialog({
  tutor,
  initialMeetings,
  initialChartDP,
}: {
  tutor: TutorLimitedResponse;
  initialMeetings: MeetingStats;
  initialChartDP: any[];
}) {
  const [meetings, setMeetings] = useState<MeetingStats>(initialMeetings);
  const [meetingsRange, setMeetingsRange] = useState<string>(DateRange.YEAR);
  const [chartDP, setChartDP] = useState<any[]>(initialChartDP);
  const [start, setStart] = useState<number>(
    moment().subtract(1, "year").valueOf()
  );
  const [end, setEnd] = useState<number>(moment().valueOf());

  async function fetchMeetings(start: string, until: string) {
    await StatisticsService.getTutorMeetingStats({
      tutorId: tutor.id,
      start: start,
      until: until,
      tz: moment.tz.guess(true),
    }).then((data) => {
      setMeetings(data);
      setChartDP(
        generateAttendanceChartDPByDateRange(
          Object.values(data.statistics),
          meetingsRange,
          start,
          until
        )
      );
    });
  }

  function exportAttendance() {
    if (meetings) {
      const csv = [
        ["Date", "Count", "Hours", "Subjects"],
        ...meetings.statistics.map((stat) => [
          stat.date,
          stat.count,
          stat.hours,
          stat.subjects,
        ]),
      ].join("\n");
      const blob = new Blob([csv], { type: "text/csv" });
      saveAs(blob, `${tutor.first_name} ${tutor.last_name}'s Attendance.csv`);
      Notifications.success("Attendance exported successfully.");
    }
  }

  function handleStartChange(start: number) {
    setStart(start);
    setMeetingsRange("");
  }

  function handleEndChange(end: number) {
    setEnd(end);
    setMeetingsRange("");
  }

  useEffect(() => {
    if (meetingsRange === DateRange.WEEK) {
      setStart(moment().subtract(1, "week").valueOf());
      setEnd(moment().valueOf());
    } else if (meetingsRange === DateRange.MONTH) {
      setStart(moment().subtract(1, "month").valueOf());
      setEnd(moment().valueOf());
    } else if (meetingsRange === DateRange.YEAR) {
      setStart(moment().subtract(1, "year").valueOf());
      setEnd(moment().valueOf());
    }
  }, [meetingsRange]);

  useEffect(() => {
    fetchMeetings(moment(start).toISOString(), moment(end).toISOString());
  }, [start, end]);

  return (
    <DialogContent
      className="dialog-content dialog-content--left medium-wide pb-2 px-12 font-montserrat"
      style={{ height: "85vh" }}
      onClose={() => {
        setStart(moment().subtract(1, "year").valueOf());
        setEnd(moment().valueOf());
        setMeetingsRange(DateRange.YEAR);
      }}
    >
      <div className="h-1/5 pb-5 pt-10">
        <h2 className="font-bold text-3xl">Attendance</h2>
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row gap-2 pt-2">
            from
            <DayInput
              id="start-date"
              value={start}
              setValue={handleStartChange}
              classes="border-b w-32 h-7 border-gray-300"
              required={true}
            />
            to
            <DayInput
              id="end-date"
              value={end}
              setValue={handleEndChange}
              min={start + 86400000}
              classes="border-b w-32 h-7 border-gray-300"
              required={true}
            />
          </div>

          <DialogAction
            primary={true}
            color={ButtonColor.SKYBLUE}
            size={ButtonSize.DEFAULT}
            cursor="pointer"
            onClick={exportAttendance}
          >
            Export
          </DialogAction>
        </div>
      </div>

      <div className="h-4/5 flex flex-row pt-3">
        <div className="h-full w-10/12">
          {chartDP && (
            <AttendanceChart
              meetingsRange={meetingsRange}
              datapoints={chartDP}
            />
          )}
        </div>

        <div className="h-full w-2/12 flex flex-col items-end">
          <div className="flex flex-col items-start">
            <div className="pb-2">Show last</div>
            <ToggleGroupRoot
              defaultValue="year"
              ariaLabel="Date range"
              color={ToggleColor.SKYBLUE}
              value={meetingsRange}
              setValue={setMeetingsRange}
              allowDeselect={true}
            >
              <ToggleGroupItem value={DateRange.WEEK} ariaLabel="Last week">
                W
              </ToggleGroupItem>
              <ToggleGroupItem value={DateRange.MONTH} ariaLabel="Last month">
                M
              </ToggleGroupItem>
              <ToggleGroupItem value={DateRange.YEAR} ariaLabel="Last year">
                Y
              </ToggleGroupItem>
            </ToggleGroupRoot>
            <div className="pt-2">from now</div>
          </div>
        </div>
      </div>
    </DialogContent>
  );
}
