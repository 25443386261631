import {
  MeetingStudentLimitedResponse,
  MeetingTutorLimitedResponse,
  OrganizationSubjectPublicResponse,
} from "client/openapi";

export function returnSubjectName(
  subject: OrganizationSubjectPublicResponse
): string {
  if (!subject) {
    return "";
  }
  return subject.name;
}

export function concatenateSubject(
  subject: OrganizationSubjectPublicResponse
): string {
  return subject.name;
}

export function concatenateMeetingDetails(
  subject: OrganizationSubjectPublicResponse,
  tutors: MeetingTutorLimitedResponse[],
  students: MeetingStudentLimitedResponse[]
): string {
  const tutorNames =
    tutors
      .slice(0, 3)
      .map((tutor) => tutor.tutor.first_name)
      .join(", ") + (tutors.length > 3 ? ", ..." : "");
  const studentNames =
    students
      .slice(0, 3)
      .map((student) => student.student.first_name)
      .join(", ") + (students.length > 3 ? ", ..." : "");

  return `${subject.name} · ${tutorNames} · ${studentNames}`;
}
export function concatenateMeetingStudents(
  students: MeetingStudentLimitedResponse[]
): string {
  const studentNames =
    students
      .slice(0, 3)
      .map((student) => student.student.first_name)
      .join(", ") + (students.length > 3 ? ", ..." : "");

  return studentNames;
}

export function concatenateMeetingTutors(
  tutors: MeetingTutorLimitedResponse[]
): string {
  const tutorNames =
    tutors
      .slice(0, 3)
      .map((tutor) => tutor.tutor.first_name)
      .join(", ") + (tutors.length > 3 ? ", ..." : "");

  return tutorNames;
}
